import { createAction, props } from '@ngrx/store';
import { Order } from '@app/order/models';

export enum OrderActionType {
  FETCH = '[Order] Fetch',
  FETCH_FAILURE = '[Order] Fetch Failure',
  LISTEN = '[Order] Listen',
  UPSERT = '[Order] Upsert',
  WEBSOCKET_UPSERT = '[Order] Websocket Upsert',
  WEBSOCKET_CONNECTED = '[Order] Websocket Connected',
  ADD_MANY = '[Order] Add Many',
}

export const orderFetch = createAction(OrderActionType.FETCH, props<{ id: string; silent?: boolean }>());
export const orderFetchFailure = createAction(
  OrderActionType.FETCH_FAILURE,
  props<{ id: string; error: string; silent: boolean }>(),
);
export const orderListen = createAction(OrderActionType.LISTEN, props<{ companyId: string }>());
export const orderUpsert = createAction(OrderActionType.UPSERT, props<{ entity: Order }>());
export const orderWebSocketUpsert = createAction(OrderActionType.WEBSOCKET_UPSERT, props<{ entity: Order }>());
export const orderAddMany = createAction(OrderActionType.ADD_MANY, props<{ entities: Order[] }>());
export const orderWebSocketConnected = createAction(OrderActionType.WEBSOCKET_CONNECTED);
