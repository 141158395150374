import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import Cropper from 'cropperjs';
import { blobToFile } from './helpers/blob-to-file.helper';

@Component({
  selector: 'tc-cropper-dialog',
  templateUrl: './cropper-dialog.component.html',
  styleUrls: ['./cropper-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CropperDialogComponent implements OnDestroy, OnInit {
  public safeUrl?: SafeUrl;
  public cropper?: Cropper;
  public url?: string;

  constructor(
    private cd: ChangeDetectorRef,
    private dialogRef: MatDialogRef<CropperDialogComponent, File>,
    @Inject(MAT_DIALOG_DATA) private data: { file: File },
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    const url = URL.createObjectURL(this.data.file);

    this.dialogRef.afterOpened().subscribe(() => {
      this.url = url;
      this.safeUrl = this.sanitizer.bypassSecurityTrustUrl(url);
      this.cd.markForCheck();
    });
  }

  ngOnDestroy(): void {
    if (this.cropper) {
      this.cropper.destroy();
      this.cropper = undefined;
    }
    if (this.url) {
      URL.revokeObjectURL(this.url);
      this.url = this.safeUrl = undefined;
    }
  }

  public imageLoaded(event: Event): void {
    const image = event.target as HTMLImageElement;
    if (!image.complete) {
      return;
    }

    this.cropper = new Cropper(image, {
      dragMode: 'move',
      aspectRatio: 1,
      background: false,
      highlight: false,
      center: false,
      toggleDragModeOnDblclick: false,
      minCropBoxWidth: 100,
      minCropBoxHeight: 100,
    });
  }

  public select(): void {
    if (!this.cropper) {
      return;
    }

    this.cropper
      .getCroppedCanvas({
        fillColor: '#eeeeee',
        minWidth: 288,
        minHeight: 288,
        imageSmoothingEnabled: true,
        imageSmoothingQuality: 'high',
      })
      .toBlob(
        blob => {
          if (blob) {
            const file = blobToFile(blob, this.data.file, { type: this.data.file.type });
            this.dialogRef.close(file);
          }
        },
        this.data.file.type,
        1,
      );
  }
}
