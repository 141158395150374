<table mat-table [hidden]="!!error" [dataSource]="dataSource" [trackBy]="trackBy" multiTemplateDataRows class="tc-table">

  <ng-content></ng-content>

</table>

<p *ngIf="dataSource.isEmpty$ | async" class="message" i18n="@@shared.table.empty">
  No entries found.
</p>

<p *ngIf="error" class="message">
  <mat-error>{{ error }}</mat-error>
</p>
