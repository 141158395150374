import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BuyerLine, ConfirmedLine, OrderLine, OrderLineRequest } from '@app/order/models';
import { priceDifference } from '@app/order/shared/components/price-difference/helpers/price-difference.helper';
import { dateDifference } from '@app/order/shared/components/date-difference/helpers/date-difference.helper';
import { quantityDifference } from '@app/order/shared/components/quantity-difference/helpers/quantity-difference.helper';
import { isChargeLinesChanged } from '@app/order/shared/components/charge-lines-difference/charge-lines-difference.component';
import { hasOpenSupplierReopenRequest, priceUnitOfMeasureIsoEquals } from '@app/order/util/helper';

@Component({
  selector: 'tc-line-reopen',
  templateUrl: './line-reopen.component.html',
  styleUrls: ['./line-reopen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineReopenComponent {
  @Input()
  orderLine!: OrderLine;

  public get current(): ConfirmedLine | undefined {
    return this.orderLine?.confirmedLine;
  }

  public get buyer(): BuyerLine {
    return this.orderLine.buyerLine;
  }

  public get reopen(): OrderLineRequest | undefined {
    if (hasOpenSupplierReopenRequest(this.orderLine)) {
      return this.orderLine.supplierLine.requests.reopenRequest;
    }

    if (this.orderLine?.buyerLine?.requests?.reopenRequest?.status === 'Open') {
      return this.orderLine.buyerLine.requests.reopenRequest;
    }
  }

  public get isSingleDelivery(): boolean {
    return this.reopen?.deliverySchedule.length === 1 && this.current?.deliverySchedule.length === 1;
  }

  public get singleDeliveryDateChanged(): boolean {
    return this.hasDeliveryDateChanged(0);
  }

  public get singleDeliveryQuantityChanged(): boolean {
    return this.hasDeliveryQuantityChanged(0);
  }

  /* eslint-disable no-prototype-builtins */
  public get deliveryScheduleChanged(): boolean {
    return (
      !!(this.reopen?.hasOwnProperty('deliverySchedule') && this.current?.hasOwnProperty('deliverySchedule')) &&
      (this.reopen?.deliverySchedule.length !== this.current.deliverySchedule.length ||
        this.reopen?.deliverySchedule.some((item, index) => {
          return this.hasDeliveryQuantityChanged(index) || this.hasDeliveryDateChanged(index);
        }))
    );
  }

  /* eslint-enable no-prototype-builtins */

  public get grossPriceChanged(): boolean {
    return (
      this.hasGrossPriceTransactionCurrencyChanged() ||
      this.priceUnitChanged ||
      (this.pricePerItemChanged &&
        (this.reopen?.prices.priceUnitQuantity !== this.current?.prices.priceUnitQuantity ||
          (!!this.current?.prices.grossPrice &&
            priceDifference(this.current?.prices.grossPrice, this.reopen?.prices.grossPrice) !== 0)))
    );
  }

  /* eslint-disable no-prototype-builtins */
  public get discountPercentageChanged(): boolean | undefined {
    return (
      this.reopen?.prices.hasOwnProperty('discountPercentage') &&
      this.current?.prices.hasOwnProperty('discountPercentage') &&
      this.reopen?.prices.discountPercentage !== this.current?.prices.discountPercentage
    );
  }

  /* eslint-enable no-prototype-builtins */

  public get netPriceChanged(): boolean {
    return this.hasNetPriceTransactionCurrencyAndUnitChanged() || this.pricePerItemChanged;
  }

  public get chargeChanged(): boolean {
    return (
      !!this.current?.chargeLines &&
      !!this.reopen?.chargeLines &&
      isChargeLinesChanged(this.current.chargeLines, this.reopen.chargeLines)
    );
  }

  private hasGrossPriceTransactionCurrencyChanged(): boolean {
    return (
      !!this.reopen?.prices.grossPrice?.priceInTransactionCurrency.currencyIso &&
      !!this.current?.prices.grossPrice?.priceInTransactionCurrency.currencyIso &&
      this.reopen.prices.grossPrice.priceInTransactionCurrency.currencyIso !==
        this.current.prices.grossPrice.priceInTransactionCurrency.currencyIso
    );
  }

  private hasNetPriceTransactionCurrencyAndUnitChanged(): boolean {
    return (
      !!this.reopen?.prices.netPrice?.priceInTransactionCurrency.currencyIso &&
      !!this.current?.prices.netPrice?.priceInTransactionCurrency.currencyIso &&
      (this.reopen.prices.netPrice.priceInTransactionCurrency.currencyIso !==
        this.current.prices.netPrice.priceInTransactionCurrency.currencyIso ||
        this.priceUnitChanged)
    );
  }

  private get pricePerItemChanged(): boolean {
    return (
      !!this.reopen?.prices.netPrice?.priceInTransactionCurrency.value &&
      !!this.current?.prices.netPrice?.priceInTransactionCurrency.value &&
      this.reopen.prices.netPrice.priceInTransactionCurrency.value / this.reopen.prices.priceUnitQuantity !==
        this.current.prices.netPrice.priceInTransactionCurrency.value / this.current.prices.priceUnitQuantity
    );
  }

  private get priceUnitChanged(): boolean {
    return !priceUnitOfMeasureIsoEquals(
      this.reopen?.prices.priceUnitOfMeasureIso,
      this.current?.prices.priceUnitOfMeasureIso,
    );
  }

  private hasDeliveryDateChanged(index: number): boolean {
    return dateDifference(this.current?.deliverySchedule[index].date, this.reopen?.deliverySchedule[index].date) !== 0;
  }

  private hasDeliveryQuantityChanged(index: number): boolean {
    return (
      quantityDifference(
        this.current?.deliverySchedule[index].quantity,
        this.reopen?.deliverySchedule[index].quantity,
      ) !== 0
    );
  }
}
