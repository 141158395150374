import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Task, TaskStatus } from '@app/task/models';
import { TaskActionType, taskAddMany, taskWebSocketUpsert, taskWebSocketUpsertWithoutFilter } from './task.actions';
import { TypedAction } from '@ngrx/store/src/models';
import { createReducer, on } from '@ngrx/store';

export type TaskState = EntityState<Task>;

export const taskAdapter = createEntityAdapter<Task>({
  selectId: entity => {
    return entity.id;
  },
});

export const initialState = taskAdapter.getInitialState();

const taskReducer = createReducer<TaskState, TypedAction<TaskActionType>>(
  initialState,
  on(taskWebSocketUpsert, taskWebSocketUpsertWithoutFilter, (state, { entity }) => {
    return entity.status === TaskStatus.Closed
      ? taskAdapter.removeOne(entity.id, state)
      : taskAdapter.upsertOne(entity, state);
  }),
  on(taskAddMany, (state, { entities }) => {
    return taskAdapter.upsertMany(entities, state);
  }),
);

export function reducer(state = initialState, action: TypedAction<TaskActionType>): TaskState {
  return taskReducer(state, action);
}
