import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { RFQ } from '@app/rfq/models/rfq.model';
import { rfqMock } from '@app/rfq/core/services/constants/rfq-details.mock';
import { isNotNullOrUndefined } from '@app/util/operators/is-not-null-or-undefined';
import { RFQLine } from '@app/rfq/models/rfq-line.model';
import { rfqLineMock } from '@app/rfq/core/services/constants/rfq-line-details.mock';

@Injectable()
export class RfqService {
  public getRfq$(rfqId: string): Observable<RFQ> {
    return of(
      rfqMock.find(({ id }) => {
        return id === rfqId;
      }),
    ).pipe(isNotNullOrUndefined());
  }

  public getRfqs$(): Observable<RFQ[]> {
    return of(rfqMock).pipe(isNotNullOrUndefined());
  }

  public getRfqLine$(rfqLineId: string): Observable<RFQLine> {
    return of(
      rfqLineMock.find(({ id }) => {
        return id === rfqLineId;
      }),
    ).pipe(isNotNullOrUndefined());
  }

  public getLines$(): Observable<RFQLine[]> {
    return of(rfqLineMock).pipe(isNotNullOrUndefined());
  }

  public getRfqLines$(rfqId: string): Observable<RFQLine[]> {
    return of(
      rfqLineMock.filter(({ id }) => {
        return id.startsWith(rfqId);
      }),
    ).pipe(isNotNullOrUndefined());
  }
}
