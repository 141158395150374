import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, UntypedFormGroup } from '@angular/forms';
import { removeNulls } from '@app/util/helpers';

@Directive() // eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class FormComponent<T, E = T> {
  @Input()
  set pending(isPending: boolean) {
    if (isPending) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  @Input()
  set value(value: E | undefined) {
    if (value !== undefined) {
      this.patchValue(value);
      this.entity = value;
    }
  }

  @Input()
  errorMessage?: string;

  @Output()
  readonly submitted = new EventEmitter<T>();

  public abstract form: UntypedFormGroup | FormGroup;

  public entity?: E;

  public skipValidation = false;

  public submit(): void {
    if (this.form.valid || this.skipValidation) {
      this.submitted.emit(this.normalizedFormValue);
    }
  }

  public patchValue(value: E): void {
    this.form.patchValue(value as { [key: string]: any; });
  }

  public reset(): void {
    if (this.entity) {
      this.value = this.entity;
    }
  }

  public get normalizedFormValue(): T {
    return removeNulls(this.form.value);
  }
}
