import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AnalyticsState } from '@app/analytics/core/state/analytics.reducer';
import { getMsalToken } from '@app/msal/core/state/msal.actions';

@Component({
  selector: 'tc-msal-callback-login-page',
  templateUrl: './msal-callback.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MsalCallbackLoginPageComponent implements OnInit {
  constructor(private store$: Store<AnalyticsState>) {}

  ngOnInit(): void {
    this.store$.dispatch(getMsalToken());
  }
}
