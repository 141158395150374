import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API } from '@app/shared/api/api.service';
import { backOffRetry } from '@app/util/operators/back-off-retry';

const canRecover = (event: HttpErrorResponse) => {
  return event.status >= 500 || event.status === 0;
};
export const MAX_ATTEMPTS = 15;

@Injectable()
export class RetryInterceptor implements HttpInterceptor {
  constructor(private api: API) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.startsWith(this.api.MAIN)) {
      return next.handle(request);
    }

    return next.handle(request).pipe(backOffRetry(canRecover, MAX_ATTEMPTS));
  }
}
