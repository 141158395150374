import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AnalyticsState } from './analytics.reducer';

export const analyticsFeatureName = 'analytics';

const selectState = createFeatureSelector<AnalyticsState>(analyticsFeatureName);

export const selectAmountPerMonth = createSelector(selectState, ({ amountPerMonth }) => {
  return amountPerMonth;
});
export const selectCountPerMonth = createSelector(selectState, ({ countPerMonth }) => {
  return countPerMonth;
});
export const selectCountPerStatus = createSelector(selectState, ({ countPerStatus }) => {
  return countPerStatus;
});
export const selectKpisConfirmed = createSelector(selectState, ({ kpisConfirmed }) => {
  return kpisConfirmed;
});
export const selectKpisRequested = createSelector(selectState, ({ kpisRequested }) => {
  return kpisRequested;
});
export const selectCountOpenedTasks = createSelector(selectState, ({ countOpenedTask }) => {
  return countOpenedTask;
});
export const selectCountCompanies = createSelector(selectState, ({ countConnectedCompanies }) => {
  return countConnectedCompanies;
});
export const selectCountOrderLines = createSelector(selectState, ({ countOrderLines }) => {
  return countOrderLines;
});
