<div class="vertical">
  <mat-list class="mat-list--properties mat-list--compact" fxLayout="row">
    <mat-list-item class="auto-height">
      <div class="value">
        <ng-container *ngIf="buyerId | tcLazyCompany | async as company">
          <tc-company-name [company]="company"></tc-company-name>
        </ng-container>
      </div>
    </mat-list-item>
    <mat-list-item class="auto-height">
      <div class="value">
        <ng-container *ngIf="supplierId | tcLazyCompany | async as company">
          <tc-company-name [company]="company"></tc-company-name>
        </ng-container>
      </div>
    </mat-list-item>
  </mat-list>
  <mat-list class="mat-list--properties mat-list--compact" fxLayout="row">
    <mat-list-item class="auto-height" style="width: 50%;">
      <div>
        <ng-container *ngIf="buyerProperties?.length > 0">
          <div fxLayout *ngFor="let property of buyerProperties">
            <p matLine class="section-item space-right">
              {{ property.key }}
            </p>
            <p matLine class="section-item space-right">
              {{ property.value }}
            </p>
          </div>
        </ng-container>
      </div>
    </mat-list-item>
    <mat-list-item class="auto-height" style="width: 50%;">
      <div>
        <ng-container  *ngIf="supplierProperties?.length > 0">
          <div fxLayout *ngFor="let property of supplierProperties">
            <p matLine class="section-item space-right">
              {{ property.key }}
            </p>
            <p matLine class="section-item space-right">
              {{ property.value }}
            </p>
          </div>
        </ng-container>
      </div>
    </mat-list-item>
  </mat-list>
</div>
