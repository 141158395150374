<div fxLayout class="vertical">
  <mat-list fxFlex="50" class="mat-list--properties mat-list--compact">
    <mat-list-item class="auto-height" *ngIf="prices?.grossPrice">
      <div class="property" i18n="@@order.line.details.gross">Gross</div>
      <div class="value">
        <h4 matLine class="wrap">
          <span>
            <span [ngClass]="{'tc-change': grossPriceChanged}">
              {{ prices?.grossPrice?.priceInTransactionCurrency | tcMoney }}
            </span>
            <tc-price-unit
              [quantity]="prices?.priceUnitQuantity"
              [unit]="prices?.priceUnitOfMeasureIso"
              [ngClass]="{'tc-change': priceUnitChanged}"
            ></tc-price-unit>
          </span>
        </h4>
      </div>
    </mat-list-item>

    <mat-list-item class="auto-height" *ngIf="prices?.discountPercentage">
      <div class="property" i18n="@@order.line.details.discount">Discount</div>
      <div class="value">
        <h4 matLine class="wrap">
          <span [ngClass]="{'tc-change': discountPercentageChanged}">
            {{ prices?.discountPercentage | number: '1.0-5' }}%
          </span>
        </h4>
      </div>
    </mat-list-item>

    <mat-list-item class="auto-height" *ngIf="prices?.netPrice">
      <div class="property" i18n="As in net price @@order.line.details.net">Net</div>
      <div class="value">
        <h4 matLine class="wrap">
          <span>
            <span [ngClass]="{'tc-change': netPriceChanged}">
              {{ prices?.netPrice?.priceInTransactionCurrency | tcMoney }}
            </span>
            <tc-price-unit
              [quantity]="prices?.priceUnitQuantity"
              [unit]="prices?.priceUnitOfMeasureIso"
              [ngClass]="{'tc-change': priceUnitChanged}"
            ></tc-price-unit>
          </span>
        </h4>
      </div>
    </mat-list-item>

    <mat-list-item class="auto-height">
      <div class="property"
           i18n="@@common.field.amount"
           matTooltip="Order total amount could not be calculated because there are differences in the price & purchase unit of measure, or in the currency of the line and charge line."
           i18n-matTooltip="@@tooltip.amount"
           matTooltipPosition="after"
           [matTooltipDisabled]="!!amountView"
      >Total amount</div>
      <div class="value wrap">
        <h4 matLine class="pos-end">
          <span [ngClass]="{'tc-change': amountChanged}">
            {{ amountView | tcMoney }}
            <tc-amount-difference
              [amount]="orderLine?.totalAmount"
              [amountIncludingRequests]="orderLine?.totalAmountIncludingRequests"
            ></tc-amount-difference>
          </span>
        </h4>
      </div>
    </mat-list-item>

    <mat-list-item *ngIf="contractNumber" class="auto-height">
      <div class="property" i18n="As in the terms that apply to the order @@order.line.details.terms">Terms</div>
      <div class="value wrap">
        <h4 matLine>
          {{ contractNumber }}
        </h4>
        <p matLine *ngIf="contractPosition">
          <ng-container i18n="As in the position of a order line in an order @@order.line.details.row">Row</ng-container>
          &nbsp;
          {{ contractPosition }}
        </p>
      </div>
    </mat-list-item>

  </mat-list>
</div>
