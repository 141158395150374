import { Pipe, PipeTransform } from '@angular/core';
import { Activity, ActivityType } from '@app/activity/models';

@Pipe({
  name: 'tcActivityFilter',
})
export class ActivityFilterPipe implements PipeTransform {
  transform(activities: Activity[] | null): Activity[] | undefined {
    if (!activities) {
      return undefined;
    }

    return activities.filter(activity => {
      return !(
        activity.activityType === ActivityType.OrderDocumentAdded && activity.content.documents.documents.length === 0
      );
    });
  }
}
