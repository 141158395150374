import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Company } from '@app/company/models';

@Component({
  selector: 'tc-company-logo',
  templateUrl: 'company-logo.component.html',
  styleUrls: ['company-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyLogoComponent {
  @Input()
  company?: Company;

  @Input()
  size = 40;

  public get logoId(): string | undefined {
    return this.company && this.company.profile.logoId;
  }
}
