import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BuyerDelivery, BuyerItem, InProgressStatus, LineDelivery, OrderLine } from '@app/order/models';
import { quantityDifference } from '@app/order/shared/components/quantity-difference/helpers/quantity-difference.helper';
import { dateDifference } from '@app/order/shared/components/date-difference/helpers/date-difference.helper';
import { defaultLineDetailColumns } from '@app/order/shared/components/line-details/line-details.component';
import { getProperty } from '@app/shared/pipes/unique.pipe';

@Component({
  selector: 'tc-line-schedule',
  templateUrl: './line-schedule.component.html',
  styleUrls: ['./line-schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineScheduleComponent {
  public get columnDefs(): string[] {
    const hasTransportMode = this.deliverySchedule.some(line => {
      return (
        Object.prototype.hasOwnProperty.call(line, 'transportMode')
        && typeof (line as LineDelivery).transportMode === 'string'
        && (line as LineDelivery).transportMode!.length > 1
      )
    });
    const hasEtd = this.deliverySchedule.some(line =>
      Object.prototype.hasOwnProperty.call(line, 'etd') && (line as LineDelivery).etd
    );
    const hasEta = this.deliverySchedule.some(line =>
      Object.prototype.hasOwnProperty.call(line, 'eta') && (line as LineDelivery).eta
    );

    return defaultLineDetailColumns.filter(col => {
      if (col === 'transport') return hasTransportMode;
      if (col === 'etd') return hasEtd;
      if (col === 'eta') return hasEta;
      return true;
    });
  }

  @Input()
  orderLine?: OrderLine;

  constructor() {}

  public get item(): BuyerItem | undefined {
    return this.orderLine?.buyerLine.item;
  }

  public get deliverySchedule(): BuyerDelivery[] | LineDelivery[] {

    const inProgressStatusValue = getProperty<InProgressStatus>('status.inProgressStatus', this.orderLine!);

    if (!!inProgressStatusValue) {
      return this.orderLine ? this.orderLine.deliveryScheduleIncludingRequests! : [];
    }

    return this.orderLine ? this.orderLine.deliverySchedule : [];
  }

  public get deliveryQuantityChanged(): boolean | undefined {
    return (
      this.requestDeliverySchedule &&
      this.persistedDeliverySchedule &&
      (this.requestDeliverySchedule.length !== this.persistedDeliverySchedule.length ||
        this.requestDeliverySchedule?.some((item, index) => {
          return this.hasDeliveryQuantityChanged(index, item.position);
        }))
    );
  }

  public get isDeliveryDecrease(): boolean {
    return (
      !!this.persistedDeliverySchedule &&
      !!this.requestDeliverySchedule &&
      this.requestDeliverySchedule.length < this.persistedDeliverySchedule.length
    );
  }

  public isDeliveryNew(i: number, position: string | undefined): boolean | undefined {
    const [requestedDeliverySchedule, persistedDeliverySchedule] = this.getElemetsForComparing(i, position);

    return (
      !!this.persistedDeliverySchedule &&
      !!this.requestDeliverySchedule &&
      requestedDeliverySchedule &&
      persistedDeliverySchedule === undefined
    );
  }

  public hasDeliveryDateChanged(i: number, position: string | undefined): boolean {
    const [requestedDeliverySchedule, persistedDeliverySchedule] = this.getElemetsForComparing(i, position);

    return !!dateDifference(requestedDeliverySchedule?.date, persistedDeliverySchedule?.date);
  }

  public hasDeliveryQuantityChanged(i: number, position: string | undefined): boolean {
    const [requestedDeliverySchedule, persistedDeliverySchedule] = this.getElemetsForComparing(i, position);

    return !!quantityDifference(
      requestedDeliverySchedule?.quantity,
      persistedDeliverySchedule?.quantity,
    );
  }

  private getElemetsForComparing(i: number, position: string | undefined): (BuyerDelivery | LineDelivery | undefined)[] {
    const condition = this.hasDeliveriesPosition;

    const requestedDeliverySchedule = this.requestDeliverySchedule.find((el, index) => {
      if (condition && position !== undefined) {
        return el.position === position
      } else {
        return index === i;
      }

    })
    const persistedDeliverySchedule = this.persistedDeliverySchedule?.find((el, index) => {
      if (condition && position !== undefined) {
        return el.position === position
      } else {
        return index === i;
      }
    })

    return [requestedDeliverySchedule, persistedDeliverySchedule];
  }

  private get hasDeliveriesPosition(): boolean {
    return (this.persistedDeliverySchedule as LineDelivery[]).filter(d => !!d?.position).length > 0
  }

  private get requestDeliverySchedule(): BuyerDelivery[] {
    return this.deliverySchedule as BuyerDelivery[];
  }

  private get persistedDeliverySchedule(): LineDelivery[] | undefined {
    return this.orderLine!.deliverySchedule;
  }
}
