export enum ShipmentTableColumnsType {
  BUYER = 'buyer',
  SUPPLIER = 'supplier',
  SHIPMENT_NUMBER = 'shipmentNumber',
  DEPARTURE_LOCATION = 'departureLocation',
  ACTUAL_DATE = 'actualDate',
  DESTINATION_LOCATION = 'destinationLocation',
  DESTINATION_SCHEDULED = 'destinationScheduledEnd',
  FINAL_DESTINATION = 'finalDestination',
  FINAL_DESTINATION_SCHEDULED = 'finalDestinationScheduledEnd',
  PROCESS = 'process',
}
