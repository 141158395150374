import { Pipe, PipeTransform } from '@angular/core';
import { BuyerDelivery, SupplierDelivery } from '@app/order/models';

@Pipe({
  name: 'tcTotalQuantity',
})
export class TotalQuantityPipe implements PipeTransform {
  transform(schedule?: (BuyerDelivery | SupplierDelivery)[]): number | null {
    if (!schedule) {
      return null;
    }

    return totalQuantity(schedule);
  }
}

export function totalQuantity(schedule: (BuyerDelivery | SupplierDelivery)[]): number {
  return schedule
    .map(delivery => {
      return delivery.quantity;
    })
    .reduce<number>((acc, cur) => {
      return acc + cur;
    }, 0);
}
