import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { first, map, tap } from 'rxjs/operators';

import { Observable } from 'rxjs';
import { AuthState } from '../state/auth.reducer';
import { selectAuthAuthenticated } from '../state/auth.selectors';

@Injectable()
export class PublicGuard implements CanActivate {
  constructor(private router: Router, private store$: Store<AuthState>) {}

  canActivate(): Observable<boolean> {
    return this.store$.pipe(
      select(selectAuthAuthenticated),
      first((authed): authed is boolean => {
        return authed !== undefined;
      }),
      tap(authed => {
        if (authed) {
          this.router.navigate(['/']);
        }
      }),
      map(authed => {
        return !authed;
      }),
    );
  }
}
