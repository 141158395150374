import { Pipe, PipeTransform } from '@angular/core';
import { Order } from '@app/order/models';

@Pipe({
  name: 'tcFirstOrderSalesNumber',
})
export class FirstOrderSalesNumberPipe implements PipeTransform {
  transform(order: Order | null): string | undefined {
    if (order && order.lines) {
      const line = order.lines.find(orderLine => {
        return orderLine.supplierLine && orderLine.supplierLine.salesOrderNumber;
      });

      return line && line.supplierLine.salesOrderNumber;
    }

    return undefined;
  }
}
