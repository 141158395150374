import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { concat, Observable, of, Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { selectAuthIdentityCompanyId } from '@app/auth/core/state/auth.selectors';
import { isNotNullOrUndefined } from '@app/util/operators/is-not-null-or-undefined';
import { CompanyState } from '@app/company/core/state/company.reducer';
import {
  catchError,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { getOtherCompanyId, getOtherCompanyName } from '@app/connection/util/other-company';
import { ConnectionService } from '@app/connection/core/services/connection.service';

@Component({
  selector: 'tc-forecast-select',
  templateUrl: './forecast-select.component.html',
  styleUrls: ['./forecast-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForecastSelectComponent implements OnInit {
  @Output()
  readonly connectionChange = new EventEmitter<{ id: string; name: string } | undefined>();

  private companyId$ = this.store$.pipe(select(selectAuthIdentityCompanyId)).pipe(isNotNullOrUndefined());
  loading = false;
  input$ = new Subject<string>();
  selectedId: string | undefined;

  items$!: Observable<{ id: string; name: string }[]>;

  constructor(private connectionService: ConnectionService, private store$: Store<CompanyState>) {}

  ngOnInit(): void {
    this.loadConnections();
  }

  loadConnections() {
    const initial$ = this.companyId$.pipe(
      take(1),
      concatMap(companyId => {
        return this.connectionService
          .search$({
            companyId,
            body: {
              connected: true,
              offset: 0,
              limit: 3,
            },
          })
          .pipe(
            map(response => {
              return response.data.map(connection => {
                return {
                  name: getOtherCompanyName(companyId, connection) as string,
                  id: getOtherCompanyId(companyId, connection) as string,
                };
              });
            }),
            catchError(() => {
              return of([]);
            }),
          );
      }),
    );

    this.items$ = concat(
      of([]),
      initial$,
      this.input$.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap(() => {
          return (this.loading = true);
        }),
        withLatestFrom(this.companyId$),
        map(([query, companyId]) => {
          return {
            companyId: companyId,
            body: {
              connected: true,
              query,
              offset: 0,
              limit: 3,
            },
          };
        }),
        switchMap(query => {
          return this.connectionService.search$(query).pipe(
            map(response => {
              return response.data.map(connection => {
                return {
                  name: getOtherCompanyName(query.companyId, connection) as string,
                  id: getOtherCompanyId(query.companyId, connection) as string,
                };
              });
            }),
          );
        }),
        tap(() => {
          return (this.loading = false);
        }),
      ),
    );
  }

  onChange(data: any) {
    this.connectionChange.emit(data);
  }
}
