import { Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DownloadService } from '../../core/services/download.service';
import { Download, ImageOptions } from '../../core/state/object-storage.model';

@Pipe({
  name: 'tcObjectStorage',
})
export class ObjectStoragePipe implements PipeTransform {
  constructor(private signedUrlService: DownloadService) {}

  // eslint-disable-next-line
  transform(id?: string, options?: ImageOptions): Observable<Download | null> {
    if (!id) {
      return of(null);
    }

    return this.signedUrlService.getDownload$({ id, options });
  }
}
