import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@app/shared/api/api.service';
import { Observable } from 'rxjs';
import { EndpointCredentials } from '@app/company/models';

export interface SCIConnector {
  url: string;
  credentials: Credential[];
}

export interface Credential {
  supplierCompanyId: string;
  username: string | null;
}

@Injectable()
export class SciConnectorService {
  constructor(private http: HttpClient, private api: API) {}

  public getById$(buyerCompanyId: string): Observable<SCIConnector> {
    return this.http.get<SCIConnector>(this.api.SCI_CONNECTOR_SETTINGS(buyerCompanyId));
  }

  public updateApiUrl$(id: string, body: { url: string }): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(this.api.SCI_CONNECTOR_SETTINGS_URL(id), body);
  }

  public deleteApiUrl$(id: string): Observable<{ ok: boolean }> {
    return this.http.delete<{ ok: boolean }>(this.api.SCI_CONNECTOR_SETTINGS(id));
  }

  public updateCredentials$(
    id: string,
    supplierCompanyId: string,
    body: EndpointCredentials,
  ): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(this.api.SCI_CONNECTOR_SETTINGS_CREDENTIALS(id, supplierCompanyId), body);
  }

  public deleteCredentials$(id: string, supplierCompanyId: string): Observable<{ ok: boolean }> {
    return this.http.delete<{ ok: boolean }>(this.api.SCI_CONNECTOR_SETTINGS_CREDENTIALS(id, supplierCompanyId));
  }
}
