import { createFeatureSelector } from '@ngrx/store';
import { taskAdapter, TaskState } from './task.reducer';

export const taskFeatureName = 'task';

const selectState = createFeatureSelector<TaskState>(taskFeatureName);

const { selectEntities } = taskAdapter.getSelectors(selectState);

export const selectTaskEntities = selectEntities;
