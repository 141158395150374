import { interval, Observable } from 'rxjs';
import { first, map, mergeMap, retryWhen, scan } from 'rxjs/operators';
import { environment } from '@environments/environment';

/**
 * RxJS operator that resubscribes an Observable on error after an increasing delay.
 */
export const backOffRetry = <E, T>(
  canRecover: (error: E) => boolean,
  maxAttempts: number,
  canReset = () => {
    return false;
  },
) => {
  return (source$: Observable<T>) => {
    return source$.pipe(
      retryWhen((errors$: Observable<E>) => {
        return errors$.pipe(
          map((error: E) => {
            if (!environment.retryConnectionFailures || !canRecover(error)) {
              // TODO: resolve this when find out meaning of this
              // eslint-disable-next-line
              throw error;
            }

            return { error, reset: canReset() };
          }),
          scan((attempts: number, { error, reset }: { error: E; reset: boolean }) => {
            const realAttempts = reset ? 0 : attempts;

            if (realAttempts > maxAttempts) {
              // TODO: resolve this when find out meaning of this
              // eslint-disable-next-line
              throw error;
            }

            return realAttempts + 1;
          }, 0),
          /* eslint:disable @typescript-eslint/no-use-before-define */
          map(backOffDelay),
          /* eslint-enable @typescript-eslint/no-use-before-define */
          mergeMap(time => {
            return interval(time).pipe(first());
          }),
        );
      }),
    );
  };
};

/**
 * Exponential Backoff Formula
 * http://dthain.blogspot.co.uk/2009/02/exponential-backoff-in-distributed.html
 */
function backOffDelay(attempt: number): number {
  const r = 1.1;
  const t = 3 * 1000;
  const f = 1.3;
  const n = attempt;
  const m = 180000;

  return Math.floor(Math.min(r * t * Math.pow(f, n), m));
}
