import { createFeatureSelector, createSelector } from '@ngrx/store';
import { selectAuthIdentityUserId } from '@app/auth/core/state/auth.selectors';
import { selectRouterParams } from '@app/store/reducers';
import { userAdapter, UserState } from './user.reducer';

export const userFeatureName = 'user';

const selectState = createFeatureSelector<UserState>(userFeatureName);

const { selectEntities, selectIds } = userAdapter.getSelectors(selectState);

export const selectUserEntities = selectEntities;
export const selectUserIds = selectIds;

export const selectUserById = (id: string) => {
  return createSelector(selectEntities, entities => {
    return entities[id];
  });
};

export const selectRoutedUserId = createSelector(selectRouterParams, params => {
  return params && (params.userId as null | string);
});

export const selectRoutedUser = createSelector(selectRoutedUserId, selectEntities, (userId, entities) => {
  return userId ? entities[userId] : null;
});

export const selectUserFromIdentity = createSelector(selectAuthIdentityUserId, selectEntities, (id, entities) => {
  return id ? entities[id] : null;
});
