import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, tap } from 'rxjs/operators';
import { notificationSend, trackEvent } from './core.actions';
import { PageAnalyticsService } from '@app/core/services/page-analytics/page-analytics.service';

@Injectable()
export class CoreEffects {
  notification$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(notificationSend),
        map(({ message, notificationType, ...v }) => {
          return { message, notificationType, duration: v.duration || 2500 };
        }),
        tap(payload => {
          this.snackBar.open(payload.message, undefined, {
            duration: payload.duration,
            panelClass: `mat-snack-bar-container--${payload.notificationType}`,
          });
        }),
      );
    },
    { dispatch: false },
  );

  track$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(trackEvent),
        tap(parameters => {
          const { eventName, ...forwardedParameters } = parameters;
          this.pageAnalytics.track(eventName || 'Click', forwardedParameters);
        }),
      );
    },
    { dispatch: false },
  );

  constructor(private actions$: Actions, private snackBar: MatSnackBar, private pageAnalytics: PageAnalyticsService) {}
}
