import { createAction, props } from '@ngrx/store';
import { OrderedColumn } from '@app/shared/components/table-columns/table-columns.component';
import {
  OrderQuery,
  OrderQueryFilters,
  OrderQueryResponse,
  OrderQuerySort,
  OrderQueryViewMode,
} from '@app/order/models';
import { OrderLineQuery, OrderLineQueryResponse } from '@app/order/models/order-line-query.model';

export enum OrderListActionType {
  QUERY_ORDERS = '[Order List] Query Orders',
  QUERY_ORDERS_SUCCESS = '[Order List] Query Orders Success',
  QUERY_LINES = '[Order List] Query Lines',
  QUERY_LINES_SUCCESS = '[Order List] Query Lines Success',
  INIT_COLUMNS = '[Order List] Init Columns',
  INITIATED_COLUMNS = '[Order List] Initiated Columns',
  UPDATE_COLUMNS = '[Order List] Update Columns',
  UPDATE_ORDER_COLUMNS = '[Order List] Update Order Columns',
  UPDATE_LINE_COLUMNS = '[Order List] Update Line Columns',
  UPDATE_FILTERS = '[Order List] Update Filters',
  UPDATE_SORT = '[Order List] Update Sort',
  UPDATE_VIEW_MODE = '[Order List] Update View Mode',
  RESET_TO_VIEW_MODE = '[Order List] Reset filters up to View Mode',
  UPDATE_SEARCH_QUERY = '[Order List] Update Search Query',
  REFRESH = '[Order List] Refresh',
}

export const orderListQueryOrders = createAction(OrderListActionType.QUERY_ORDERS, props<{ query: OrderQuery }>());
export const orderListQueryOrderSuccess = createAction(
  OrderListActionType.QUERY_ORDERS_SUCCESS,
  props<{ query: OrderQuery; response: OrderQueryResponse }>(),
);
export const orderListQueryLines = createAction(OrderListActionType.QUERY_LINES, props<{ query: OrderLineQuery }>());
export const orderListQueryLinesSuccess = createAction(
  OrderListActionType.QUERY_LINES_SUCCESS,
  props<{ query: OrderLineQuery; response: OrderLineQueryResponse }>(),
);
export const orderListUpdateColumns = createAction(
  OrderListActionType.UPDATE_COLUMNS,
  props<{ columns: OrderedColumn[] }>(),
);
export const orderListInitColumns = createAction(OrderListActionType.INIT_COLUMNS);
export const orderListInitiatedColumns = createAction(
  OrderListActionType.INITIATED_COLUMNS,
  props<{
    orderColumns: OrderedColumn[];
    lineColumns: OrderedColumn[];
  }>(),
);
export const orderListUpdateOrderColumns = createAction(
  OrderListActionType.UPDATE_ORDER_COLUMNS,
  props<{ orderColumns: OrderedColumn[] }>(),
);
export const orderListUpdateLineColumns = createAction(
  OrderListActionType.UPDATE_LINE_COLUMNS,
  props<{ lineColumns: OrderedColumn[] }>(),
);
export const orderListUpdateFilters = createAction(
  OrderListActionType.UPDATE_FILTERS,
  props<{ filters: OrderQueryFilters }>(),
);
export const orderListUpdateSort = createAction(OrderListActionType.UPDATE_SORT, props<{ sort: OrderQuerySort }>());
export const orderListUpdateViewMode = createAction(
  OrderListActionType.UPDATE_VIEW_MODE,
  props<{ viewMode: OrderQueryViewMode }>(),
);
export const orderListRefresh = createAction(OrderListActionType.REFRESH);
export const orderListReset = createAction(OrderListActionType.RESET_TO_VIEW_MODE);

export const orderListUpdateSearchQuery = createAction(
  OrderListActionType.UPDATE_SEARCH_QUERY,
  props<{ query: string }>(),
);
