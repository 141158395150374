<form [formGroup]="form" (ngSubmit)="submit()">
  <mat-expansion-panel [expanded]="connection" [hideToggle]="connection" [disabled]="errMsg.length > 0">
    <mat-expansion-panel-header>
      <mat-panel-title i18n="@@company.settings.overdue.title">Overdue tasks</mat-panel-title>
      <mat-panel-description>
        <ng-container *ngIf="!form.get('turnOffOverdue')?.value; else disabled">
          {{form.get('delayOverdueDays')?.value}}
          <ng-container i18n="@@company.settings.overdue.delay.title">day(s) after the confirmed delivery date</ng-container>
        </ng-container>
        <ng-template #disabled i18n="@@common.disabled">Disabled</ng-template>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <h4 *ngIf="!connection" class="hint" i18n="@@company.settings.overdue.ask1">This is the default setting. Supplier-specific settings can be configured on the Network page.</h4>
    <h4 i18n="@@company.settings.overdue.ask">How many days after the confirmed delivery date should the supplier be notified about overdue lines?</h4>
    <mat-checkbox
      class="ch-never"
      formControlName="turnOffOverdue"
      (change)="toggle($event.checked)"
      i18n="@@user.settings.never"
    >Never</mat-checkbox>
    <ng-container *ngIf="!form.get('turnOffOverdue')?.value">
      <mat-form-field fxLayout="row">
        <mat-label i18n="@@user.settings.days">Day(s)</mat-label>
        <mat-select formControlName="delayOverdueDays">
          <mat-option *ngFor="let period of periodList" [value]="period">{{ period }}</mat-option>
        </mat-select>
        <mat-error *tcMatFormFieldError i18n="@@common.error.min.one">
          This field should be more than 0
        </mat-error>
      </mat-form-field>
    </ng-container>
    <div>
      <mat-label i18n="@@company.settings.overdue.receipts">Are goods receipts booked during the weekend?</mat-label>
      <mat-radio-group class="example-radio-group" formControlName="delayDuringWeekend">
        <mat-radio-button class="example-radio-button" value="yes" i18n="@@common.yes">Yes</mat-radio-button>
        <mat-radio-button class="example-radio-button" value="no" i18n="@@common.no">No</mat-radio-button>
      </mat-radio-group>
    </div>
    <p *ngIf="errMsg.length > 0">
      <mat-error>{{ errMsg }}</mat-error>
    </p>

    <mat-action-row>
      <button *ngIf="!hasRemove" mat-button type="button" (click)="cancel()" i18n="button @@common.btn.cancel">CANCEL</button>
      <ng-container *ngIf="hasRemove">
        <button mat-button type="button" (click)="onClose()" i18n="button @@common.btn.close">CLOSE</button>
        <button mat-button type="button" (click)="onRemove()" [disabled]="errMsg.length > 0"  i18n="button @@common.btn.remove">REMOVE</button>
      </ng-container>
      <button mat-button type="submit" [disabled]="!form.valid || errMsg.length > 0" color="primary" i18n="button @@common.btn.save">SAVE</button>
    </mat-action-row>

  </mat-expansion-panel>

</form>
