import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DocumentInterface, Order } from '@app/order/models';
import { AttachDocumentConfig } from '../attach-document-dialog/config/attach-document.config';

@Component({
  selector: 'tc-order-documents',
  templateUrl: './order-documents.component.html',
  styleUrls: ['./order-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderDocumentsComponent {
  public readonly displayedColumns = ['file', 'attachedBy', 'name', 'code', 'revision', 'type', 'description'];

  @Input()
  order?: Order;

  get documents() {
    if (this.order) {
      const documentInfo = (item: DocumentInterface) => {
        return {
          name: item.name || '',
          revision: item.revision || '',
          code: item.code || '',
          type: (item.type && AttachDocumentConfig.DOCUMENT_TYPE_DESCRIPTION[item.type]) || '',
          description: item.description || '',
          url: item.url,
          objectId: item.objectId,
        };
      };

      const allDocuments = [
        ...(this.order.supplierOrder.documents.map((item: DocumentInterface) => {
          return {
            ...documentInfo(item),
            linkedTo: this.order!.buyerOrder.purchaseOrderNumber,
            attachedBy: this.order!.supplierOrder.companyId,
          };
        }) || []),
        ...(this.order.buyerOrder.documents.map((item: DocumentInterface) => {
          return {
            linkedTo: this.order!.buyerOrder.purchaseOrderNumber,
            attachedBy: this.order!.buyerOrder.companyId,
            ...documentInfo(item),
          };
        }) || []),
      ];

      return allDocuments;
    }

    return [];
  }
}
