<mat-list>
  <tc-setup-2fa-step-1></tc-setup-2fa-step-1>
  <mat-divider></mat-divider>
  <tc-setup-2fa-step-2 [configure2FA]="configure2FA$ | async" [identity]="identity$ | async"></tc-setup-2fa-step-2>
  <mat-divider></mat-divider>
  <tc-setup-2fa-step-3 [configure2FA]="configure2FA$ | async" (submitted)="onEnable2FA($event)"></tc-setup-2fa-step-3>
  <ng-container *ngIf="isLastStep$ | async">
    <mat-divider></mat-divider>
    <tc-setup-recovery-codes [backupCodes]="backupCodes$ | async" (resetForm)="onReset()"></tc-setup-recovery-codes>
  </ng-container>
</mat-list>
