import { NgModule } from '@angular/core';
import { RootSharedModule } from '@app/shared/root-shared.module';
import { AftershipComponent } from './components/aftership/aftership.component';
import { MarineTrafficComponent } from './components/marine-traffic/marine-traffic.component';
import { LocationComponent } from './components/location/location.component';

const components = [AftershipComponent, MarineTrafficComponent, LocationComponent];

@NgModule({
  imports: [RootSharedModule],
  declarations: [components],
  exports: [components],
})
export class ShipmentSharedModule {}
