import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Money, Order, OrderLine, StartRemoveLineLabel } from '@app/order/models';
import { UUID } from '@app/shared/models';
import { CompanyRole } from '@app/company/models';
import { MatDialog } from '@angular/material/dialog';
import { RemoveLabelDialogComponent } from '@app/order/shared/components/remove-label-dialog/remove-label-dialog.component';
import { AttachDocumentEntityType } from '@app/order/private/components/attach-document-dialog/interfaces/enums/attach-document-entity-type.enum';
import { isNotNullOrUndefined } from '@app/util/operators/is-not-null-or-undefined';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'tc-line-summary',
  templateUrl: './line-summary.component.html',
  styleUrls: ['./line-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineSummaryComponent {
  @Input()
  orderLine?: OrderLine;

  @Input()
  order?: Order;

  @Input()
  role?: CompanyRole;

  @Input()
  companyId?: string;

  @Output()
  readonly removeLineLabel = new EventEmitter<StartRemoveLineLabel>();

  public get buyerId(): UUID | undefined {
    return this.order?.buyerOrder.companyId;
  }

  public get supplierId(): UUID | undefined {
    return this.order?.supplierOrder.companyId;
  }

  public get supplierAccountNumber(): string | undefined {
    return this.order?.buyerOrder.supplierAccountNumber;
  }

  public get buyerAccountNumber(): string | undefined {
    return this.order?.supplierOrder.buyerAccountNumber;
  }

  public get purchaseOrderLinePosition(): string | undefined {
    return this.orderLine?.buyerLine.position;
  }

  public get purchaseOrderLineDescription(): string | undefined {
    return this.orderLine?.buyerLine.description;
  }

  public get buyerProjectNumber(): string | undefined {
    return this.orderLine?.buyerLine.projectNumber;
  }

  public get buyerProductionNumber(): string | undefined {
    return this.orderLine?.buyerLine.productionNumber;
  }

  public get salesOrderNumber(): string | undefined {
    return this.orderLine?.buyerLine.salesOrderNumber;
  }

  public get amountView(): Money | undefined {
    return this.orderLine?.totalAmountIncludingRequests;
  }

  public get amountChanged(): boolean | undefined {
    return (
      this.orderLine?.totalAmountIncludingRequests?.value !== this.orderLine?.totalAmount?.value ||
      this.orderLine?.totalAmountIncludingRequests?.currencyIso !== this.orderLine?.totalAmount?.currencyIso
    );
  }

  public get supplierSalesOrderNumber(): string | undefined {
    return this.orderLine?.supplierLine.salesOrderNumber;
  }

  public get supplierSalesOrderLineDescription(): string | undefined {
    return this.orderLine?.supplierLine.description;
  }

  public get labels(): string[] | undefined {
    return this.orderLine?.buyerLine?.labels;
  }

  constructor(private dialog: MatDialog) {}

  onRemoveLabel(text: string) {
    const dialogRef = this.dialog.open(RemoveLabelDialogComponent, {
      data: {
        text,
        entityType: AttachDocumentEntityType.ORDER_LINE,
        entityId: `${this.orderLine!.buyerLine.position}`,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(
        isNotNullOrUndefined(),
        filter(d => {
          return !!d;
        }),
      )
      .subscribe(result => {
        if (this.order && this.role) {
          this.removeLineLabel.emit({
            orderId: this.order.id,
            linePosition: this.orderLine!.buyerLine.position,
            removedLabel: {
              reason: result.reason,
              text,
            },
            role: this.role,
          });
        }
      });
  }
}
