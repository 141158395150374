import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserInterface } from '@app/user/models';

import { UUID } from '@app/shared/models';

@Component({
  selector: 'tc-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityComponent {
  @Input()
  user?: UserInterface;

  @Input()
  timestamp?: Date;

  @Input()
  companyId!: UUID;

  @Input()
  internal!: boolean;

  public get userId(): string | undefined {
    return this.user && this.user.id;
  }
}
