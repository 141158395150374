import { NgIfContext } from '@angular/common';
import { EmbeddedViewRef, TemplateRef, ViewContainerRef } from '@angular/core';

export abstract class ConditionalDirective {
  private context = new NgIfContext();
  private viewRef: EmbeddedViewRef<NgIfContext> | null = null;

  constructor(private viewContainer: ViewContainerRef, private templateRef: TemplateRef<NgIfContext>) {}

  protected updateView(condition: boolean): void {
    this.context.$implicit = this.context.ngIf = condition;

    if (condition && !this.viewRef) {
      this.viewContainer.clear();
      this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef, this.context);
    } else if (!condition && this.viewRef) {
      this.viewContainer.clear();
      this.viewRef = null;
    }
  }
}
