import { Pipe, PipeTransform } from '@angular/core';
import { OrderLineSearchView } from '@app/order/models';

@Pipe({
  name: 'tcOrderLineRouterLink',
})
export class OrderLineRouterLinkPipe implements PipeTransform {
  transform(orderLine?: OrderLineSearchView | null): string[] | null {
    if (!orderLine) {
      return null;
    }

    return ['/orders', orderLine.orderId, 'line', orderLine.id];
  }
}
