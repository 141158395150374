import { NgModule } from '@angular/core';
import { ObjectStorageSharedModule } from '@app/object-storage/shared/object-storage-shared.module';
import { RootSharedModule } from '@app/shared/root-shared.module';
import { ProfilePictureComponent } from './components/profile-picture/profile-picture.component';
import { RoleIconComponent } from './components/role-icon/role-icon.component';
import { UserNameComponent } from './components/user-name/user-name.component';
import { UserSummaryComponent } from './components/user-summary/user-summary.component';
import { UsersTableComponent } from './components/users-table/users-table.component';
import { LazyUserPipe } from './pipes/lazy-user.pipe';

const components = [
  ProfilePictureComponent,
  RoleIconComponent,
  UserNameComponent,
  UserSummaryComponent,
  UsersTableComponent,
];

const pipes = [LazyUserPipe];

@NgModule({
  imports: [RootSharedModule, ObjectStorageSharedModule],
  declarations: [components, pipes],
  exports: [components, pipes],
})
export class UserSharedModule {}
