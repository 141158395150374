import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { UserInterface } from '@app/user/models';
import { UserActionType, userAddMany, userRemoveSuccess, userSSOUpdate, userUpdate, userUpsert } from './user.actions';
import { TypedAction } from '@ngrx/store/src/models';
import { createReducer, on } from '@ngrx/store';
import { mapUpdatedToUserSettings } from '@app/user/core/state/util';

export type UserState = EntityState<UserInterface>;

export const userAdapter = createEntityAdapter<UserInterface>({
  selectId: entity => {
    return entity.id;
  },
});

export const initialState = userAdapter.getInitialState();

const userReducer = createReducer<UserState, TypedAction<UserActionType>>(
  initialState,
  on(userUpsert, (state, { entity }) => {
    return userAdapter.upsertOne(entity, state);
  }),
  on(userAddMany, (state, { entities }) => {
    return userAdapter.addMany(entities, state);
  }),
  on(userRemoveSuccess, (state, { entity: { id } }) => {
    return userAdapter.removeOne(id, state);
  }),
  on(userUpdate, userSSOUpdate, (state, { id, changes }) => {
    const { profile, settings } = changes;
    const mappedSettings = mapUpdatedToUserSettings(settings);
    const userData: Pick<UserInterface, 'settings' | 'profile'> = { profile, settings: mappedSettings };

    return userAdapter.updateOne({ id, changes: userData }, state);
  }),
);

export function reducer(state = initialState, action: TypedAction<UserActionType>): UserState {
  return userReducer(state, action);
}
