import { Measurement } from '@app/analytics/models';
import { UUID } from '@app/shared/models';

export interface AnalyticsQuery {
  supplierId: UUID;
  from: Date;
  to: Date;
  interval: AnalyticsQueryInterval;
}

export enum AnalyticsQueryInterval {
  Month = 'm',
  Week = 'w',
  Day = 'd',
}

export interface AnalyticsQueryResponse {
  data: Measurement[];
  total: number;
}

export interface AnalyticsStatusQueryResponse {
  confirmed?: number;
  inProgress?: number;
  issued?: number;
}

export type AnalyticsRange = 'year' | 'month' | 'week';
export type AnalyticsStatus = keyof AnalyticsStatusQueryResponse;
