import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Shipment,
  ShipmentApprove,
  ShipmentAttach,
  ShipmentDespatch,
  ShipmentLocation,
  ShipmentLocationSearch,
  ShipmentReject,
  ShipmentResend,
} from '@app/shipment/models';
import { HttpClient } from '@angular/common/http';
import { API } from '@app/shared/api/api.service';
import { TypedAction } from '@ngrx/store/src/models';
import { paramsFromObject } from '@app/util/http';
import { WebSocketService } from '@app/core/services/web-socket/web-socket.service';
import { ShipmentActionType } from '@app/shipment/core/state/shipment.actions';
import { UUID } from '@app/shared/models';

@Injectable()
export class ShipmentService {
  constructor(private http: HttpClient, private webSocketService: WebSocketService, private api: API) {}

  public websocket$(
    companyId: string,
    action: () => TypedAction<ShipmentActionType.WEBSOCKET_CONNECTED>,
  ): Observable<Shipment> {
    const params = paramsFromObject({ companyId });

    return this.webSocketService.createWebSocket$<Shipment>(this.api.SHIPMENT_WS(), params, action);
  }

  public resendShipment$({ shipmentId }: ShipmentResend): Observable<{ ok: boolean }> {
    const encodedShipmentId = encodeURIComponent(shipmentId);

    return this.http.post<{ ok: boolean }>(this.api.SHIPMENT_RESEND(encodedShipmentId), {});
  }

  public rejectShipment$({ shipmentId, reason }: ShipmentReject): Observable<{ ok: boolean }> {
    const encodedShipmentId = encodeURIComponent(shipmentId);

    return this.http.post<{ ok: boolean }>(this.api.SHIPMENT_REJECT(encodedShipmentId), { reason });
  }

  public approveShipment$({ shipmentId }: ShipmentApprove): Observable<{ ok: boolean }> {
    const encodedShipmentId = encodeURIComponent(shipmentId);

    return this.http.post<{ ok: boolean }>(this.api.SHIPMENT_APPROVE(encodedShipmentId), {});
  }

  public createShipment$(body: ShipmentDespatch): Observable<{ id: UUID }> {
    return this.http.post<{ id: UUID }>(this.api.SHIPMENT_CREATE(), body);
  }

  public searchLocationInShipments$(body: ShipmentLocationSearch): Observable<{ data: ShipmentLocation[] }> {
    return this.http.post<{ data: ShipmentLocation[] }>(this.api.SHIPMENT_SEARCH_LOCATION(), body);
  }

  public attachShipmentDocument$({ shipmentId, body }: ShipmentAttach): Observable<{ ok: boolean }> {
    const encodedShipmentId = encodeURIComponent(shipmentId);

    return this.http.post<{ ok: boolean }>(this.api.SHIPMENT_DOCUMENT(encodedShipmentId), body);
  }
}
