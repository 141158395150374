import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as root from '@app/store/reducers';
import { UpdateService } from '../../services/update/update.service';
import { authActAsCompany, authStartLogout } from '@app/auth/core/state/auth.actions';
import {
  selectAuthAuthenticated,
  selectAuthIdentityCompanyId,
  selectAuthIdentityUserId,
} from '@app/auth/core/state/auth.selectors';
import { selectCompanyFromIdentity } from '@app/company/core/state';
import { selectUserFromIdentity } from '@app/user/core/state/user.selectors';

@Component({
  selector: 'tc-app',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  public authenticated$ = this.store$.pipe(select(selectAuthAuthenticated));
  public userId$ = this.store$.pipe(select(selectAuthIdentityUserId));
  public user$ = this.store$.pipe(select(selectUserFromIdentity));
  public companyId$ = this.store$.pipe(select(selectAuthIdentityCompanyId));
  public company$ = this.store$.pipe(select(selectCompanyFromIdentity));

  public get currentRoute(): string | null {
    const route = this.route.snapshot.firstChild;

    return route && route.url[0] && route.url[0].path;
  }

  public get isAuthRoute(): boolean {
    return this.currentRoute === 'login';
  }

  public get isNetworkRoute(): string | null | boolean {
    return this.currentRoute && ['company', 'user'].includes(this.currentRoute);
  }

  constructor(
    private route: ActivatedRoute,
    private store$: Store<root.GlobalState>,
    private updateService: UpdateService,
  ) {}

  ngOnInit(): void {
    this.updateService.init();
  }

  public logout(): void {
    this.store$.dispatch(authStartLogout());
  }

  public endActingAsCompany(): void {
    this.store$.dispatch(authActAsCompany({ companyId: '' }));
  }
}
