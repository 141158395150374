import { DataSource } from '@angular/cdk/table';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export class TableDataSource<T> extends DataSource<any> {
  constructor(private data$: Observable<T[] | null>) {
    super();
  }

  // Disable rxjs-finnish as we can't rename inherited method from DataSource
  // eslint-disable-next-line
  connect(): Observable<{ entity: T }[]> {
    return this.data$.pipe(
      filter(entities => {
        return !!entities;
      }),
      map(entities => {
        return entities!.map(entity => {
          return { entity };
        });
      }),
    );
  }

  get isEmpty$(): Observable<boolean> {
    return this.data$.pipe(
      map(entities => {
        return !!entities && entities.length === 0;
      }),
    );
  }

  disconnect(): void {
    // noop
  }
}
