import { createAction, props } from '@ngrx/store';
import { BackupCode, Identity, PolicyNode, RefreshToken, Tokens } from '@app/auth/models';
import { UUID } from '@app/shared/models';
import { TwoFACode } from '@app/auth/core/state/auth.model';

export enum AuthActionType {
  INITIALIZE = '[Auth] Initialize',
  REFRESH = '[Auth] Refresh',
  REFRESH_SSO = '[Auth] Refresh SSO',
  REFRESH_SSO_SUCCESS = '[Auth] Refresh SSO Success',
  REFRESHED = '[Auth] Refresh Completed',
  SET_AUTHENTICATED = '[Auth] Set Authenticated',
  SET_TOKENS = '[Auth] Set Tokens',

  SET_IDENTITY_AND_BOOTUP = '[Auth] Set Identity and bootup',
  SET_IDENTITY_WITHOUT_BOOTING = '[Auth] Set Identity without booting',
  SET_IDENTITY_AND_GENERATE_2FA_SECRET = '[Auth] Set Identity and generate 2fa',

  SET_PERMISSIONS = '[Auth] Set Permissions',
  ACT_AS_COMPANY = '[Auth] Act As Company',
  REDIRECT_TO_LOGIN = '[Auth] Redirect To Login',
  REDIRECT_AFTER_LOGIN = '[Auth] Redirect After Login',
  START_LOGOUT = '[Auth] Start Logout',
  BACK_TO_LOGIN = '[Auth] Back to Login',
  LOGOUT_FROM_AZURE_AD = '[Auth] Logout from Azure AD',
  LOGOUT = '[Auth] Logout',
  GENERATE_2FA_SECRET = '[Auth] Generate 2FA Secret',
  GENERATE_2FA_SECRET_FAILURE = '[Auth] Generate 2FA Secret Failure',
  GENERATE_2FA_SECRET_SUCCESS = '[Auth] Generate 2FA Secret Success',

  ENABLE_2FA = '[Auth] Enable 2FA',
  SETUP_2FA_AND_LOGIN = '[Auth] Setup 2FA and login',
  ENABLE_2FA_SUCCESS = '[Auth] Enable 2FA Success',
  ENABLE_2FA_FAILURE = '[Auth] Enable 2FA Failure',

  DISABLE_2FA = '[Auth] Disable 2FA',
  DISABLE_2FA_RECOVERY_CODE = '[Auth] Disable 2FA Recovery code',

  DISABLE_2FA_SUCCESS = '[Auth] Disable 2FA Success',
  DISABLE_2FA_FAILURE = '[Auth] Disable 2FA Failure',

  CLEAR_CONFIGURE_2FA_STATE = '[Auth] Clear configure 2FA state',

  CHECK_USER_COUNTRY = '[Auth] Check user country',
  SAVE_USER_COUNTRY = '[Auth] Save user country',
}

export const authInitialize = createAction(AuthActionType.INITIALIZE);

export const authRefresh = createAction(AuthActionType.REFRESH, props<RefreshToken>());

export const checkUserCountry = createAction(AuthActionType.CHECK_USER_COUNTRY);
export const saveUserCountry = createAction(AuthActionType.SAVE_USER_COUNTRY, props<{ isChina: boolean }>());

export const authRefreshSSO = createAction(AuthActionType.REFRESH_SSO);
export const authRefreshSSOSuccess = createAction(AuthActionType.REFRESH_SSO_SUCCESS);

export const authRefreshCompleted = createAction(AuthActionType.REFRESHED);
export const authSetAuthenticated = createAction(
  AuthActionType.SET_AUTHENTICATED,
  props<{ isAuthenticated: boolean }>(),
);
export const authSetTokens = createAction(AuthActionType.SET_TOKENS, props<Tokens>());

export const authSetIdentityAndBootup = createAction(AuthActionType.SET_IDENTITY_AND_BOOTUP, props<Identity>());

export const authSetIdentityWithoutBooting = createAction(
  AuthActionType.SET_IDENTITY_WITHOUT_BOOTING,
  props<Identity>(),
);

export const authSetIdentityWithoutBootingAndGenerateSecret = createAction(
  AuthActionType.SET_IDENTITY_AND_GENERATE_2FA_SECRET,
  props<Identity>(),
);

export const authSetPermissions = createAction(AuthActionType.SET_PERMISSIONS, props<PolicyNode>());
export const authActAsCompany = createAction(AuthActionType.ACT_AS_COMPANY, props<{ companyId: UUID }>());
export const authRedirectToLogin = createAction(AuthActionType.REDIRECT_TO_LOGIN, props<{ path: string }>());
export const authRedirectAfterLogin = createAction(AuthActionType.REDIRECT_AFTER_LOGIN, props<{ path: string }>());
export const authLogout = createAction(AuthActionType.LOGOUT);
export const authStartLogout = createAction(AuthActionType.START_LOGOUT);
export const authBackToLogin = createAction(AuthActionType.BACK_TO_LOGIN);
export const authLogoutFromAzureAd = createAction(AuthActionType.LOGOUT_FROM_AZURE_AD);

export const authGenerate2FASecret = createAction(AuthActionType.GENERATE_2FA_SECRET);
export const authGenerate2FASecretFailure = createAction(AuthActionType.GENERATE_2FA_SECRET_FAILURE);
export const authGenerate2FASecretSuccess = createAction(
  AuthActionType.GENERATE_2FA_SECRET_SUCCESS,
  props<{ secret: string }>(),
);
export const authEnable2FA = createAction(AuthActionType.ENABLE_2FA, props<TwoFACode>());
export const authEnable2FASuccess = createAction(AuthActionType.ENABLE_2FA_SUCCESS, props<Identity>());
export const authEnable2FAFailure = createAction(AuthActionType.ENABLE_2FA_FAILURE, props<{ code: string }>());

export const authSetup2FAAndLogin = createAction(AuthActionType.SETUP_2FA_AND_LOGIN, props<TwoFACode>());

export const authDisable2FA = createAction(AuthActionType.DISABLE_2FA, props<TwoFACode>());
export const authDisable2FAViaRecovery = createAction(AuthActionType.DISABLE_2FA_RECOVERY_CODE, props<BackupCode>());
export const authDisable2FASuccess = createAction(AuthActionType.DISABLE_2FA_SUCCESS, props<Identity>());
export const authDisable2FAFailure = createAction(AuthActionType.DISABLE_2FA_FAILURE, props<{ code: string }>());

export const authClearConfigure2FAState = createAction(AuthActionType.CLEAR_CONFIGURE_2FA_STATE);
