import { MixpanelRealtimeContext } from '@app/core/services/page-analytics/enums/mixpanel-realtime-context.enum';
import { MixpanelButtonClickEvents } from '@app/core/services/page-analytics/enums/mixpanel-button-click-events.enum';
import { MixpanelRealtimeEventsSetInterface } from '../interfaces/mixpanel-realtime-events-set.interface';

export class ActivityRealtimeConfig {
  public static get MixpanelViewContext(): Record<MixpanelRealtimeContext, MixpanelRealtimeEventsSetInterface> {
    return {
      [MixpanelRealtimeContext.ORDER]: {
        order: MixpanelButtonClickEvents.ORDER_OPEN_FROM_ACTIVITY,
        line: MixpanelButtonClickEvents.ORDER_LINE_OPEN_FROM_ACTIVITY,
        shipment: MixpanelButtonClickEvents.SHIPMENT_OPEN_FROM_ACTIVITY,
      },
      [MixpanelRealtimeContext.RFQ]: {
        order: MixpanelButtonClickEvents.RFQ_OPEN_FROM_ACTIVITY,
        line: MixpanelButtonClickEvents.RFQ_LINE_OPEN_FROM_ACTIVITY,
        shipment: MixpanelButtonClickEvents.EMPTY,
      },
      [MixpanelRealtimeContext.USER]: {
        order: MixpanelButtonClickEvents.USER_ORDER_OPEN_FROM_ACTIVITY,
        line: MixpanelButtonClickEvents.USER_ORDER_LINE_OPEN_FROM_ACTIVITY,
        shipment: MixpanelButtonClickEvents.USER_SHIPMENT_OPEN_FROM_ACTIVITY,
      },
      [MixpanelRealtimeContext.COMPANY]: {
        order: MixpanelButtonClickEvents.COMPANY_ORDER_OPEN_FROM_ACTIVITY,
        line: MixpanelButtonClickEvents.COMPANY_ORDER_LINE_OPEN_FROM_ACTIVITY,
        shipment: MixpanelButtonClickEvents.COMPANY_SHIPMENT_OPEN_FROM_ACTIVITY,
      },
      [MixpanelRealtimeContext.DASHBOARD]: {
        order: MixpanelButtonClickEvents.DASHBOARD_ORDER_OPEN_FROM_ACTIVITY,
        line: MixpanelButtonClickEvents.DASHBOARD_ORDER_LINE_OPEN_FROM_ACTIVITY,
        shipment: MixpanelButtonClickEvents.DASHBOARD_SHIPMENT_OPEN_FROM_ACTIVITY,
      },
      [MixpanelRealtimeContext.EMPTY]: {
        order: MixpanelButtonClickEvents.EMPTY,
        line: MixpanelButtonClickEvents.EMPTY,
        shipment: MixpanelButtonClickEvents.EMPTY,
      },
      [MixpanelRealtimeContext.SHIPMENT]: {
        order: MixpanelButtonClickEvents.ORDER_OPEN_FROM_ACTIVITY,
        line: MixpanelButtonClickEvents.ORDER_LINE_OPEN_FROM_ACTIVITY,
        shipment: MixpanelButtonClickEvents.SHIPMENT_OPEN_FROM_ACTIVITY,
      },
    };
  }
}
