import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ShipmentQuery } from '@app/shipment/models';
import { shipmentAdapter, ShipmentState } from './shipment.reducer';
import {
  createPagedSliceSelectorWithoutPlaceholder,
  selectRouterPageQuery,
  selectRouterParams,
  selectRouterQueryParams,
} from '@app/store/reducers';
import { addCompanyToShipmentFilters, mapQueryParametersToShipmentFilters } from '@app/shipment/core/state/util';
import { selectAuthIdentityCompanyId } from '@app/auth/core/state/auth.selectors';
import { selectCompanyOrderRoleFromIdentity } from '@app/company/core/state';

export const shipmentFeatureName = 'shipment';

const selectState = createFeatureSelector<ShipmentState>(shipmentFeatureName);

const { selectEntities, selectIds } = shipmentAdapter.getSelectors(selectState);

export const selectShipmentIds = selectIds;
export const selectShipmentEntities = selectEntities;
export const selectShipmentById = (id: string) => {
  return createSelector(selectEntities, entities => {
    return entities[id];
  });
};
export const selectRoutedShipmentId = createSelector(selectRouterParams, params => {
  return params && (params.shipmentId as undefined | string);
});

export const selectRoutedShipment = createSelector(selectRoutedShipmentId, selectEntities, (id, entities) => {
  return id ? entities[id] : undefined;
});

export const selectShipmentsTotal = createSelector(selectState, ({ total }) => {
  return total;
});

export const selectShipmentShowDocument = createSelector(selectState, ({ showDocument }) => {
  return showDocument;
});

export const selectShipmentsColumns = createSelector(selectState, ({ columns }) => {
  return columns;
});

const selectShipments = createSelector(selectIds, selectEntities, (ids, entities) => {
  return ids
    ? (ids as string[]).map(id => {
        return id ? entities[id] : null;
      })
    : null;
});

export const selectShipmentsPaged = createPagedSliceSelectorWithoutPlaceholder(selectShipments);
export const selectShipmentSearchQuery = createSelector(selectRouterQueryParams, params => {
  return params && params.query ? String(params.query) : '';
});

export const selectShipmentListFilters = createSelector(selectRouterQueryParams, queryParams => {
  return queryParams && mapQueryParametersToShipmentFilters(queryParams);
});

const selectFiltersWithCompany = createSelector(
  selectShipmentListFilters,
  selectAuthIdentityCompanyId,
  selectCompanyOrderRoleFromIdentity,
  (filters, companyId, role) => {
    return filters && addCompanyToShipmentFilters(filters, companyId, role);
  },
);

export const selectShipmentsQuery = createSelector(
  selectRouterPageQuery,
  selectFiltersWithCompany,
  selectShipmentSearchQuery,
  (pageQuery, filters, query): ShipmentQuery => {
    return {
      query,
      filters,
      offset: pageQuery.offset,
      limit: pageQuery.limit,
    };
  },
);
