import { environment } from '@environments/environment';

export interface AzureAppDetails {
  clientId: string;
  tenantId: string;
}

export interface SsoConnectionMap {
  [key: string]: AzureConnection;
}

export type AzureConnection = 'damen' | 'elcee' | 'huisman' | 'wassenburg' | 'tradecloud';

export interface AzureConnectionMappings {
  [key: string]: AzureAppDetails;
}

export const azureAppMappings: AzureConnectionMappings = {
  ...environment.azure.app,
};

const globalDomainMappings: SsoConnectionMap = {
  ...environment.azure.domainMappings,
};

const connectionsByEmail: { [email: string]: AzureConnection } = {
  'user@tradecloud1local.com': 'tradecloud',
  'user1@tradecloud1local.com': 'tradecloud',
};

export const ssoConnections = {
  getConnection: (email: string): AzureConnection | undefined => {
    const domain = email.substring(email.lastIndexOf('@') + 1);

    // first check env and user specific domain mappings
    return connectionsByEmail[email] || globalDomainMappings[domain];
  },
};
