import { RFQ } from '@app/rfq/models/rfq.model';

export const rfqMock: RFQ[] = [
  {
    id: '09484ff6-e0f0-510b-819f-5fa3ed780726-15-#47039733',
    destination: {
      locationType: 'SupplierPremises',
      code: 'LOC002',
      names: ['name 002'],
      addressLines: ['address 1', 'address 2'],
      countryCodeIso2: 'NL',
    },
    terms: {
      incotermsCode: 'EXW',
      incoterms: 'incotermsCode',
      paymentTermsCode: 'paymentTermsCode',
      paymentTerms: 'paymentTerms',
    },

    purchaseOrderNumber: '12-#49930900',
    endAt: new Date('2020-09-24T14:43:22.000Z'),

    supplierOrder: {
      companyId: '875a9a3b-e134-5515-9b25-2a33d60604f4',
      name: 'Sail - Hardware Store',
      buyerAccountNumber: '',
      description: 'Sales order description',
      properties: [],
      documents: [],
      notes: [],
    },

    indicators: {
      deliveryOverdue: false,
    },
    status: {
      processStatus: 'Issued',
      logisticsStatus: 'Open',
    },
    eventDates: {
      issuedAt: new Date('2020-09-17T14:43:22.000Z'),
    },
  },
  {
    id: '09484ff6-e0f0-510b-819f-5fa3ed780726-15-#47039733',
    destination: {
      locationType: 'SupplierPremises',
      code: 'LOC002',
      names: ['name 002'],
      addressLines: ['address 1', 'address 2'],
      countryCodeIso2: 'NL',
    },
    terms: {
      incotermsCode: 'EXW',
      incoterms: 'incotermsCode',
      paymentTermsCode: 'paymentTermsCode',
      paymentTerms: 'paymentTerms',
    },

    purchaseOrderNumber: '12-#49930900',
    endAt: new Date('2020-09-24T14:43:22.000Z'),

    supplierOrder: {
      companyId: '875a9a3b-e134-5515-9b25-2a33d60604f4',
      name: 'Sail - Hardware Store',
      buyerAccountNumber: '',
      description: 'Sales order description',
      properties: [],
      documents: [],
      notes: [],
    },

    indicators: {
      deliveryOverdue: false,
    },
    status: {
      processStatus: 'Issued',
      logisticsStatus: 'Open',
    },
    eventDates: {
      issuedAt: new Date('2020-09-17T14:43:22.000Z'),
    },
  },
  {
    id: '09484ff6-e0f0-111b-819f-5fa3ed780726-15-#47039733',
    destination: {
      locationType: 'warehouse',
      code: 'W45',
      names: ['Warehouse'],
      addressLines: ['Atlantic street 5'],
      postalCode: '7795 AW',
      city: 'London',
      countryCodeIso2: 'UK',
    },
    terms: {
      incotermsCode: 'EXW',
      incoterms: 'Ex works',
      paymentTermsCode: '60D',
      paymentTerms: '60 days',
    },

    purchaseOrderNumber: '12-#11110900',
    endAt: new Date('2020-09-24T14:43:22.000Z'),

    supplierOrder: {
      companyId: '875a9a3b-e134-5515-9b25-2a33d60604f4',
      name: 'Blue sailboats',
      buyerAccountNumber: '',
      description: 'Sales order description',
      properties: [],
      documents: [],
      notes: [],
    },

    indicators: {
      deliveryOverdue: false,
    },
    status: {
      processStatus: 'Submitted',
      logisticsStatus: 'Open',
    },
    eventDates: {
      issuedAt: new Date('2020-09-17T14:43:22.000Z'),
    },
  },
  {
    id: '09484ff6-e0f0-122b-819f-5fa3ed780726-15-#47039733',
    destination: {
      locationType: 'factory',
      code: 'F4',
      names: ['factory 4'],
      addressLines: ['Pacific Straße 1'],
      postalCode: '12074',
      city: 'Berlin',
      countryCodeIso2: 'DE',
    },
    terms: {
      incotermsCode: 'DDP',
      incoterms: 'Delivered Duty Paid',
      paymentTermsCode: '30D',
      paymentTerms: '30 days',
    },

    purchaseOrderNumber: '08-#86430153',
    endAt: new Date('2020-09-24T14:43:22.000Z'),

    supplierOrder: {
      companyId: '875a9a3b-e134-5515-9b25-2a33d60604f4',
      name: 'Blue sailboats',
      buyerAccountNumber: '',
      description: 'Sales order description',
      properties: [],
      documents: [],
      notes: [],
    },

    indicators: {
      deliveryOverdue: false,
    },
    status: {
      processStatus: 'Submitted',
      logisticsStatus: 'Open',
    },
    eventDates: {
      issuedAt: new Date('2020-09-17T14:43:22.000Z'),
    },
  },
  {
    id: '09484ff6-e0f0-133b-819f-5fa3ed780726-15-#47039733',
    destination: {
      locationType: 'warehouse',
      code: 'W41',
      names: ['warehouse 41'],
      addressLines: ['Pacific Straße 1'],
      postalCode: '12074',
      city: 'Berlin',
      countryCodeIso2: 'DE',
    },
    terms: {
      incotermsCode: 'DDP',
      incoterms: 'Delivered Duty Paid',
      paymentTermsCode: '30D',
      paymentTerms: '30 days',
    },

    purchaseOrderNumber: '95-#84369137',
    endAt: new Date('2020-10-09T14:43:22.000Z'),

    supplierOrder: {
      companyId: '875a9a3b-e134-5515-9b25-2a33d60604f4',
      name: 'Blue sailboats',
      buyerAccountNumber: '',
      description: 'Sales order description',
      properties: [],
      documents: [],
      notes: [],
    },

    indicators: {
      deliveryOverdue: false,
    },
    status: {
      processStatus: 'Issued',
      logisticsStatus: 'Open',
    },
    eventDates: {
      issuedAt: new Date('2020-10-02T14:43:22.000Z'),
    },
  },
  {
    id: '09484ff6-e0f0-510b-819f-5fa3ed780726-15-#47039733',
    destination: {
      locationType: 'SupplierPremises',
      code: 'LOC002',
      names: ['name 002'],
      addressLines: ['address 1', 'address 2'],
      countryCodeIso2: 'NL',
    },
    terms: {
      incotermsCode: 'EXW',
      incoterms: 'incotermsCode',
      paymentTermsCode: 'paymentTermsCode',
      paymentTerms: 'paymentTerms',
    },

    purchaseOrderNumber: '12-#49930900',
    endAt: new Date('2020-09-24T14:43:22.000Z'),

    supplierOrder: {
      companyId: '875a9a3b-e134-5515-9b25-2a33d60604f4',
      name: 'Sail - Hardware Store',
      buyerAccountNumber: '',
      description: 'Sales order description',
      properties: [],
      documents: [],
      notes: [],
    },

    indicators: {
      deliveryOverdue: false,
    },
    status: {
      processStatus: 'Issued',
      logisticsStatus: 'Open',
    },
    eventDates: {
      issuedAt: new Date('2020-09-17T14:43:22.000Z'),
    },
  },
  {
    id: '09484ff6-e0f0-111b-819f-5fa3ed780726-15-#47039733',
    destination: {
      locationType: 'warehouse',
      code: 'W45',
      names: ['Warehouse'],
      addressLines: ['Atlantic street 5'],
      postalCode: '7795 AW',
      city: 'London',
      countryCodeIso2: 'UK',
    },
    terms: {
      incotermsCode: 'EXW',
      incoterms: 'Ex works',
      paymentTermsCode: '60D',
      paymentTerms: '60 days',
    },

    purchaseOrderNumber: '12-#11110900',
    endAt: new Date('2020-09-24T14:43:22.000Z'),

    supplierOrder: {
      companyId: '875a9a3b-e134-5515-9b25-2a33d60604f4',
      name: 'Blue sailboats',
      buyerAccountNumber: '',
      description: 'Sales order description',
      properties: [],
      documents: [],
      notes: [],
    },

    indicators: {
      deliveryOverdue: false,
    },
    status: {
      processStatus: 'Submitted',
      logisticsStatus: 'Open',
    },
    eventDates: {
      issuedAt: new Date('2020-09-17T14:43:22.000Z'),
    },
  },
  {
    id: '09484ff6-e0f0-122b-819f-5fa3ed780726-15-#47039733',
    destination: {
      locationType: 'factory',
      code: 'F4',
      names: ['factory 4'],
      addressLines: ['Pacific Straße 1'],
      postalCode: '12074',
      city: 'Berlin',
      countryCodeIso2: 'DE',
    },
    terms: {
      incotermsCode: 'DDP',
      incoterms: 'Delivered Duty Paid',
      paymentTermsCode: '30D',
      paymentTerms: '30 days',
    },

    purchaseOrderNumber: '08-#86430153',
    endAt: new Date('2020-09-24T14:43:22.000Z'),

    supplierOrder: {
      companyId: '875a9a3b-e134-5515-9b25-2a33d60604f4',
      name: 'Blue sailboats',
      buyerAccountNumber: '',
      description: 'Sales order description',
      properties: [],
      documents: [],
      notes: [],
    },

    indicators: {
      deliveryOverdue: false,
    },
    status: {
      processStatus: 'Submitted',
      logisticsStatus: 'Open',
    },
    eventDates: {
      issuedAt: new Date('2020-09-17T14:43:22.000Z'),
    },
  },
  {
    id: '09484ff6-e0f0-133b-819f-5fa3ed780726-15-#47039733',
    destination: {
      locationType: 'warehouse',
      code: 'W41',
      names: ['warehouse 41'],
      addressLines: ['Pacific Straße 1'],
      postalCode: '12074',
      city: 'Berlin',
      countryCodeIso2: 'DE',
    },
    terms: {
      incotermsCode: 'DDP',
      incoterms: 'Delivered Duty Paid',
      paymentTermsCode: '30D',
      paymentTerms: '30 days',
    },

    purchaseOrderNumber: '95-#84369137',
    endAt: new Date('2020-10-09T14:43:22.000Z'),

    supplierOrder: {
      companyId: '875a9a3b-e134-5515-9b25-2a33d60604f4',
      name: 'Blue sailboats',
      buyerAccountNumber: '',
      description: 'Sales order description',
      properties: [],
      documents: [],
      notes: [],
    },

    indicators: {
      deliveryOverdue: false,
    },
    status: {
      processStatus: 'Issued',
      logisticsStatus: 'Open',
    },
    eventDates: {
      issuedAt: new Date('2020-10-02T14:43:22.000Z'),
    },
  },
  {
    id: '09484ff6-e0f0-133b-819f-5fa3ed780726-15-#47039733',
    destination: {
      locationType: 'warehouse',
      code: 'W41',
      names: ['warehouse 41'],
      addressLines: ['Pacific Straße 1'],
      postalCode: '12074',
      city: 'Berlin',
      countryCodeIso2: 'DE',
    },
    terms: {
      incotermsCode: 'DDP',
      incoterms: 'Delivered Duty Paid',
      paymentTermsCode: '30D',
      paymentTerms: '30 days',
    },

    purchaseOrderNumber: '95-#84369137',
    endAt: new Date('2020-10-09T14:43:22.000Z'),

    supplierOrder: {
      companyId: '875a9a3b-e134-5515-9b25-2a33d60604f4',
      name: 'Blue sailboats',
      buyerAccountNumber: '',
      description: 'Sales order description',
      properties: [],
      documents: [],
      notes: [],
    },

    indicators: {
      deliveryOverdue: false,
    },
    status: {
      processStatus: 'Issued',
      logisticsStatus: 'Open',
    },
    eventDates: {
      issuedAt: new Date('2020-10-02T14:43:22.000Z'),
    },
  },
];
