<ng-container *ngIf="vm$ | async as vm">
<mat-expansion-panel
  [expanded]="panelExpanded"
  (afterCollapse)="delete2FASecret()"
  [ngClass]="vm.configure2FA.secret ? 'full-width' : ''"
>
  <mat-expansion-panel-header>
    <mat-panel-title i18n="@@shared.2fa.factor">Two-factor authentication</mat-panel-title>
    <mat-panel-description>
      <ng-container *ngIf="vm.identity.twoFAEnabled; else notConfigured" i18n="@@shared.2fa.configured">Enabled.</ng-container>
      <ng-template #notConfigured i18n="@@shared.2fa.not.configured">Not configured.</ng-template>&nbsp;<ng-container *ngIf="vm.identity.twoFAEnforced" i18n="@@shared.2fa.enable.enfroced">2FA enforced on company level.</ng-container>
    </mat-panel-description>
  </mat-expansion-panel-header>
  <tc-setup-2fa
    *ngIf="vm.configure2FA.secret; else enableDisableButtons"
   ></tc-setup-2fa>
  <ng-template #enableDisableButtons>
    <tc-disable-2fa
      *ngIf="vm.identity.twoFAEnabled"
      [configure2FA]="vm.configure2FA"
      (submitted)="onDisable2FA($event)"
    ></tc-disable-2fa>
    <a *ngIf="!vm.identity.twoFAEnabled" mat-raised-button color="primary" (click)="generateSecret()" i18n="@@shared.2fa.enable">Enable</a>
  </ng-template>

  <mat-action-row>
    <ng-container *ngIf="vm.identity.twoFAEnabled; else setup">
      <ng-container *ngIf="!vm.backupCodes.length">
        <button mat-button type="button" (click)="reset()" i18n="button @@common.btn.cancel">CANCEL</button>
      </ng-container>
    </ng-container>
    <ng-template #setup>
      <button mat-button type="button" (click)="reset()" i18n="button @@common.btn.cancel">CANCEL</button>
      <button mat-button type="button" *ngIf="!vm.configure2FA.secret" (click)="generateSecret()" color="primary" i18n="@@shared.2fa.generate">
        SETUP
      </button>
    </ng-template>
  </mat-action-row>
</mat-expansion-panel>
</ng-container>
