import { createEntityAdapter, EntityState } from '@ngrx/entity';

import { Account } from '@app/account/models';

import {
  AccountActionType,
  accountAdd,
  accountCreate,
  accountCreateFailure,
  accountCreateSuccess,
  accountNotFound, accountReassignSend, accountReassignSendFailure, accountReassignSendSuccess,
} from './account.actions';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';
import { createReducer, on } from '@ngrx/store';
import { OnReducer } from '@ngrx/store/src/reducer_creator';

export interface AccountState extends EntityState<Account | null> {
  readonly pending?: boolean;
  readonly error?: string;
}

export const createId = (companyId: string, accountCompanyId: string) => {
  return `${companyId}_${accountCompanyId}`;
};

export const selectId = (account: Account | null) => {
  return account ? createId(account.companyId, account.accountCompanyId) : 'null';
};

export const accountEntityAdapter = createEntityAdapter<Account | null>({ selectId });

export const initialState = accountEntityAdapter.getInitialState();

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const notFoundReducer: OnReducer<
  AccountState,
  [ActionCreator<AccountActionType.NOT_FOUND, (props: { id: string }) => { id: string }>]
> = (state: AccountState, { id }) => {
  return {
    ...state,
    entities: {
      ...state.entities,
      [id]: null,
    },
    ids: [...state.ids, id],
  };
};

const accountReducer = createReducer<AccountState, TypedAction<AccountActionType>>(
  initialState,
  on(accountAdd, (state: AccountState, { entity }) => {
    return accountEntityAdapter.addOne(entity, state);
  }),
  on(accountNotFound, notFoundReducer),
  on(accountCreate,accountReassignSend, (state, { companyId, body }) => {
    return accountEntityAdapter.upsertOne({ companyId, ...body }, { ...state, pending: true, error: undefined });
  }),
  on(accountCreateSuccess, accountReassignSendSuccess, state => {
    return { ...state, pending: false, error: undefined };
  }),
  on(accountCreateFailure, accountReassignSendFailure, (state, { id, error }) => {
    return {
      ...state,
      entities: {
        ...state.entities,
        [id]: null,
      },
      pending: false,
      error,
    };
  }),
);

export function reducer(state = initialState, action: TypedAction<AccountActionType>): AccountState {
  return accountReducer(state, action);
}
