const ONE_DAY = 1000 * 3600 * 24;

export function dateDifference(currentFirstDelivery?: Date, proposedFirstDelivery?: Date): number | undefined {
  if (!currentFirstDelivery || !proposedFirstDelivery) {
    return undefined;
  }

  const timeDiff = proposedFirstDelivery.getTime() - currentFirstDelivery.getTime();

  return Math.ceil(timeDiff / ONE_DAY);
}
