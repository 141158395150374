import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, switchMap } from 'rxjs/operators';
import { API } from '@app/shared/api/api.service';
import { SendMessage } from '@app/conversation/models';
import { Subscription } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { AuthState } from '@app/auth/core/state/auth.reducer';
import { selectAuthIdentityCompanyId } from '@app/auth/core/state/auth.selectors';

@Injectable()
export class ConversationService {
  constructor(private http: HttpClient, private api: API, private store$: Store<AuthState>) {}

  public sendMessage({ entityId, entityType, message, internal }: SendMessage): Subscription {
    return this.store$
      .pipe(
        select(selectAuthIdentityCompanyId),
        switchMap(companyId => {
          return this.http.post(this.api.CONVERSATION_SEND_MESSAGE(), {
            entityId,
            entityType,
            message,
            internal,
            createdByCompanyId: companyId,
          });
        }),
        first(),
      )
      .subscribe();
  }
}
