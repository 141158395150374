<mat-list-item class="auto-height two-fa-row">
  <div class="left-column">
    <qrcode [qrdata]="getTOTPString()" [width]="160" [errorCorrectionLevel]="'H'"></qrcode>
  </div>
  <div class="right-column">
    <h4 mat-subheader class="twoFa" i18n="@@shared.2fa.step2">Step 2</h4>
    <h3 mat-subheader class="twoFa" i18n="@@shared.2fa.scan">Scan this QR code</h3>
    <p i18n="@@shared.2fa.open">Open the authentication app and:</p>
    <ul>
      <li i18n="@@shared.2fa.tap">Tap the "+" icon in the top-right of the app</li>
      <li i18n="@@shared.2fa.scan">Scan the image to the left, using your phone's camera</li>
    </ul>
  </div>
</mat-list-item>
