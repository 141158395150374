<h2 mat-dialog-title i18n="@@storage.dialog.title">Select a picture</h2>

<mat-dialog-content>
  <div class="cropper">
    <img *ngIf="safeUrl" [src]="safeUrl" (load)="imageLoaded($event)">
    <mat-spinner *ngIf="!cropper" [diameter]="36" [strokeWidth]="4"></mat-spinner>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button mat-dialog-close i18n="button @@common.btn.cancel">CANCEL</button>
  <button mat-button color="primary" (click)="select()" cdkFocusInitial i18n="@@storage.dialog.select">SELECT</button>
</mat-dialog-actions>
