export function blobToFile(blob: Blob, originalFile: File, options?: FilePropertyBag): File {
  try {
    return new File([blob], originalFile.name, options);
  } catch (e) {
    // No file constructor support
    (blob as any).name = originalFile.name;

    return blob as File;
  }
}
