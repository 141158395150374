import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { FullRouterStateSerializer, RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '@environments/environment';
import { globalReducers, metaReducers } from './reducers';
import { CustomRouterStateSerializer } from './router-serializer';
import { AccountEffects } from '../account/core/state/account.effects';
import { AnalyticsEffects } from '../analytics/core/state/analytics.effects';
import { AuthEffects } from '../auth/core/state/auth.effects';
import { CompanyEffects } from '../company/core/state/company.effects';
import { ConnectionEffects } from '../connection/core/state/connection.effects';
import { CoreEffects } from '../core/state/core.effects';
import { ObjectStorageEffects } from '../object-storage/core/state/object-storage.effects';
import { OrderLineEffects } from '../order-line/core/state/order-line.effects';
import { OrderEffects } from '../order/core/state/order.effects';
import { ShipmentEffects } from '../shipment/core/state/shipment.effects';
import { TaskEffects } from '../task/core/state/task.effects';
import { UserEffects } from '../user/core/state/user.effects';
import { CacheEffects } from '@app/cache/state/cache.effects';
import { MsalEffects } from '@app/msal/core/state/msal.effects';

@NgModule({
  imports: [
    StoreModule.forRoot(globalReducers, {
      metaReducers,
      runtimeChecks: { strictStateImmutability: false, strictActionImmutability: false },
    }),
    StoreRouterConnectingModule.forRoot({ serializer: FullRouterStateSerializer }),
    environment.production ? [] : StoreDevtoolsModule.instrument(),
    EffectsModule.forRoot([
      AccountEffects,
      AnalyticsEffects,
      AuthEffects,
      CacheEffects,
      CompanyEffects,
      ConnectionEffects,
      CoreEffects,
      ObjectStorageEffects,
      OrderLineEffects,
      OrderEffects,
      MsalEffects,
      ShipmentEffects,
      TaskEffects,
      UserEffects,
    ]),
  ],
  providers: [{ provide: RouterStateSerializer, useClass: CustomRouterStateSerializer }],
})
export class AppStoreModule {}
