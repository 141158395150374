<mat-list-item class="auto-height two-fa-row">
  <div class="left-column">
    <mat-icon>text_fields</mat-icon>
  </div>
  <div class="right-column">
    <h4 mat-subheader class="twoFa" i18n="@@shared.2fa.step3">Step 3</h4>
    <h3 mat-subheader class="twoFa" i18n="@@shared.2fa.code">Enter Verification Code</h3>
    <p i18n="@@shared.2fa.barcode">Once the QR code above is scanned, enter the 6-digit verification code generated by the app</p>
    <form [formGroup]="form" (ngSubmit)="submit()">
      <mat-form-field appearance="fill">
        <mat-label i18n="@@shared.2fa.verify">Verify the code</mat-label>
        <input type="number" matInput formControlName="code">
        <mat-error *tcMatFormFieldError="'required'" i18n="@@shared.2fa.err.code">
          Enter a Code
        </mat-error>
        <mat-error *tcMatFormFieldError="'identity.2fa.invalid_code'" i18n="error @@shared.err.invalid.code">
          Invalid Code
        </mat-error>
        <mat-error *tcMatFormFieldError="'identity.2fa.validation_error'" i18n="error @@shared.err.again.code">
          Try Again!
        </mat-error>
      </mat-form-field>

      <button type="submit" mat-raised-button color="primary" [disabled]="!form.valid" i18n="@@shared.2fa.submit">Verify</button>
    </form>
  </div>
</mat-list-item>
