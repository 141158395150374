import { RoleGuard } from './guards/role.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { PrivateGuard } from './guards/private.guard';
import { PublicGuard } from './guards/public.guard';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { AuthService } from './services/auth.service';
import { JwtService } from './services/jwt.service';
import { reducer } from './state/auth.reducer';
import { authFeatureName } from './state/auth.selectors';
import { EnvSwitchGuard } from '@app/auth/core/guards/env-switch.guard';

@NgModule({
  imports: [StoreModule.forFeature(authFeatureName, reducer)],
  providers: [
    RoleGuard,
    PrivateGuard,
    EnvSwitchGuard,
    PublicGuard,
    AuthService,
    JwtService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
  ],
})
export class AuthCoreModule {}
