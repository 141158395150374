import { Pipe, PipeTransform } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { OrderLineSearchView } from '@app/order/models';
import { isNotNullOrUndefined } from '@app/util/operators/is-not-null-or-undefined';
import { orderLineFetch } from '@app/order-line/core/state/order-line.actions';
import { OrderLineState } from '@app/order-line/core/state/order-line.reducer';
import { selectOrderLineById } from '@app/order-line/core/state/order-line.selectors';

@Pipe({
  name: 'tcLazyOrderLine',
})
export class LazyOrderLinePipe implements PipeTransform {
  constructor(private store$: Store<OrderLineState>) {}

  // eslint-disable-next-line
  transform(id?: string): Observable<OrderLineSearchView> {
    if (!id) {
      throw new Error('LazyOderLinePipe can only be used with Order-Line UUID');
    }

    this.store$.dispatch(orderLineFetch({ id }));

    return this.store$.pipe(select(selectOrderLineById(id)), isNotNullOrUndefined());
  }
}
