import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BuyerOrderTerms, IncotermsCode, LocationType, Order } from '@app/order/models';

@Component({
  selector: 'tc-order-terms',
  templateUrl: './order-terms.component.html',
  styleUrls: ['./order-terms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderTermsComponent {
  @Input()
  order?: Order;

  public get showIncotermsView(): boolean {
    return !!this.incotermsCode || !!this.incotermsLocationName || !!this.incoterms;
  }

  public get showPaymentView(): boolean {
    return !!this.paymentTermsCode || !!this.paymentTermsDescription || !!this.paymentTerms;
  }

  public get incotermsCode(): IncotermsCode | undefined {
    return this.terms?.incotermsCode;
  }

  public get incotermsLocationName(): string | undefined {
    return this.terms?.incotermsLocationName;
  }

  public get incoterms(): string | undefined {
    return this.terms?.incoterms;
  }

  public get incotermsLocationType(): LocationType | undefined {
    return this.terms?.incotermsLocationType;
  }

  public get paymentTermsCode(): string | undefined {
    return this.terms?.paymentTermsCode;
  }

  public get paymentTermsDescription(): string | undefined {
    return this.terms?.paymentTermsDescription;
  }

  public get paymentTerms(): string | undefined {
    return this.terms?.paymentTerms;
  }

  private get terms(): BuyerOrderTerms | undefined {
    return this.order?.buyerOrder.terms;
  }
}
