import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as root from '@app/store/reducers';
import { getCompanyOrderRole } from '@app/company/util/get-company-order-role';
import { selectAuthIdentityCompanyId } from '@app/auth/core/state/auth.selectors';
import { companyAdapter, CompanyState } from './company.reducer';

export const companyFeatureName = 'company';

const selectState = createFeatureSelector<CompanyState>(companyFeatureName);

const { selectEntities, selectIds } = companyAdapter.getSelectors(selectState);

export const selectCompanyEntities = selectEntities;
export const selectCompanyIds = selectIds;
export const selectCompanyById = (id: string) => {
  return createSelector(selectEntities, entities => {
    return entities[id];
  });
};
export const selectRoutedCompanyId = createSelector(root.selectRouterParams, params => {
  return params && params.companyId;
});

export const selectRoutedCompany = createSelector(selectRoutedCompanyId, selectEntities, (companyId, entities) => {
  return companyId && entities[companyId];
});

export const selectRoutedCompanyReminderSettings = createSelector(selectRoutedCompany, entity => {
  return entity?.taskOverdueSettings;
});

export const selectRoutedCompanyWebhookConnectorSettings = createSelector(selectRoutedCompany, entity => {
  return entity?.webhookConnector;
});

export const selectRoutedCompanyDelaySettings = createSelector(selectRoutedCompany, entity => {
  return entity?.orderOverdueDelaySettings;
});


export const selectRoutedCompanyOverdueConnection = createSelector(selectRoutedCompanyDelaySettings, entity => {
  return entity?.connectionOverdueSettings;
})

export const selectCompanyFromIdentity = createSelector(selectAuthIdentityCompanyId, selectEntities, (id, entities) => {
  return id ? entities[id] : undefined;
});

export const selectCompanyRoleFromIdentity = createSelector(selectCompanyFromIdentity, entity => {
  return entity && entity.roles;
});

export const selectCompanyOrderRoleFromIdentity = createSelector(selectCompanyFromIdentity, entity => {
  return entity && getCompanyOrderRole(entity);
});

export const selectCompanySciUrl = createSelector(selectRoutedCompany, company => {
  return company?.url;
});

export const selectCompanyShipmentSettings = createSelector(selectRoutedCompany, company => {
  return {
    webhookUrl: company?.webhookUrl,
    method: company?.method,
    basicAuthCredentials: company?.basicAuthCredentials,
    enabledEventNames: company?.enabledEventNames,
  };
});

export const selectCompanySciCredentials = createSelector(selectRoutedCompany, company => {
  return company && company.credentials ? company.credentials : [];
});

export const selectPending = createSelector(selectState, ({ pending }: CompanyState) => {
  return pending;
});
export const selectError = createSelector(selectState, ({ error }: CompanyState) => {
  return error;
});
