import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { GlobalState, selectRouterQueryPageIndex, selectRouterQueryPageSize } from '@app/store/reducers';

@Component({
  selector: 'tc-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaginatorComponent {
  @Input()
  length!: number;

  @Output()
  readonly changePage = new EventEmitter<{ offset: number; limit: number }>();

  public pageIndex$ = this.store$.pipe(select(selectRouterQueryPageIndex));
  public pageSize$ = this.store$.pipe(select(selectRouterQueryPageSize));

  constructor(private router: Router, private store$: Store<GlobalState>) {}

  public onPage({ pageIndex, pageSize }: PageEvent): void {
    const offset = pageIndex * pageSize;
    const limit = pageSize;

    this.changePage.emit({ offset, limit });

    this.router.navigate([], {
      queryParams: { offset, limit },
      queryParamsHandling: 'merge',
    });
  }
}
