<ng-container *ngIf="{canDirect: canDirect$ | async} as data">
  <ng-container *ngIf="display === ''">
    <h4 matLine>
      <a (click)="!isLoading && document && openAttachedDocument(document, data.canDirect)" [ngClass]="{'doc_loading': isLoading}">
        {{ title }}
      </a>
    </h4>
    <p matLine *ngIf="document && document.code !==  title">{{ document?.code }}</p>
    <p matLine>{{ document && document.revision }}</p>
    <p matLine>{{ document && document.type && document.type.replace(replacStr, " ") }}</p>
    <p matLine>{{ document && document.description }}</p>
  </ng-container>
  <ng-container *ngIf="display === 'inline'">
    <a (click)="!isLoading && document && openAttachedDocument(document, data.canDirect)" [ngClass]="{'doc_loading': isLoading}">
      {{ title }}
    </a>
  </ng-container>
  <button
    *ngIf="display === 'icon'"
    mat-button
    class="button-menu"
    (click)="!isLoading && document && openAttachedDocument(document, data.canDirect)" [ngClass]="{'doc_loading': isLoading}"
  >
    <mat-icon>download</mat-icon>
  </button>
</ng-container>
