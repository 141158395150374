import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { merge, Observable, Subject } from 'rxjs';
import { UserInterface } from '@app/user/models';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime, filter, map, pluck, startWith, switchMap, takeUntil } from 'rxjs/operators';
import { CompanyRole } from '@app/company/models';
import { UserService } from '@app/user/core/services/user.service';

export interface AssignDialogData {
  role: CompanyRole;
  companyId: string;
}

@Component({
  selector: 'tc-assign-dialog',
  templateUrl: './assign-dialog.component.html',
  styleUrls: ['./assign-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssignDialogComponent implements OnInit, OnDestroy {
  public assignForm = this.fb.group({
    userIds: new UntypedFormControl([], Validators.required),
  });
  public contactQuery = new UntypedFormControl(null);
  public userSuggestions$!: Observable<UserInterface[]>;
  public contactCancellation$ = new Subject<UserInterface[]>();

  public title!: string;
  public btnSave!: string;
  public canRemove: boolean = false;

  private destroyed$ = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: { value: AssignDialogData; template: { title: string; btnSave: string; canRemove?: boolean } },
    private dialogRef: MatDialogRef<AssignDialogComponent>,
    private fb: UntypedFormBuilder,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.title = this.data.template.title;
    this.btnSave = this.data.template.btnSave;
    this.canRemove = !!this.data.template?.canRemove;
    this.userSuggestions$ = merge(
      this.searchUsers$(this.contactQuery.valueChanges),
      this.contactCancellation$.asObservable(),
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public addUser({ option: { value } }: MatAutocompleteSelectedEvent): void {
    this.contactQuery.reset();
    const user = value as UserInterface;
    this.assignForm.controls.userIds.setValue([user.id], { emit: true });
    this.contactCancellation$.next([]);
  }

  public submit(): void {
    if (this.assignForm.valid) {
      const [userId] = this.assignForm.value.userIds;
      this.dialogRef.close({ userId });
    }
  }

  public removeUser(): void {
    this.dialogRef.close({ userId: null });
  }

  private searchUsers$(value$: Observable<string>): Observable<UserInterface[]> {
    return value$.pipe(
      filter((value): value is string => {
        return typeof value === 'string';
      }),
      filter(value => {
        return value.length > 2;
      }),
      debounceTime(300),
      startWith(''),
      map(query => {
        return {
          companyId: this.data.value.companyId,
          companyRole: this.data.value.role,
          query,
          offset: 0,
        };
      }),
      switchMap(query => {
        return this.userService.search$(query).pipe(
          pluck('data'),
          map(users => {
            return users.sort((a, b) => {
              return (a.profile.firstName + a.profile.lastName).localeCompare(b.profile.firstName + b.profile.lastName);
            });
          }),
          map(users => {
            return users.filter(user => {
              return user.status === 'active';
            });
          }),
        );
      }),
      takeUntil(this.destroyed$),
    );
  }
}
