import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'tc-expand-collapse',
  templateUrl: './expand-collapse.component.html',
  styleUrls: ['./expand-collapse.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpandCollapseComponent {
  @Input()
  summary!: string;

  @Input()
  open = false;

  @Output()
  readonly toggleChange = new EventEmitter<boolean>();

  public toggle(): void {
    this.open = !this.open;
    this.toggleChange.emit(this.open);
  }
}
