import { coerceArray, coerceNonEmptyArray } from '@app/util/helpers';
import { ShipmentFilterParameters, ShipmentQueryFilters, ShipmentQueryFiltersDto } from '@app/shipment/models';
import { CompanyRole } from '@app/company/models';

export function mapQueryParametersToShipmentFilters({
  query,
  companyIds,
}: ShipmentFilterParameters): ShipmentQueryFilters {
  return {
    query: coerceArray(query),
    companyIds: coerceArray(companyIds),
  };
}

export function mapShipmentFiltersToQueryParameters({
  query,
  companyIds,
}: ShipmentQueryFilters): ShipmentFilterParameters {
  const data = {
    companyIds: coerceNonEmptyArray(companyIds),
  };

  if (query) {
    return {
      ...data,
      query: query[0],
    };
  }

  return data;
}

export function addCompanyToShipmentFilters(
  { query, companyIds }: ShipmentQueryFilters,
  companyId?: string,
  role?: CompanyRole | null,
): ShipmentQueryFiltersDto {
  if (role === undefined || !companyId) {
    return {
      supplierShipment: {
        companyIds: [],
      },
      buyerShipment: {
        companyIds: [],
      },
    };
  }

  if (role === CompanyRole.BUYER) {
    return {
      supplierShipment: {
        companyIds,
      },
      buyerShipment: {
        companyIds: [companyId],
      },
    };
  }

  if (role === CompanyRole.SUPPLIER) {
    return {
      supplierShipment: {
        companyIds: [companyId],
      },
      buyerShipment: {
        companyIds,
      },
    };
  }

  return {
    supplierShipment: {
      companyIds: [],
    },
    buyerShipment: {
      companyIds: [],
    },
  };
}
