<div class="activities mat-typography">
  <div class="filter">
    <mat-form-field appearance="fill">
      <mat-label i18n="As in Show me this @@activity.realtime.label">Show</mat-label>
      <mat-select
        [(ngModel)]="selectedEntityType"
        (ngModelChange)="onFiltersChange(true)"
      >
        <mat-option
          *ngFor="let option of filterOptions"
          [textContent]="option.title"
          [value]="option.value"
        ></mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <tc-activity-list
    [mixpanelContext]="mixpanelContext"
    [activities]="activities$ | async | tcActivityFilter"
  ></tc-activity-list>
</div>

<button mat-button *ngIf="canLoadMore$ | async" (click)="loadMore()" i18n="button @@activity.realtime.load">LOAD MORE</button>
