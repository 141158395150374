import { createAction, props } from '@ngrx/store';
import { TaskEntityType, TaskQueryDto, TaskQueryFilters, TaskQueryResponse, TaskType } from '@app/task/models';
import { AssignType } from '@app/order/routes/assign/state/assign.model';

export enum TaskListActionType {
  QUERY = '[Task List] Query',
  QUERY_SUCCESS = '[Task List] Query Success',
  QUERY_FAILURE = '[Task List] Query Failure',
  UPDATE_FILTERS = '[Task List] Update Filters',
  RESET_FILTERS = '[Task List] Reset Filters',
  REFRESH = '[Task List] Refresh',
  LOAD_MORE = '[Task List] Load more',
  OUTDATE = '[Task List] Out date',

  REASSIGN_ORDER_LINE_TASK = '[Task List] Reassign order and line',
  SEND_REASSIGN_ORDER_LINE_TASK = '[Task List] Send Reassign order and line',
  REASSIGN_CONVERSATION_TASK = '[Task List] Reassign conversation',
  SEND_REASSIGN_CONVERSATION_TASK = '[Task List] Send Reassign conversation',
  REASSIGN_SHIPMENT_TASK = '[Task List] Reassign shipment',
  SEND_REASSIGN_SHIPMENT_TASK = '[Task List] Send Reassign shipment',
  REASSIGN_FORECAST_TASK = '[Task List] Reassign forecast',
  SEND_REASSIGN_FORECAST_TASK = '[Task List] Send Reassign forecast',
}

export const taskListQuery = createAction(TaskListActionType.QUERY, props<{ query: TaskQueryDto }>());
export const taskListQuerySuccess = createAction(
  TaskListActionType.QUERY_SUCCESS,
  props<{ query: TaskQueryDto; response: TaskQueryResponse }>(),
);
export const taskListQueryFailure = createAction(TaskListActionType.QUERY_FAILURE, props<{ error: string }>());
export const taskListUpdateFilters = createAction(
  TaskListActionType.UPDATE_FILTERS,
  props<{ filters: TaskQueryFilters }>(),
);
export const taskListRefresh = createAction(TaskListActionType.REFRESH);
export const taskListLoadMore = createAction(TaskListActionType.LOAD_MORE);
export const taskListResetFilters = createAction(
  TaskListActionType.RESET_FILTERS,
  props<{ filters: TaskQueryFilters }>(),
);
export const taskListOutdate = createAction(TaskListActionType.OUTDATE);
export const reassignOrderLine = createAction(TaskListActionType.REASSIGN_ORDER_LINE_TASK, props<TaskStartAssign>());
export const sendReassignOrderLine = createAction(
  TaskListActionType.SEND_REASSIGN_ORDER_LINE_TASK,
  props<TaskOrderLineReassign>(),
);
export const reassignConversation = createAction(
  TaskListActionType.REASSIGN_CONVERSATION_TASK,
  props<TaskStartAssign>(),
);
export const sendReassignConversation = createAction(
  TaskListActionType.SEND_REASSIGN_CONVERSATION_TASK,
  props<TaskConversationReassign>(),
);

export const reassignShipment = createAction(TaskListActionType.REASSIGN_SHIPMENT_TASK, props<TaskStartAssign>());
export const sendReassignShipment = createAction(
  TaskListActionType.SEND_REASSIGN_SHIPMENT_TASK,
  props<TaskShipmentReassign>(),
);

export const reassignForecast = createAction(TaskListActionType.REASSIGN_FORECAST_TASK, props<TaskStartAssign>());
export const sendReassignForecast = createAction(
  TaskListActionType.SEND_REASSIGN_FORECAST_TASK,
  props<TaskForecastReassign>(),
);

export interface TaskStartAssign {
  actionType: AssignType.REASSIGN | AssignType.ASSIGN;
  taskType: TaskType;
  messageId?: string;
  taskId?: string;
  entityId?: string;
  entityType?: TaskEntityType;
  orderId?: string;
}

export interface TaskOrderLineReassign {
  companyId: string;
  entityId: string;
  userId: string;
  taskIds: string[];
}

export interface TaskOrderLineReassignRequest {
  body: TaskOrderLineReassign;
}

export interface TaskShipmentReassign {
  companyId: string;
  entityId: string;
  userId: string;
  taskIds: string[];
}

export interface TaskShipmentReassignRequest {
  body: TaskShipmentReassign;
}

export interface TaskForecastReassign {
  entityId: string;
  userId: string;
  taskIds: string[];
}

export interface TaskForecastReassignRequest {
  body: TaskForecastReassign;
}

export interface TaskConversationReassign {
  companyId: string;
  entityId: string;
  entityType: string;
  userId: string;
  messageId: string;
}

export interface TaskConversationReassignRequest {
  body: TaskConversationReassign;
}
