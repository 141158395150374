import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Clipboard } from '@angular/cdk/clipboard';
import { saveAs } from 'file-saver';

@Component({
  selector: 'tc-setup-recovery-codes',
  templateUrl: './setup-recovery-codes.component.html',
  styleUrls: ['../base/two-factor-authentication.component.scss', './setup-recovery-codes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SetupRecoveryCodesComponent {
  @Input()
  backupCodes!: string[];

  @Output()
  readonly resetForm = new EventEmitter();

  public readonly isUserNotSaveCodes$ = new BehaviorSubject(true);

  get formattedText(): string[] {
    return this.backupCodes.map(str => {
      return `${str}\n`;
    });
  }

  constructor(private clipboard: Clipboard) {}

  copy(): void {
    this.clipboard.copy(this.formattedText.join('').toString());
    this.isUserNotSaveCodes$.next(false);
  }

  downloadFile(): void {
    const blob = new Blob(this.formattedText, { type: 'text/plain;charset=utf-8' });
    saveAs(blob, 'Tradecloud-backup-codes.txt');
    this.isUserNotSaveCodes$.next(false);
  }

  close(): void {
    this.resetForm.emit();
  }
}
