import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Configure2FA } from '@app/auth/core/state/auth.reducer';
import { Identity } from '@app/auth/models';

@Component({
  selector: 'tc-setup-2fa-step-2',
  templateUrl: './setup-2fa-step2.component.html',
  styleUrls: ['../base/two-factor-authentication.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Setup2FAStep2Component {
  @Input()
  configure2FA!: Configure2FA;

  @Input()
  identity!: Identity;

  getTOTPString(): string {
    return `otpauth://totp/Tradecloud1:${this.identity.username}?secret=${this.configure2FA.secret}&issuer=Tradecloud1&algorithm=SHA1&digits=6&period=30`;
  }
}
