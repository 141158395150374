import { ChangeDetectionStrategy, Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { selectRoutedOrder, selectRoutedOrderLine } from '@app/order/core/state/order.selectors';
import { isNotNullOrUndefined } from '@app/util/operators/is-not-null-or-undefined';
import { OrderState } from '@app/order/core/state/order.reducer';
import { AssignType } from '@app/order/routes/assign/state/assign.model';
import { selectCompanyOrderRoleFromIdentity } from '@app/company/core/state';
import { Observable, zip } from 'rxjs';
import { Shipment } from '@app/shipment/models';
import { concatMap, map } from 'rxjs/operators';
import { ShipmentSearchService } from '@app/shipment/core/services/shipment-search.service';
import { assignStartSend } from '@app/order/routes/assign/state/assign.actions';
import { selectCachedExpandLineTypes, selectCachedLinePanels } from '@app/cache/state/cache.selectors';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { cachingExpandValue, updateLinePanels } from '@app/cache/state/cache.actions';
import { changePanelSort } from '@app/order/util/helper';
import { orderLineDetailRemoveLabel } from '@app/order/routes/order-line-detail/state/order-line-detail.actions';
import { Order, StartRemoveLineLabel } from '@app/order/models';
import { selectAuthIdentityCompanyId } from '@app/auth/core/state/auth.selectors';

export enum LinePanelType {
  LINE_SUMMARY = 'lineSummary',
  LINE_ITEM = 'lineItem',
  LINE_CERTIFICATION = 'lineCertification',
  LINE_CHARGE_INFO = 'lineChargeInfo',
  LINE_NOTES = 'lineNotes',
  LINE_PROPERTIES = 'lineProperties',
  LINE_PRICING = 'linePricing',
  LINE_SHIPPING = 'lineShipping',
  LINE_ITEM_DETAILS = 'lineItemDetails',
  LINE_CONTACTS = 'lineContacts',
  LINE_DOCUMENTS = 'lineDocuments',
  LINE_DELIVERY_SCHEDULE = 'lineDeliverySchedule',
  LINE_DELIVERY_HISTORY = 'lineDeliveryHistory',
}

@Component({
  selector: 'tc-line-panels',
  templateUrl: './line-panels.component.html',
  styleUrls: ['./line-panels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinePanelsComponent {
  public readonly panelType = LinePanelType;
  public panelsConfig$: Observable<Record<LinePanelType, number>> = this.store$.pipe(select(selectCachedLinePanels));

  public readonly expandPanels$ = this.store$.pipe(select(selectCachedExpandLineTypes));
  public order$ = this.store$.pipe(select(selectRoutedOrder)).pipe(isNotNullOrUndefined());
  public orderLine$ = this.store$.pipe(select(selectRoutedOrderLine)).pipe(isNotNullOrUndefined());
  public role$ = this.store$.pipe(select(selectCompanyOrderRoleFromIdentity));
  public connectedShipments$: Observable<Shipment[]>;
  public actingAsCompanyId$ = this.store$.pipe(select(selectAuthIdentityCompanyId));

  constructor(private store$: Store<OrderState>, private shipmentSearchService: ShipmentSearchService) {
    this.connectedShipments$ = zip(this.order$, this.orderLine$).pipe(
      concatMap(([order, orderLine]) => {
        return this.shipmentSearchService.query$({
          filters: {
            buyerShipment: {
              companyIds: [order.buyerOrder.companyId],
            },
            lines: {
              purchaseOrderLinePosition: orderLine.buyerLine.position,
              purchaseOrderNumber: order.buyerOrder.purchaseOrderNumber,
            },
          },
          offset: 0,
          limit: 100,
        });
      }),
      map(response => {
        return response.data;
      }),
    );
  }

  public onAssign(actionType: AssignType.ASSIGN | AssignType.REASSIGN, order: Order): void {
    this.store$.dispatch(
      assignStartSend({
        actionType,
        orderId: order.id,
        version: order.version,
      }),
    );
  }

  onRemoveLabel(data: StartRemoveLineLabel) {
    this.store$.dispatch(orderLineDetailRemoveLabel(data));
  }

  togglePanel(panelType: LinePanelType, oldValue: boolean) {
    this.store$.dispatch(cachingExpandValue({ path: panelType, value: !oldValue }));
  }

  drop(event: CdkDragDrop<void>, config: Record<LinePanelType, number>) {
    const value = changePanelSort(event.item.data, event.currentIndex - event.previousIndex, config);
    this.store$.dispatch(updateLinePanels({ value }));
  }
}
