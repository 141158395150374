import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Company } from '@app/company/models';

@Component({
  selector: 'tc-company-name',
  templateUrl: './company-name.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanyNameComponent {
  @Input()
  company?: Company;

  public get name(): string | undefined {
    return this.company && this.company.name;
  }
}
