import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { quantityDifference } from './helpers/quantity-difference.helper';

@Component({
  selector: 'tc-quantity-difference',
  templateUrl: './quantity-difference.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuantityDifferenceComponent {
  @Input()
  currentQuantity?: number;

  @Input()
  proposedQuantity?: number;

  public get difference(): number | undefined {
    return quantityDifference(this.currentQuantity, this.proposedQuantity);
  }
}
