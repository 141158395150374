import { Component, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ShipmentActionDetail, ShipmentReject } from '@app/shipment/models';

@Component({
  selector: 'tc-shipment-reject-dialog',
  templateUrl: './shipment-reject-dialog.component.html',
  styleUrls: ['./shipment-reject-dialog.component.scss'],
})
export class ShipmentRejectDialogComponent {
  public form = new UntypedFormGroup({
    reason: new UntypedFormControl('', [Validators.required]),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: { value: ShipmentActionDetail },
    private dialogRef: MatDialogRef<ShipmentRejectDialogComponent, ShipmentReject>,
  ) {}

  public get value(): { reason: string } {
    return this.form.value as { reason: string };
  }

  public submit(): void {
    if (!this.form.valid) {
      return;
    }

    const { id } = this.data.value;

    const payload: ShipmentReject = {
      shipmentId: id,
      reason: this.value.reason,
    };

    this.dialogRef.close(payload);
  }
}
