import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { LogisticsStatus, LineDelivery } from '@app/order/models';

@Component({
  selector: 'tc-logistics-status',
  templateUrl: './logistics-status.component.html',
  styleUrls: ['./logistics-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogisticsStatusComponent {
  @Input()
  status!: LogisticsStatus;

  @Input()
  transportMode?: LineDelivery;
}
