import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { OrderLineSearchService } from '@app/order/core/services/order-line-search.service';
import { AnalyticsQuery, AnalyticsQueryResponse } from '@app/analytics/models';
import {
  AnalyticsStatuses,
  AnalyticsStatusMetricsDtoInterface,
} from '@app/order/core/services/dto/analytics-status-metrics.dto.interface';
import { CompanyRole } from '@app/company/models';
import { stubIntervalQueryResponse } from './mocks/stub-interval-query-response.mock';
import { stubPerMonthQueryResponse } from './mocks/stub-per-month-query-response.mock';

@Injectable()
export class AnalyticsService {
  constructor(private orderLineSearchService: OrderLineSearchService) {}

  public getAmountPerMonth$(): Observable<AnalyticsQueryResponse> {
    return of(stubPerMonthQueryResponse()).pipe(delay(1000));
  }

  public getCountPerMonth$(): Observable<AnalyticsQueryResponse> {
    return of(stubPerMonthQueryResponse()).pipe(delay(1000));
  }

  public getCountPerStatus$(
    statuses: AnalyticsStatuses[],
    companyId: string | undefined,
    userRoles: CompanyRole | undefined | null,
  ): Observable<AnalyticsStatusMetricsDtoInterface[]> {
    const companyParamName = userRoles === CompanyRole.BUYER ? 'buyerCompanyId' : 'supplierCompanyId';

    return this.orderLineSearchService.getProcessStatus$({
      statuses: statuses.join(','),
      [companyParamName]: companyId,
    });
  }

  public getKpisConfirmed$(query: AnalyticsQuery): Observable<AnalyticsQueryResponse> {
    return this.orderLineSearchService.query$({ limit: 5, offset: 0 }).pipe(
      map(({ data }) => {
        return stubIntervalQueryResponse(query, data);
      }),
    );
  }

  public getKpisRequested$(query: AnalyticsQuery): Observable<AnalyticsQueryResponse> {
    return this.orderLineSearchService.query$({ limit: 5, offset: 0 }).pipe(
      map(({ data }) => {
        return stubIntervalQueryResponse(query, data);
      }),
    );
  }
}
