<div [class.empty]="isMeasurementsEmpty">
  <highcharts-chart
    *ngIf="!isMeasurementsEmpty; else emptyInput"
    [Highcharts]="Highcharts"
    [options]="options"></highcharts-chart>
  <ng-template
    #emptyInput
    i18n="@@analytics.empty"
  >No data available</ng-template>
</div>

