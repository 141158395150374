import { createAction, props } from '@ngrx/store';
import { Assign, StartAssign } from '@app/order/routes/assign/state/assign.model';

export enum AssignActionType {
  START_SEND = '[Assign] Start Send',
  SEND = '[Assign] Send',
  SEND_SUCCESS = '[Assign] Send Success',
  SEND_FAILURE = '[Assign] Send Failure',
}

export const assignStartSend = createAction(AssignActionType.START_SEND, props<StartAssign>());
export const assignSend = createAction(AssignActionType.SEND, props<Assign>());
export const assignSendSuccess = createAction(AssignActionType.SEND_SUCCESS, props<{ userId: string }>());
export const assignSendFailure = createAction(AssignActionType.SEND_FAILURE, props<{ error: string }>());
