<div class="vertical" fxLayout *ngIf="shipmentHistory && shipmentHistory.length > 1">
  <mat-list class="mat-list--properties mat-list--compact" fxFlex="50">
    <mat-list-item  class="auto-height">
      <div class="property" i18n="@@common.field.ship.quantity">Shipped quantity</div>
      <div class="value wrap-ship-links">
        {{total}}
        {{ item?.purchaseUnitOfMeasureIso | tcUnitSymbol }}
      </div>
    </mat-list-item>
  </mat-list>
</div>
<mat-table class="compact-table" [dataSource]="shipmentHistory" *ngIf="shipmentHistory && shipmentHistory.length > 0">

  <ng-container matColumnDef="id">
    <mat-header-cell *matHeaderCellDef i18n="@@common.shipment.number">Shipment number</mat-header-cell>
    <mat-cell *matCellDef="let element; let i = index;">
      <a [routerLink]="['/shipments/detail', element.shipmentId]" class="link">
        {{element.shipmentNumber}}
      </a>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="position">
    <mat-header-cell *matHeaderCellDef i18n="@@common.position">Position</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.deliverySchedulePosition}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="quantity">
    <mat-header-cell *matHeaderCellDef i18n="@@common.quantity">Quantity</mat-header-cell>
    <mat-cell *matCellDef="let element; let i = index;">
      {{element.despatchQuantity | number}}
      {{ item?.purchaseUnitOfMeasureIso | tcUnitSymbol }}
    </mat-cell>
  </ng-container>

  <mat-header-row class="compact-row" *matHeaderRowDef="columnDefs"></mat-header-row>
  <mat-row class="compact" *matRowDef="let row; columns: columnDefs; let i = index;"></mat-row>
</mat-table>
