import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';

import { DemoComponent } from '@app/shared/components/demo/demo.component';

@Injectable()
export class DemoService {
  private snackBarRef?: MatSnackBarRef<DemoComponent>;

  constructor(private snackBar: MatSnackBar) {}

  public show(): void {
    // setTimeout is used to avoid ExpressionChangedAfterItHasBeenCheckedError:
    // https://github.com/angular/material2/issues/11357
    setTimeout(() => {
      this.snackBarRef = this.snackBar.openFromComponent(DemoComponent, {
        panelClass: 'mat-snack-bar-container--demo',
        verticalPosition: 'top',
      });
    });
  }

  public hide(): void {
    // Keeping both methods asynchronous for consistency
    setTimeout(() => {
      if (this.snackBarRef) {
        this.snackBarRef.dismiss();
      }
    });
  }
}
