import { AnalyticsQuery, AnalyticsQueryInterval, AnalyticsQueryResponse, Measurement } from '@app/analytics/models';
import { OrderLineSearchView } from '@app/order/models';
import * as moment from 'moment';

export function stubIntervalQueryResponse(
  query: AnalyticsQuery,
  orderLines: OrderLineSearchView[],
): AnalyticsQueryResponse {
  const response: AnalyticsQueryResponse = {
    data: [],
    total: 0,
  };
  const iterator = moment(query.from);

  while (iterator.isSameOrBefore(query.to)) {
    const measurement: Measurement = {
      date: iterator.toDate(),
      value: Math.random(),
    };

    if (query.interval === AnalyticsQueryInterval.Day) {
      measurement.orderLine = orderLines[response.total % orderLines.length];
    }

    response.data.push(measurement);
    response.total++;

    switch (query.interval) {
      case AnalyticsQueryInterval.Month:
        iterator.add(1, 'month');
        break;
      case AnalyticsQueryInterval.Week:
        iterator.add(1, 'week');
        break;
      default:
        iterator.add(1, 'day');
    }
  }

  return response;
}
