import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@environments/environment';
import { IntercomModule } from 'ng-intercom';
import { AppRoutesModule } from './app-routes.module';
import { AppComponent } from './core/containers/app/app.component';
import { CoreModule } from './core/core.module';
import { AppStoreModule } from './store/app-store.module';
import { envServiceProvider } from './env-service.provider';
import { SortablejsModule } from 'ngx-sortablejs';
import localeNL from '@angular/common/locales/nl';
import localePL from '@angular/common/locales/pl';
import localeCN from '@angular/common/locales/zh-Hans-SG';
import localeES from '@angular/common/locales/es-BR';
import localeDE from '@angular/common/locales/de';
import localeFR from '@angular/common/locales/fr';
import localeAR from '@angular/common/locales/ar-AE';
import localeUR from '@angular/common/locales/ur';
import localeBN from '@angular/common/locales/bn';
import localeTR from '@angular/common/locales/tr';
import localePT from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';

registerLocaleData(localeNL, 'nl');
registerLocaleData(localePL, 'pl');
registerLocaleData(localeCN, 'zh');
registerLocaleData(localeES, 'es');
registerLocaleData(localeDE, 'de');
registerLocaleData(localeFR, 'fr');
registerLocaleData(localeAR, 'ar');
registerLocaleData(localeUR, 'ur');
registerLocaleData(localeBN, 'bn');
registerLocaleData(localeTR, 'tr');
registerLocaleData(localePT, 'pt');

@NgModule({
  imports: [
    AppRoutesModule,
    AppStoreModule,
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
    environment.environment !== 'test'
      ? IntercomModule.forRoot({
          appId: environment.intercomAppId,
          updateOnRouterChange: true,
        })
      : [],
    MatNativeDateModule,
    ServiceWorkerModule.register('/ngsw-worker.js', {
      enabled: false,
    }),
    SortablejsModule.forRoot({
      animation: 150,
    }),
  ],
  providers: [envServiceProvider],
  bootstrap: [AppComponent],
})
export class AppModule {}
