import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Order } from '@app/order/models';
import { UUID } from '@app/shared/models';

@Component({
  selector: 'tc-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotesComponent {
  @Input()
  order?: Order;

  public get buyerId(): UUID | undefined {
    return this.order?.buyerOrder.companyId;
  }

  public get supplierId(): UUID | undefined {
    return this.order?.supplierOrder.companyId;
  }

  public get supplierNotes(): string[] | undefined {
    return this.order?.supplierOrder.notes;
  }

  public get buyerNotes(): string[] | undefined {
    return this.order?.buyerOrder.notes;
  }
}
