import { createAction, props } from '@ngrx/store';

import { Account, CreateAccount, UpdateAccount } from '@app/account/models';
import { StartReassignDefaultUser } from '@app/order/routes/assign/state/assign.model';

export enum AccountActionType {
  FETCH = '[Account] Fetch',
  ADD = '[Account] Add',
  NOT_FOUND = '[Account] Not Found',
  CREATE = '[Account] Create',
  CREATE_SUCCESS = '[Account] Create Success',
  CREATE_FAILURE = '[Account] Create Failure',

  START_REASSIGN_USER = '[Account] Start Reassign',
  REASSIGN_USER = '[Account] Reassign',
  REASSIGN_USER_SUCCESS = '[Account] Reassign Success',
  REASSIGN_USER_FAILURE = '[Account] Reassign Failure',
}

export const fetchAccount = createAction(
  AccountActionType.FETCH,
  props<{ companyId: string; accountCompanyId: string }>(),
);
export const accountAdd = createAction(AccountActionType.ADD, props<{ entity: Account }>());
export const accountNotFound = createAction(AccountActionType.NOT_FOUND, props<{ id: string }>());
export const accountCreate = createAction(AccountActionType.CREATE, props<CreateAccount>());
export const accountCreateSuccess = createAction(AccountActionType.CREATE_SUCCESS);
export const accountCreateFailure = createAction(
  AccountActionType.CREATE_FAILURE,
  props<{ id: string; error: string }>(),
);
export const accountReassignStartSend = createAction(AccountActionType.START_REASSIGN_USER, props<StartReassignDefaultUser>());
export const accountReassignSend = createAction(AccountActionType.REASSIGN_USER, props<UpdateAccount>());
export const accountReassignSendSuccess = createAction(AccountActionType.REASSIGN_USER_SUCCESS);
export const accountReassignSendFailure = createAction(AccountActionType.REASSIGN_USER_FAILURE, props<{ id: string; error: string, status: number }>());
