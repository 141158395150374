import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { AnalyticsService } from './services/analytics.service';
import { reducer } from './state/analytics.reducer';
import { analyticsFeatureName } from './state/analytics.selectors';
import { LookerService } from '@app/analytics/core/services/looker.service';

@NgModule({
  imports: [StoreModule.forFeature(analyticsFeatureName, reducer)],
  providers: [AnalyticsService, LookerService],
})
export class AnalyticsCoreModule {}
