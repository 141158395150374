import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, exhaustMap, filter, map, mapTo, withLatestFrom } from 'rxjs/operators';
import { TaskService } from '@app/task/core/services/task.service';
import { NotificationType } from '@app/core/state/core.model';
import { notificationSend } from '@app/core/state/core.actions';
import { taskAddMany, taskWebSocketUpsert, taskWebSocketUpsertWithoutFilter } from '@app/task/core/state/task.actions';
import {
  reassignConversation,
  reassignForecast,
  reassignOrderLine,
  reassignShipment,
  sendReassignConversation,
  sendReassignForecast,
  sendReassignOrderLine,
  sendReassignShipment,
  TaskListActionType,
  taskListLoadMore,
  taskListOutdate,
  taskListQuery,
  taskListQueryFailure,
  taskListQuerySuccess,
  taskListRefresh,
  taskListResetFilters,
  taskListUpdateFilters,
} from './task-list.actions';
import { TaskListState } from '@app/task/routes/task-list/state/task-list.reducer';
import { selectIdsLength, selectTaskListQuery, selectTaskListTotal } from './task-list.selectors';
import { selectRouterPageQuery, selectRouterQueryPageSize, selectRouterState } from '@app/store/reducers';
import { orderLineDetailConflicted } from '@app/order/routes/order-line-detail/state/order-line-detail.actions';
import { CompanyRole } from '@app/company/models';
import { AssignType } from '@app/order/routes/assign/state/assign.model';
import { assignSendFailure, assignSendSuccess } from '@app/order/routes/assign/state/assign.actions';
import { MatDialog } from '@angular/material/dialog';
import { selectCompanyOrderRoleFromIdentity } from '@app/company/core/state';
import { selectAuthIdentityCompanyId } from '@app/auth/core/state/auth.selectors';
import { AssignDialogComponent } from '@app/order/routes/assign/containers/assign-dialog.component';

@Injectable()
export class TaskListEffects {
  query$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(taskListQuery),
      concatMap(({ query }) => {
        return this.taskService.query$(query).pipe(
          map(response => {
            return taskListQuerySuccess({ query, response });
          }),
          catchError(({ error }: HttpErrorResponse) => {
            return of(taskListQueryFailure({ error: error.message || error }));
          }),
        );
      }),
    );
  });

  querySuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(taskListQuerySuccess),
      map(({ response: { data } }) => {
        return data;
      }),
      map(entities => {
        return taskAddMany({ entities });
      }),
    );
  });

  queryFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(taskListQueryFailure),
      mapTo(
        notificationSend({
          message: $localize`:@@ts.task.query.failed:Failed to query the tasks. Please try again`,
          notificationType: NotificationType.ERROR,
        }),
      ),
    );
  });

  taskUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        taskWebSocketUpsert,
        taskWebSocketUpsertWithoutFilter,
      ),
      withLatestFrom(
        this.store$.pipe(select(selectTaskListTotal)),
        this.store$.pipe(select(selectRouterQueryPageSize)),
        this.store$.pipe(select(selectIdsLength)),
      ),
      filter(([_, total, pageSize, storageLength]) => {
        return pageSize > storageLength && pageSize < total!;
      }),
      mapTo(taskListLoadMore()),
    );
  });

  updateFilters$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(taskListUpdateFilters),
        map(({ filters }) => {
          return filters;
        }),
        map(resetPagingOffset),
        map(async queryParams => {
          return this.router.navigate([], { queryParams, queryParamsHandling: 'merge' });
        }),
      );
    },
    { dispatch: false },
  );

  outdateOrderLine$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(orderLineDetailConflicted),
      withLatestFrom(this.store$.pipe(select(selectRouterState))),
      map(([_, route]) => {
        return route.state.url;
      }),
      filter(url => {
        return url.startsWith('/workflow');
      }),
      mapTo(taskListOutdate()),
    );
  });

  resetFilters$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(taskListResetFilters), // exhaust map ?
        withLatestFrom(this.store$.pipe(select(selectRouterPageQuery))),
        map(([{ filters }, limit]) => {
          return { ...filters, ...limit };
        }),
        map(resetPagingOffset),
        map(async queryParams => {
          return this.router.navigate([], { queryParams });
        }),
      );
    },
    { dispatch: false },
  );

  refresh$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(taskListRefresh),
      withLatestFrom(this.store$.pipe(select(selectTaskListQuery))),
      filter(([_, query]) => {
        return !!query;
      }),
      map(([_, query]) => {
        return taskListQuery({ query });
      }),
    );
  });

  reassignTask$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(reassignForecast, reassignShipment, reassignConversation, reassignOrderLine),
      withLatestFrom(
        this.store$.pipe(select(selectCompanyOrderRoleFromIdentity)),
        this.store$.pipe(select(selectAuthIdentityCompanyId)),
      ),
      exhaustMap(([{ type, actionType, entityType, entityId, taskId, messageId }, companyRole, companyId]) => {
        const role = companyRole === CompanyRole.BUYER ? CompanyRole.BUYER : CompanyRole.SUPPLIER;

        return this.dialog
          .open(AssignDialogComponent, {
            data: {
              value: { role, companyId },
              template: {
                title:
                  actionType === AssignType.ASSIGN
                    ? $localize`:@@ts.assign.task.t1:Assign task to`
                    : $localize`:@@ts.assign.task.t2:Reassign task to`,
                btnSave:
                  actionType === AssignType.ASSIGN
                    ? $localize`:@@ts.assign.b1:ASSIGN`
                    : $localize`:@@ts.assign.b2:REASSIGN`,
              },
            },
          })
          .beforeClosed()
          .pipe(
            filter(value => {
              return !!value;
            }),
            map(({ userId }) => {
              switch (type) {
                case TaskListActionType.REASSIGN_FORECAST_TASK:
                  return sendReassignForecast({ userId, entityId: entityId!, taskIds: [taskId!] });
                case TaskListActionType.REASSIGN_ORDER_LINE_TASK:
                  return sendReassignOrderLine({
                    userId,
                    entityId: entityId!,
                    companyId: companyId!,
                    taskIds: [taskId!],
                  });
                case TaskListActionType.REASSIGN_SHIPMENT_TASK:
                  return sendReassignShipment({
                    userId,
                    entityId: entityId!,
                    companyId: companyId!,
                    taskIds: [taskId!],
                  });
                case TaskListActionType.REASSIGN_CONVERSATION_TASK:
                  return sendReassignConversation({
                    userId,
                    entityId: entityId!,
                    companyId: companyId!,
                    entityType: entityType!,
                    messageId: messageId!,
                  });
              }
            }),
          );
      }),
    );
  });

  sendReassignOrderLine$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sendReassignOrderLine),
      exhaustMap(({ entityId, userId, companyId, taskIds }) => {
        return this.taskService.reassignOrderTask$({ body: { companyId, entityId, userId, taskIds } }).pipe(
          map(() => {
            return assignSendSuccess({ userId });
          }),
          catchError(({ error }: HttpErrorResponse) => {
            return of(assignSendFailure({ error }));
          }),
        );
      }),
    );
  });

  sendReassignShipment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sendReassignShipment),
      exhaustMap(({ entityId, userId, companyId, taskIds }) => {
        return this.taskService.reassignShipment$({ body: { entityId, companyId, userId, taskIds } }).pipe(
          map(() => {
            return assignSendSuccess({ userId });
          }),
          catchError(({ error }: HttpErrorResponse) => {
            return of(assignSendFailure({ error }));
          }),
        );
      }),
    );
  });

  sendReassignForecast$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sendReassignForecast),
      exhaustMap(({ entityId, userId, taskIds }) => {
        return this.taskService.reassignForecast$({ body: { entityId, userId, taskIds } }).pipe(
          map(() => {
            return assignSendSuccess({ userId });
          }),
          catchError(({ error }: HttpErrorResponse) => {
            return of(assignSendFailure({ error }));
          }),
        );
      }),
    );
  });

  sendReassignConversation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sendReassignConversation),
      exhaustMap(({ entityId, userId, entityType, messageId, companyId }) => {
        return this.taskService
          .reassignConversation$({
            body: {
              entityId,
              entityType,
              userId,
              messageId,
              companyId,
            },
          })
          .pipe(
            map(() => {
              return assignSendSuccess({ userId });
            }),
            catchError(({ error }: HttpErrorResponse) => {
              return of(assignSendFailure({ error }));
            }),
          );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private router: Router,
    private store$: Store<TaskListState>,
    private taskService: TaskService,
    private dialog: MatDialog,
  ) {}
}

export function resetPagingOffset<T extends Params>(queryParams: T): T & { offset: number } {
  return {
    ...(queryParams as any),
    offset: 0,
  };
}
