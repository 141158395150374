import { Observable } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';

/**
 * Operator which does not emit when an id already exists.
 */
export const filterById = (currentIds$: Observable<(string | number)[]>) => {
  return (source$: Observable<string>) => {
    return source$.pipe(
      withLatestFrom(currentIds$),
      filter(([id, ids]) => {
        return !!id && !ids.includes(id);
      }),
      map(([id, ids]) => {
        return id;
      }),
    );
  };
};
