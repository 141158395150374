import { NgModule } from '@angular/core';
import { RootSharedModule } from '@app/shared/root-shared.module';
import { SmartDatePipe } from './pipes/smart-date.pipe';

const pipes = [SmartDatePipe];

@NgModule({
  imports: [RootSharedModule],
  declarations: [pipes],
  exports: [pipes],
})
export class TaskSharedModule {}
