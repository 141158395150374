<div fxLayout>
  <div fxFlex="50" class="buyer-col vertical">
    <mat-list class="mat-list--properties mat-list--compact">
      <mat-list-item  class="mat-list-item--multi-line auto-height">
        <ng-container *ngIf="buyerId | tcLazyCompany | async as company">
          <tc-company-summary [company]="company" [accountCode]="buyerAccountNumber"></tc-company-summary>
        </ng-container>
      </mat-list-item>

      <mat-list-item class="auto-height">
        <ng-container *ngIf="order?.buyerOrder?.orderType === 'RFQ'; else noRfqType" >
          <div class="property" i18n="@@order.buyer.details.rfq">RFQ</div>
        </ng-container>
        <ng-template #noRfqType>
          <div class="property" i18n="@@order.buyer.details.supplier.purchase">Purchase order</div>
        </ng-template>
        <div class="value">
          <h4 matLine class="wrap" [class.loading-text]="!purchaseOrderNumber">
            {{ purchaseOrderNumber }}
          </h4>
        </div>
      </mat-list-item>

      <mat-list-item class="auto-height" *ngIf="buyerOrderDescription">
        <div class="property" i18n="@@order.summary.desc">
          Order description
        </div>
        <div class="value">
          <p matLine class="wrap">
            {{ buyerOrderDescription }}
          </p>
        </div>
      </mat-list-item>

      <mat-list-item class="auto-height">
        <div class="property"
            i18n="@@common.field.amount"
            [matTooltip]="amountHint"
            matTooltipPosition="after"
            [matTooltipDisabled]="!!amountView"
        >Total amount</div>
        <div class="value">
          <h4 matLine class="wrap">
            <span [ngClass]="{'tc-change': amountChanged}">
              {{ amountView | tcMoney }}
              <tc-amount-difference
                [amount]="order?.totalAmount"
                [amountIncludingRequests]="order?.totalAmountIncludingRequests"
              ></tc-amount-difference>
            </span>
          </h4>
        </div>
      </mat-list-item>


      <mat-list-item class="auto-height" *ngIf="partyNames?.length > 0">
        <div class="property" i18n="@@order.summary.invoice">Invoice to</div>
        <div class="value">
          <ng-container *ngFor="let name of partyNames">
            <h4 matLine  class="wrap">
              {{ name }}
            </h4>
          </ng-container>
        </div>
      </mat-list-item>

      <mat-list-item class="auto-height">
        <div class="property" i18n="@@order.line.table.first.delivery">First delivery</div>
        <div class="value">
          <h4 matLine class="wrap">
            <span [class.overdue]="!!order?.indicators.deliveryOverdue">
              {{ order?.firstDeliveryDate | date:'dd/MM/yyyy' }}
            </span>
          </h4>
        </div>
      </mat-list-item>

      <mat-list-item class="auto-height">
        <tc-label-list [removable]="buyerId === companyId" (removeLabel)="onRemoveLabel($event)"  [labels]="order?.buyerOrder.customLabels"></tc-label-list>
      </mat-list-item>

    </mat-list>
  </div>
  <div fxFlex="50" class="supplier-col vertical">
    <mat-list class="mat-list--properties mat-list--compact">
      <mat-list-item class="mat-list-item--multi-line auto-height">
        <ng-container *ngIf="supplierId | tcLazyCompany | async as company">
          <tc-company-summary [company]="company" [accountCode]="supplierAccountNumber"></tc-company-summary>
        </ng-container>
      </mat-list-item>

      <mat-list-item class="auto-height"  *ngIf="salesOrderNumbers && salesOrderNumbers.length > 0">
        <div class="property" i18n="@@common.field.sales.order.number">Sales order number</div>
        <div class="value">
          <ng-container *ngFor="let number of salesOrderNumbers | tcUnique">
            <p matLine class="wrap">
              {{ number }}
            </p>
          </ng-container>
        </div>
      </mat-list-item>

      <mat-list-item class="auto-height" *ngIf="supplierOrderDescription">
        <div class="property" i18n="@@order.summary.desc">
          Order description
        </div>
        <div class="value">
          <p matLine class="wrap">
            {{ supplierOrderDescription }}
          </p>
        </div>
      </mat-list-item>

      <mat-list-item class="auto-height" *ngIf="labels?.length > 0">
        <div class="property" i18n="@@order.buyer.details.services">Value-added services labels</div>
        <div class="value">
          <ng-container *ngFor="let label of labels">
            <p matLine class="wrap">
              {{ label }}
            </p>
          </ng-container>
        </div>
      </mat-list-item>

      <mat-list-item class="auto-height">
        <tc-label-list [removable]="supplierId === companyId" (removeLabel)="onRemoveLabel($event)" [labels]="order?.supplierOrder.customLabels"></tc-label-list>
      </mat-list-item>

    </mat-list>
  </div>

</div>
