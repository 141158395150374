<mat-list-item class="auto-height">
  <div class="wrapper">
    <h4 mat-subheader class="twoFa" i18n="@@shared.2fa.recovery.step4">Step 4</h4>
    <h3 mat-subheader class="twoFa" i18n="@@shared.2fa.recovery.title">Save your backup codes</h3>
    <p i18n="@@shared.2fa.recovery.codes">Keep these backup codes somewhere safe but accessible.</p>
    <p i18n="@@shared.2fa.recovery.extra">If you lose access to your phone, you can authenticate using one of these backup codes.</p>
    <div class="backup-container">
      <div class="backup-part">
        <ng-container *ngFor="let code of backupCodes | slice:0:backupCodes.length / 2; let i = index">
          <pre>{{code}}</pre>
        </ng-container>
      </div>
      <div class="backup-part">
        <ng-container *ngFor="let code of backupCodes | slice:backupCodes.length / 2; let i = index">
          <pre>{{code}}</pre>
        </ng-container>
      </div>
    </div>
    <div class="actions">
      <div class="actions__save">
        <button mat-stroked-button color="primary" i18n="button @@common.btn.copy" (click)="copy()">Copy</button>
        <button mat-stroked-button color="primary" i18n="button @@common.btn.download" (click)="downloadFile()">Download</button>
      </div>
      <button mat-raised-button color="primary" i18n="@@shared.2fa.recovery.agreed" [disabled]="isUserNotSaveCodes$ | async" (click)="close()">I have saved my recovery codes</button>
    </div>
  </div>
</mat-list-item>
