import { AttachDocumentType } from '@app/order/private/components/attach-document-dialog/interfaces/enums/attach-document-type.enum';

export class AttachDocumentConfig {
  public static get DOCUMENT_TYPE_DESCRIPTION(): Record<AttachDocumentType, string> {
    return {
      [AttachDocumentType.GENERAL]: $localize`:@@ts.attach.doc.type.gen:General`,
      [AttachDocumentType.CLASS_PRODUCT_CERTIFICATE]: $localize`:@@ts.attach.doc.type.class:Class Product Certificate`,
      [AttachDocumentType.MANUFACTURERS_CERTIFICATE]: $localize`:@@ts.attach.doc.type.man:Manufacturers Certificate`,
      [AttachDocumentType.TYPE_APPROVAL_CERTIFICATE]: $localize`:@@ts.attach.doc.type.type:Type approval Certificate`,
      [AttachDocumentType.MED_CERTIFICATE]: $localize`:@@ts.attach.doc.type.med:MED Certificate`,
      [AttachDocumentType.SAFETY_DATA_SHEET]: $localize`:@@ts.attach.doc.type.sds:Safety Data Sheet (SDS)`,
      [AttachDocumentType.MANUAL]: $localize`:@@ts.attach.doc.type.manual:Manual`,
      [AttachDocumentType.PACKING_INSTRUCTIONS]: $localize`:@@ts.attach.doc.type.gen:Packing instructions`,
      [AttachDocumentType.INVOICE]: $localize`:@@ts.attach.doc.type.inv:Invoice`,
      [AttachDocumentType.PACKING_LIST]: $localize`:@@ts.attach.doc.type.pack.list:Packing List`,
      [AttachDocumentType.CERTIFICATES_OF_ORIGIN]: $localize`:@@ts.attach.doc.type.cer.orig:Certificates of Origin`,
      [AttachDocumentType.CERTIFICATE]: $localize`:@@ts.attach.doc.type.cer:Certificate`,
      [AttachDocumentType.BILL_OF_LANDING]: $localize`:@@ts.attach.doc.type.bill.land:Bill of Lading`,
      [AttachDocumentType.DANGEROUS_GOODS_FORMS]: $localize`:@@ts.attach.doc.type.dan.good:Dangerous Goods Forms`,
      [AttachDocumentType.PROOF_DELIVERY]: $localize`:@@ts.attach.doc.type.proof.delivery:Proof of Delivery`,
      [AttachDocumentType.PROFORMA_INVOICE]: $localize`:@@ts.attach.doc.type.invoice.proforma:Proforma invoice`,
      [AttachDocumentType.PACKAGING_INSTRUCTION]: $localize`:@@ts.attach.doc.type.pack.inst:Packaging instruction`,
    };
  }
}
