import { Pipe, PipeTransform } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { Company } from '@app/company/models';
import { companyFetch } from '@app/company/core/state/company.actions';
import { CompanyState } from '@app/company/core/state/company.reducer';
import { selectCompanyById } from '@app/company/core/state';

@Pipe({
  name: 'tcLazyCompany',
})
export class LazyCompanyPipe implements PipeTransform {
  constructor(private store$: Store<CompanyState>) {}

  // eslint-disable-next-line
  transform(id?: string): Observable<Company | undefined> {
    if (!id) {
      return of(undefined);
    }

    this.store$.dispatch(companyFetch({ id, flush: false }));

    return this.store$.pipe(select(selectCompanyById(id)));
  }
}
