import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { dateDifference } from './helpers/date-difference.helper';

@Component({
  selector: 'tc-date-difference',
  templateUrl: './date-difference.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateDifferenceComponent {
  @Input()
  currentDate?: Date;

  @Input()
  proposedDate?: Date;

  public get difference(): number | undefined {
    return dateDifference(this.currentDate, this.proposedDate);
  }
}
