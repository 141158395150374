import { ErrorHandler, Inject, Injectable } from '@angular/core';
import * as Rollbar from 'rollbar';
import { ROLLBAR } from './rollbar.factory';

@Injectable()
export class ErrorTrackingService extends ErrorHandler {
  constructor(@Inject(ROLLBAR) private rollbar: Rollbar) {
    super();
  }

  handleError(err: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;

    if (chunkFailedMessage.test(err.message)) {
      this.rollbar.warn(err.originalError || err);
      window.location.reload();
    } else {
      super.handleError(err);
      this.rollbar.error(err.originalError || err);
    }
  }
}
