<mat-list-item class="auto-height two-fa-row">
  <div class="left-column">
    <mat-icon>add_to_home_screen</mat-icon>
  </div>
  <div class="right-column">
    <h4 mat-subheader class="twoFa" i18n="@@shared.2fa.step1">Step 1</h4>
    <h3 mat-subheader class="twoFa" i18n="@@shared.2fa.app">Get the App</h3>
    <p i18n="@@shared.2fa.install">Download and install the Google Authenticator, Duo Mobile, Authy, or Windows Phone Authenticator app for your phone or tablet</p>
  </div>
</mat-list-item>
