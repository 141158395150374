import { Injectable } from '@angular/core';
import ls from 'localstorage-slim';

@Injectable()
export class TtlStorageService {
  public get<T>(key: string): T | null {
    return ls.get<T>(key);
  }

  public set<T>(key: string, value: T, localConfig?: any): boolean | void {
    return ls.set<T>(key, value, localConfig);
  }

  public clear(): boolean | undefined | void {
    return ls.clear();
  }

  public remove(key: string): boolean | undefined | void {
    return ls.remove(key);
  }

  public flush(force?: boolean): boolean | undefined | void {
    return ls.flush(force);
  }
}

export enum TtlPeriods {
  WEEK = 60 * 60 * 24 * 7,
  DAY = 60 * 60 * 24,
}
