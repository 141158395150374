import { Injectable } from '@angular/core';
import { StorageService } from '@app/core/services/storage/storage.service';
import { OrderFilterParameters, OrderQueryViewMode, OrderSortParameters } from '@app/order/models';
import { CachedTrackedVersionKey } from '@app/cache/models';

@Injectable()
export class CacheManagerService {
  private readonly versions: Record<CachedTrackedVersionKey, number> = {
    [CachedTrackedVersionKey.ORDERS_FILTERS]: 6,
    [CachedTrackedVersionKey.TASK_FILTERS]: 5,

    [CachedTrackedVersionKey.ORDER_COLUMNS]: 2,
    [CachedTrackedVersionKey.LINE_COLUMNS]: 2,
    [CachedTrackedVersionKey.ORDER_PANELS]: 0,
    [CachedTrackedVersionKey.LINE_PANELS]: 0,

    [CachedTrackedVersionKey.CONVERTER_ORDER_GENERAL]: 0,
    [CachedTrackedVersionKey.CONVERTER_ORDER_BUYER]: 0,
    [CachedTrackedVersionKey.CONVERTER_ORDER_SUPPLIER]: 0,

    [CachedTrackedVersionKey.CONVERTER_ORDER_LINE_GENERAL]: 0,
    [CachedTrackedVersionKey.CONVERTER_ORDER_LINE_BUYER]: 0,
    [CachedTrackedVersionKey.CONVERTER_ORDER_LINE_SUPPLIER]: 0,
    [CachedTrackedVersionKey.CONVERTER_ORDER_LINE_CONFIRMED]: 0,
  };

  constructor(private storage: StorageService) {}

  clearOutdatedKeys(userId: string): void {
    const listKeys = Object.keys(this.versions) as CachedTrackedVersionKey[];
    listKeys.forEach(key => {
      const savedVersionByKey = this.getSafetyValueFromStorage<number>(`${key}-version`) || 0;

      if (savedVersionByKey < this.versions[key]) {
        this.storage.remove(`${userId}-${key}`);
        this.storage.set(`${key}-version`, this.versions[key]);
      }
    });
  }

  getSafetyValueFromStorage<T>(path: string): T | null {
    try {
      return this.storage.get<T>(path);
    } catch (e) {
      this.storage.remove(path);

      return null;
    }
  }

  validateFromCacheFilters(
    value: OrderFilterParameters & OrderSortParameters & { viewMode?: OrderQueryViewMode },
  ): any {
    if (value?.viewMode === 'lines') {
      delete value.destinations;
    }

    // into orders page avoid buyerLine.item.number sorting
    if (value?.viewMode !== 'lines' && value.sort === 'buyerLine.item.number') {
      value.sort = 'buyerOrder.erpIssueDateTime';
      value.direction = 'desc';
    }

    return value;
  }
}
