import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import { select, Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, concatMap, delay, filter, map, mapTo, mergeMap, mergeMapTo, tap, withLatestFrom } from 'rxjs/operators';
import { NotificationType } from '@app/core/state/core.model';
import { fetchById } from '@app/util/operators/fetch-by-id';
import { filterById } from '@app/util/operators/filter-by-id';
import { CompanyService } from '../services/company.service';
import { CompanyState } from './company.reducer';
import { selectCompanyIds, selectRoutedCompanyDelaySettings, selectRoutedCompanyId } from './index';
import {
  addSciCredentials,
  addSciCredentialsFailure,
  addSciCredentialsSuccess,
  companyFetch,
  companyFetchFailure,
  companyListen,
  companyNameUpdate,
  companyUpdate,
  companyUpdateFailure,
  companyUpdateSuccess,
  companyUpsert,
  deleteDocumentsWebhookConnector,
  deleteOrderWebhookConnector,
  deleteSciCompanyUrl,
  deleteSciCredentials,
  disableActSupplier,
  documentsWebhookConnectorUpdate,
  documentsWebhookConnectorUpdateSuccess,
  documentsWebhookDownloadCertificate,
  enableActSupplier,
  enforce2FA, fetchLookerToken, fetchLookerTokenSuccess,
  fetchWebhookConnector,
  fetchWebhookConnectorSuccess,
  ordersWebhookConnectorUpdate,
  ordersWebhookConnectorUpdateSuccess,
  ordersWebhookDownloadCertificate,
  overdueSettingsFetch,
  overdueSettingsFetchFailure,
  overdueSettingsFetchSuccess,
  overdueSettingsUpdate,
  overdueSettingsUpdateConnection,
  overdueSettingsUpdateSuccess,
  sciSettingsFetch,
  sciSettingsFetchSuccess,
  shipmentIntegrationSettingsDelete,
  shipmentIntegrationSettingsFetch,
  shipmentIntegrationSettingsFetchSuccess,
  shipmentIntegrationSettingsUpsert,
  shipmentIntegrationSettingsUpsertFailure,
  shipmentIntegrationSettingsUpsertSuccess,
  unrestrict2FA,
  upsertSciCompanyUrl,
  upsertSciCompanyUrlSuccess,
  workflowSettingsFetch,
  workflowSettingsOverdueUpdate,
  workflowSettingsUpdate,
  workflowSettingsUpsert,
} from './company.actions';
import { notificationSend } from '@app/core/state/core.actions';
import { SciConnectorService } from '@app/company/core/services/sci-connector.service';
import { selectAuthIdentityCompanyId } from '@app/auth/core/state/auth.selectors';
import { ShipmentConnectorService } from '@app/company/core/services/shipment-connector.service';
import { CompanyRole } from '@app/company/models';
import { IntegrationSettingsView, OrderWebhookService } from '@app/company/core/services/order-webhook.service';
import { LookerService } from '@app/analytics/core/services/looker.service';
import { LookerToken } from '@app/analytics/models';
import * as saveAs from 'file-saver';

@Injectable()
export class CompanyEffects {
  fetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyFetch),
      concatMap(data => {
        return of(data).pipe(
          concatMap(
            ({ id, flush }): Observable<string> => {
              if (flush) {
                return of(id);
              }

              return filterById(this.store$.pipe(select(selectCompanyIds)))(of(id));
            },
          ),
          fetchById(id =>
            // TODO: an instance of CompanyService here should be replaced with a
            // CompanySearchService one when the latter starts responding with a
            // proper integration settings once again.
            {
              return this.companyService.getById$(id).pipe(
                concatMap(entity => {
                  const extraRequests$ = entity?.roles?.includes(CompanyRole.BUYER)
                    ? [sciSettingsFetch({ id })]
                    : [];

                  const actions = data.workflow
                    ? [companyUpsert({ entity }), workflowSettingsFetch({ id }), ...extraRequests$]
                    : [companyUpsert({ entity }), ...extraRequests$];

                  return actions;
                }),
                catchError(({ error }: HttpErrorResponse) => {
                  return of(
                    companyFetchFailure({
                      id,
                      error: error.message || error,
                    }),
                  );
                }),
              );
            },
          ),
        );
      }),
    );
  });

  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyUpdate),
      map(({ id, changes }) => {
        return { id, changes };
      }),
      concatMap(({ id, changes }) => {
        return this.companyService.update$(id, changes).pipe(
          mapTo(companyUpdateSuccess()),
          catchError(({ error }: HttpErrorResponse) => {
            return of(
              companyUpdateFailure({
                id,
                error: error.message || error,
              }),
            );
          }),
        );
      }),
    );
  });

  updateName$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyNameUpdate),
      map(({ id, changes }) => {
        return { id, changes };
      }),
      concatMap(({ id, changes }) => {
        return this.companyService.updateName$(id, changes).pipe(
          mapTo(companyUpdateSuccess()),
          catchError(({ error }: HttpErrorResponse) => {
            return of(
              companyUpdateFailure({
                id,
                error: error.message || error,
              }),
            );
          }),
        );
      }),
    );
  });

  upsertSciCompanyUrl$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(upsertSciCompanyUrl),
      concatMap(({ id, body }) => {
        return this.sciService.updateApiUrl$(id, body).pipe(
          mapTo(upsertSciCompanyUrlSuccess({ id, changes: body })),
          catchError(({ error }: HttpErrorResponse) => {
            return of(
              notificationSend({
                message: error.message || error, // todo: i18n
                notificationType: NotificationType.ERROR,
              }),
            );
          }),
        );
      }),
    );
  });

  upsertSciCompanyUrlSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(upsertSciCompanyUrlSuccess),
      mapTo(
        notificationSend({
          message: $localize`:@@ts.company.sci.success:Company SCI connector URL updated`,
          notificationType: NotificationType.SUCCESS,
        }),
      ),
    );
  });

  deleteSciCompanyUrl$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteSciCompanyUrl),
      concatMap(({ id }) => {
        return this.sciService.deleteApiUrl$(id).pipe(
          mapTo(
            notificationSend({
              message: $localize`:@@ts.company.sci.delete:Company SCI integration disabled`,
              notificationType: NotificationType.SUCCESS,
            }),
          ),
          catchError(({ error }: HttpErrorResponse) => {
            return of(
              notificationSend({
                message: error.message || error, // todo: i18n
                notificationType: NotificationType.ERROR,
              }),
            );
          }),
        );
      }),
    );
  });

  fetchShipmentIntegrationSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(shipmentIntegrationSettingsFetch),
      map(({ id }) => {
        return { id };
      }),
      concatMap(({ id }) => {
        return this.shipmentConnectorService.getById$(id).pipe(
          map(changes => {
            return shipmentIntegrationSettingsFetchSuccess({ id, changes });
          }),
          catchError(() => {
            return EMPTY;
          }),
        );
      }),
    );
  });

  upsertShipmentIntegrationSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(shipmentIntegrationSettingsUpsert),
      concatMap(({ id, body }) => {
        return this.shipmentConnectorService.update$(id, body).pipe(
          map(changes => {
            return shipmentIntegrationSettingsUpsertSuccess();
          }),
          catchError(({ error }: HttpErrorResponse) => {
            return of(shipmentIntegrationSettingsUpsertFailure({ error, id }));
          }),
        );
      }),
    );
  });

  deleteShipmentConnector$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(shipmentIntegrationSettingsDelete),
      concatMap(({ id }) => {
        return this.shipmentConnectorService.delete$(id).pipe(
          mapTo(
            notificationSend({
              message: $localize`:@@ts.company.ship.con.delete:Shipment Webhook Connector Integration disabled`,
              notificationType: NotificationType.SUCCESS,
            }),
          ),
          catchError(({ error }: HttpErrorResponse) => {
            return of(
              notificationSend({
                message: error.message || error,
                notificationType: NotificationType.ERROR,
              }),
            );
          }),
        );
      }),
    );
  });

  fetchSciSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(sciSettingsFetch),
      map(({ id }) => {
        return { id };
      }),
      concatMap(({ id }) => {
        return this.sciService.getById$(id).pipe(
          map(changes => {
            return sciSettingsFetchSuccess({ id, changes });
          }),
          catchError(() => {
            return EMPTY;
          }),
        );
      }),
    );
  });

  addSciCredentials$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(addSciCredentials),
      concatMap(({ body, id, supplierCompanyId }) => {
        return this.sciService.updateCredentials$(id, supplierCompanyId, body).pipe(
          mergeMapTo([
            addSciCredentialsSuccess(),
            notificationSend({
              message: $localize`:@@ts.company.sci.add:SCI credentials added.`,
              notificationType: NotificationType.SUCCESS,
            }),
          ]),
          catchError(({ error }: HttpErrorResponse) => {
            return of(
              addSciCredentialsFailure({ error, id, supplierCompanyId }),
              notificationSend({
                message: error.message || error, // todo: i18n
                notificationType: NotificationType.ERROR,
              }),
            );
          }),
        );
      }),
    );
  });

  deleteSciCredentials$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteSciCredentials),
      withLatestFrom(this.store$.pipe(select(selectAuthIdentityCompanyId))),
      filter(([, id]) => {
        return !!id;
      }),
      concatMap(([{ supplierCompanyId }, id]) => {
        return this.sciService.deleteCredentials$(id!, supplierCompanyId).pipe(
          mapTo(
            notificationSend({
              message: $localize`:@@ts.company.sci.cred.delete:SCI credentials deleted`,
              notificationType: NotificationType.SUCCESS,
            }),
          ),
          catchError(({ error }: HttpErrorResponse) => {
            return of(
              notificationSend({
                message: error.message || error, // todo: i18n
                notificationType: NotificationType.ERROR,
              }),
            );
          }),
        );
      }),
    );
  });

  fetchCompanyOverdueSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(overdueSettingsFetch),
      map(({ id }) => {
        return { id };
      }),
      concatMap(({ id }) => {
        return this.companyService.getOrderOverdueDelay$(id).pipe(
          map(entity => {
            return overdueSettingsFetchSuccess({ id, changes: entity });
          }),
          catchError(() => {
            return of(overdueSettingsFetchFailure({ id }));
          }),
        );
      }),
    );
  });

  updateCompanyOverdueSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(overdueSettingsUpdate),
      withLatestFrom(
        this.store$.pipe(select(selectRoutedCompanyDelaySettings))
      ),
      concatMap(([{ id, changes }, companySettings]) => {
        const upsertChanges = {
          ...changes,
          connectionOverdueSettings: { ...companySettings?.connectionOverdueSettings, }
        }

        return this.companyService.updateCompanySettingsOverdueDelay$(id, upsertChanges).pipe(
          map(entity => {
            return overdueSettingsUpdateSuccess({ id, changes: upsertChanges });
          }),
          catchError(({ error }: HttpErrorResponse) => {
            return of(
              notificationSend({
                message: error.message || error,
                notificationType: NotificationType.ERROR,
              }),
            );
          }),
        );
      }),
    );
  });

  updateCompanyOverdueSettingsConnection$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(overdueSettingsUpdateConnection),
      withLatestFrom(
        this.store$.pipe(select(selectRoutedCompanyDelaySettings))
      ),
      concatMap(([{ companyId, connectionCompanyId, changes }, companySettings]) => {
        const upsertChanges = {
          ...companySettings,
          connectionOverdueSettings: {
            ...companySettings?.connectionOverdueSettings,
            [connectionCompanyId]: changes
          }
        }

        if (changes === null) {
          delete upsertChanges.connectionOverdueSettings[connectionCompanyId]
        }
        return this.companyService.updateCompanySettingsOverdueDelay$(companyId, upsertChanges).pipe(
          map(entity => {
            return overdueSettingsUpdateSuccess({ id: companyId, changes: upsertChanges });
          }),
          catchError(({ error }: HttpErrorResponse) => {
            return of(
              notificationSend({
                message: error.message || error,
                notificationType: NotificationType.ERROR,
              }),
            );
          }),
        );
      }),
    );
  });

  fetchWorkflowSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(workflowSettingsFetch),
      map(({ id }) => {
        return { id };
      }),
      concatMap(({ id }) => {
        return this.companyService.getWorkflowSettings$(id).pipe(
          map(({ enabledAcknowledgeEventNames, taskOverdueSettings }) => {
            return { enabledAcknowledgeEventNames, taskOverdueSettings };
          }),
          map(entity => {
            return workflowSettingsUpsert({ id, changes: entity });
          }),
          catchError(({ error }: HttpErrorResponse) => {
            return of(
              notificationSend({
                message: error.message || error, // todo: i18n
                notificationType: NotificationType.ERROR,
              }),
            );
          }),
        );
      }),
    );
  });

  updateWorkflowSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(workflowSettingsUpdate),
      map(({ id, changes }) => {
        return { id, changes };
      }),
      concatMap(({ id, changes }) => {
        return this.companyService.updateWorkflowSettings$(id, changes).pipe(
          mapTo(
            notificationSend({
              message: $localize`:@@ts.company.settings.success:Company workflow settings updated`,
              notificationType: NotificationType.SUCCESS,
            }),
          ),
          catchError(({ error }: HttpErrorResponse) => {
            return of(
              notificationSend({
                message: error.message || error, // todo: i18n
                notificationType: NotificationType.ERROR,
              }),
            );
          }),
        );
      }),
    );
  });

  updateWorkflowOverdue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(workflowSettingsOverdueUpdate),
      map(({ id, changes }) => {
        return { id, changes };
      }),
      concatMap(({ id, changes }) => {
        return this.companyService.updateWorkflowSettingsOverdue$(id, changes).pipe(
          mapTo(
            notificationSend({
              message: $localize`:@@ts.company.settings.success:Company workflow settings overdue updated`,
              notificationType: NotificationType.SUCCESS,
            }),
          ),
          catchError(({ error }: HttpErrorResponse) => {
            return of(
              notificationSend({
                message: error.message || error, // todo: i18n
                notificationType: NotificationType.ERROR,
              }),
            );
          }),
        );
      }),
    );
  });

  enableActingAsSupplier$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(enableActSupplier),
      concatMap(({ id }) => {
        return this.companyService.enableCompanyAsSupplier$(id).pipe(
          mergeMap(() => {
            return [
              companyFetch({ id, flush: true }),
              notificationSend({
                message: $localize`:@@ts.company.act.success:Enabled act as Supplier`,
                notificationType: NotificationType.SUCCESS,
              }),
            ];
          }),
          catchError(() => {
            return of(
              notificationSend({
                message: $localize`:@@ts.company.act.error:Unable act as Supplier. Try again!`,
                notificationType: NotificationType.ERROR,
              }),
            );
          }),
        );
      }),
    );
  });

  disableActingAsSupplier$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(disableActSupplier),
      concatMap(({ id }) => {
        return this.companyService.disableCompanyAsSupplier$(id).pipe(
          mergeMap(() => {
            return [
              companyFetch({ id, flush: true }),
              notificationSend({
                message: $localize`:@@ts.company.disable.act.success:Disabled act as Supplier`,
                notificationType: NotificationType.SUCCESS,
              }),
            ];
          }),
          catchError(() => {
            return of(
              notificationSend({
                message: $localize`:@@ts.company.disable.act.error:Unable disable act as Supplier. Try again!`,
                notificationType: NotificationType.ERROR,
              }),
            );
          }),
        );
      }),
    );
  });

  enforce2FA$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(enforce2FA),
      concatMap(({ id }) => {
        return this.companyService.enforce2FA$(id).pipe(
          mergeMap(() => {
            return [
              companyFetch({ id, flush: true }),
              notificationSend({
                message: $localize`:@@ts.company.2fa.success:Enforced 2FA`,
                notificationType: NotificationType.SUCCESS,
              }),
            ];
          }),
          catchError(() => {
            return of(
              notificationSend({
                message: $localize`:@@ts.company.2fa.error:Unable to enforce 2FA. Try again!`,
                notificationType: NotificationType.ERROR,
              }),
            );
          }),
        );
      }),
    );
  });

  unrestrict2FA$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(unrestrict2FA),
      concatMap(({ id }) => {
        return this.companyService.unrestrict2FA$(id).pipe(
          mergeMap(() => {
            return [
              companyFetch({ id, flush: true }),
              notificationSend({
                message: $localize`:@@ts.company.2fa.unrestrict.success:Unrestricted 2FA`,
                notificationType: NotificationType.SUCCESS,
              }),
            ];
          }),
          catchError(() => {
            return of(
              notificationSend({
                message: $localize`:@@ts.company.2fa.unrestrict.error:Unable to unrestrict 2FA. Try again!`,
                notificationType: NotificationType.ERROR,
              }),
            );
          }),
        );
      }),
    );
  });

  updateSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyUpdateSuccess),
      mapTo(
        notificationSend({
          message: $localize`:@@ts.company.update.success:Company settings updated`,
          notificationType: NotificationType.SUCCESS,
        }),
      ),
    );
  });

  updateFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyUpdateFailure),
      mapTo(
        notificationSend({
          message: $localize`:@@ts.company.update.failed:Failed to update the company settings. Please try again.`,
          notificationType: NotificationType.ERROR,
        }),
      ),
    );
  });

  listen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(companyListen),
      map(({ companyId }) => {
        return companyId;
      }),
      concatMap(companyId => {
        return this.companyService.websocket$(companyId).pipe(
          map(
            entity => {
              return companyUpsert({ entity });
            },
            catchError(() => {
              return EMPTY;
            }),
          ),
        );
      }),
    );
  });

  route$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      withLatestFrom(this.store$.pipe(select(selectRoutedCompanyId))),
      filter(([action, id]) => {
        return !!id;
      }),
      map(([action, id]) => {
        return companyFetch({ id, flush: false });
      }),
    );
  });

  fetchWebhookConnectorSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchWebhookConnector),
      map(({ id }) => {
        return { id };
      }),
      concatMap(({ id }) => {
        return this.orderWebhookService.getById$(id).pipe(
          mergeMap((changes: IntegrationSettingsView) => {
            return [fetchWebhookConnectorSuccess({ id, changes })];
          }),
          catchError(() => {
            return EMPTY;
          }),
        );
      }),
    );
  });

  fetchLookerToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchLookerToken),
      map(({ id }) => {
        return { id };
      }),
      concatMap(({ id }) => {
        return this.lookerService.getLookerToken$(id).pipe(
          mergeMap((body: LookerToken) => {
            return [fetchLookerTokenSuccess({ id, changes: body })];
          }),
          catchError(() => {
            return EMPTY;
          }),
        );
      }),
    );
  });

  deleteOrderWebhookConnector$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteOrderWebhookConnector),
      map(({ id }) => {
        return { id };
      }),
      concatMap(({ id }) => {
        return this.orderWebhookService.disableOrderEvents$(id).pipe(
          map(() => {
            return notificationSend({
              message: $localize`:@@ts.company.update.removed:Removed`,
              notificationType: NotificationType.SUCCESS,
            });
          }),
          catchError(({ error }: HttpErrorResponse) => {
            return of(
              notificationSend({
                message: error.message || error,
                notificationType: NotificationType.ERROR,
              }),
            );
          }),
        );
      }),
    );
  });

  deleteDocumentsWebhookConnector$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(deleteDocumentsWebhookConnector),
      map(({ id }) => {
        return { id };
      }),
      concatMap(({ id }) => {
        return this.orderWebhookService.disableDocumentsEvents$(id).pipe(
          map(() => {
            return notificationSend({
              message: $localize`:@@ts.company.update.removed:Removed`,
              notificationType: NotificationType.SUCCESS,
            });
          }),
          catchError(({ error }: HttpErrorResponse) => {
            return of(
              notificationSend({
                message: error.message || error,
                notificationType: NotificationType.ERROR,
              }),
            );
          }),
        );
      }),
    );
  });

  documentsWebhookConnectorUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(documentsWebhookConnectorUpdate),
      map(({ id, body }) => {
        return { id, body };
      }),
      concatMap(({ id, body }) => {
        return this.orderWebhookService.updateDocumentsEvents$(id, body).pipe(
          mergeMap(() => {
            return [
              documentsWebhookConnectorUpdateSuccess({
                id,
                changes: body,
              }),
              companyUpdateSuccess(),
            ];
          }),
          catchError(({ error }: HttpErrorResponse) => {
            return of(
              notificationSend({
                message: error.message || error,
                notificationType: NotificationType.ERROR,
              }),
            );
          }),
        );
      }),
    );
  });

  ordersWebhookConnectorUpdate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ordersWebhookConnectorUpdate),
      map(({ id, body }) => {
        return { id, body };
      }),
      concatMap(({ id, body }) => {
        return this.orderWebhookService.updateOrderEvents$(id, body).pipe(
          mergeMap(() => {
            return [
              ordersWebhookConnectorUpdateSuccess({
                id,
                changes: body,
              }),
              companyUpdateSuccess(),
            ];
          }),
          catchError(({ error }: HttpErrorResponse) => {
            return of(
              notificationSend({
                message: error.message || error,
                notificationType: NotificationType.ERROR,
              }),
            );
          }),
        );
      }),
    );
  });

  autoDownloadOrderWebhookCertificate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ordersWebhookConnectorUpdateSuccess),
      filter(data => !!data.changes.authentication?.oAuth?.clientCertificate),
      delay(1000),
      map(({id}) => {
        return ordersWebhookDownloadCertificate({id})})
      )
  });

  downloadOrderWebhookCertificate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ordersWebhookDownloadCertificate),
      concatMap(data => {
        return this.orderWebhookService.getOrdersCertificateById$(data.id).pipe(
          tap((res) => {
            const file = new Blob([res.certificate]);
            saveAs(file, `orders-public-key.cert`);
          })
        );
      })
    )
  }, { dispatch: false });

  autoDownloadDocumentWebhookCertificate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(documentsWebhookConnectorUpdateSuccess),
      filter(data => !!data.changes.authentication?.oAuth?.clientCertificate),
      delay(1000),
      map(({id}) => {
        return documentsWebhookDownloadCertificate({id})})
      )
  });

  downloadDocumentWebhookCertificate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(documentsWebhookDownloadCertificate),
      concatMap(data => {
        return this.orderWebhookService.getDocumentsCertificateById$(data.id).pipe(
          tap((res) => {
            const file = new Blob([res.certificate]);
            saveAs(file, `documents-public-key.cert`);
          })
        );
      })
    )
  }, { dispatch: false });

  constructor(
    private actions$: Actions,
    private store$: Store<CompanyState>,
    private companyService: CompanyService,
    private orderWebhookService: OrderWebhookService,
    private sciService: SciConnectorService,
    private lookerService: LookerService,
    private shipmentConnectorService: ShipmentConnectorService,
  ) {}
}
