import { getCurrencySymbol } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tcCurrencySymbol',
})
export class CurrencySymbolPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}

  transform(currencyIso?: string): string | null {
    if (!currencyIso) {
      return null;
    }

    return getCurrencySymbol(currencyIso, 'narrow', this.locale);
  }
}
