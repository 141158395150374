import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Order } from '@app/order/models';

@Component({
  selector: 'tc-order-name',
  templateUrl: './order-name.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderNameComponent {
  @Input()
  order?: Order;

  public get name(): string {
    if (!this.order) {
      return '';
    }

    return this.order.buyerOrder.purchaseOrderNumber;
  }
}
