import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, mapTo, mergeMapTo } from 'rxjs/operators';
import { NotificationType } from '@app/core/state/core.model';
import { ConnectionService } from '../services/connection.service';
import { createId } from './connection.reducer';
import {
  connectionAccept,
  connectionAcceptFailure,
  connectionAcceptSuccess,
  connectionRequest,
  connectionRequestFailure,
  connectionRequestSuccess,
} from './connection.actions';
import { notificationSend, trackEvent } from '@app/core/state/core.actions';
import { MixpanelButtonClickEvents } from '@app/core/services/page-analytics/enums/mixpanel-button-click-events.enum';

@Injectable()
export class ConnectionEffects {
  request$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(connectionRequest),
      map(({ companyId, otherCompanyId, otherCompanyName, body }) => {
        return {
          companyId,
          otherCompanyId,
          otherCompanyName,
          body,
        };
      }),
      exhaustMap(payload => {
        return this.connectionService.request$(payload).pipe(
          mapTo(connectionRequestSuccess()),
          catchError(({ error }: HttpErrorResponse) => {
            const id = createId(payload.companyId, payload.otherCompanyId);

            return of(
              connectionRequestFailure({
                id,
                error: error.message || error,
              }),
            );
          }),
        );
      }),
    );
  });

  requestSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(connectionRequestSuccess),
      mergeMapTo([
        trackEvent({ eventName: MixpanelButtonClickEvents.CONNECTION_INVITED }),
        notificationSend({
          message: $localize`:@@ts.connection.success:Request sent`,
          notificationType: NotificationType.SUCCESS,
        }),
      ]),
    );
  });

  requestFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(connectionRequestFailure),
      mapTo(
        notificationSend({
          message: $localize`:@@ts.connection.failed:Failed to send the request. Please try again.`,
          notificationType: NotificationType.ERROR,
        }),
      ),
    );
  });

  accept$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(connectionAccept),
      map(({ companyId, otherCompanyId }) => {
        return { companyId, otherCompanyId };
      }),
      exhaustMap(payload => {
        return this.connectionService.accept$(payload).pipe(
          mapTo(connectionAcceptSuccess()),
          catchError(({ error }: HttpErrorResponse) => {
            const id = createId(payload.otherCompanyId, payload.companyId);

            return of(
              connectionAcceptFailure({
                id,
                error: error.message || error,
              }),
            );
          }),
        );
      }),
    );
  });

  acceptSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(connectionAcceptSuccess),
      mapTo(
        notificationSend({
          message: $localize`:@@ts.connection.accept.success:Invite accepted`,
          notificationType: NotificationType.SUCCESS,
        }),
      ),
    );
  });

  acceptFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(connectionAcceptFailure),
      mapTo(
        notificationSend({
          message: $localize`:@@ts.connection.accept.failed:Failed to accept the invite. Please try again.`,
          notificationType: NotificationType.ERROR,
        }),
      ),
    );
  });

  constructor(private actions$: Actions, private connectionService: ConnectionService) {}
}
