import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { UserInterface } from '@app/user/models';
import { UserRole } from '@app/user/models/enums/user-role.enum';

@Component({
  selector: 'tc-role-icon',
  templateUrl: 'role-icon.component.html',
  styleUrls: ['role-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoleIconComponent {
  @Input()
  user?: UserInterface;

  public get isAdmin(): boolean | undefined {
    return (
      this.user &&
      this.user.roles &&
      (this.user.roles.includes(UserRole.ADMIN) || this.user.roles.includes(UserRole.SUPER_USER))
    );
  }

  public get isSupport(): boolean | undefined {
    return this.user && this.user.roles && this.user.roles.includes(UserRole.SUPPORT);
  }

  public get isIntegrationUser(): boolean | undefined {
    return this.user && this.user.roles && this.user.roles.includes(UserRole.INTEGRATION);
  }
}
