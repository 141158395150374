<ng-container *ngIf="location">
  <p>{{location.id}} {{location.idScheme}}</p>
  <p>
    <ng-container *ngFor="let name of location.names">
      {{name}}
    </ng-container>
  </p>
  <p>
    <ng-container *ngFor="let addressLine of location.addressLines">
      {{addressLine}}
    </ng-container>
  </p>
  <p>{{location.postalCode}} {{location.city}} <span>{{location.countryCodeIso2}}</span></p>
</ng-container>
