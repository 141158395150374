import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CompanySharedModule } from '@app/company/shared/company-shared.module';
import { OrderLineSharedModule } from '@app/order-line/shared/order-line-shared.module';
import { OrderSharedModule } from '@app/order/shared/order-shared.module';
import { RootSharedModule } from '@app/shared/root-shared.module';
import { UserSharedModule } from '@app/user/shared/user-shared.module';
import { ActivityListComponent } from './components/activity-list/activity-list.component';
import { DocumentActivityListComponent } from './components/document-activity-list/document-activity-list.component';
import { ActivityRealtimeComponent } from './components/activity-realtime/activity-realtime.component';
import { ActivityComponent } from './components/activity/activity.component';
import { ObjectStorageSharedModule } from '@app/object-storage/shared/object-storage-shared.module';
import { ActivityFilterPipe } from './pipes/activity-filter';

const declarations = [
  ActivityListComponent,
  ActivityRealtimeComponent,
  ActivityComponent,
  ActivityFilterPipe,
  DocumentActivityListComponent,
];

const moduleExports = [ActivityListComponent, ActivityRealtimeComponent, ActivityComponent];

@NgModule({
  imports: [
    FormsModule,
    RootSharedModule,
    UserSharedModule,
    CompanySharedModule,
    OrderSharedModule,
    OrderLineSharedModule,
    ObjectStorageSharedModule,
  ],
  declarations: [declarations],
  exports: [moduleExports],
})
export class ActivitySharedModule {}
