import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { MsalRoutingModule } from './msal-routing.module';
import { MsalCallbackLoginPageComponent } from '@app/msal/containers/msal-callback-login-page.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MsalCallbackLogoutPageComponent } from '@app/msal/containers/msal-callback-logout-page.component';

@NgModule({
  imports: [SharedModule, MsalRoutingModule, MatGridListModule, MatProgressBarModule],
  declarations: [MsalCallbackLoginPageComponent, MsalCallbackLogoutPageComponent],
})
export class MsalModule {}
