<mat-list class="mat-list--properties mat-list--compact">
  <h3 mat-subheader class="mat-subheader--border">
    <ng-container *ngIf="orderType === 'RFQ'; else noRfqType"  i18n="@@order.line.rfq.details.title">RFQ line details</ng-container>
    <ng-template #noRfqType>
      <ng-container i18n="@@order.line.details.title">Order line details</ng-container>
    </ng-template>
  </h3>
</mat-list>

<main class="sections" [fxLayout]="layout" [fxLayoutGap]="fxOptions[layout].gap">

  <mat-list class="mat-list--properties mat-list--compact" [fxFlex]="fxOptions[layout].flex">
    <mat-list-item class="auto-height">
      <div class="property" i18n="@@order.line.details.item">Item</div>
      <div class="value wrap">
        <h4 matLine [class.loading-text]="!item">
          {{ item?.number }}
        </h4>
        <p matLine *ngIf="item?.revision">
          <ng-container i18n="As in the version of an item @@order.line.details.revision">Revision</ng-container>
          &nbsp;
          {{ item?.revision }}
        </p>
        <p matLine *ngIf="item?.name">
          {{ item?.name }}
        </p>
        <p matLine *ngIf="item?.description">
          {{ item?.description }}
        </p>
      </div>
    </mat-list-item>

    <mat-list-item *ngIf="dimensions.length" class="auto-height">
      <div class="property" i18n="@@order.line.dimensions">Dimensions</div>
      <div class="value wrap">
        <ng-container *ngFor="let dimension of dimensions">
          <h4 matLine>
            <span class="secondary">{{ dimension.attribute }}</span>
            <span>{{dimension.measure}} {{dimension.unitOfMeasureUnece | tcUnitSymbol}}</span>
          </h4>
        </ng-container>
      </div>
    </mat-list-item>

    <mat-list-item *ngIf="certification?.type" class="auto-height">
      <div class="property" i18n="@@order.line.details.certification">Certification type</div>
      <div class="value wrap">
        <h4 matLine>{{certification?.type}}</h4>
      </div>
    </mat-list-item>

    <mat-list-item *ngIf="certification?.classificationSociety" class="auto-height">
      <div class="property" i18n="@@order.line.details.classificationSociety">Classification society</div>
      <div class="value wrap">
        <h4 matLine>{{certification?.classificationSociety}}</h4>
      </div>
    </mat-list-item>

    <mat-list-item *ngIf="item?.supplierItemNumber" class="auto-height">
      <div class="property" i18n="@@order.line.details.supplier">Supplier item</div>
      <div class="value wrap">
        {{ item?.supplierItemNumber }}
      </div>
    </mat-list-item>

    <mat-list-item class="auto-height">
      <div class="property" i18n="@@order.line.details.price">Pricing</div>
      <div class="value wrap" [class.loading-text]="!prices">
        <h4 matLine *ngIf="prices?.grossPrice">
          <span class="secondary" i18n="@@order.line.details.gross">Gross</span>
          <span>
            <span [ngClass]="{change: grossPriceChanged}">
              {{ prices?.grossPrice?.priceInTransactionCurrency | tcMoney }}
            </span>
            <tc-price-unit
              [quantity]="prices?.priceUnitQuantity"
              [unit]="prices?.priceUnitOfMeasureIso"
              [ngClass]="{change: priceUnitChanged}"
            ></tc-price-unit>
          </span>
        </h4>
        <h4 matLine *ngIf="prices?.discountPercentage">
          <span class="secondary" i18n="@@order.line.details.discount">Discount</span>
          <span [ngClass]="{change: discountPercentageChanged}">
            {{ prices?.discountPercentage | number: '1.0-5' }}%
          </span>
        </h4>
        <h4 matLine *ngIf="prices?.netPrice">
          <span class="secondary" i18n="As in net price @@order.line.details.net">Net</span>
          <span>
            <span [ngClass]="{change: netPriceChanged}">
              {{ prices?.netPrice?.priceInTransactionCurrency | tcMoney }}
            </span>
            <tc-price-unit
              [quantity]="prices?.priceUnitQuantity"
              [unit]="prices?.priceUnitOfMeasureIso"
              [ngClass]="{change: priceUnitChanged}"
            ></tc-price-unit>
          </span>
        </h4>
      </div>
    </mat-list-item>

    <mat-list-item class="auto-height">
      <div class="property"
           i18n="@@common.field.amount"
           matTooltip="Order total amount could not be calculated because there are differences in the price & purchase unit of measure, or in the currency of the line and charge line."
           i18n-matTooltip="@@tooltip.amount"
           matTooltipPosition="after"
           [matTooltipDisabled]="!!amountView"
      >Total amount</div>
      <div class="value wrap">
        <h4 matLine class="pos-end">
          <span [ngClass]="{change: amountChanged}">
            {{ amountView | tcMoney }}
            <tc-amount-difference
              [amount]="orderLine?.totalAmount"
              [amountIncludingRequests]="orderLine?.totalAmountIncludingRequests"
            ></tc-amount-difference>
          </span>
        </h4>
      </div>
    </mat-list-item>

    <mat-list-item *ngIf="contractNumber" class="auto-height">
      <div class="property" i18n="As in the terms that apply to the order @@order.line.details.terms">Terms</div>
      <div class="value wrap">
        <h4 matLine>
          {{ contractNumber }}
        </h4>
        <p matLine *ngIf="contractPosition">
          <ng-container i18n="As in the position of a order line in an order @@order.line.details.row">Row</ng-container>
          &nbsp;
          {{ contractPosition }}
        </p>
      </div>
    </mat-list-item>
  </mat-list>

  <mat-list class="mat-list--properties mat-list--compact" [fxFlex]="fxOptions[layout].flex">
    <mat-list-item *ngIf="deliverySchedule.length > 1" class="auto-height">
      <div class="property" i18n="@@order.line.details.ordered.quantity">Ordered quantity</div>
      <div class="value wrap">
        <span
          [ngClass]="{change: deliveryQuantityChanged}"
        >
          {{ deliverySchedule | tcTotalQuantity | number }}
          {{ item?.purchaseUnitOfMeasureIso | tcUnitSymbol }}
        </span>
      </div>
    </mat-list-item>

    <mat-list-item *ngIf="deliverySchedule && deliverySchedule.length > 0" class="auto-height">
      <div class="property" i18n="@@order.line.details.schedule">Delivery schedule</div>
    </mat-list-item>
    <mat-table class="compact-table" [dataSource]="deliverySchedule" *ngIf="deliverySchedule && deliverySchedule.length > 0">
      <ng-container matColumnDef="position">
        <mat-header-cell *matHeaderCellDef i18n="as in the position of an order line in an order @@common.position">Position</mat-header-cell>
        <mat-cell *matCellDef="let element"> {{element.position}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="date">
        <mat-header-cell *matHeaderCellDef i18n="@@common.date">Date</mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index;">
          <span class="delivery-date" [ngClass]="{changeLine: hasDeliveryDateChanged(i)}"  [class.overdue]="element?.indicators?.deliveryOverdue">{{element.date | date:'dd/MM/yyyy'}}</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="quantity">
        <mat-header-cell *matHeaderCellDef i18n="@@common.quantity">Quantity</mat-header-cell>
        <mat-cell *matCellDef="let element; let i = index;">
          <span>
            <span [ngClass]="{changeLine: hasDeliveryQuantityChanged(i)}">{{element.quantity | number}}</span>
            {{ item?.purchaseUnitOfMeasureIso | tcUnitSymbol }}
          </span>
        </mat-cell>

      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef i18n="@@common.status">Status</mat-header-cell>
        <mat-cell *matCellDef="let element">
          <tc-logistics-status [status]="element.status"></tc-logistics-status>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="transport">
        <mat-header-cell *matHeaderCellDef i18n="@@common.transport">Mode of transport</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{ element?.transportMode }}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="etd">
        <mat-header-cell
          *matHeaderCellDef
          i18n="@@common.etd"
          matTooltip="Estimated Time of Departure"
          i18n-matTooltip="@@tooltip.etd"
        >ETD</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{element.etd | date:'dd/MM/yyyy'}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="eta">
        <mat-header-cell
          *matHeaderCellDef
          i18n="@@common.eta"
          matTooltip="Estimated Time of Arrival"
          i18n-matTooltip="@@tooltip.eta"
        >ETA</mat-header-cell>
        <mat-cell *matCellDef="let element">
          {{element.eta | date:'dd/MM/yyyy'}}
        </mat-cell>
      </ng-container>
      <mat-header-row class="compact-row" *matHeaderRowDef="columnDefs"></mat-header-row>
      <mat-row class="compact" *matRowDef="let row; columns: columnDefs; let i = index;" [ngClass]="{changeLine: isDeliveryDecrease || isDeliveryNew(i)}"></mat-row>
    </mat-table>

    <mat-list-item *ngIf="deliveryHistory && deliveryHistory.length > 1" class="auto-height">
      <div class="property" i18n="@@order.line.details.delivered.quantity">Delivered quantity</div>
      <div class="value wrap" [class.loading-text]="!deliverySchedule">
        {{ deliveryHistory | tcTotalQuantity | number }}
        {{ item?.purchaseUnitOfMeasureIso | tcUnitSymbol }}
      </div>
    </mat-list-item>

    <mat-list-item *ngIf="deliveryHistory && deliveryHistory.length > 0" class="auto-height">
      <div class="property" i18n="Indicates when and in which quantity goods are delivered @@order.line.details.history">Delivery history</div>
      <div class="value wrap" [class.loading-text]="!deliveryHistory">
        <ng-container *ngFor="let delivery of deliveryHistory">
          <h4 matLine>
            <span class="position">
              {{ delivery.position }}
            </span>
            <span>
              {{ delivery.date | date:'dd/MM/yyyy' }}
            </span>
            <span class="secondary">
              {{ delivery.quantity | number }}
              {{ item?.purchaseUnitOfMeasureIso | tcUnitSymbol }}
            </span>
          </h4>
        </ng-container>
      </div>
    </mat-list-item>
  </mat-list>
</main>
