import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Money } from '@app/order/models';

@Component({
  selector: 'tc-amount-difference',
  templateUrl: './amount-difference.component.html',
  styleUrls: ['./amount-difference.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AmountDifferenceComponent {
  @Input()
  amount?: Money;

  @Input()
  amountIncludingRequests?: Money;

  public get differenceAmount(): number | undefined {
    if (!this.amountIncludingRequests || !this.amount || this.amount?.value === 0) {
      return undefined;
    }

    const ratio = this.amountIncludingRequests.value / this.amount?.value;

    return ratio - 1;
  }
}
