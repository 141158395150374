import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CustomLabel } from '@app/order/models';

@Component({
  selector: 'tc-label-list',
  templateUrl: './label-list.component.html',
  styleUrls: ['./label-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LabelListComponent {
  @Input()
  labels!: CustomLabel[];

  @Input()
  removable = false;

  @Output()
  readonly removeLabel = new EventEmitter<string>();

  onRemove(text: string) {
    this.removeLabel.emit(text);
  }
}
