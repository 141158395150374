import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { OrderLineSearchService } from './services/order-line-search.service';
import { OrderSearchService } from './services/order-search.service';
import { OrderService } from './services/order.service';
import { reducer } from './state/order.reducer';
import { orderFeatureName } from './state/order.selectors';

@NgModule({
  imports: [StoreModule.forFeature(orderFeatureName, reducer)],
  providers: [OrderLineSearchService, OrderSearchService, OrderService],
})
export class OrderCoreModule {}
