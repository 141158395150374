<mat-form-field floatLabel="never" class="mat-form-field--clear">
  <mat-icon matPrefix>search</mat-icon>
  <mat-placeholder i18n="@@core.search.prefix">Search Tradecloud for orders, people, companies</mat-placeholder>
  <input matInput [formControl]="control" [matAutocomplete]="search">
  <tc-mat-form-field-clear matSuffix></tc-mat-form-field-clear>

  <mat-autocomplete #search autoActiveFirstOption (optionSelected)="onSelect($event)">
    <ng-container *ngIf="suggestions$ | async as suggestions; else searching">
      <mat-option *ngIf="suggestions.orders?.length === 0 && suggestions.orderLines?.length === 0 && suggestions.users?.length > 0 && suggestions.companies?.length > 0" [disabled]="true" i18n="@@common.no.results.found">
        No results found
      </mat-option>

      <mat-optgroup *ngIf="suggestions.orders === null || suggestions.orders.length > 0" i18n-label="@@core.label.orders" label="ORDERS">
        <mat-option *ngFor="let order of suggestions.orders" [value]="['/orders', order.id]">
          {{ order.buyerOrder.purchaseOrderNumber }}
        </mat-option>
        <mat-option *ngIf="suggestions.orders === null" [disabled]="true" i18n="@@core.search.no.orders">
          Could not retrieve orders
        </mat-option>
      </mat-optgroup>

      <mat-optgroup *ngIf="suggestions.orderLines === null || suggestions.orderLines.length > 0" i18n-label="@@core.label.orderlines" label="ORDER LINES">
        <mat-option *ngFor="let orderLine of suggestions.orderLines" [value]="['/orders', orderLine.orderId, 'line', orderLine.id]">
          {{ orderLine.buyerLine.position }}
        </mat-option>
        <mat-option *ngIf="suggestions.orderLines === null" [disabled]="true" i18n="@@core.search.no.orderlines">
          Could not retrieve order lines
        </mat-option>
      </mat-optgroup>

      <mat-optgroup *ngIf="suggestions.shipments === null || suggestions.shipments.length > 0" i18n-label="@@core.label.shipments" label="SHIPMENTS">
        <mat-option *ngFor="let shipment of suggestions.shipments" [value]="['/shipments/detail', shipment.id]">
          {{ shipment.supplierShipment?.shipmentNumber }}
        </mat-option>
        <mat-option *ngIf="suggestions.shipments === null" [disabled]="true" i18n="@@core.search.no.shipments">
          Could not retrieve shipments
        </mat-option>
      </mat-optgroup>

      <mat-optgroup *ngIf="suggestions.users === null || suggestions.users.length > 0" i18n-label="@@core.label.people" label="PEOPLE">
        <mat-option *ngFor="let user of suggestions.users" [value]="['/user', user.id]" [disabled]="true">
          <tc-user-summary [user]="user"></tc-user-summary>
        </mat-option>
        <mat-option *ngIf="suggestions.users === null" [disabled]="true" i18n="@@core.search.no.people">
          Could not retrieve people
        </mat-option>
      </mat-optgroup>

      <mat-optgroup *ngIf="suggestions.companies === null || suggestions.companies.length > 0" i18n-label="@@core.label.companies" label="COMPANIES">
        <mat-option *ngFor="let company of suggestions.companies" [value]="['/company', company.id]" [disabled]="true">
          <tc-company-summary [company]="company"></tc-company-summary>
        </mat-option>
        <mat-option *ngIf="suggestions.companies === null" [disabled]="true" i18n="@@core.search.no.companies">
          Could not retrieve companies
        </mat-option>
      </mat-optgroup>
    </ng-container>

    <ng-template #searching>
      <mat-option *ngIf="length > 0 && length < 3" [disabled]="true" i18n="@@core.search.hint">
        Type three characters to start searching
      </mat-option>
      <mat-option *ngIf="(searching$ | async) === true" [disabled]="true" i18n="@@core.search.searching">
        Searching...
      </mat-option>
    </ng-template>
  </mat-autocomplete>
</mat-form-field>
