import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from '@app/shared/api/api.service';
import { Observable } from 'rxjs';
import { Shipment, ShipmentQuery, ShipmentQueryResponse } from '@app/shipment/models';

@Injectable()
export class ShipmentSearchService {
  constructor(private http: HttpClient, private api: API) {}

  public query$(body: ShipmentQuery): Observable<ShipmentQueryResponse> {
    return this.http.post<ShipmentQueryResponse>(this.api.SHIPMENT_SEARCH_QUERY(), body);
  }

  public getById$(id: string): Observable<Shipment> {
    const encodedId = encodeURIComponent(id);

    return this.http.get<Shipment>(this.api.SHIPMENT_ID(encodedId));
  }
}
