import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AssignedTo, TaskQueryDto, TaskQueryFilters, TaskStatus } from '@app/task/models';
import { selectAuthIdentityCompanyId } from '@app/auth/core/state/auth.selectors';
import { mapQueryParametersToDtoFilters, mapQueryParametersToFilters } from './util';
import { createPagedSliceSelector, selectRouterPageQuery, selectRouterQueryParams } from '@app/store/reducers';
import { selectTaskEntities } from '@app/task/core/state/task.selectors';
import { TaskListState } from './task-list.reducer';

export const taskListFeatureName = 'taskList';

const selectState = createFeatureSelector<TaskListState>(taskListFeatureName);

export const selectTaskListTotal = createSelector(selectState, ({ total }) => {
  return total;
});

export const selectTaskListAggregations = createSelector(selectState, ({ aggregations }) => {
  return aggregations;
});

export const selectTaskListOutdated = createSelector(selectState, ({ outdated }) => {
  return outdated;
});

export const selectTaskListLoadMore = createSelector(selectState, ({ loadMore }) => {
  return loadMore;
});

const selectIds = createSelector(selectState, ({ ids }) => {
  return ids;
});

export const selectClosedIds = createSelector(selectState, ({ closedIds }) => {
  return closedIds;
});

export const selectIdsLength = createSelector(selectState, ({ ids }) => {
  return ids ? ids.length : 0;
});

const selectTasks = createSelector(
  selectIds,
  selectTaskEntities,
  selectClosedIds,
  selectRouterQueryParams,
  (ids, entities, closedTaskIds, params: TaskQueryFilters | undefined | null) => {
    if (ids) {
      const taskStatus = params?.taskStatus || TaskStatus.Created;
      const tasks = ids
        .filter(id => {
          if (taskStatus === TaskStatus.Created) {
            for (const closedId of closedTaskIds) {
              if (closedId === id) {
                return false;
              }
            }
          }

          return true;
        })
        .map(id => {
          return id ? entities[id] : null;
        });

      return tasks;
    }

    return null;
  },
);

export const selectTaskListTasksPaged = createPagedSliceSelector(selectTasks);

export const selectTaskListFilters = createSelector(
  selectAuthIdentityCompanyId,
  selectRouterQueryParams,
  mapQueryParametersToFilters,
);

export const selectTaskListAssignedToFilter = createSelector(selectTaskListFilters, (filters: TaskQueryFilters) => {
  return filters.assignedTo;
});

export const selectTaskListDtoFilters = createSelector(
  selectAuthIdentityCompanyId,
  selectRouterQueryParams,
  mapQueryParametersToDtoFilters,
);

export const canResetFilters = createSelector(selectTaskListFilters, (filters: TaskQueryFilters) => {
  return (
    filters.taskStatus === 'closed' ||
    filters.taskType !== undefined ||
    !!filters.contactUserIds?.length ||
    !!filters.category?.length ||
    !!filters.module?.length ||
    filters.unassigned ||
    filters?.assignedTo !== AssignedTo.COMPANY ||
    !!filters.relatedCompanyIds?.length
  );
});

export const selectTaskListQuery = createSelector(
  selectRouterPageQuery,
  selectTaskListDtoFilters,
  ({ offset, limit }, filters: TaskQueryFilters): TaskQueryDto => {
    return {
      filters,
      offset,
      limit,
    };
  },
);
