import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as login from '@app/auth/routes/login/state';
import * as validate from '@app/auth/routes/validate/state';
import { selectAuthIdentity, selectConfigure2FA, selectRecoveryCodes } from '@app/auth/core/state/auth.selectors';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Configure2FA } from '@app/auth/core/state/auth.reducer';
import { Identity } from '@app/auth/models';
import { TwoFACode } from '@app/auth/core/state/auth.model';
import { isNotNullOrUndefined } from '@app/util/operators/is-not-null-or-undefined';

@Injectable()
export class TwoFactorSetupService {
  public identity$: Observable<Identity>;
  public configure2FA$?: Observable<Configure2FA>;
  public backupCodes$: Observable<string[]>;
  public lastStep$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public readonly onReset$: Observable<void>;
  public readonly onEnable$: Observable<TwoFACode>;
  private reset$: Subject<void> = new Subject();
  private enable$: Subject<TwoFACode> = new Subject();

  constructor(private store$: Store<login.State | validate.State>) {
    this.identity$ = this.store$.pipe(select(selectAuthIdentity), isNotNullOrUndefined());
    this.configure2FA$ = this.store$.pipe(select(selectConfigure2FA));
    this.backupCodes$ = this.store$.pipe(select(selectRecoveryCodes));

    this.onReset$ = this.reset$.asObservable();
    this.onEnable$ = this.enable$.asObservable();
  }

  public emitReset(): void {
    this.reset$.next();
  }

  public emitEnable(event: TwoFACode): void {
    this.enable$.next(event);
  }

  public emitLastStep(value: boolean): void {
    this.lastStep$.next(value);
  }
}
