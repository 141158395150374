<div class="details" [class.details--open]="open">
  <div class="details__summary" (click)="toggle()">
    <div matSubheader>
      {{ summary }}

      <mat-icon class="open" matListIcon>arrow_drop_down</mat-icon>
      <mat-icon class="close" matListIcon>arrow_drop_up</mat-icon>
    </div>
  </div>

  <div class="details__content">
    <ng-content></ng-content>
  </div>
</div>
