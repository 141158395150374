const dateFormat = /^\d{4}-\d\d-\d\d(T\d\d:\d\d:\d\d)?(\.\d+)?Z?$/;

export function reviveDates(data: any): any {
  try {
    const text = JSON.stringify(data);

    return JSON.parse(text, dateTimeReviver);
  } catch (e) {
    return data;
  }
}

/* eslint:disable @typescript-eslint/no-use-before-define */
export function dateTimeReviver(key: any, value: any): any {
  if (typeof value === 'string' && dateFormat.test(value)) {
    return new Date(value);
  }

  return value;
}

/* eslint-enable @typescript-eslint/no-use-before-define */
