import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ExpansionFormComponent } from '@app/shared/abstracts/expansion-form.component';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  FormTaskOverdueDelaySettings,
  OrderOverdueDelaySettings,
} from '@app/company/routes/settings/components/task-overdue/task-overdue-delay.model';
import { INTEGER_PATTERN, PERIOD } from '@app/company/routes/settings/components/utils';

@Component({
  selector: 'tc-task-overdue',
  templateUrl: './task-overdue.component.html',
  styleUrls: ['./task-overdue.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskOverdueComponent extends ExpansionFormComponent<OrderOverdueDelaySettings, OrderOverdueDelaySettings> {
  public readonly periodList = PERIOD;
  public form = new UntypedFormGroup({
    delayOverdueDays: new UntypedFormControl(1, [Validators.pattern(INTEGER_PATTERN), Validators.min(1), Validators.required]),
    delayDuringWeekend: new UntypedFormControl('no', Validators.required),
    turnOffOverdue: new UntypedFormControl(false, Validators.required),
  });

  @Input()
  hasRemove = false

  @Input()
  connection = false

  @Input()
  errMsg = '';

  @Output()
  readonly remove = new EventEmitter<void>();

  @Output()
  readonly closeDialog = new EventEmitter<void>();

  patchValue(value: OrderOverdueDelaySettings | null): void {
    if (value) {
      const confirmOrderLineBySupplierValue = value;
      const formValue: FormTaskOverdueDelaySettings = {
        delayOverdueDays: confirmOrderLineBySupplierValue.delayOverdueDays,
        delayDuringWeekend: confirmOrderLineBySupplierValue.delayDuringWeekend ? 'no' : 'yes',
        turnOffOverdue: confirmOrderLineBySupplierValue.turnOffOverdue,
      };
      this.form.patchValue(formValue);
    }
  }

  toggle(value: boolean): void {
    if (value) {
      this.removeMinValidator();
    } else {
      this.addAllValidators();
    }
  }

  removeMinValidator(): void {
    const control = this.form.controls.delayOverdueDays;
    control.setValidators([Validators.pattern(INTEGER_PATTERN), Validators.required]);
    control.updateValueAndValidity();
  }

  addAllValidators(): void {
    const control = this.form.controls.delayOverdueDays;
    control.setValidators([Validators.pattern(INTEGER_PATTERN), Validators.min(1), Validators.required]);
    control.updateValueAndValidity();
  }

  get normalizedFormValue(): OrderOverdueDelaySettings {
    const value: FormTaskOverdueDelaySettings = this.form.value;

    const data: OrderOverdueDelaySettings = {
      delayOverdueDays: Number(value.delayOverdueDays),
      delayDuringWeekend: value.delayDuringWeekend !== 'yes',
      turnOffOverdue: value.turnOffOverdue,
    };

    return data;
  }

  onRemove() {
    this.remove.emit();
  }

  onClose() {
    this.closeDialog.emit();
  }
}
