import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AnalyticsState } from '@app/analytics/core/state/analytics.reducer';
import { authLogout } from '@app/auth/core/state/auth.actions';

@Component({
  selector: 'tc-msal-callback-logout-page',
  templateUrl: './msal-callback.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MsalCallbackLogoutPageComponent implements OnInit {
  constructor(private store$: Store<AnalyticsState>) {}

  ngOnInit(): void {
    this.store$.dispatch(authLogout());
  }
}
