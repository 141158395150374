import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from '@app/shared/api/api.service';
import { Observable } from 'rxjs';
import { IntegrationCallMethod } from '@app/company/models/enums/integration-settings.enum';
import { EndpointCredentials } from '@app/company/models';

export interface ShipmentIntegrationSettings {
  companyId: string;
  webhookUrl?: string;
  method?: IntegrationCallMethod;
  basicAuthCredentials?: EndpointCredentials;
  enabledEventNames?: string[];
}

export type UpdateShipmentIntegrationSettings = Required<Omit<ShipmentIntegrationSettings, 'companyId'>>;

@Injectable()
export class ShipmentConnectorService {
  constructor(private http: HttpClient, private api: API) {}

  public getById$(buyerCompanyId: string): Observable<ShipmentIntegrationSettings> {
    return this.http.get<ShipmentIntegrationSettings>(this.api.SHIPMENT_WEBHOOK_CONNECTOR_SETTINGS(buyerCompanyId));
  }

  public update$(id: string, body: UpdateShipmentIntegrationSettings): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(this.api.SHIPMENT_WEBHOOK_CONNECTOR_SETTINGS(id), body);
  }

  public delete$(id: string): Observable<{ ok: boolean }> {
    return this.http.delete<{ ok: boolean }>(this.api.SHIPMENT_WEBHOOK_CONNECTOR_SETTINGS(id));
  }
}
