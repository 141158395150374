<mat-list class="mat-list--properties mat-list--compact">
  <h3 mat-subheader class="mat-subheader--border">RFQ details</h3>

  <mat-list-item class="auto-height">
    <div class="property" i18n="@@common.field.destination">Destination</div>
    <div class="value">
      <h4 matLine class="wrap" [class.loading-text]="!destination">
        {{ destination?.code }}
      </h4>
      <p matLine class="wrap" *ngFor="let name of destination?.names">
        {{ name }}
      </p>
      <p matLine class="wrap" *ngFor="let line of destination?.addressLines">
        {{ line }}
      </p>
      <p matLine class="wrap" *ngIf="destination?.postalCode || destination?.city">
        {{ destination?.postalCode }} {{ destination?.city }}
      </p>
      <p matLine class="wrap">
        {{ destination?.countryCodeIso2 }} {{ destination?.countryName }}
      </p>
      <p matLine class="wrap">
        {{ destination?.locationType }}
      </p>
    </div>
  </mat-list-item>

  <mat-list-item *ngIf="incotermsCode" class="auto-height">
    <div class="property" i18n="No translation needed @@common.field.inconterms">Incoterms</div>
    <div class="value">
      <h4 matLine class="wrap">
        {{ incotermsCode | tcIncotermsName }}
      </h4>
      <p matLine class="wrap" *ngIf="incotermsLocationName">
        {{ incotermsLocationName }}
      </p>
      <p matLine class="wrap">
        {{ incoterms }}
      </p>
    </div>
  </mat-list-item>

  <mat-list-item *ngIf="paymentTermsCode" class="auto-height">
    <div class="property" i18n="@@common.field.payment.terms">Payment terms</div>
    <div class="value">
      <h4 matLine class="wrap">
        {{ paymentTermsCode }}
      </h4>
      <p matLine class="wrap" *ngIf="paymentTermsDescription">
        {{ paymentTermsDescription }}
      </p>
      <p matLine class="wrap">
        {{ paymentTerms }}
      </p>
    </div>
  </mat-list-item>

  <mat-list-item class="auto-height">
    <div class="property">RFQ end date</div>
    <div class="value">
      <h4 matLine class="wrap">
        {{endAt | date:'dd/MM/yyyy'}}
      </h4>
    </div>
  </mat-list-item>
</mat-list>
