import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  Input,
  QueryList,
  ViewChild,
} from '@angular/core';
import { MatColumnDef, MatHeaderRowDef, MatRowDef, MatTable } from '@angular/material/table';

import { TableDataSource } from './table-data-source';

interface Row<T> {
  entity: T;
}

@Component({
  selector: 'tc-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent<T> implements AfterContentInit, AfterViewInit {
  @Input()
  dataSource!: TableDataSource<T>;

  @Input()
  columns!: string[];

  @Input()
  error?: string;

  @ContentChild(MatHeaderRowDef)
  headerRowDef!: MatHeaderRowDef;

  @ContentChildren(MatRowDef)
  rowDefs!: QueryList<MatRowDef<T>>;

  @ContentChildren(MatColumnDef)
  columnDefs!: QueryList<MatColumnDef>;

  @ViewChild(MatTable, { static: true })
  table!: MatTable<T>;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    const { nativeElement } = this.elementRef;
    const thead = nativeElement.querySelector('thead');
    const tbody = nativeElement.querySelector('tbody');

    tbody.addEventListener('scroll', () => {
      thead.scrollLeft = tbody.scrollLeft;
    });
  }

  ngAfterContentInit(): void {
    this.table.addHeaderRowDef(this.headerRowDef);
    this.rowDefs.forEach(rowDef => {
      this.table.addRowDef(rowDef);
    });
    this.columnDefs.forEach(columnDef => {
      this.table.addColumnDef(columnDef);
    });
  }

  public trackBy(index: number, row: Row<T>): T | number {
    return row.entity || index;
  }
}
