import { Pipe, PipeTransform } from '@angular/core';
import { OrderLine } from '@app/order/models';
import {
  hasBuyerReopenRequestChargeLines,
  hasOpenBuyerReopenRequest,
  hasOpenSupplierProposal,
  hasOpenSupplierReopenRequest,
  hasSupplierOpenProposalChargeLines,
  hasSupplierReopenRequestChargeLines,
} from '@app/order/util/helper';

@Pipe({
  name: 'tcQuantityChargeLines',
})
export class QuantityChargeLinesPipe implements PipeTransform {
  transform(orderLine: OrderLine): number {
    if (hasOpenBuyerReopenRequest(orderLine) && hasBuyerReopenRequestChargeLines(orderLine)) {
      return orderLine.buyerLine.requests!.reopenRequest!.chargeLines.length;
    }

    if (hasOpenSupplierReopenRequest(orderLine) && hasSupplierReopenRequestChargeLines(orderLine)) {
      return orderLine.supplierLine.requests.reopenRequest!.chargeLines.length;
    }

    if (hasOpenSupplierProposal(orderLine) && hasSupplierOpenProposalChargeLines(orderLine)) {
      return orderLine.supplierLine.requests.proposal!.chargeLines.length;
    }

    return orderLine?.confirmedLine?.chargeLines.length || orderLine?.buyerLine.chargeLines.length;
  }
}
