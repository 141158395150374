export function getImageQuality(dpr: number): number {
  if (dpr >= 3) {
    return 15;
  }
  if (dpr >= 2) {
    return 25;
  }

  return 55;
}
