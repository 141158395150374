<ng-container [ngSwitch]="status">
  <span *ngSwitchCase="'Issued'" i18n="@@common.process.issued">Issued</span>
  <span *ngSwitchCase="'InProgress'" i18n="@@common.process.inprogress">In progress</span>
  <span *ngSwitchCase="'Confirmed'" i18n="@@common.process.confirmed">Confirmed</span>
  <span *ngSwitchCase="'Rejected'" i18n="@@common.process.rejected">Rejected</span>
  <span *ngSwitchCase="'Completed'" i18n="@@common.process.completed">Completed</span>
  <span *ngSwitchCase="'Cancelled'" i18n="@@common.process.cancelled">Cancelled</span>
  <span *ngSwitchCase="'Submitted'" i18n="@@common.process.submitted">Submitted</span>
  <span *ngSwitchCase="'OpenSupplierProposal'" i18n="@@common.in.progress.OpenSupplierProposal">Supplier proposal</span>
  <span *ngSwitchCase="'RejectedSupplierProposal'" i18n="@@common.in.progress.RejectedSupplierProposal">Rejected proposal</span>
  <span *ngSwitchCase="'ReissuedRejectedLine'" i18n="@@common.in.progress.ReissuedRejectedLine">Reissued by the buyer</span>
  <span *ngSwitchCase="'OpenSupplierReopenRequest'" i18n="@@common.in.progress.OpenSupplierReopenRequest">Supplier request</span>
  <span *ngSwitchCase="'OpenBuyerReopenRequest'" i18n="@@common.in.progress.OpenBuyerReopenRequest">Buyer request</span>
  <span *ngSwitchCase="'RevertedCompletedLine'" i18n="@@common.in.progress.RevertedCompletedLine">Reverted completion</span>
  <span *ngSwitchCase="'OpenBuyerReconfirmationRequest'" i18n="@@common.in.progress.OpenBuyerReconfirmationRequest">Reconfirmation request</span>
  <span *ngSwitchDefault [class.text-placeholder]="true"></span>
</ng-container>
