import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Price } from '@app/order/models';
import { priceDifference } from '@app/order/shared/components/price-difference/helpers/price-difference.helper';

@Component({
  selector: 'tc-price-difference',
  templateUrl: './price-difference.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceDifferenceComponent {
  @Input()
  currentPrice?: Price;

  @Input()
  proposedPrice?: Price;

  public get difference(): number | undefined {
    return priceDifference(this.currentPrice, this.proposedPrice);
  }
}
