import { createAction, props } from '@ngrx/store';
import { CreateOrGetSsoUserPayload, MsalJwtToken } from '@app/msal/core/state/msal.model';

export enum MsalActionType {
  GET_MSAL_TOKEN = '[Msal] Get Msal token',
  INITIATE_SSO_AUTHENTICATION = '[Msal] Initiate sso authentication',
  EXCHANGE_JWT_FOR_ACCESS_TOKEN = '[Msal] Exchange jwt for access token',

  UPSERT_SSO_USER = '[Msal] SSO user upsert',
  UPSERT_SSO_USER_SUCCESS = '[Msal] SSO user upsert success',
}

export const getMsalToken = createAction(MsalActionType.GET_MSAL_TOKEN);

export const initiateSsoAuthentication = createAction(
  MsalActionType.INITIATE_SSO_AUTHENTICATION,
  props<MsalJwtToken>(),
);

export const upsertSsoUser = createAction(MsalActionType.UPSERT_SSO_USER, props<CreateOrGetSsoUserPayload>());
