import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter } from 'rxjs/operators';

@Injectable()
export class UpdateService {
  constructor(private snackBar: MatSnackBar, private swUpdate: SwUpdate) {}

  public init(): void {
    this.watchForUpdates();
  }

  private watchForUpdates(): void {
    this.swUpdate.versionUpdates
      .pipe(
        filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
      )
      .subscribe(event => {
        const snackBarRef = this.snackBar.open(
          $localize`:@@ts.sw.update:Tradecloud update available`,
          $localize`:@@ts.sw.update.btn:REFRESH`,
      );

      // eslint-disable-next-line rxjs/no-nested-subscribe
      snackBarRef.onAction().subscribe(() => {
        window.location.reload();
      });
    });
  }
}
