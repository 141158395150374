import { createAction, props } from '@ngrx/store';
import { UpdateUserInterface, UserInterface } from '@app/user/models';
import { UUID } from '@app/shared/models';

export enum UserActionType {
  FETCH = '[User] Fetch',
  FETCH_FAILURE = '[User] Fetch Failure',
  LISTEN = '[User] Listen',
  UPSERT = '[User] Upsert',
  ADD_MANY = '[User] Add Many',
  UPDATE = '[User] Update',
  UPDATE_SSO = '[User] SSO Update',
  REINVITE = '[User] Reinvite',
  REINVITE_SUCCESS = '[User] Reinvite Success',
  REINVITE_FAILURE = '[User] Reinvite Failure',
  REMOVE = '[User] Remove user',
  REMOVE_SUCCESS = '[User] Remove user Success',
  REMOVE_FAILURE = '[User] Remove user Failure',
  UPDATE_SUCCESS = '[User] Update Success',
  UPDATE_FAILURE = '[User] Update Failure',
}

export const userFetch = createAction(UserActionType.FETCH, props<Pick<UserInterface, 'id'>>());
export const userFetchFailure = createAction(UserActionType.FETCH_FAILURE, props<{ id: UUID; error: string }>());
export const userListen = createAction(UserActionType.LISTEN, props<{ companyId: string }>());
export const userUpsert = createAction(UserActionType.UPSERT, props<{ entity: UserInterface; force?: boolean }>());
export const userReinvite = createAction(UserActionType.REINVITE, props<{ id: string }>());
export const userReinviteSuccess = createAction(UserActionType.REINVITE_SUCCESS);
export const userReinviteFailure = createAction(
  UserActionType.REINVITE_FAILURE,
  props<{ id: string; error: string }>(),
);
export const userRemove = createAction(UserActionType.REMOVE, props<{ id: string }>());
export const userRemoveSuccess = createAction(UserActionType.REMOVE_SUCCESS, props<{ entity: UserInterface }>());
export const userRemoveFailure = createAction(UserActionType.REMOVE_FAILURE, props<{ error: string; id: string }>());
export const userAddMany = createAction(UserActionType.ADD_MANY, props<{ entities: UserInterface[] }>());
export const userUpdate = createAction(UserActionType.UPDATE, props<{ id: string; changes: UpdateUserInterface }>());
export const userSSOUpdate = createAction(
  UserActionType.UPDATE_SSO,
  props<{ id: string; changes: UpdateUserInterface }>(),
);
export const userUpdateSuccess = createAction(UserActionType.UPDATE_SUCCESS);
export const userUpdateFailure = createAction(UserActionType.UPDATE_FAILURE, props<{ id: string; error: string }>());
