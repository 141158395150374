import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'tcSmartDate',
})
export class SmartDatePipe implements PipeTransform {
  transform(date?: Date): string | null {
    const m = moment(date);
    const now = moment();

    if (!m.isValid()) {
      return null;
    }

    if (m.isSame(now, 'date')) {
      return m.format('HH:mm');
    }

    if (m.isSame(now, 'year')) {
      return m.format('MMM D');
    }

    return m.format('DD/MM/YYYY');
  }
}
