import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { AccountService } from './services/account.service';
import { reducer } from './state/account.reducer';
import { accountFeatureName } from './state/account.selectors';

@NgModule({
  imports: [StoreModule.forFeature(accountFeatureName, reducer)],
  providers: [AccountService],
})
export class AccountCoreModule {}
