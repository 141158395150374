import { WebSocketSubject } from 'rxjs/webSocket';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

export function startPingingWebSocketUntilClosed<T>(socket$: WebSocketSubject<T>): void {
  const pingInterval = 30000;

  interval(pingInterval)
    .pipe(
      takeWhile(() => {
        return !socket$.closed;
      }),
    )
    .subscribe(() => {
      socket$.next('ping' as any);
    });
}
