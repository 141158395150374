export enum CachedURL {
  ORDER_LIST = '/orders',
  WORKFLOW = '/workflow',
  SHIPMENTS = '/shipments',
}

export enum CachedKey {
  ACTIVITY = 'activity',
  VIEW_MODE = 'orders-view-mode',
  SORT = 'orders-sort',

  ORDER_LIST_LIMIT = 'orders-limit',
  WORKFLOW_LIMIT = 'tasks-limit',
  EXPAND_COLLAPSE = 'expand',

  SHIPMENT_FILTERS = 'shipment-filters',
}

export enum CachedTrackedVersionKey {
  TASK_FILTERS = 'task-filters',
  ORDERS_FILTERS = 'orders-filters',

  ORDER_PANELS = 'order-panels',
  LINE_PANELS = 'line-panels',

  ORDER_COLUMNS = 'order-columns',
  LINE_COLUMNS = 'line-columns',

  CONVERTER_ORDER_GENERAL = 'converter-order-columns-general',
  CONVERTER_ORDER_BUYER = 'converter-order-columns-buyer',
  CONVERTER_ORDER_SUPPLIER = 'converter-order-columns-supplier',

  CONVERTER_ORDER_LINE_GENERAL = 'converter-order-line-columns-general',
  CONVERTER_ORDER_LINE_BUYER = 'converter-order-line-columns-buyer',
  CONVERTER_ORDER_LINE_SUPPLIER = 'converter-order-line-columns-supplier',
  CONVERTER_ORDER_LINE_CONFIRMED = 'converter-order-line-columns-confirmed',
}
