import { HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import jwt_decode from 'jwt-decode';
import { EMPTY, of } from 'rxjs';
import {
  catchError,
  concatMap,
  exhaustMap,
  filter,
  map,
  mapTo,
  mergeMap,
  mergeMapTo,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { PageAnalyticsService } from '@app/core/services/page-analytics/page-analytics.service';
import { StorageService } from '@app/core/services/storage/storage.service';
import { WebSocketService } from '@app/core/services/web-socket/web-socket.service';
import { NotificationType } from '@app/core/state/core.model';
import { isPrerender } from '@app/util/helpers';
import { AccessTokenPayload, Identity, ServerIdentityModel, toIdentity, Tokens } from '@app/auth/models';
import { AuthService } from '../services/auth.service';
import { selectAuthIdentityUserId, selectAuthRedirectPath } from './auth.selectors';
import {
  authActAsCompany,
  authBackToLogin,
  authDisable2FA,
  authDisable2FAFailure,
  authDisable2FASuccess,
  authDisable2FAViaRecovery,
  authEnable2FA,
  authEnable2FAFailure,
  authEnable2FASuccess,
  authGenerate2FASecret,
  authGenerate2FASecretFailure,
  authGenerate2FASecretSuccess,
  authInitialize,
  authLogout,
  authLogoutFromAzureAd,
  authRedirectAfterLogin,
  authRedirectToLogin,
  authRefresh,
  authRefreshSSO,
  authRefreshSSOSuccess,
  authSetAuthenticated,
  authSetIdentityAndBootup,
  authSetIdentityWithoutBooting,
  authSetIdentityWithoutBootingAndGenerateSecret,
  authSetTokens,
  authSetup2FAAndLogin,
  authStartLogout,
  checkUserCountry,
  saveUserCountry,
} from './auth.actions';
import { AuthState } from '../state/auth.reducer';
import { companyFetch, companyListen } from '@app/company/core/state/company.actions';
import { notificationSend, trackEvent } from '@app/core/state/core.actions';
import { orderListen } from '@app/order/core/state/order.actions';
import { taskListen } from '@app/task/core/state/task.actions';
import { userFetch, userListen, userUpsert } from '@app/user/core/state/user.actions';
import { JwtService } from '@app/auth/core/services/jwt.service';
import { AuthenticationResult } from '@azure/msal-browser';
import { MsalService } from '@app/msal/msal.service';
import { MixpanelButtonClickEvents } from '@app/core/services/page-analytics/enums/mixpanel-button-click-events.enum';
import { TtlPeriods, TtlStorageService } from '@app/core/services/storage/ttl-storage.service';
import { clearCachedFilters } from '@app/cache/state/cache.actions';
import { shipmentListen } from '@app/shipment/core/state/shipment.actions';

export const TOKENS_KEY = 'auth.tokens';

@Injectable()
export class AuthEffects {
  init$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROOT_EFFECTS_INIT),
      filter(() => {
        return !isPrerender();
      }),
      mapTo(authInitialize()),
    );
  });

  initialize$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authInitialize),
      tap(() => {
        this.pageAnalyticsService.initialize();
      }),
      map(() => {
        return this.storage.get<Tokens>(TOKENS_KEY);
      }),
      mergeMap(tokens => {
        if (tokens) {
          const identity = jwt_decode<AccessTokenPayload>(tokens.accessToken).data;
          if (identity.status === 'authenticated') {
            return [
              authSetAuthenticated({ isAuthenticated: true }),
              authSetTokens(tokens),
              authSetIdentityAndBootup(toIdentity(identity)),
            ];
          }

          // scenario when user refreshes on setup 2FA screen
          if (identity.status === 'needs_2fa_setup') {
            return [
              authSetTokens(tokens),
              authSetAuthenticated({ isAuthenticated: false }),
              authSetIdentityWithoutBootingAndGenerateSecret(toIdentity(identity)),
            ];
          }

          return [authSetTokens(tokens), authSetAuthenticated({ isAuthenticated: false })];
        }

        return [authSetAuthenticated({ isAuthenticated: false })];
      }),
    );
  });

  checkCountry$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(checkUserCountry),
      concatMap(() => {
        const countryCode = this.ttlStorage.get<string>('country_code');
        if (countryCode !== null) {
          const directDownload = countryCode === 'CN';

          return of(saveUserCountry({ isChina: directDownload }));
        }

        return this.authService.checkUserGEO$().pipe(
          map(value => {
            const directDownload = value?.country_code === 'CN';
            this.ttlStorage.set<string>('country_code', value.country_code, { ttl: TtlPeriods.WEEK });

            return saveUserCountry({ isChina: directDownload });
          }),
          catchError(() => {
            return of(
              trackEvent({ eventName: MixpanelButtonClickEvents.CHECK_USER_COUNTRY_FAILED }),
              saveUserCountry({ isChina: false }),
            );
          }),
        );
      }),
    );
  });

  refresh$: any = createEffect((): any => {
    return this.actions$.pipe(
      ofType(authRefresh),
      map(action => {
        return action.refreshToken;
      }),
      exhaustMap(refreshToken => {
        return this.authService.refresh$(refreshToken).pipe(
          // Do not dispatch an action as the new tokens are read by an http interceptor
          filter(() => {
            return false;
          }),
          catchError((error: HttpErrorResponse) => {
            if (error.status === 401) {
              return of(authLogout());
            }

            return EMPTY;
          }),
        );
      }),
    );
  });

  refreshSSO$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authRefreshSSO),
      exhaustMap(() => {
        return this.msalService.acquireToken$().pipe(
          mergeMap((session: AuthenticationResult) => {
            return this.authService.upsertSsoIdentity$(session.idToken);
          }),
          mergeMap(() => {
            return [authRefreshSSOSuccess()];
          }),
          catchError((e: any) => {
            console.error(e);

            return of(authLogoutFromAzureAd());
          }),
        );
      }),
    );
  });

  // sets up websocket connections and fetch the needed information

  setIdentityAndBootup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authSetIdentityAndBootup),
      map(({ type, ...identity }) => {
        return identity;
      }),
      tap((identity: Identity) => {
        this.pageAnalyticsService.session(identity);
      }),
      mergeMap(({ userId, companyId }) => {
        return [
          userFetch({ id: userId }),
          userListen({ companyId }),
          companyFetch({ id: companyId, flush: false, workflow: true }),
          companyListen({ companyId }),
          orderListen({ companyId }),
          taskListen({ companyId }),
          checkUserCountry(),
          shipmentListen({ companyId }),
        ];
      }),
    );
  });

  setIdentityWithoutBooting$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authSetIdentityWithoutBooting),
        map(({ type, ...identity }) => {
          return identity;
        }),
        tap((identity: Identity) => {
          this.pageAnalyticsService.session(identity);
        }),
      );
    },
    { dispatch: false },
  );

  actAsCompany$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authActAsCompany),
      map(({ companyId }) => {
        return { companyId };
      }),
      filter(({ companyId }) => {
        return !!companyId;
      }),
      tap(() => {
        this.webSocketService.closeAll();
      }),
      mergeMap(({ companyId }) => {
        return [
          clearCachedFilters(),
          userListen({ companyId }),
          companyListen({ companyId }),
          orderListen({ companyId }),
          taskListen({ companyId }),
          companyFetch({ id: companyId, flush: true, workflow: true }),
          shipmentListen({ companyId }),
        ];
      }),
    );
  });

  setTokens$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authSetTokens),
      map(({ accessToken, refreshToken }) => {
        return { accessToken, refreshToken };
      }),
      tap(tokens => {
        this.storage.set(TOKENS_KEY, tokens);
      }),
      map(tokens => {
        return jwt_decode<AccessTokenPayload>(tokens.accessToken);
      }),
      map(tokenPayload => {
        return authSetIdentityWithoutBooting(toIdentity(tokenPayload.data));
      }),
    );
  });

  redirectToLogin$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authRedirectToLogin),
        tap(() => {
          this.router.navigate(['/login']);
        }),
      );
    },
    { dispatch: false },
  );

  redirectAfterLogin$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(authRedirectAfterLogin),
        withLatestFrom(this.store$.pipe(select(selectAuthRedirectPath))),
        map(([action, path]) => {
          return path ? path : '/';
        }),
        tap(path => {
          if (path !== '/') {
            // remove locale from path /en/test -> /test
            const [slash, , ...pathWithoutLocale] = path.split('/');
            const fullPathWithoutLocale = [slash, ...pathWithoutLocale].join('/');
            this.router.navigate([decodeURIComponent(fullPathWithoutLocale)]);
          } else {
            this.router.navigate([path]);
          }
        }),
      );
    },
    { dispatch: false },
  );

  startLogout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authStartLogout),
      map(() => {
        return this.storage.get<Tokens>(TOKENS_KEY);
      }),
      exhaustMap(tokens => {
        if (tokens) {
          const identity = jwt_decode<AccessTokenPayload>(tokens.accessToken).data;
          if (identity.identityProvider === 'azure_ad') {
            this.storage.remove(TOKENS_KEY);

            return [authLogoutFromAzureAd()];
          }
        }

        return this.authService.logout$().pipe(
          mergeMap(() => {
            return [
              authLogout(),
              notificationSend({
                message: $localize`:@@ts.auth.logout:Logged out`,
                notificationType: NotificationType.SUCCESS,
              }),
            ];
          }),
        );
      }),
    );
  });

  logoutFromAzureAD$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authLogoutFromAzureAd),
      exhaustMap(() => {
        return this.msalService.logout$().pipe(
          mergeMap(() => {
            return [authLogout()];
          }),
        );
      }),
    );
  });

  backToLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authBackToLogin),
      exhaustMap(() => {
        return this.authService.logout$().pipe(
          mergeMap(() => {
            return [authLogout()];
          }),
        );
      }),
    );
  });

  logout$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authLogout),
      tap(() => {
        this.storage.remove(TOKENS_KEY);
        this.webSocketService.closeAll();
        this.pageAnalyticsService.logout();
      }),
      mergeMapTo([authRedirectToLogin({ path: '' })]),
    );
  });

  generate2FASecret$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authGenerate2FASecret),
      concatMap(() => {
        return this.authService.generate2FASecret$().pipe(
          map(authGenerate2FASecretSuccess),
          catchError(() => {
            return of(authGenerate2FASecretFailure());
          }),
        );
      }),
    );
  });

  enable2FA$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authEnable2FA),
      concatMap(twoFACode => {
        return this.jwtService.getValidTokens$().pipe(
          concatMap((tokens: Tokens) => {
            const refreshToken = tokens.refreshToken;

            return this.authService.enable2FA$({ code: twoFACode.code }, refreshToken).pipe(
              map((identity: ServerIdentityModel) => {
                return authEnable2FASuccess(toIdentity(identity));
              }),
              catchError(({ error }: HttpErrorResponse) => {
                return of(authEnable2FAFailure(error));
              }),
            );
          }),
        );
      }),
    );
  });

  setup2FAAndLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authSetup2FAAndLogin),
      concatMap(twoFACode => {
        return this.jwtService.getValidTokens$().pipe(
          concatMap((tokens: Tokens) => {
            const refreshToken = tokens.refreshToken;

            return this.authService.enable2FA$({ code: twoFACode.code }, refreshToken).pipe(
              concatMap((identity: ServerIdentityModel) => {
                return [authEnable2FASuccess(toIdentity(identity)), authSetIdentityAndBootup(toIdentity(identity))];
              }),
              catchError(({ error }: HttpErrorResponse) => {
                return of(authEnable2FAFailure(error));
              }),
            );
          }),
        );
      }),
    );
  });

  disable2FA$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authDisable2FA),
      concatMap(twoFACode => {
        return this.jwtService.getValidTokens$().pipe(
          concatMap((tokens: Tokens) => {
            const refreshToken = tokens.refreshToken;

            return this.authService.disable2FA$({ code: twoFACode.code }, refreshToken).pipe(
              map((identity: ServerIdentityModel) => {
                return authDisable2FASuccess(toIdentity(identity));
              }),
              catchError(({ error }: HttpErrorResponse) => {
                return of(authDisable2FAFailure(error));
              }),
            );
          }),
        );
      }),
    );
  });

  disable2FA$ViaRecovery = createEffect(() => {
    return this.actions$.pipe(
      ofType(authDisable2FAViaRecovery),
      map(action => {
        return action.backupCode;
      }),
      concatMap(backupCode => {
        return this.authService.disable2FAViaRecoveryCode$({ backupCode }).pipe(
          map((identity: ServerIdentityModel) => {
            return authDisable2FASuccess(toIdentity(identity));
          }),
          catchError(({ error }: HttpErrorResponse) => {
            return of(authDisable2FAFailure(error));
          }),
        );
      }),
    );
  });

  setIdentityAndGenerateSecret$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(authSetIdentityWithoutBootingAndGenerateSecret),
      concatMap((identity: Identity) => {
        return this.authService.generate2FASecret$().pipe(
          mergeMap((payload: { secret: string }) => {
            return [authSetIdentityWithoutBooting(identity), authGenerate2FASecretSuccess(payload)];
          }),
          catchError(() => {
            return of(
              authLogout(),
              notificationSend({
                message: $localize`:@@ts.auth.error:Try again`,
                notificationType: NotificationType.ERROR,
              }),
            );
          }),
        );
      }),
    );
  });

  redirectToUserLocale$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(userUpsert),
        withLatestFrom(
          this.store$.pipe(select(selectAuthIdentityUserId)),
          this.store$.pipe(select(selectAuthRedirectPath)),
        ),
        filter(([inserted, userIdentityId, redirectPath]) => {
          return (
            inserted.entity.id === userIdentityId && (!redirectPath || redirectPath.length < 5 || !!inserted.force)
          );
        }),
        map(([{ entity }, _]) => {
          return entity;
        }),
        filter(entity => {
          return !!entity?.settings?.languageCode && entity.settings.languageCode !== this.locale;
        }),
        map(entity => {
          const userLocale = entity.settings!.languageCode;
          const [, , ...withoutLoc] = window.location.pathname.split('/');

          const redirectUrl = `/${userLocale}/${withoutLoc.join('/')}`;

          this.pageAnalyticsService.track(MixpanelButtonClickEvents.REDIRECT_TO_USER_LOCALE, {
            userLocale,
            redirectUrl,
          });

          window.location.href = redirectUrl;
        }),
      );
    },
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private jwtService: JwtService,
    private msalService: MsalService,
    private pageAnalyticsService: PageAnalyticsService,
    private router: Router,
    private storage: StorageService,
    private ttlStorage: TtlStorageService,
    private store$: Store<AuthState>,
    private webSocketService: WebSocketService,
    @Inject(LOCALE_ID) private locale: string,
  ) {}
}
