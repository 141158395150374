import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, exhaustMap, filter, map, mapTo } from 'rxjs/operators';
import { fetchById } from '@app/util/operators/fetch-by-id';
import { filterById } from '@app/util/operators/filter-by-id';
import { CropperDialogComponent } from '../../shared/components/cropper-dialog/cropper-dialog.component';
import { ObjectStorageService } from '../services/object-storage.service';
import { NotificationType } from '@app/core/state/core.model';
import { notificationSend } from '@app/core/state/core.actions';
import {
  objectStorageCrop,
  objectStorageDownload,
  objectStorageDownloadSuccess,
  objectStorageUpload,
  objectStorageUploadFailure,
  objectStorageUploadSuccess,
} from './object-storage.actions';
import { ObjectStorageState } from '@app/object-storage/core/state/object-storage.reducer';
import { selectObjectStorageIds } from './object-storage.selectors';

@Injectable()
export class ObjectStorageEffects {
  upload$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(objectStorageUpload),
      map(({ file, correlationId }) => {
        return { file, correlationId };
      }),
      exhaustMap(({ file, correlationId }) => {
        return this.objectStorageService.upload$(file).pipe(
          map(({ id }) => {
            return objectStorageUploadSuccess({ id, correlationId });
          }),
          catchError(({ error, status }: HttpErrorResponse) => {
            if (status === 415) {
              return of(
                notificationSend({
                  message: $localize`:@@ts.object.dialog.415:This file cannot be used as a profile picture`,
                  notificationType: NotificationType.ERROR,
                }),
              );
            }

            return of(
              objectStorageUploadFailure({
                correlationId,
                error: error.message || error,
              }),
            );
          }),
        );
      }),
    );
  });

  uploadFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(objectStorageUploadFailure),
      mapTo(
        notificationSend({
          message: $localize`:@@ts.object.storage.failed:Failed to upload the file. Please try again.`,
          notificationType: NotificationType.ERROR,
        }),
      ),
    );
  });

  download$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(objectStorageDownload),
      map(({ fileQuery }) => {
        return fileQuery;
      }),
      filterById(this.store$.pipe(select(selectObjectStorageIds))),
      fetchById(fileQuery => {
        return this.objectStorageService.download$(fileQuery).pipe(
          map(response => {
            return objectStorageDownloadSuccess({ fileQuery, response });
          }),
          catchError(() => {
            return EMPTY;
          }),
        );
      }),
    );
  });

  crop$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(objectStorageCrop),
      exhaustMap(({ file, correlationId }) => {
        return this.dialog
          .open(CropperDialogComponent, {
            data: { file },
          })
          .afterClosed()
          .pipe(
            filter((result?: File) => {
              return !!result;
            }),
            map(result => {
              return objectStorageUpload({ file: result!, correlationId });
            }),
          );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private dialog: MatDialog,
    private objectStorageService: ObjectStorageService,
    private store$: Store<ObjectStorageState>,
  ) {}
}
