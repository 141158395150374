import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { ConnectionService } from './services/connection.service';
import { reducer } from './state/connection.reducer';
import { connectionFeatureName } from '@app/connection/core/state';

@NgModule({
  imports: [StoreModule.forFeature(connectionFeatureName, reducer)],
  providers: [ConnectionService],
})
export class ConnectionCoreModule {}
