import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { FormComponent } from '@app/shared/abstracts/form.component';
import { TwoFACode } from '@app/auth/core/state/auth.model';
import { Identity } from '@app/auth/models';
import { Verify2FAError, Verify2FALimitError } from '@app/auth/routes/login/state';

export const LENGTH_VERIFICATION_CODE = 6;
export const LENGTH_BACKUP_CODE = 10;

@Component({
  selector: 'tc-verify-2fa-code',
  templateUrl: './verify-2fa-code-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Verify2faCodeFormComponent extends FormComponent<TwoFACode> implements OnChanges {
  @Input()
  identity?: Identity;

  @Input()
  set verify2FAError(data: Verify2FAError) {
    if ((data as Verify2FALimitError)?.message) {
      this.verifyErrorMessage = (data as Verify2FALimitError).message;
      this.attempts = (data as Verify2FALimitError).attemptsLeft;
      if ((data as Verify2FALimitError).banned) {
        this.unlockedDate = new Date(Date.now() + (data as Verify2FALimitError).banned * 1000);
      }
    }
  }

  attempts?: number;
  unlockedDate?: Date;
  verifyErrorMessage?: string;

  public form = new UntypedFormGroup({
    code: new UntypedFormControl(
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(LENGTH_VERIFICATION_CODE),
        Validators.maxLength(LENGTH_VERIFICATION_CODE),
      ]),
    ),
  });

  @Output()
  readonly backToLogin = new EventEmitter<void>();

  @Output()
  readonly switchToBackup = new EventEmitter<void>();

  get showAttempts(): boolean {
    return typeof this.attempts === 'number' && this.attempts > 0;
  }

  goBackToLogin(): void {
    this.backToLogin.emit();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.verify2FAError && changes.verify2FAError.currentValue !== changes.verify2FAError.previousValue) {
      const errorCode = changes.verify2FAError.currentValue?.code;
      if (errorCode) {
        const error =
          errorCode === 'identity.2fa.invalid_code'
            ? { [errorCode]: true }
            : { ['identity.2fa.validation_error']: true };
        this.form.controls.code.setErrors(error);
      }
    }
  }

  public switchToBackupForm(): void {
    this.switchToBackup.emit();
  }
}
