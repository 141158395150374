import { createReducer, on } from '@ngrx/store';
import { Identity, Tokens } from '@app/auth/models';
import {
  authActAsCompany,
  AuthActionType,
  authClearConfigure2FAState,
  authDisable2FA,
  authDisable2FAFailure,
  authDisable2FASuccess,
  authEnable2FA,
  authEnable2FAFailure,
  authEnable2FASuccess,
  authGenerate2FASecret,
  authGenerate2FASecretFailure,
  authGenerate2FASecretSuccess,
  authLogout,
  authRedirectAfterLogin,
  authRedirectToLogin,
  authRefresh,
  authRefreshCompleted,
  authRefreshSSO,
  authRefreshSSOSuccess,
  authSetAuthenticated,
  authSetIdentityAndBootup,
  authSetIdentityWithoutBooting,
  authSetTokens,
  saveUserCountry,
} from './auth.actions';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';
import { OnReducer } from '@ngrx/store/src/reducer_creator';
import { ActionStatus, ServerError } from '@app/core/state/core.model';

export interface Configure2FA {
  secret?: string;
  secretGenerationStatus?: ActionStatus;
  enable2FAStatus?: ActionStatus;
  disable2FAStatus?: ActionStatus;
  enable2FAErrorCode?: string;
  twoFABackupCodes?: string[];
  disable2FAErrorCode?: string;
}

export interface AuthState {
  readonly authenticated?: boolean;
  readonly configure2FA: Configure2FA;
  readonly refreshing: boolean;
  readonly directDownload: boolean;
  readonly tokens?: Tokens;
  readonly identity?: Identity;
  readonly actingAsCompanyId?: string;
  readonly redirectPath?: string;
}

export const initialState: AuthState = {
  refreshing: false,
  directDownload: false,
  configure2FA: {},
};

const setIdentityReducer: OnReducer<AuthState, [ActionCreator<AuthActionType, (props: Identity) => Identity>]> = (
  state: AuthState,
  {
    email,
    companyId,
    companyRoles,
    authorizedCompanyIds,
    status,
    userId,
    username,
    userRoles,
    twoFAEnabled,
    twoFAEnforced,
    identityProvider,
  },
) => {
  return {
    ...state,
    identity: {
      email,
      companyId,
      authorizedCompanyIds,
      status,
      userId,
      username,
      userRoles,
      twoFAEnabled,
      twoFAEnforced,
      identityProvider,
      companyRoles,
    },
  };
};

const authReducer = createReducer<AuthState, TypedAction<AuthActionType>>(
  initialState,
  on(authRefresh, state => {
    return { ...state, refreshing: true };
  }),
  on(authRefreshSSO, state => {
    return { ...state, refreshing: true };
  }),
  on(authRefreshSSOSuccess, state => {
    return { ...state, refreshing: false };
  }),
  on(authRefreshCompleted, state => {
    return { ...state, refreshing: false };
  }),
  on(authSetAuthenticated, (state, { isAuthenticated }) => {
    return { ...state, authenticated: isAuthenticated };
  }),
  on(authSetTokens, (state, { accessToken, refreshToken }) => {
    return {
      ...state,
      refreshing: false,
      tokens: { accessToken, refreshToken },
    };
  }),
  on(authSetIdentityAndBootup, setIdentityReducer),
  on(authSetIdentityWithoutBooting, setIdentityReducer),
  on(authActAsCompany, (state, { companyId }) => {
    return { ...state, actingAsCompanyId: companyId };
  }),
  on(saveUserCountry, (state, { isChina }) => {
    return { ...state, directDownload: isChina };
  }),
  on(authRedirectToLogin, (state, { path }) => {
    return { ...state, redirectPath: path };
  }),
  on(authRedirectAfterLogin, state => {
    return { ...state, authenticated: true };
  }),
  on(authLogout, state => {
    return { ...initialState, authenticated: false };
  }),
  on(
    authGenerate2FASecret,
    (state): AuthState => {
      return {
        ...state,
        configure2FA: {
          ...state.configure2FA,
          secretGenerationStatus: undefined,
        },
      };
    },
  ),
  on(
    authGenerate2FASecretSuccess,
    (state, { secret }): AuthState => {
      return {
        ...state,
        configure2FA: {
          secret,
          secretGenerationStatus: ActionStatus.SUCCESS,
        },
        identity: {
          ...state.identity!,
          twoFAEnabled: false,
        },
      };
    },
  ),
  on(
    authGenerate2FASecretFailure,
    (state): AuthState => {
      return {
        ...state,
        configure2FA: {
          ...state.configure2FA,
          secretGenerationStatus: ActionStatus.ERROR,
        },
      };
    },
  ),
  on(
    authEnable2FA,
    (state): AuthState => {
      return {
        ...state,
        configure2FA: {
          ...state.configure2FA,
          enable2FAErrorCode: undefined,
          enable2FAStatus: undefined,
        },
      };
    },
  ),
  on(
    authEnable2FASuccess,
    (state, action): AuthState => {
      const updatedState = setIdentityReducer(state, action);
      const { twoFABackupCodes } = action;

      return {
        ...updatedState,
        configure2FA: {
          ...state.configure2FA,
          twoFABackupCodes,
          enable2FAStatus: ActionStatus.SUCCESS,
        },
      };
    },
  ),
  on(
    authEnable2FAFailure,
    (state, error: ServerError): AuthState => {
      return {
        ...state,
        configure2FA: {
          ...state.configure2FA,
          enable2FAStatus: ActionStatus.ERROR,
          enable2FAErrorCode: error.code,
        },
      };
    },
  ),
  on(
    authClearConfigure2FAState,
    (state): AuthState => {
      return {
        ...state,
        configure2FA: {},
      };
    },
  ),
  on(
    authDisable2FA,
    (state): AuthState => {
      return {
        ...state,
        configure2FA: {
          ...state.configure2FA,
          disable2FAErrorCode: undefined,
          disable2FAStatus: undefined,
        },
      };
    },
  ),
  on(authDisable2FASuccess, setIdentityReducer),
  on(
    authDisable2FASuccess,
    (state): AuthState => {
      return {
        ...state,
        configure2FA: {
          ...state.configure2FA,
          disable2FAStatus: ActionStatus.SUCCESS,
        },
      };
    },
  ),
  on(
    authDisable2FAFailure,
    (state, error: ServerError): AuthState => {
      return {
        ...state,
        configure2FA: {
          ...state.configure2FA,
          disable2FAStatus: ActionStatus.ERROR,
          disable2FAErrorCode: error.code,
        },
      };
    },
  ),
);

export function reducer(state = initialState, action: TypedAction<AuthActionType>): AuthState {
  return authReducer(state, action);
}
