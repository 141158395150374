import { createAction, props } from '@ngrx/store';
import {
  CloseConversationTask,
  CloseForecastAcknowledgeTasks,
  CloseOrderAcknowledgeTasks,
  CloseShipmentTasks,
  Task,
} from '@app/task/models';

export enum TaskActionType {
  LISTEN = '[Task] Listen',
  WEBSOCKET_UPSERT = '[Task] Websocket Upsert',
  WEBSOCKET_PURE_UPSERT = '[Task] Websocket without filters Upsert',
  ADD_MANY = '[Task] Add Many',
  CLOSE_CONVERSATION_TASK = '[Task] Close Conversation Task',
  CLOSE_ORDER_ACKNOWLEDGE_TASKS = '[Task] Close Order Acknowledge Tasks',
  CLOSE_FORECAST_ACKNOWLEDGE_TASK = '[Task] Close ForecastAcknowledge TAsk',
  CLOSE_TASK_SUCCESS = '[Task] Close Task Success',
  CLOSE_TASK_FAILURE = '[Task] Close Task Failure',
  CLOSE_SHIPMENT_TASKS = '[Task] Close Shipment Tasks',
}

export const taskListen = createAction(TaskActionType.LISTEN, props<{ companyId: string }>());
export const taskWebSocketUpsert = createAction(TaskActionType.WEBSOCKET_UPSERT, props<{ entity: Task, userId: string }>());
export const taskWebSocketUpsertWithoutFilter = createAction(
  TaskActionType.WEBSOCKET_PURE_UPSERT,
  props<{ entity: Task, userId: string }>(),
);
export const taskAddMany = createAction(TaskActionType.ADD_MANY, props<{ entities: Task[] }>());
export const taskClose = createAction(TaskActionType.CLOSE_CONVERSATION_TASK, props<CloseConversationTask>());
export const shipmentTaskClose = createAction(TaskActionType.CLOSE_SHIPMENT_TASKS, props<CloseShipmentTasks>());
export const orderTasksAcknowledgeClose = createAction(
  TaskActionType.CLOSE_ORDER_ACKNOWLEDGE_TASKS,
  props<CloseOrderAcknowledgeTasks>(),
);
export const forecastTasksAcknowledgeClose = createAction(
  TaskActionType.CLOSE_FORECAST_ACKNOWLEDGE_TASK,
  props<CloseForecastAcknowledgeTasks>(),
);
export const taskCloseSuccess = createAction(TaskActionType.CLOSE_TASK_SUCCESS);
export const taskCloseFailure = createAction(TaskActionType.CLOSE_TASK_FAILURE, props<{ error: string }>());
