import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { finalize, map, take } from 'rxjs/operators';
import { ObjectStorageService } from '@app/object-storage/core/services/object-storage.service';
import { DocumentInterface } from '@app/order/models';
import { saveAs } from 'file-saver';
import { combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { AuthState } from '@app/auth/core/state/auth.reducer';
import { selectDirectDownload } from '@app/auth/core/state/auth.selectors';
import { trackEvent } from '@app/core/state/core.actions';
import { MixpanelButtonClickEvents } from '@app/core/services/page-analytics/enums/mixpanel-button-click-events.enum';

@Component({
  selector: 'tc-download-document',
  templateUrl: './download-document.component.html',
  styleUrls: ['./download-document.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadDocumentComponent {
  @Input()
  document?: DocumentInterface;

  @Input()
  display?: string = '';

  public readonly canDirect$ = this.store$.select(selectDirectDownload);

  public isLoading = false;

  public replacStr = /-/g;

  constructor(
    private objectStorageService: ObjectStorageService,
    private store$: Store<AuthState>,
    private cd: ChangeDetectorRef,
  ) {}

  get title(): string | undefined {
    return this.document?.name || this.document?.code || this.document?.url || this.document?.objectId;
  }

  public openAttachedDocument = (document?: DocumentInterface, canDirect?: boolean): void => {
    if (!document) {
      return;
    }

    if (canDirect) {
      const id: string = document.objectId as string;

      this.isLoading = true;
      combineLatest([
        this.objectStorageService.documentDirectDownload$(id),
        this.objectStorageService.documentDownload$(id),
      ])
        .pipe(
          map(([file, { filename }]) => {
            return { filename, file };
          }),
          take(1),
          finalize(() => {
            this.isLoading = false;
            this.cd.markForCheck();
          }),
        )
        .subscribe(({ filename, file }) => {
          this.downloadFile(file, filename);
          this.store$.dispatch(trackEvent({ eventName: MixpanelButtonClickEvents.DIRECT_DOWNLOAD_DOCUMENT }));
        });

      return;
    }

    const { url, objectId = '' } = document;
    if (url) {
      window.open(url);

      return;
    }

    if (!objectId) {
      return;
    }

    this.objectStorageService
      .documentDownload$(objectId)
      .pipe(
        map(({ downloadUrl }) => {
          return downloadUrl;
        }),
      )
      .subscribe(objectUrl => {
        return window.open(objectUrl as string);
      });
  };

  public downloadFile(data: ArrayBuffer, filename?: string): void {
    const file = new Blob([data]);
    saveAs(file, filename);
  }
}
