import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Certification, OrderLine } from '@app/order/models';

@Component({
  selector: 'tc-line-certification',
  templateUrl: './line-certification.component.html',
  styleUrls: ['./line-certification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineCertificationComponent {
  @Input()
  orderLine?: OrderLine;

  public get certification(): Certification | undefined {
    return this.orderLine?.buyerLine.certification;
  }
}
