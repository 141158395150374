import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@app/shared/api/api.service';
import { Order, OrderQuery, OrderQueryResponse, OrderSuggest, OrderSuggestResponse } from '@app/order/models';
import { Observable } from 'rxjs';
import { UUID } from '@app/shared/models';
import { DestinationResponse } from '@app/order/models/order-destinations.model';

@Injectable()
export class OrderSearchService {
  constructor(private http: HttpClient, private api: API) {}

  public getById$(id: string): Observable<Order> {
    const encodedId = encodeURIComponent(id);

    return this.http.get<Order>(this.api.ORDER_SEARCH_ID(encodedId));
  }

  public query$(body: OrderQuery): Observable<OrderQueryResponse> {
    return this.http.post<OrderQueryResponse>(this.api.ORDER_SEARCH_QUERY(), body);
  }

  public suggest$(body: OrderSuggest): Observable<OrderSuggestResponse> {
    return this.http.post<OrderSuggestResponse>(this.api.ORDER_SEARCH_SUGGEST(), body);
  }

  public destinations$(companyId: UUID): Observable<DestinationResponse> {
    return this.http.get<DestinationResponse>(this.api.ORDER_DESTINATIONS(), { params: { companyId } });
  }

  public customLabels$(companyId: UUID): Observable<{ customLabels: string[] }> {
    return this.http.get<{ customLabels: string[] }>(this.api.ORDER_CUSTOM_LABELS(companyId));
  }
}
