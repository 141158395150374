import { ChangeDetectionStrategy, Component, OnDestroy, QueryList, ViewChildren } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { select, Store } from '@ngrx/store';
import { OrderListState } from '@app/order/routes/order-list/state/order-list.reducer';
import {
  authClearConfigure2FAState,
  authDisable2FA,
  authDisable2FAViaRecovery,
  authEnable2FA,
  authGenerate2FASecret,
} from '@app/auth/core/state/auth.actions';
import { TwoFACode } from '@app/auth/core/state/auth.model';
import {
  LENGTH_BACKUP_CODE,
  LENGTH_VERIFICATION_CODE,
} from '@app/auth/routes/login/components/verify-2fa-code/verify-2fa-code-form.component';
import { TwoFactorSetupService } from '@app/shared/services/two-factor-setup.service';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { combineLatest, Observable, Subject } from 'rxjs';
import { selectAuthIdentity, selectConfigure2FA, selectRecoveryCodes } from '@app/auth/core/state/auth.selectors';
import { Identity } from '@app/auth/models';
import { Configure2FA } from '@app/auth/core/state/auth.reducer';
import { isNotNullOrUndefined } from '@app/util/operators/is-not-null-or-undefined';

@Component({
  selector: 'tc-two-factor-authentication',
  templateUrl: './two-factor-authentication.component.html',
  styleUrls: ['./base/two-factor-authentication.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TwoFactorSetupService],
})
export class TwoFactorAuthenticationComponent implements OnDestroy {
  @ViewChildren(MatExpansionPanel)
  panels!: QueryList<MatExpansionPanel>;
  panelExpanded: boolean;

  readonly vm$: Observable<{ identity: Identity; configure2FA: Configure2FA; backupCodes: string[] }> = combineLatest([
    this.store$.pipe(select(selectAuthIdentity), isNotNullOrUndefined()),
    this.store$.pipe(select(selectConfigure2FA)),
    this.store$.pipe(select(selectRecoveryCodes)),
  ]).pipe(
    map(([identity, configure2FA, backupCodes]) => {
      return { identity, configure2FA, backupCodes };
    }),
  );

  private destroyed$ = new Subject<void>();

  constructor(private store$: Store<OrderListState>, private twoFService: TwoFactorSetupService) {
    this.panelExpanded = false;
    this.initTwoFASetup();
    this.initUILogic();
  }

  onDisable2FA(event: TwoFACode): void {
    if (event.code.length === LENGTH_VERIFICATION_CODE) {
      this.store$.dispatch(authDisable2FA(event));
    }
    if (event.code.length === LENGTH_BACKUP_CODE) {
      this.store$.dispatch(authDisable2FAViaRecovery({ backupCode: event.code }));
    }
  }

  enable2FA(value: TwoFACode): void {
    this.store$.dispatch(authEnable2FA(value));
  }

  reset(): void {
    this.store$.dispatch(authClearConfigure2FAState());
    this.panels.first.close();
  }

  generateSecret(): void {
    this.store$.dispatch(authGenerate2FASecret());
  }

  delete2FASecret(): void {
    this.store$.dispatch(authClearConfigure2FAState());
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private initTwoFASetup(): void {
    this.twoFService.onReset$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.reset();
    });

    this.twoFService.onEnable$.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      this.enable2FA(value);
    });
  }

  private initUILogic(): void {
    this.store$
      .pipe(select(selectConfigure2FA))
      .pipe(
        filter(configure2FA => {
          return configure2FA?.enable2FAStatus === 'success';
        }),
        tap(() => {
          this.twoFService.emitLastStep(true);
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();

    this.store$
      .pipe(select(selectConfigure2FA))
      .pipe(
        filter(configure2FA => {
          return configure2FA?.disable2FAStatus === 'success';
        }),
        tap(() => {
          this.twoFService.emitLastStep(false);
          this.store$.dispatch(authClearConfigure2FAState());
          this.panels && this.panels.first.close();
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }
}
