import { Shipment } from '@app/shipment/models';
import { Pipe, PipeTransform } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { isNotNullOrUndefined } from '@app/util/operators/is-not-null-or-undefined';
import { ShipmentState } from '@app/shipment/core/state/shipment.reducer';
import { selectShipmentById } from '@app/shipment/core/state/shipment.selectors';
import { shipmentFetch } from '@app/shipment/core/state/shipment.actions';

@Pipe({
  name: 'tcLazyShipment',
})
export class LazyShipmentPipe implements PipeTransform {
  constructor(private store$: Store<ShipmentState>) {}

  // eslint-disable-next-line
  transform(id?: string, silent = true): Observable<Shipment> {
    if (!id) {
      throw new Error('LazyShipmentPipe can only be used with Shipment UUID');
    }

    this.store$.dispatch(shipmentFetch({ id, silent }));

    return this.store$.pipe(select(selectShipmentById(id)), isNotNullOrUndefined());
  }
}
