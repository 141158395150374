import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InProgressStatus, ProcessStatus } from '@app/order/models';

@Component({
  selector: 'tc-process-status',
  templateUrl: './process-status.component.html',
  styleUrls: ['./process-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProcessStatusComponent {
  @Input()
  status!: ProcessStatus | InProgressStatus;
}
