import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OrderType } from '@app/order/models';

@Component({
  selector: 'tc-order-type',
  templateUrl: './order-type.component.html',
  styleUrls: ['./order-type.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderTypeComponent {
  @Input()
  type!: OrderType;
}
