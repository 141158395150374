import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OrderLine } from '@app/order/models';
import { unText } from '@app/order/private/components/item-details/item-details.component';
import { countries } from '@app/order/routes/order-line-detail/components/line-edit-items-dialog/countries';

@Component({
  selector: 'tc-line-item-details',
  templateUrl: './line-item-details.component.html',
  styleUrls: ['./line-item-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineItemDetailsComponent {
  public readonly countries = countries;

  @Input()
  orderLine!: OrderLine;

  public get combinedNomenclatureCode(): string {
    return this.orderLine?.mergedItemDetails?.combinedNomenclatureCode || unText;
  }

  public get countryOfOriginCodeIso2(): string {
    if (this.orderLine?.mergedItemDetails?.countryOfOriginCodeIso2) {
      const codeIso2 = this.orderLine.mergedItemDetails.countryOfOriginCodeIso2;
      const country = this.countries.find(v => {
        return v.value === codeIso2;
      }) || { label: unText };

      return country.label;
    }

    return unText;
  }

  public get batchNumber(): string {
    return this.orderLine?.mergedItemDetails?.batchNumber || unText;
  }

  public get dangerousGoodsCodeUnece(): string {
    return this.orderLine?.mergedItemDetails?.dangerousGoodsCodeUnece || unText;
  }

  public get netWeight(): number | string {
    return this.orderLine?.mergedItemDetails?.netWeight || unText;
  }

  public get netWeightUnitOfMeasureIso(): string | undefined {
    return this.orderLine?.mergedItemDetails?.netWeightUnitOfMeasureIso;
  }

  public get serialNumber(): string {
    return this.orderLine?.mergedItemDetails?.serialNumber || unText;
  }
}
