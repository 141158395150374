<div class="vertical">
  <mat-list class="mat-list--properties mat-list--compact" fxLayout="row">
    <mat-list-item class="auto-height">
      <div class="value">
        <h4 *ngIf="destination?.code" matLine class="wrap" [class.loading-text]="!destination">
          {{ destination?.code }}
        </h4>
        <p matLine class="wrap" *ngFor="let name of destination?.names">
          {{ name }}
        </p>
        <p matLine class="wrap" *ngFor="let line of destination?.addressLines">
          {{ line }}
        </p>
        <p matLine class="wrap" *ngIf="destination?.postalCode || destination?.city">
          {{ destination?.postalCode }} {{ destination?.city }}
        </p>
        <p matLine class="wrap">
          {{ destination?.countryCodeIso2 }} {{ destination?.countryName }}
        </p>
        <p matLine class="wrap">
          {{ destination?.locationType }}
        </p>
      </div>
    </mat-list-item>
  </mat-list>
</div>
