<div class="vertical">
  <mat-list class="mat-list--properties mat-list--compact" fxLayout="row">
    <mat-list-item class="auto-height">
      <div class="value">
        <p *ngIf="buyerParty?.idScheme || buyerParty?.id" matLine class="wrap">
          {{ buyerParty?.idScheme }} {{buyerParty?.id}}
        </p>
        <p matLine class="wrap" *ngFor="let name of buyerParty?.names">
          {{ name }}
        </p>
        <p matLine class="wrap" *ngFor="let line of buyerParty?.addressLines">
          {{ line }}
        </p>
        <p matLine class="wrap" *ngIf="buyerParty?.postalCode || buyerParty?.city">
          {{ buyerParty?.postalCode }} {{ buyerParty?.city }}
        </p>
        <p matLine class="wrap">
          {{ buyerParty?.countryCodeIso2 }} {{ buyerParty?.countryName }}
        </p>
      </div>
    </mat-list-item>
  </mat-list>
</div>
