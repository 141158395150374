import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, EMPTY, merge, Observable, Subject } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { ActivityService } from '../../../core/services/activity.service';
import { Activity, ActivityEntityType, ActivityQueryFilter } from '../../../models';
import { EntityTypeOption, EntityTypeOptionValue } from '../../../models/activity-entity-type-option.model';
import { MixpanelRealtimeContext } from '@app/core/services/page-analytics/enums/mixpanel-realtime-context.enum';

const ITEMS_PER_PAGE = 10;
const defaultFiltersOptions: EntityTypeOption[] = [
  {
    title: $localize`:@@ts.activity.options.all:All activity`,
    value: {},
  },
  {
    title: $localize`:@@ts.activity.options.order:Order`,
    value: { entityType: ActivityEntityType.Order },
  },
  {
    title: $localize`:@@ts.activity.options.order.line:Order Line`,
    value: { entityType: ActivityEntityType.OrderLine },
  },
  {
    title: $localize`:@@ts.activity.options.conversation:Conversation`,
    value: { entityType: ActivityEntityType.Conversation },
  },
  {
    title: $localize`:@@ts.activity.options.shipment:Shipment`,
    value: { entityType: ActivityEntityType.Shipment },
  },
];

@Component({
  selector: 'tc-activity-realtime',
  templateUrl: './activity-realtime.component.html',
  styleUrls: ['./activity-realtime.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityRealtimeComponent implements OnDestroy, OnInit {
  @Input()
  mixpanelContext!: MixpanelRealtimeContext;

  @Input()
  filterOptions: EntityTypeOption[] = defaultFiltersOptions;

  @Input()
  set lastValue(option: EntityTypeOptionValue | undefined) {
    if (option !== this.selectedEntityType) {
      const op = this.filterOptions.find(filterOption => {
        return filterOption.value.entityType === option?.entityType;
      });
      this.selectedEntityType = op?.value || this.filterOptions[0].value;
      this.onFiltersChange();
    }
  }

  @Input()
  set filters(filters: ActivityQueryFilter | undefined) {
    this.additionalFilters = filters || {};
    this.onFiltersChange();
  }

  @Output()
  readonly changeSelect = new EventEmitter<EntityTypeOptionValue>();

  public selectedEntityType: EntityTypeOptionValue = defaultFiltersOptions[0].value;
  public additionalFilters: ActivityQueryFilter = {};
  public activities$ = new BehaviorSubject<Activity[]>([]);
  private destroyed$ = new Subject<void>();
  private loadMore$ = new Subject<void>();
  private total = 0;
  private query$ = new BehaviorSubject<ActivityQueryFilter>({});
  private initialQuery$!: Observable<Activity[]>;
  private webSocketUpdates$!: Observable<Activity[]>;
  private loadMoreActivities$!: Observable<Activity[]>;

  public get canLoadMore$(): Observable<boolean> {
    return this.activities$.pipe(
      map(activities => {
        return activities.length < this.total;
      }),
    );
  }

  constructor(private activityService: ActivityService) {}

  ngOnInit(): void {
    this.initialQuery$ = this.query$.pipe(
      filter(() => {
        return this.mixpanelContext !== MixpanelRealtimeContext.EMPTY;
      }),
      switchMap(query => {
        return this.activityService.query$({
          filters: query,
          offset: 0,
          limit: ITEMS_PER_PAGE,
        });
      }),
      tap(response => {
        return (this.total = response.total);
      }),
      map(
        response => {
          return response.data;
        },
        catchError(() => {
          return EMPTY;
        }),
      ),
    );

    this.webSocketUpdates$ = this.query$.pipe(
      filter(() => {
        return this.mixpanelContext !== MixpanelRealtimeContext.EMPTY;
      }),
      switchMap(query => {
        return this.activityService.listen$(query);
      }),
      withLatestFrom(this.activities$),
      map(
        ([newActivity, currentActivities]) => {
          return [newActivity, ...currentActivities];
        },
        catchError(() => {
          return EMPTY;
        }),
      ),
    );

    this.loadMoreActivities$ = this.loadMore$.pipe(
      switchMap(() => {
        return this.query$;
      }),
      withLatestFrom(this.activities$),
      switchMap(([query, currentActivities]) => {
        return this.activityService
          .query$({
            filters: query,
            offset: currentActivities.length,
            limit: ITEMS_PER_PAGE,
          })
          .pipe(
            tap(response => {
              return (this.total = response.total);
            }),
            map(response => {
              return response.data;
            }),
            map(newActivities => {
              return [...currentActivities, ...newActivities];
            }),
          );
      }),
    );

    merge(this.initialQuery$, this.webSocketUpdates$, this.loadMoreActivities$)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(val => {
        this.activities$.next(val);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  public loadMore(): void {
    this.loadMore$.next();
  }

  public onFiltersChange(shouldSaveValueIntoCaching = false): void {
    this.query$.next({
      ...this.additionalFilters,
      ...this.selectedEntityType,
    });

    if (shouldSaveValueIntoCaching) {
      this.changeSelect.emit(this.selectedEntityType);
    }
  }
}
