import { InjectionToken } from '@angular/core';
import * as Rollbar from 'rollbar';
import { environment } from '@environments/environment';
import { versions } from '@environments/versions';

const rollbarConfig: Rollbar.Configuration = {
  enabled: !!environment.rollbarAccessToken,
  accessToken: environment.rollbarAccessToken,
  captureUncaught: true,
  captureUnhandledRejections: true,
  autoInstrument: {
    navigation: false
  },
  payload: {
    environment: environment.environment,
    client: {
      javascript: {
        code_version: versions.revision,
        guess_uncaught_frames: true,
      },
    },
    server: {
      branch: versions.branch,
    },
  },
  scrubFields: ['authorization'],
  transform: (payload: Rollbar.Payload) => {
    const requestUrl = payload?.request?.url;
    if (requestUrl && requestUrl.includes('msal-callback/login')) {
      payload.request.url = '[REDACTED msal-callback URL]';
    }
  },
};

export function rollbarFactory(): Rollbar {
  return new Rollbar(rollbarConfig);
}

export const ROLLBAR = new InjectionToken<Rollbar>('rollbar');
