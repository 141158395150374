<form [formGroup]="assignForm" (ngSubmit)="submit()">

  <h1 mat-dialog-title>{{title}}</h1>

    <mat-list-item class="chip-list-container">
      <tc-chip-list formControlName="userIds">
        <ng-container *tcChipDef="let value">
          <tc-user-name [user]="value | tcLazyUser | async"></tc-user-name>
        </ng-container>
      </tc-chip-list>
    </mat-list-item>

    <mat-list-item>
      <mat-form-field floatLabel="never">
        <mat-label i18n="contact as in contact person @@order.assign.search">Type to search for a contact...</mat-label>
        <input [matAutocomplete]="autocomplete" [formControl]="contactQuery" type="text" matInput>
      </mat-form-field>

      <mat-autocomplete #autocomplete (optionSelected)="addUser($event)">
        <mat-option *ngFor="let user of userSuggestions$ | async" [value]="user">
          {{ user.profile.firstName }} {{ user.profile.lastName }}
        </mat-option>
      </mat-autocomplete>
    </mat-list-item>
  <mat-divider></mat-divider>

  <mat-dialog-actions>
    <button mat-button color="primary" mat-dialog-close i18n="button @@common.btn.cancel">CANCEL</button>
    <ng-container *ngIf="canRemove">
      <button mat-button color="primary" (click)="removeUser()" i18n="button @@common.btn.cancel">REMOVE</button>
    </ng-container>
    <button mat-button color="primary" type="submit">{{btnSave}}</button>
  </mat-dialog-actions>

</form>
