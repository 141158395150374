import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ChargeLine } from '@app/order/models';
import { quantityDifference } from '@app/order/shared/components/quantity-difference/helpers/quantity-difference.helper';
import { priceDifference } from '@app/order/shared/components/price-difference/helpers/price-difference.helper';
import { CHARGE_TYPES } from '@app/order/routes/order-line-detail/components/line-dialog/charge-types';

@Component({
  selector: 'tc-charge-lines-difference',
  templateUrl: './charge-lines-difference.component.html',
  styleUrls: ['../line-reopen/line-reopen.component.scss', './charge-lines-difference.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChargeLinesDifferenceComponent {
  public readonly chargeTypeMap: Map<string, string> = new Map(
    CHARGE_TYPES.map((i): [string, string] => {
      return [i.value, i.label];
    }),
  );

  @Input()
  current!: ChargeLine[];

  @Input()
  requested!: ChargeLine[];

  get modified(): ChargeLine[] {
    return this.requested.slice(0, this.modifiedLength);
  }

  get added(): ChargeLine[] {
    return this.requested.slice(this.modifiedLength);
  }

  get removed(): ChargeLine[] {
    return this.current.slice(this.modifiedLength);
  }

  public isChargeTypeCodeChanged(index: number): boolean {
    return this.requested[index]?.chargeTypeCode !== this.current[index]?.chargeTypeCode;
  }

  public isQuantityChanged(index: number): boolean {
    return !!quantityDifference(this.requested[index]?.quantity, this.current[index]?.quantity);
  }

  public isPriceUnitOfMeasureChanged(index: number): boolean | undefined {
    return (
      !!this.current[index]?.priceUnitOfMeasureIso &&
      !!this.requested[index]?.priceUnitOfMeasureIso &&
      this.current[index].priceUnitOfMeasureIso !== this.requested[index].priceUnitOfMeasureIso
    );
  }

  get modifiedLength(): number {
    const currentLength = this.current.length;
    const requestedLength = this.requested.length;

    return currentLength > requestedLength ? requestedLength : currentLength;
  }

  public isPriceChanged(index: number): boolean {
    return (
      this.isPriceTransactionCurrencyChanged(index) ||
      !!priceDifference(this.current[index]?.price, this.requested[index]?.price)
    );
  }

  private isPriceTransactionCurrencyChanged(index: number): boolean {
    return (
      !!this.current[index]?.price.priceInTransactionCurrency &&
      !!this.requested[index]?.price?.priceInTransactionCurrency &&
      this.current[index]?.price.priceInTransactionCurrency.currencyIso !==
        this.requested[index]?.price?.priceInTransactionCurrency.currencyIso
    );
  }
}

export function isChargeLinesChanged(previous: ChargeLine[], requested: ChargeLine[]): boolean {
  const hasInsideChanges = () => {
    return requested.some((value, index, array) => {
      return (
        previous[index].chargeTypeCode !== value.chargeTypeCode ||
        previous[index].chargeDescription !== value.chargeDescription ||
        !!quantityDifference(previous[index].quantity, value.quantity) ||
        previous[index].priceUnitOfMeasureIso !== value.priceUnitOfMeasureIso ||
        !!priceDifference(previous[index].price, value.price) ||
        previous[index].price.priceInTransactionCurrency.currencyIso !==
          value.price.priceInTransactionCurrency.currencyIso
      );
    });
  };

  return previous.length !== requested.length || hasInsideChanges();
}
