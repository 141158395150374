import { Action, createFeatureSelector, createSelector } from '@ngrx/store';
import { AuthState } from './auth.reducer';
import { AuthActionType } from './auth.actions';

export const isLogoutAction = (action: Action) => {
  return action.type === AuthActionType.LOGOUT;
};

export const authFeatureName = 'auth';

const selectState = createFeatureSelector<AuthState>(authFeatureName);

export const selectAuthAuthenticated = createSelector(selectState, ({ authenticated }) => {
  return authenticated;
});
export const selectAuthRefreshing = createSelector(selectState, ({ refreshing }) => {
  return refreshing;
});
export const selectAuthTokens = createSelector(selectState, ({ tokens }) => {
  return tokens;
});
export const selectAuthPermissions = createSelector(selectState, ({ identity }) => {
  return identity?.authorizedCompanyIds;
});
const selectActingAsCompanyId = createSelector(selectState, ({ actingAsCompanyId }) => {
  return actingAsCompanyId;
});
export const selectAuthRedirectPath = createSelector(selectState, ({ redirectPath }) => {
  return redirectPath;
});

const selectIdentity = createSelector(selectState, ({ identity }) => {
  return identity;
});
export const selectAuthIdentityUserId = createSelector(selectIdentity, identity => {
  return identity && identity.userId;
});
export const selectAuthIdentityUserRoles = createSelector(selectIdentity, identity => {
  return identity && identity.userRoles;
});
export const selectAuthIdentityCompanyRoles = createSelector(selectIdentity, identity => {
  return identity && identity.companyRoles;
});
export const selectAuthIdentityCompanyId = createSelector(
  selectIdentity,
  selectActingAsCompanyId,
  (identity, actingAsCompanyId) => {
    return actingAsCompanyId ? actingAsCompanyId : identity && identity.companyId;
  },
);

export const selectAuthIdentity = createSelector(selectIdentity, identity => {
  return identity;
});
export const selectAuthUserId = createSelector(selectAuthIdentity, identity => {
  return identity ? identity.userId : null;
});
export const selectConfigure2FA = createSelector(selectState, ({ configure2FA }) => {
  return configure2FA;
});
export const selectRecoveryCodes = createSelector(selectConfigure2FA, ({ twoFABackupCodes }) => {
  return twoFABackupCodes || [];
});
export const selectDirectDownload = createSelector(selectState, ({ directDownload }) => {
  return directDownload;
});
