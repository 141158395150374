<mat-sidenav-container class="mat-drawer-container--overflow">
  <mat-sidenav fixedInViewport #sidenav>
    <tc-sidenav-navigation
      *ngIf="(authenticated$ | async) && !isAuthRoute"
      [companyId]="companyId$ | async"
      [currentRoute]="currentRoute">
    </tc-sidenav-navigation>
  </mat-sidenav>

  <mat-sidenav-content>
    <tc-navigation
      *ngIf="(authenticated$ | async) && !isAuthRoute"
      [userId]="userId$ | async"
      [user]="user$ | async"
      [companyId]="companyId$ | async"
      [company]="company$ | async"
      [currentRoute]="currentRoute"
      (toggleSidenav)="sidenav.toggle()"
      (logout)="logout()"
      (endActingAsCompany)="endActingAsCompany()">
    </tc-navigation>

    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
