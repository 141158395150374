import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export function inputIsNotNullOrUndefined<T>(input: null | undefined | T): input is T {
  return input !== null && input !== undefined;
}

export function isNotNullOrUndefined<T>(): (source$: Observable<T | null | undefined>) => Observable<T> {
  return (source$: Observable<null | undefined | T>) => {
    return source$.pipe(filter(inputIsNotNullOrUndefined));
  };
}
