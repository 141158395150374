import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, HostBinding } from '@angular/core';

@Component({
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DemoComponent implements AfterViewInit {
  @HostBinding('class')
  className = 'mat-simple-snackbar';

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    const pane = this.elementRef.nativeElement.closest('.cdk-overlay-pane');

    if (pane instanceof HTMLElement) {
      pane.classList.add('cdk-overlay-pane--demo');
    }
  }
}
