import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Activity, ActivityType } from '@app/activity/models';
import { MixpanelRealtimeContext } from '@app/core/services/page-analytics/enums/mixpanel-realtime-context.enum';
import { MixpanelRealtimeEventsSetInterface } from '../activity-realtime/interfaces/mixpanel-realtime-events-set.interface';
import { MixpanelButtonClickEvents } from '@app/core/services/page-analytics/enums/mixpanel-button-click-events.enum';
import { ActivityRealtimeConfig } from '../activity-realtime/config/activity-realtime.config';

@Component({
  selector: 'tc-activity-list',
  templateUrl: './activity-list.component.html',
  styleUrls: ['./activity-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActivityListComponent implements OnInit {
  @Input()
  mixpanelContext!: MixpanelRealtimeContext;

  @Input()
  activities!: Activity[];

  public ActivityType = ActivityType;
  public mixpanelOrderClickEvent!: MixpanelButtonClickEvents;
  public mixpanelShipmentClickEvent!: MixpanelButtonClickEvents;
  public mixpanelOrderLineClickEvent!: MixpanelButtonClickEvents;

  ngOnInit(): void {
    this.setMixpanelEvents(ActivityRealtimeConfig.MixpanelViewContext[this.mixpanelContext]);
  }

  public trackByFn(index: number, item: Activity): string {
    return item.id;
  }

  private setMixpanelEvents({ order, line, shipment }: MixpanelRealtimeEventsSetInterface): void {
    this.mixpanelOrderClickEvent = order;
    this.mixpanelOrderLineClickEvent = line;
    this.mixpanelShipmentClickEvent = shipment;
  }
}
