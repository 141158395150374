import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from '@app/shared/api/api.service';
import { Observable } from 'rxjs';
import { ForecastSearch, ForecastSearchResponse } from '@app/forecast/models/forecast-query.model';

@Injectable()
export class ForecastSearchService {
  constructor(private http: HttpClient, private api: API) {}

  public query$(query: ForecastSearch): Observable<ForecastSearchResponse> {
    return this.http.post<ForecastSearchResponse>(this.api.FORECAST_LINE_SEARCH(), query);
  }
}
