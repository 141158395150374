import { NgIfContext } from '@angular/common';
import { Directive, Host, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatFormField } from '@angular/material/form-field';

import { ConditionalDirective } from '@app/shared/abstracts/conditional.directive';

@Directive({
  selector: '[tcMatFormFieldError]',
})
export class MatFormFieldErrorDirective extends ConditionalDirective implements OnInit {
  @Input()
  tcMatFormFieldError?: string;

  constructor(
    @Host() private matFormField: MatFormField,
    viewContainer: ViewContainerRef,
    templateRef: TemplateRef<NgIfContext>,
  ) {
    super(viewContainer, templateRef);
  }

  ngOnInit(): void {
    const { stateChanges: stateChanges$, ngControl } = this.matFormField._control;

    if (ngControl) {
      stateChanges$.subscribe(() => {
        const showError = this.hasError(ngControl as NgControl);
        this.updateView(showError);
      });
    }
  }

  private hasError(control: NgControl): boolean {
    if (this.tcMatFormFieldError) {
      return control.hasError(this.tcMatFormFieldError);
    }

    if (control.errors) {
      return Object.keys(control.errors).length > 0;
    }

    return false;
  }
}
