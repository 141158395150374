import { NgModule } from '@angular/core';
import { RootSharedModule } from '@app/shared/root-shared.module';
import { SendMessageFormComponent } from './components/send-message-form/send-message-form.component';

const components = [SendMessageFormComponent];

@NgModule({
  imports: [RootSharedModule],
  declarations: [components],
  exports: [components],
})
export class ConversationPrivateModule {}
