import { Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngrx/router-store';
import { Injectable } from '@angular/core';

/**
 * The RouterStateSerializer takes the current RouterStateSnapshot
 * and returns any pertinent information needed. The snapshot contains
 * all information about the state of the router at the given point in time.
 * The entire snapshot is complex and not always needed. In this case, you only
 * need the URL and route/query parameters from the snapshot in the store.
 */

export interface RouterState {
  url: string;
  queryParams: Params;
  params: Params;
}

@Injectable()
export class CustomRouterStateSerializer implements RouterStateSerializer<RouterState> {
  serialize({ root, url }: RouterStateSnapshot): RouterState {
    // We have to traverse the route tree to get the params from the current route
    let route = root;
    while (route.firstChild) {
      route = route.firstChild;
    }

    return {
      url,
      queryParams: root.queryParams,
      params: route.params,
    };
  }
}
