import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function multipleLengthValidator(...args: number[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null;
    }

    const isValid = args.some(allowedLength => {
      return allowedLength === value.toString().length;
    });

    return isValid ? null : { exactlySize: { value } };
  };
}
