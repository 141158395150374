import { NgModule } from '@angular/core';
import { AccountSharedModule } from '../account/shared/account-shared.module';
import { ActivitySharedModule } from '../activity/shared/activity-shared.module';
import { AnalyticsSharedModule } from '../analytics/shared/analytics-shared.module';
import { AuthSharedModule } from '../auth/shared/auth-shared.module';
import { CompanySharedModule } from '../company/shared/company-shared.module';
import { ConversationSharedModule } from '../conversation/shared/conversation-shared.module';
import { ObjectStorageSharedModule } from '../object-storage/shared/object-storage-shared.module';
import { OrderLineSharedModule } from '../order-line/shared/order-line-shared.module';
import { OrderSharedModule } from '../order/shared/order-shared.module';
import { ShipmentSharedModule } from '../shipment/shared/shipment-shared.module';
import { TaskSharedModule } from '../task/shared/task-shared.module';
import { UserSharedModule } from '../user/shared/user-shared.module';
import { RootSharedModule } from './root-shared.module';
import { RfqSharedModule } from '@app/rfq/shared/rfq-shared.module';

const sharedModules = [
  RootSharedModule,
  AccountSharedModule,
  ActivitySharedModule,
  AnalyticsSharedModule,
  AuthSharedModule,
  CompanySharedModule,
  ConversationSharedModule,
  ObjectStorageSharedModule,
  OrderLineSharedModule,
  OrderSharedModule,
  RfqSharedModule,
  ShipmentSharedModule,
  TaskSharedModule,
  UserSharedModule,
];

@NgModule({
  imports: sharedModules,
  exports: sharedModules,
})
export class SharedModule {}
