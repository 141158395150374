import { CompanyRole } from '@app/company/models';
import { Account } from '@app/account/models';

export enum AssignType {
  ASSIGN = 0,
  REASSIGN = 1,
}

export interface StartAssign {
  actionType: AssignType.REASSIGN | AssignType.ASSIGN;
  version: number;
  orderId: string;
}

export interface StartReassignDefaultUser {
  actionType: AssignType.REASSIGN | AssignType.ASSIGN;
  account: Account;
}

export interface Assign {
  userCompanyRole: CompanyRole;
  orderId: string;
  userId: string;
  version: number;
}

export interface AssignRequest {
  orderId: string;
  body: { userId: string; version: number };
}
