import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { paramsFromObject } from '@app/util/http';
import { Account, CreateAccount, UpdateAccount } from '@app/account/models';
import { API } from '@app/shared/api/api.service';
import { Observable } from 'rxjs';

@Injectable()
export class AccountService {
  constructor(private http: HttpClient, private api: API) {}

  public getByAccountCompanyId$(companyId: string, accountCompanyId: string): Observable<Account> {
    const encodedCompanyId = encodeURIComponent(companyId);
    const params = paramsFromObject({ accountCompanyId });

    return this.http.get<Account>(this.api.COMPANY_ACCOUNT(encodedCompanyId), { params });
  }

  public getByAccountCode$(companyId: string, accountCode: string): Observable<Account> {
    const encodedCompanyId = encodeURIComponent(companyId);
    const encodedAccountCode = encodeURIComponent(accountCode);

    return this.http.get<Account>(this.api.COMPANY_ACCOUNT_CODE(encodedCompanyId, encodedAccountCode));
  }

  public update$({ companyId, body }: UpdateAccount): Observable<{ ok: boolean }> {
    const encodedCompanyId = encodeURIComponent(companyId);

    return this.http.post<{ ok: boolean }>(this.api.COMPANY_ACCOUNT(encodedCompanyId), body);
  }

  public create$({ companyId, body }: CreateAccount): Observable<{ ok: boolean }> {
    const encodedCompanyId = encodeURIComponent(companyId);

    return this.http.post<{ ok: boolean }>(this.api.COMPANY_ACCOUNT_CREATE(encodedCompanyId), body);
  }
}
