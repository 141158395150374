import { createAction, props } from '@ngrx/store';
import { AnalyticsQuery, AnalyticsQueryResponse, AnalyticsStatusQueryResponse } from '../../models';

export enum AnalyticsActionType {
  QUERY_AMOUNT_PER_MONTH = '[Analytics] Query Amount Per Month',
  QUERY_AMOUNT_PER_MONTH_SUCCESS = '[Analytics] Query Amount Per Month Success',
  QUERY_COUNT_PER_MONTH = '[Analytics] Query Count Per Month',
  QUERY_COUNT_PER_MONTH_SUCCESS = '[Analytics] Query Count Per Month Success',
  QUERY_COUNT_PER_STATUS = '[Analytics] Query Count Per Status',
  QUERY_COUNT_PER_STATUS_SUCCESS = '[Analytics] Query Count Per Status Success',
  QUERY_KPIS = '[Analytics] Query KPIs',
  QUERY_KPIS_CONFIRMED = '[Analytics] Query KPIs Confirmed',
  QUERY_KPIS_CONFIRMED_SUCCESS = '[Analytics] Query KPIs Confirmed Success',
  QUERY_KPIS_REQUESTED = '[Analytics] Query KPIs Requested',
  QUERY_KPIS_REQUESTED_SUCCESS = '[Analytics] Query KPIs Requested Success',

  QUERY_COUNT_ORDER_LINES = '[Analytics] Query Count Order lines',
  QUERY_COUNT_ORDER_LINES_SUCCESS = '[Analytics] Query Count Order lines Success',

  QUERY_COUNT_TASKS = '[Analytics] Query Count Tasks',
  QUERY_COUNT_TASKS_SUCCESS = '[Analytics] Query Count Tasks Success',

  QUERY_COUNT_CONNECTIONS = '[Analytics] Query Count Connections',
  QUERY_COUNT_CONNECTIONS_SUCCESS = '[Analytics] Query Count Connections Success',
}

export const queryCountConnections = createAction(AnalyticsActionType.QUERY_COUNT_CONNECTIONS);
export const queryCountConnectionsSuccess = createAction(
  AnalyticsActionType.QUERY_COUNT_CONNECTIONS_SUCCESS,
  props<{ value: number }>(),
);

export const queryCountOrderLines = createAction(AnalyticsActionType.QUERY_COUNT_ORDER_LINES);
export const queryCountOrderLinesSuccess = createAction(
  AnalyticsActionType.QUERY_COUNT_ORDER_LINES_SUCCESS,
  props<{ value: number }>(),
);

export const queryCountTasks = createAction(AnalyticsActionType.QUERY_COUNT_TASKS);
export const queryCountTasksSuccess = createAction(
  AnalyticsActionType.QUERY_COUNT_TASKS_SUCCESS,
  props<{ value: number }>(),
);

export const queryAmountPerMonth = createAction(AnalyticsActionType.QUERY_AMOUNT_PER_MONTH);
export const queryAmountPerMonthSuccess = createAction(
  AnalyticsActionType.QUERY_AMOUNT_PER_MONTH_SUCCESS,
  props<{ response: AnalyticsQueryResponse }>(),
);
export const queryCountPerMonth = createAction(AnalyticsActionType.QUERY_COUNT_PER_MONTH);
export const queryCountPerMonthSuccess = createAction(
  AnalyticsActionType.QUERY_COUNT_PER_MONTH_SUCCESS,
  props<{ response: AnalyticsQueryResponse }>(),
);
export const queryCountPerStatus = createAction(AnalyticsActionType.QUERY_COUNT_PER_STATUS);
export const queryCountPerStatusSuccess = createAction(
  AnalyticsActionType.QUERY_COUNT_PER_STATUS_SUCCESS,
  props<{ response: AnalyticsStatusQueryResponse }>(),
);
export const queryKpis = createAction(AnalyticsActionType.QUERY_KPIS, props<{ query: AnalyticsQuery }>());
export const queryKpisConfirmed = createAction(
  AnalyticsActionType.QUERY_KPIS_CONFIRMED,
  props<{ query: AnalyticsQuery }>(),
);
export const queryKpisConfirmedSuccess = createAction(
  AnalyticsActionType.QUERY_KPIS_CONFIRMED_SUCCESS,
  props<{ query: AnalyticsQuery; response: AnalyticsQueryResponse }>(),
);
export const queryKpisRequested = createAction(
  AnalyticsActionType.QUERY_KPIS_REQUESTED,
  props<{ query: AnalyticsQuery }>(),
);
export const queryKpisRequestedSuccess = createAction(
  AnalyticsActionType.QUERY_KPIS_REQUESTED_SUCCESS,
  props<{ query: AnalyticsQuery; response: AnalyticsQueryResponse }>(),
);
