import { NgModule } from '@angular/core';
import { RootSharedModule } from '@app/shared/root-shared.module';
import { OrderLineNameComponent } from './components/order-line-name/order-line-name.component';
import { LazyOrderLinePipe } from './pipes/lazy-order-line.pipe';
import { OrderLineRouterLinkPipe } from './pipes/order-line-router-link.pipe';
import { LineDocumentsComponent } from '@app/order-line/shared/components/line-documents/line-documents.component';
import { CompanySharedModule } from '@app/company/shared/company-shared.module';
import { ObjectStorageSharedModule } from '@app/object-storage/shared/object-storage-shared.module';

const components = [OrderLineNameComponent, LineDocumentsComponent];
const pipes = [LazyOrderLinePipe, OrderLineRouterLinkPipe];

@NgModule({
  imports: [RootSharedModule, CompanySharedModule, ObjectStorageSharedModule],
  declarations: [components, pipes],
  exports: [components, pipes],
})
export class OrderLineSharedModule {}
