import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'tc-file-select',
  templateUrl: './file-select.component.html',
  styleUrls: ['./file-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileSelectComponent {
  @Input()
  disabled = false;

  @Output()
  readonly selected = new EventEmitter<File>();

  @Input()
  accept?: string;

  public onSelect(event: Event): void {
    const input = (event.target || event.srcElement) as HTMLInputElement;
    const file = input.files && input.files[0];

    if (!file) {
      return;
    }

    this.selected.emit(file);
    input.value = '';
  }
}
