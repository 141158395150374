import { AnalyticsQueryResponse, Measurement } from '@app/analytics/models';
import * as moment from 'moment';

function randomInt(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function stubPerMonthQueryResponse(): AnalyticsQueryResponse {
  const response: AnalyticsQueryResponse = {
    data: [],
    total: 0,
  };

  const iterator = moment.utc().startOf('month').subtract(1, 'year');

  Array.from({ length: 12 }).forEach(() => {
    const measurement: Measurement = {
      date: iterator.toDate(),
      value: randomInt(0, 100),
    };

    response.data.push(measurement);
    response.total++;

    iterator.add(1, 'month');

    return measurement;
  });

  return response;
}
