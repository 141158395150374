<form [formGroup]="form" (ngSubmit)="submit()">
  <mat-form-field>
    <mat-label>
      {{ !!internalControl?.value ? LABEL_INTERNAL : LABEL_MESSAGE }}
    </mat-label>
    <textarea autocomplete="off" [errorStateMatcher]="matcher" formControlName="message" matInput (input)="enforceLineLimit($event)" ngModel #message class="input-message"></textarea>
  </mat-form-field>
  <!--
    We intentionally don't disable the button when the form is invalid to make
    it stand out more visually. And there is actually no need to: FormComponent
    will take care that invalid forms won't get submitted.
  -->
  <div class="wrap">
    <button
      class="tc-upcase"
      color="primary"
      [class.internal]="!!internalControl?.value"
      mat-flat-button
      [tcTrackClick]="{ eventName: mixpanelClickEvent}"
      type="submit"
    >
      {{ !!internalControl?.value ? BUTTON_INTERNAL : BUTTON_MESSAGE }}
    </button>

    <mat-tab-group
      [selectedIndex]="internalControl?.value"
      (selectedIndexChange)="internalControl?.setValue($event)"
      [class.internal-link]="!!internalControl?.value"
    >
      <mat-tab i18n-label="@@conversation.message" label="Message"></mat-tab>
      <ng-container *ngIf="hasInternal">
        <mat-tab i18n-label="@@conversation.internal" label="Internal note"></mat-tab>
      </ng-container>
    </mat-tab-group>
  </div>
</form>
