import { createAction, props } from '@ngrx/store';
import { NotificationType } from './core.model';

export enum NotificationActionType {
  NOTIFICATION = '[Core] Notification',
}

export const notificationSend = createAction(
  NotificationActionType.NOTIFICATION,
  props<{ message: string; notificationType: NotificationType; duration?: number }>(),
);

export enum AnalyticsActionType {
  Track = '[Core] Track',
}

export const trackEvent = createAction(AnalyticsActionType.Track, props<any>());
