import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { InProgressStatus, Money, OrderLine, Prices } from '@app/order/models';
import { priceDifference } from '@app/order/shared/components/price-difference/helpers/price-difference.helper';
import { getProperty } from '@app/shared/pipes/unique.pipe';

@Component({
  selector: 'tc-line-pricing',
  templateUrl: './line-pricing.component.html',
  styleUrls: ['./line-pricing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinePricingComponent {
  @Input()
  orderLine?: OrderLine;

  public get prices(): Prices | undefined {

    const inProgressStatusValue = getProperty<InProgressStatus>('status.inProgressStatus', this.orderLine!);

    if (!!inProgressStatusValue) {
      return this.orderLine ? this.orderLine.pricesIncludingRequests! : undefined;
    }

    return this.orderLine ? this.orderLine.prices : undefined;
  }

  public get amountView(): Money | undefined {

    const inProgressStatusValue = getProperty<InProgressStatus>('status.inProgressStatus', this.orderLine!);

    if (!!inProgressStatusValue) {
      return this.orderLine ? this.orderLine.totalAmountIncludingRequests! : undefined;
    }

    return this.orderLine ? this.orderLine.totalAmount : undefined;
  }

  public get amountChanged(): boolean | undefined {
    return (
      this.persistedAmount?.value !== this.requestedAmount?.value ||
      this.persistedAmount?.currencyIso !== this.requestedAmount?.currencyIso
    );
  }

  public get contractNumber(): string | undefined {
    return this.orderLine?.buyerLine.terms?.contractNumber;
  }

  public get contractPosition(): string | undefined {
    return this.orderLine?.buyerLine.terms?.contractPosition;
  }

  private get persistedAmount(): Money | undefined {
    return this.orderLine?.totalAmount;
  }

  private get requestedAmount(): Money | undefined {
    return this.amountView;
  }

  private get persistedPrices(): Prices | undefined {
    return this.orderLine?.prices;
  }

  private get requestPrices(): Prices | undefined {
    return this.prices;
  }

  private hasGrossPriceTransactionCurrencyChanged(): boolean {
    return (
      !!this.persistedPrices?.grossPrice?.priceInTransactionCurrency &&
      !!this.requestPrices?.grossPrice?.priceInTransactionCurrency &&
      this.persistedPrices.grossPrice.priceInTransactionCurrency.currencyIso !==
        this.requestPrices.grossPrice.priceInTransactionCurrency.currencyIso
    );
  }

  public get grossPriceChanged(): boolean {
    return (
      this.hasGrossPriceTransactionCurrencyChanged() ||
      (this.pricePerItemChanged &&
        !!this.persistedPrices?.grossPrice &&
        !!priceDifference(this.persistedPrices.grossPrice, this.requestPrices?.grossPrice))
    );
  }

  public get priceUnitChanged(): boolean | undefined {
    return (
      this.requestPrices &&
      this.persistedPrices &&
      (this.requestPrices.priceUnitOfMeasureIso !== this.persistedPrices.priceUnitOfMeasureIso ||
        (this.pricePerItemChanged && this.requestPrices.priceUnitQuantity !== this.persistedPrices.priceUnitQuantity))
    );
  }

  public get netPriceChanged(): boolean | undefined {
    return this.hasNetPriceTransactionCurrencyChanged() || this.pricePerItemChanged;
  }

  public get discountPercentageChanged(): boolean | undefined {
    return (
      this.requestPrices &&
      this.persistedPrices &&
      this.persistedPrices.discountPercentage !== this.requestPrices.discountPercentage
    );
  }

  private hasNetPriceTransactionCurrencyChanged(): boolean {
    return (
      !!this.persistedPrices?.netPrice?.priceInTransactionCurrency &&
      !!this.requestPrices?.netPrice?.priceInTransactionCurrency &&
      this.persistedPrices.netPrice.priceInTransactionCurrency.currencyIso !==
        this.requestPrices.netPrice.priceInTransactionCurrency.currencyIso
    );
  }

  private get pricePerItemChanged(): boolean {
    return (
      !!this.persistedPrices?.netPrice &&
      !!this.requestPrices?.netPrice &&
      this.persistedPrices.netPrice.priceInTransactionCurrency.value / this.persistedPrices.priceUnitQuantity !==
        this.requestPrices.netPrice.priceInTransactionCurrency.value / this.requestPrices.priceUnitQuantity
    );
  }
}
