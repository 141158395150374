export enum MixpanelPageViewEvents {
  DASHBOARD = 'Dashboard page view',
  ORDERS = 'Orders page view',
  TASK = 'Task page view',
  ANALYTICS = 'Analytics page view',
  NETWORK = 'Network page view',
  CATALOG = 'Catalog page view',
  FORECASTS = 'Forecasts page view',
  SHIPMENTS = 'Shipments page view',
  KNOWLEDGE_BASE = 'Knowledge base view',
  KNOWLEDGE_BASE_EXPORT = 'Knowledge base view export CSV',
}
