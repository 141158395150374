import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WebSocketService } from '@app/core/services/web-socket/web-socket.service';
import { HttpUrlCodec } from '@app/util/http';
import { Activity, ActivityQuery, ActivityQueryFilter, ActivityQueryResponse } from '@app/activity/models';
import { API } from '@app/shared/api/api.service';
import { Observable } from 'rxjs';

@Injectable()
export class ActivityService {
  constructor(private http: HttpClient, private webSocketService: WebSocketService, private api: API) {}

  public listen$(filters: ActivityQueryFilter): Observable<Activity> {
    let params = new HttpParams({ encoder: new HttpUrlCodec() });

    if (filters.entityId) {
      params = params.set('entityId', filters.entityId);
    }

    if (filters.entityType) {
      params = params.set('entityType', filters.entityType);
    }

    if (filters.userIds && filters.userIds[0]) {
      params = params.set('userId', filters.userIds[0]);
    }

    if (filters.companyIds && filters.companyIds[0]) {
      params = params.set('companyId', filters.companyIds[0]);
    }

    return this.webSocketService.createWebSocket$<Activity>(this.api.ACTIVITY_WS(), params);
  }

  public query$(body: ActivityQuery): Observable<ActivityQueryResponse> {
    return this.http.post<ActivityQueryResponse>(this.api.ACTIVITY_SEARCH(), body);
  }
}
