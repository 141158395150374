import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BuyerItem, Dimension, OrderLine } from '@app/order/models';

@Component({
  selector: 'tc-line-item',
  templateUrl: './line-item.component.html',
  styleUrls: ['./line-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineItemComponent {
  @Input()
  orderLine?: OrderLine;

  public get item(): BuyerItem | undefined {
    return this.orderLine?.buyerLine.item;
  }

  public get dimensions(): Dimension[] {
    return this.orderLine?.buyerLine?.item.dimensions || [];
  }
}
