<div class="inner-table" *ngIf="documents.length || showLineMode">
  <fieldset class="fieldset-border">
    <legend i18n="@@ts.activity.options.order:Order">Order</legend>
    <p class="mode-of-transport" *ngIf="showLineMode">Mode of transport <span [ngClass]="{'transport-bg' : showLineMode !== transportMode}">{{showLineMode}}</span></p>
    <p class="sub-title" i18n="@@common.field.documents" *ngIf="documents.length">Documents</p>

    <table mat-table [dataSource]="documents" *ngIf="documents.length">
      <ng-container matColumnDef="file">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <tc-download-document
            [document]="element"
            display="icon"
          ></tc-download-document>
        </td>
      </ng-container>

      <ng-container matColumnDef="attachedBy">
        <th mat-header-cell *matHeaderCellDef i18n="@@common.docs.attached">Attached by</th>
        <td mat-cell *matCellDef="let element">
          <ng-container *ngIf="element.attachedBy | tcLazyCompany | async as company">
            <tc-company-name [company]="company"></tc-company-name>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef i18n="@@user.table.name">Name</th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="revision">
        <th mat-header-cell *matHeaderCellDef i18n="@@order.line.details.revision">Revision</th>
        <td mat-cell *matCellDef="let element"> {{element.revision}} </td>
      </ng-container>

      <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef i18n="@@common.docs.code">Code</th>
        <td mat-cell *matCellDef="let element"> {{element.code}} </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef i18n="@@common.docs.type">Type</th>
        <td mat-cell *matCellDef="let element"> {{element.type}} </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef i18n="@@order.charge.line.description">Description</th>
        <td mat-cell *matCellDef="let element"> {{element.description}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    </fieldset>
</div>
