import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { UserInterface } from '@app/user/models';

@Component({
  selector: 'tc-user-summary',
  templateUrl: 'user-summary.component.html',
  styleUrls: ['user-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSummaryComponent {
  @Input()
  user?: UserInterface;

  public get position(): string | undefined {
    return this.user && this.user.profile.position;
  }
}
