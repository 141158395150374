import { HttpRequest } from '@angular/common/http';
import { Tokens } from '@app/auth/models';

export function setHeader(request: HttpRequest<any>, token: string): HttpRequest<any> {
  return request.clone({
    setHeaders: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export function setLogoutHeader(request: HttpRequest<any>, token: Tokens): HttpRequest<any> {
  return request.clone({
    setHeaders: {
      'Refresh-Token': `${token.refreshToken}`,
    },
  });
}
