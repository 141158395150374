import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@app/shared/api/api.service';
import { Observable } from 'rxjs';
import { Download } from '../state/object-storage.model';

@Injectable()
export class ObjectStorageService {
  constructor(private http: HttpClient, private api: API) {}

  public upload$(file: File): Observable<{ id: string }> {
    const body = new FormData();
    body.append('file', file, file.name);

    return this.http.post<{ id: string }>(this.api.OBJECT_STORAGE_UPLOAD_IMAGE(), body);
  }

  public download$(fileQuery: string): Observable<Download> {
    const [id, paramsString] = fileQuery.split('?');
    const paramsArr = paramsString.split('&');
    const paramsObj: { [key: string]: string } = {};

    paramsArr.forEach((pair: string) => {
      const [key, value] = pair.split('=');

      paramsObj[key] = value;
    });

    return this.http.get<Download>(this.api.OBJECT_STORAGE_IMAGE(id), { params: paramsObj });
  }

  public documentUpload$(file: File): Observable<{ id: string }> {
    const body = new FormData();
    body.append('file', file, file.name);

    return this.http.post<{ id: string }>(this.api.OBJECT_DOCUMENT_STORAGE_UPLOAD(), body);
  }

  public documentDownload$(id: string): Observable<Download> {
    return this.http.get<Download>(this.api.OBJECT_DOCUMENT_STORAGE_DOWNLOAD(id));
  }

  public documentDirectDownload$(id: string): Observable<ArrayBuffer> {
    return this.http.get<ArrayBuffer>(this.api.OBJECT_DOCUMENT_DIRECT_DOWNLOAD(id), {
      responseType: 'arraybuffer' as 'json',
      observe: 'body',
    });
  }
}
