import { HttpParameterCodec, HttpParams } from '@angular/common/http';

/**
 * Remove when the default Codec works correctly
 * https://github.com/angular/angular/issues/18261
 */
export class HttpUrlCodec implements HttpParameterCodec {
  public encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  public encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  public decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  public decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

export const paramsFromObject = <T>(object: T) => {
  return new HttpParams({
    encoder: new HttpUrlCodec(),
    fromObject: object as T & { [key: string]: string },
  });
};
