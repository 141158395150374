import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ForecastTableComponent } from './components/forecast-table/forecast-table.component';
import { ForecastPageComponent } from './containers/forecast-page/forecast-page.component';
import { ForecastRoutingModule } from './forecast-routing.module';
import { ForecastCoreModule } from '@app/forecast/core/forecast-core.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { ForecastSelectComponent } from './components/forecast-select/forecast-select.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, FormsModule, SharedModule, NgSelectModule, ForecastRoutingModule, ForecastCoreModule],
  declarations: [ForecastTableComponent, ForecastPageComponent, ForecastSelectComponent],
})
export class ForecastModule {}
