export enum AnalyticsStatuses {
  ISSUED = 'Issued',
  IN_PROGRESS = 'InProgress',
  CONFIRMED = 'Confirmed',
  REJECTED = 'Rejected',
  COMPLETED = 'Completed',
  CANCELLED = 'Cancelled',
}

export interface AnalyticsStatusMetricsDtoInterface {
  status: AnalyticsStatuses;
  count: number;
}
