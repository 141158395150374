<div [ngStyle]="{ 'width.px': size, 'height.px': size }">
  <ng-container *ngIf="profilePictureId; else defaultPlaceholder">
    <img *ngIf="profilePictureId | tcObjectStorage:{ w: size } | async as file; else defaultPlaceholder" [src]="file.downloadUrl">
  </ng-container>

  <ng-template #defaultPlaceholder>
    <div *ngIf="showPlaceholder; else defaultProfilePicture" class="placeholder" [ngStyle]="{'background-color': getRandomColor(fullName)}">
      <ng-container>{{ initials }}</ng-container>
    </div>

    <ng-template #defaultProfilePicture>
      <img src="assets/Captain-icon.png">
    </ng-template>

  </ng-template>

  <tc-role-icon *ngIf="showRole" [user]="user"></tc-role-icon>
</div>
