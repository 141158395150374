import { MessageMeta, UUID } from '@app/shared/models';
import { DocumentInterface, OrderLineRequests } from '@app/order/models';

export interface Task {
  id: UUID;
  entityId: string;
  entityType: TaskEntityType;
  taskType: TaskType;
  assigneeCompanyId: UUID;
  content: WorkflowContent;
  status: TaskStatus;
  eventName: string;
  createdAt: Date;
  updatedAt?: Date;
  closedAt?: Date;
  indicators?: {
    taskOverdue?: boolean;
  };
  createdBy: {
    companyId: UUID;
    userId?: UUID;
  };
  assigneeUserId?: UUID;
  meta?: MessageMeta;
  relatedCompanies?: {
    buyerCompanyId: UUID;
    supplierCompanyId: UUID;
  };
}

export interface WorkflowContent {
  conversation: {
    messageId: string;
    content: string;
    conversationId: {
      entityId: string;
      entityType: string;
    };
  };
  orderLine?: {
    orderId: string;
    lineId: string;
    proposal: { reason: string };
    reason?: string;
    documents: DocumentInterface[];
    supplierLine: {
      requests: OrderLineRequests;
    };
    buyerLine: {
      requests: OrderLineRequests;
    };
  };
  shipment: {
    shipmentId: string;
    shipmentNumber: string;
    reason?: {
      description: string;
    };
  };
  order?: {
    orderId: string;
    documents: DocumentInterface[];
  };
  forecast?: {
    forecastId: string;
    forecastNumber: string;
  };
}

export enum TaskType {
  RespondToMessage = 'respondToMessage',
  ConfirmOrderLineBySupplier = 'orderConfirmLineBySupplier',
  ReconfirmOrderLineBySupplierType = 'orderReconfirmLineBySupplier',
  ApproveOrderLineByBuyer = 'orderApproveLineByBuyer',
  ApproveLineReopenRequestByBuyer = 'orderApproveLineReopenRequestByBuyer',
  ApproveLineReopenRequestBySupplier = 'orderApproveLineReopenRequestBySupplier',
  RequestReopenOrderLineBySupplier = 'orderRequestReopenLineBySupplier',
  Acknowledge = 'acknowledge',
  ShipmentApproveByBuyer = 'shipmentApproveByBuyer',
  ShipmentReissueBySupplier = 'shipmentReissueBySupplier',
}

export enum TaskEntityType {
  CONVERSATION = 'conversation',
  LINE = 'line',
  ORDER = 'order',
  FORECAST = 'forecast',
  SHIPMENT = 'shipment',
}

export enum TaskStatus {
  Created = 'created',
  Closed = 'closed',
}

export enum AssignedTo {
  COMPANY = 'MyCompany',
  BUYERS = 'MyBuyers',
  SUPPLIERS = 'MySuppliers',
}

export enum TaskCategory {
  Conversations = 'Conversations',
  Exceptions = 'Exceptions',
  Notifications = 'Notifications',
}

export enum TaskModule {
  Purchase = 'Purchase',
  Forecast = 'Forecast',
  Shipment = 'Shipment',
  RFQ = 'RFQ',
}

export const CLASSIFICATION_NAMES = { ...TaskCategory, ...TaskModule };

export interface CloseConversationTask {
  entityId: UUID;
  entityType: TaskEntityType;
  messageId: UUID;
}

export interface CloseOrderAcknowledgeTasks {
  orderId: string;
  taskIds: string[];
  companyId: string;
}

export interface CloseForecastAcknowledgeTasks {
  forecastId: string;
  taskIds: string[];
}

export interface CloseShipmentTasks {
  shipmentId: string;
  taskIds: string[];
}

export interface CloseTaskResponse {
  ok: boolean;
}
