import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';

import { TableDataSource } from '@app/shared/components/table/table-data-source';
import { UserInterface } from '@app/user/models';
import { UserRole } from '@app/user/models/enums/user-role.enum';

@Component({
  selector: 'tc-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersTableComponent implements OnInit {
  @Input()
  data$!: Observable<(UserInterface | null)[]>;

  @Input()
  roles!: UserRole[];

  @Output()
  readonly reinviteUser = new EventEmitter<string>();

  @Output()
  readonly removeUser = new EventEmitter<string>();

  public isSuperAdminUser = false;

  public dataSource!: TableDataSource<UserInterface | null>;
  public readonly columns = ['profilePictureId', 'name', 'position', 'createdAt', 'actions'];

  ngOnInit(): void {
    this.dataSource = new TableDataSource(this.data$);
    this.isSuperAdminUser =
      this.roles &&
      (this.roles.includes(UserRole.SUPER_USER) ||
        this.roles.includes(UserRole.SUPPORT) ||
        this.roles.includes(UserRole.ADMIN));
  }

  reinvite(id: string): void {
    this.reinviteUser.emit(id);
  }

  remove(id: string): void {
    this.removeUser.emit(id);
  }
}
