import { CompanyRole } from '@app/company/models';
import { UserRole } from '@app/user/models/enums/user-role.enum';

export interface Tokens {
  accessToken: string;
  refreshToken?: string;
}

export interface RefreshToken {
  refreshToken: string;
}

export interface AccessTokenPayload {
  data: ServerIdentityModel;
  exp: number;
}

export interface Identity {
  email: string;
  userId: string;
  authorizedCompanyIds: string[];
  username: string;
  userRoles: UserRole[];
  companyRoles: CompanyRole[];
  companyId: string;
  status: Status;
  twoFAEnabled: boolean;
  twoFAEnforced: boolean;
  identityProvider: IdentityProvider;
  twoFABackupCodes?: string[];
}

export interface ServerIdentityModel {
  username: string;
  authorizedCompanyIds: string[];
  userId: string;
  userRoles: UserRole[];
  companyRoles: CompanyRole[];
  companyId: string;
  twoFAEnabled: boolean;
  twoFAEnforced: boolean;
  twoFABackupCodes?: string[];
  status: Status;
  identityProvider: IdentityProvider;
}

export interface BackupCode {
  backupCode: string;
}

export const toIdentity = ({
  companyId,
  status,
  userId,
  username,
  userRoles,
  companyRoles,
  twoFAEnabled,
  twoFAEnforced,
  identityProvider,
  twoFABackupCodes,
  authorizedCompanyIds,
}: ServerIdentityModel): Identity => {
  return {
    email: username,
    companyId,
    status,
    userId,
    username,
    userRoles,
    companyRoles,
    twoFAEnabled,
    twoFAEnforced,
    twoFABackupCodes,
    identityProvider,
    authorizedCompanyIds,
  };
};

export interface PolicyNodes {
  [id: string]: PolicyNode;
}

export interface PolicyNode {
  policies: string[];
  childrenById: PolicyNodes;
}

export type Status = 'initial' | 'pending' | 'authenticated' | 'needs_2fa_code_verification' | 'needs_2fa_setup';
export type IdentityProvider = 'tradecloud' | 'azure_ad';
