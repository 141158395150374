<div *ngIf="requested.length">
  <ng-container *ngFor="let chargeLine of modified let i = index;">
    <strong
      *ngIf="isChargeTypeCodeChanged(i) || isQuantityChanged(i) || isPriceUnitOfMeasureChanged(i) || isPriceChanged(i)"
      class="label"
    ><span i18n="@@common.charge.line">Charge line</span> {{chargeLine.position}}</strong>
    <span class="section" *ngIf="isChargeTypeCodeChanged(i)">
      <strong i18n="@@common.chargeType">Charge type</strong>
      {{ chargeTypeMap.get(current[i]?.chargeTypeCode) }}
      &#8594;
      <span class="change">
        {{ chargeTypeMap.get(chargeLine.chargeTypeCode) }}
      </span>
    </span>
    <span class="section" *ngIf="isQuantityChanged(i) || isPriceUnitOfMeasureChanged(i)">
      <strong i18n="@@common.quantity">Quantity</strong>
      {{ current[i].quantity | number }}
      {{  current[i].priceUnitOfMeasureIso | tcUnitSymbol }}
      &#8594;
      <span class="change">
        {{ chargeLine.quantity | number }}
        {{ chargeLine.priceUnitOfMeasureIso | tcUnitSymbol }}
      </span>
    </span>
    <span class="section" *ngIf="isPriceChanged(i)">
      <strong i18n="@@order.line.reopen.price.gross">Price</strong>
      {{ current[i].price.priceInTransactionCurrency | tcMoney }}
      &#8594;
      <span class="change">
        {{ chargeLine.price.priceInTransactionCurrency | tcMoney }}
        <tc-price-difference
          [currentPrice]="current[i].price"
          [proposedPrice]="chargeLine.price"
        ></tc-price-difference>
    </span>
  </span>
  </ng-container>

  <ng-container *ngFor="let chargeLine of added let i = index;">
    <ng-container
      [ngTemplateOutlet]="addedAndRemoved"
      [ngTemplateOutletContext]="{ $implicit: chargeLine, label: 'added' }"
    >
    </ng-container>
  </ng-container>

  <ng-container *ngFor="let chargeLine of removed let i = index;">
    <ng-container
      [ngTemplateOutlet]="addedAndRemoved"
      [ngTemplateOutletContext]="{ $implicit: chargeLine, label: 'removed' }"
    >
    </ng-container>
  </ng-container>

  <ng-template let-chargeLine let-label="label" #addedAndRemoved>
    <strong
      class="label"
    ><span i18n="@@common.charge.line">Charge line</span> {{chargeLine?.position}} {{label}}</strong>
    <span class="section">
      <strong i18n="@@common.chargeType">Charge type</strong>
      &#8594;
      <span class="change">
        {{ chargeTypeMap.get(chargeLine.chargeTypeCode) }}
      </span>
    </span>
    <span class="section">
      <strong i18n="@@common.quantity">Quantity</strong>
      &#8594;
      <span class="change">
        {{ chargeLine.quantity | number }}
        {{ chargeLine.priceUnitOfMeasureIso | tcUnitSymbol }}
      </span>
    </span>
    <span class="section">
      <strong i18n="@@order.line.reopen.price.gross">Price</strong>
      &#8594;
      <span class="change">
        {{ chargeLine.price.priceInTransactionCurrency | tcMoney }}
        <tc-price-difference
          [proposedPrice]="chargeLine.price"
        ></tc-price-difference>
    </span>
  </span>
  </ng-template>
</div>
