<ng-container
  *ngIf="{config: panelsConfig$ | async } as panels"
>
  <mat-accordion
    [style]="{ display: 'flex', 'flex-direction': 'column' }"
    multi
    cdkDropList
    (cdkDropListDropped)="drop($event, panels.config)"
    *ngIf="{
      order: order$ | async,
      line: orderLine$ | async,
      expand: expandPanels$ | async
    } as vm"
  >
    <mat-expansion-panel
      cdkDrag
      [style.order]="panels.config.lineSummary"
      [cdkDragData]="panelType.LINE_SUMMARY"
      [expanded]="vm.expand.lineSummary"
    >
      <mat-expansion-panel-header (click)="togglePanel(panelType.LINE_SUMMARY, vm.expand.lineSummary)">
        <mat-panel-title>
          <ng-container *ngIf="vm.order?.buyerOrder?.orderType === 'RFQ'; else noRfqType" >
            <strong i18n="@@common.line.rfq.summary">RFQ line summary</strong>
          </ng-container>
          <ng-template #noRfqType>
            <strong i18n="@@common.line.summary">Order line summary</strong>
          </ng-template>
        </mat-panel-title>
        <mat-panel-description>
          <mat-icon class="icon-move" cdkDragHandle (click)="$event.stopPropagation()">open_with</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <tc-line-summary
        [role]="role$ | async"
        [companyId]="actingAsCompanyId$ | async"
        [orderLine]="vm.line"
        [order]="vm.order"
        (removeLineLabel)="onRemoveLabel($event)"
      ></tc-line-summary>
    </mat-expansion-panel>
    <mat-expansion-panel
      cdkDrag
      [style.order]="panels.config.lineItem"
      [cdkDragData]="panelType.LINE_ITEM"
      [expanded]="vm.expand.lineItem"
    >
    <mat-expansion-panel-header (click)="togglePanel(panelType.LINE_ITEM, vm.expand.lineItem)">
        <mat-panel-title>
          <strong i18n="@@order.line.details.item">Item</strong>
        </mat-panel-title>
        <mat-panel-description>
          <mat-icon class="icon-move" cdkDragHandle (click)="$event.stopPropagation()">open_with</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <tc-line-item
        [orderLine]="orderLine$ | async"
      ></tc-line-item>
    </mat-expansion-panel>

    <ng-container *ngIf="vm.line?.buyerLine | tcHasAnyValues : 'certification'">
      <mat-expansion-panel
        cdkDrag
        [style.order]="panels.config.lineCertification"
        [cdkDragData]="panelType.LINE_CERTIFICATION"
        [expanded]="vm.expand.lineCertification"
      >
      <mat-expansion-panel-header (click)="togglePanel(panelType.LINE_CERTIFICATION, vm.expand.lineCertification)">
          <mat-panel-title>
            <strong i18n="@@common.line.certification">Certification</strong>
          </mat-panel-title>
          <mat-panel-description>
            <mat-icon class="icon-move" cdkDragHandle (click)="$event.stopPropagation()">open_with</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <tc-line-certification
          [orderLine]="orderLine$ | async"
        ></tc-line-certification>
      </mat-expansion-panel>
    </ng-container>

    <ng-container
      *ngIf="vm.line?.buyerLine?.requests?.reopenRequest?.chargeLines?.length > 0
        || vm.line?.supplierLine?.requests?.reopenRequest?.chargeLines?.length > 0
        || vm.line?.supplierLine?.requests?.proposal?.chargeLines?.length > 0
        || vm.line?.confirmedLine?.chargeLines?.length > 0
        || vm.line?.buyerLine?.chargeLines?.length > 0"
    >
      <mat-expansion-panel
        class="no--padding"
        cdkDrag
        [style.order]="panels.config.lineChargeInfo"
        [cdkDragData]="panelType.LINE_CHARGE_INFO"
        [expanded]="vm.expand.lineChargeInfo"
      >
        <mat-expansion-panel-header (click)="togglePanel(panelType.LINE_CHARGE_INFO, vm.expand.lineChargeInfo)">
          <mat-panel-title>
            <strong i18n="@@common.charge.line">Charge line</strong>
          </mat-panel-title>
          <mat-panel-description>
            <mat-icon class="icon-move" cdkDragHandle (click)="$event.stopPropagation()">open_with</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <tc-charge-line-table [orderLine]="orderLine$ | async"></tc-charge-line-table>
      </mat-expansion-panel>
    </ng-container>

    <ng-container *ngIf="vm.line?.supplierLine?.notes?.length > 0 || vm.line?.buyerLine?.notes?.length > 0">
      <mat-expansion-panel
        cdkDrag
        [style.order]="panels.config.lineNotes"
        [cdkDragData]="panelType.LINE_NOTES"
        [expanded]="vm.expand.lineNotes"
      >
        <mat-expansion-panel-header (click)="togglePanel(panelType.LINE_NOTES, vm.expand.lineNotes)">
          <mat-panel-title><strong i18n="@@common.field.notes">Notes</strong></mat-panel-title>
          <mat-panel-description>
            <mat-icon class="icon-move" cdkDragHandle (click)="$event.stopPropagation()">open_with</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <tc-line-notes
          [order]="order$ | async"
          [orderLine]="orderLine$ | async"
        ></tc-line-notes>
      </mat-expansion-panel>
    </ng-container>

    <ng-container *ngIf="vm.line?.supplierLine?.properties?.length > 0 || vm.line?.buyerLine?.properties?.length > 0">
      <mat-expansion-panel
      cdkDrag
      [style.order]="panels.config.lineProperties"
      [cdkDragData]="panelType.LINE_PROPERTIES"
      [expanded]="vm.expand.lineProperties"
    >
      <mat-expansion-panel-header (click)="togglePanel(panelType.LINE_PROPERTIES, vm.expand.lineProperties)">
        <mat-panel-title>
          <strong i18n="@@common.field.properties">Properties</strong>
        </mat-panel-title>
        <mat-panel-description>
          <mat-icon class="icon-move" cdkDragHandle (click)="$event.stopPropagation()">open_with</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <tc-line-properties
        [order]="order$ | async"
        [orderLine]="orderLine$ | async"
      ></tc-line-properties>
    </mat-expansion-panel>
    </ng-container>

    <mat-expansion-panel
      cdkDrag
      [style.order]="panels.config.linePricing"
      [cdkDragData]="panelType.LINE_PRICING"
      [expanded]="vm.expand.linePricing"
    >
      <mat-expansion-panel-header (click)="togglePanel(panelType.LINE_PRICING, vm.expand.linePricing)">
        <mat-panel-title>
          <strong i18n="@@order.line.details.price">Pricing</strong>
        </mat-panel-title>
        <mat-panel-description>
          <mat-icon class="icon-move" cdkDragHandle (click)="$event.stopPropagation()">open_with</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <tc-line-pricing [orderLine]="orderLine$ | async"></tc-line-pricing>
    </mat-expansion-panel>
    <mat-expansion-panel
      cdkDrag
      [style.order]="panels.config.lineDeliverySchedule"
      [cdkDragData]="panelType.LINE_DELIVERY_SCHEDULE"
      [expanded]="vm.expand.lineDeliverySchedule"
    >
      <mat-expansion-panel-header (click)="togglePanel(panelType.LINE_DELIVERY_SCHEDULE, vm.expand.lineDeliverySchedule)">
        <mat-panel-title>
          <strong i18n="@@order.line.details.schedule">Delivery schedule</strong>
        </mat-panel-title>
        <mat-panel-description>
          <mat-icon class="icon-move" cdkDragHandle (click)="$event.stopPropagation()">open_with</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <tc-line-schedule [orderLine]="orderLine$ | async"></tc-line-schedule>
    </mat-expansion-panel>

    <ng-container *ngIf="vm.line?.shipmentHistory?.length > 0">
      <mat-expansion-panel
        cdkDrag
        [style.order]="panels.config.lineShipping"
        [cdkDragData]="panelType.LINE_SHIPPING"
        [expanded]="vm.expand.lineShipping"
      >
        <mat-expansion-panel-header (click)="togglePanel(panelType.LINE_SHIPPING, vm.expand.lineShipping)">
          <mat-panel-title>
            <strong i18n="@@common.field.shipping.history">Shipping history</strong>
          </mat-panel-title>
          <mat-panel-description>
            <mat-icon class="icon-move" cdkDragHandle (click)="$event.stopPropagation()">open_with</mat-icon>
          </mat-panel-description>
        </mat-expansion-panel-header>
        <tc-line-shipments
          [shipmentHistory]="vm.line.shipmentHistory"
          [item]="vm.line.buyerLine?.item"
        ></tc-line-shipments>
      </mat-expansion-panel>
    </ng-container>

    <ng-container *ngIf="vm.line?.confirmedLine?.deliveryHistory?.length > 0 || vm.line?.buyerLine?.deliveryHistory?.length > 0">
      <mat-expansion-panel
      cdkDrag
      [style.order]="panels.config.lineDeliveryHistory"
      [cdkDragData]="panelType.LINE_DELIVERY_HISTORY"
      [expanded]="vm.expand.lineDeliveryHistory"
    >
      <mat-expansion-panel-header (click)="togglePanel(panelType.LINE_DELIVERY_HISTORY, vm.expand.lineDeliveryHistory)">
        <mat-panel-title>
          <strong i18n="Indicates when and in which quantity goods are delivered @@order.line.details.history">Delivery history</strong>
        </mat-panel-title>
        <mat-panel-description>
          <mat-icon class="icon-move" cdkDragHandle (click)="$event.stopPropagation()">open_with</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <tc-line-delivery-history [orderLine]="orderLine$ | async"></tc-line-delivery-history>
    </mat-expansion-panel>
    </ng-container>

    <mat-expansion-panel
      cdkDrag
      [style.order]="panels.config.lineItemDetails"
      [cdkDragData]="panelType.LINE_ITEM_DETAILS"
      [expanded]="vm.expand.lineItemDetails"
    >
    <mat-expansion-panel-header (click)="togglePanel(panelType.LINE_ITEM_DETAILS, vm.expand.lineItemDetails)">
        <mat-panel-title>
          <strong i18n="@@order.item.details.title">Item details</strong>
        </mat-panel-title>
        <mat-panel-description>
          <mat-icon class="icon-move" cdkDragHandle (click)="$event.stopPropagation()">open_with</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <tc-line-item-details
        [orderLine]="orderLine$ | async"
      ></tc-line-item-details>
    </mat-expansion-panel>
    <mat-expansion-panel
      cdkDrag
      [style.order]="panels.config.lineContacts"
      [cdkDragData]="panelType.LINE_CONTACTS"
      [expanded]="vm.expand.lineContacts"
    >
    <mat-expansion-panel-header (click)="togglePanel(panelType.LINE_CONTACTS, vm.expand.lineContacts)">
        <mat-panel-title>
          <strong i18n="@@common.field.contacts">Contacts</strong>
        </mat-panel-title>
        <mat-panel-description>
          <mat-icon class="icon-move" cdkDragHandle (click)="$event.stopPropagation()">open_with</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <tc-contact-info
        [order]="order$ | async"
        [role]="role$ | async"
        [assignEnabled]="true"
        (assign)="onAssign($event, vm.order)"
      ></tc-contact-info>
    </mat-expansion-panel>


    <ng-container *ngIf="vm.line?.buyerLine?.documents?.length > 0 || vm.line?.supplierLine?.documents?.length > 0">
      <mat-expansion-panel
      cdkDrag
      class="no--padding"
      [style.order]="panels.config.lineDocuments"
      [cdkDragData]="panelType.LINE_DOCUMENTS"
      [expanded]="vm.expand.lineDocuments"
    >
      <mat-expansion-panel-header  (click)="togglePanel(panelType.LINE_DOCUMENTS, vm.expand.lineDocuments)">
        <mat-panel-title>
          <strong i18n="@@common.field.documents">Documents</strong>
        </mat-panel-title>
        <mat-panel-description>
          <mat-icon class="icon-move" cdkDragHandle (click)="$event.stopPropagation()">open_with</mat-icon>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <tc-line-documents
        [orderLine]="orderLine$ | async"
        [order]="order$ | async"
      ></tc-line-documents>
    </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</ng-container>
