export enum NotificationType {
  ERROR = 'error',
  INFO = 'info',
  SUCCESS = 'success',
  WARNING = 'warning',
}

export enum ActionStatus {
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface ServerError {
  code: string;
}

export enum LanguageCode {
  EN = 'en',
  NL = 'nl',
  PL = 'pl',
  FR = 'fr',
  ES = 'es',
  DE = 'de',
  AR = 'ar',
  BN = 'bn',
  PT = 'pt',
  TR = 'tr',
  UR = 'ur',
  ZH = 'zh',
}

export enum StatusCode {
  CONFLICT = 409,
}

export const conflictedMessage = $localize`:@@ts.msg.conflict:The data has been refreshed. Please try again.`;
