import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Connection } from '@app/connection/models';
import {
  connectionAccept,
  connectionAcceptFailure,
  ConnectionActionType,
  connectionAddMany,
  connectionRequest,
  connectionRequestFailure,
} from './connection.actions';
import { TypedAction } from '@ngrx/store/src/models';
import { createReducer, on } from '@ngrx/store';

export type ConnectionState = EntityState<Connection>;

export const createId = (requestingCompanyId: string, acceptingCompanyId: string) => {
  return `${requestingCompanyId}_${acceptingCompanyId}`;
};

export const selectId = ({ requestingCompanyId, acceptingCompanyId }: Connection) => {
  return createId(requestingCompanyId, acceptingCompanyId);
};

export const connectionAdapter = createEntityAdapter<Connection>({ selectId });

export const initialState = connectionAdapter.getInitialState();

const connectionReducer = createReducer<ConnectionState, TypedAction<ConnectionActionType>>(
  initialState,
  on(connectionAddMany, (state, { entities }) => {
    return connectionAdapter.addMany(entities, state);
  }),
  on(connectionRequest, (state, { companyId, otherCompanyId, otherCompanyName }) => {
    return connectionAdapter.addOne(
      {
        requestingCompanyId: companyId,
        requestingCompanyName: '',
        acceptingCompanyId: otherCompanyId,
        acceptingCompanyName: otherCompanyName,
        requestedAt: new Date(),
        connected: false,
        requestingCompanyAccountCode: '',
        acceptingCompanyAccountCode: '',
      },
      state,
    );
  }),
  on(connectionRequestFailure, (state, { id }) => {
    return connectionAdapter.removeOne(id, state);
  }),
  on(connectionAccept, (state, { otherCompanyId, companyId }) => {
    return connectionAdapter.updateOne(
      { id: createId(otherCompanyId, companyId), changes: { connected: true } },
      state,
    );
  }),
  on(connectionAcceptFailure, (state, { id }) => {
    return connectionAdapter.updateOne({ id, changes: { connected: false } }, state);
  }),
);

export function reducer(state = initialState, action: TypedAction<ConnectionActionType>): ConnectionState {
  return connectionReducer(state, action);
}
