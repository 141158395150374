import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Order, Property } from '@app/order/models';
import { UUID } from '@app/shared/models';

@Component({
  selector: 'tc-order-properties',
  templateUrl: './order-properties.component.html',
  styleUrls: ['./order-properties.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderPropertiesComponent {
  @Input()
  order?: Order;

  public get buyerId(): UUID | undefined {
    return this.order?.buyerOrder.companyId;
  }

  public get supplierId(): UUID | undefined {
    return this.order?.supplierOrder.companyId;
  }

  public get supplierProperties(): Property[] | undefined {
    return this.order?.supplierOrder.properties;
  }

  public get buyerProperties(): Property[] | undefined {
    return this.order?.buyerOrder.properties;
  }
}
