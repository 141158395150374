import { ChangeDetectionStrategy, Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  selectCachedExpandPanelContacts,
  selectCachedExpandPanelDestination,
  selectCachedExpandPanelDocuments,
  selectCachedExpandPanelInvoice,
  selectCachedExpandPanelNotes,
  selectCachedExpandPanelProperties,
  selectCachedExpandPanelSummary,
  selectCachedExpandPanelTerms,
  selectCachedOrderPanels,
} from '@app/cache/state/cache.selectors';
import { OrderState } from '@app/order/core/state/order.reducer';
import { MixpanelButtonClickEvents } from '@app/core/services/page-analytics/enums/mixpanel-button-click-events.enum';
import { Observable } from 'rxjs';
import { cachingExpandValue, updateOrderPanels } from '@app/cache/state/cache.actions';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { selectRoutedOrder } from '@app/order/core/state/order.selectors';
import { map } from 'rxjs/operators';
import { RoleManagerService } from '@app/order/routes/order-detail/services/role-manager.service';
import { AssignType } from '@app/order/routes/assign/state/assign.model';
import { assignStartSend } from '@app/order/routes/assign/state/assign.actions';
import { Order, StartRemoveOrderLabel } from '@app/order/models';
import { changePanelSort } from '@app/order/util/helper';
import { orderDetailRemoveLabel } from '@app/order/routes/order-detail/state/order-detail.actions';
import { selectAuthIdentityCompanyId } from '@app/auth/core/state/auth.selectors';

export enum OrderPanelType {
  SUMMARY = 'summary',
  NOTES = 'notes',
  PROPERTIES = 'properties',
  DESTINATION = 'destination',
  TERMS = 'terms',
  CONTACTS = 'contacts',
  INVOICE = 'invoice',
  DOCUMENTS = 'documents',
}

@Component({
  selector: 'tc-order-panels',
  templateUrl: './order-panels.component.html',
  styleUrls: ['./order-panels.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderPanelsComponent {
  public readonly expandPanelSummary$ = this.store$.pipe(select(selectCachedExpandPanelSummary));
  public readonly expandPanelNotes$ = this.store$.pipe(select(selectCachedExpandPanelNotes));
  public readonly expandPanelProperties$ = this.store$.pipe(select(selectCachedExpandPanelProperties));
  public readonly expandPanelDestination$ = this.store$.pipe(select(selectCachedExpandPanelDestination));
  public readonly expandPanelTerms$ = this.store$.pipe(select(selectCachedExpandPanelTerms));
  public readonly expandPanelDocuments$ = this.store$.pipe(select(selectCachedExpandPanelDocuments));
  public readonly expandPanelContacts$ = this.store$.pipe(select(selectCachedExpandPanelContacts));
  public readonly expandPanelInvoice$ = this.store$.pipe(select(selectCachedExpandPanelInvoice));

  public mixpanelClickEvents = MixpanelButtonClickEvents;
  public readonly panelType = OrderPanelType;

  public actingAsCompanyId$ = this.store$.pipe(select(selectAuthIdentityCompanyId));

  public panelsConfig$: Observable<Record<OrderPanelType, number>> = this.store$.pipe(select(selectCachedOrderPanels));
  public order$ = this.store$.pipe(select(selectRoutedOrder));
  public role$ = this.roleManagerService.appliedRole$.pipe(
    map(([role]) => {
      return role;
    }),
  );

  constructor(private store$: Store<OrderState>, private roleManagerService: RoleManagerService) {}

  togglePanel(panelType: OrderPanelType, oldValue: boolean) {
    this.store$.dispatch(cachingExpandValue({ path: panelType, value: !oldValue }));
  }

  public onAssign(actionType: AssignType.ASSIGN | AssignType.REASSIGN, order: Order): void {
    this.store$.dispatch(
      assignStartSend({
        actionType,
        orderId: order.id,
        version: order.version,
      }),
    );
  }

  onRemoveLabel(data: StartRemoveOrderLabel) {
    this.store$.dispatch(orderDetailRemoveLabel(data));
  }

  drop(event: CdkDragDrop<void>, config: Record<OrderPanelType, number>) {
    const value = changePanelSort(event.item.data, event.currentIndex - event.previousIndex, config);
    this.store$.dispatch(updateOrderPanels({ value }));
  }
}
