import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WebSocketService } from '@app/core/services/web-socket/web-socket.service';
import { paramsFromObject } from '@app/util/http';
import {
  Company,
  CompanyQuery,
  CompanySearchResponse,
  CompanyWorkflowSettings,
  UpdateCompany,
} from '@app/company/models';
import { API } from '@app/shared/api/api.service';
import { Observable } from 'rxjs';
import { TaskOverdueSettings } from '@app/company/routes/settings/components/task-reminder/task-reminder.model';
import {
  OrderOverdueDelaySettings,
  OverdueDelay,
} from '@app/company/routes/settings/components/task-overdue/task-overdue-delay.model';

@Injectable()
export class CompanyService {
  constructor(private http: HttpClient, private webSocketService: WebSocketService, private api: API) {}

  public getById$(id: string): Observable<Company> {
    return this.http.get<Company>(this.api.COMPANY_ID(id));
  }

  public update$(id: string, body: UpdateCompany): Observable<{ ok: boolean }> {
    return this.http.put<{ ok: boolean }>(this.api.COMPANY_ID(id), body);
  }

  public updateName$(id: string, body: Pick<Company, 'name'>): Observable<{ ok: boolean }> {
    return this.http.patch<{ ok: boolean }>(this.api.COMPANY_PATCH_NAME(id), body);
  }

  public enforce2FA$(id: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(this.api.COMPANY_ENFORCE_2FA(id), {});
  }

  public unrestrict2FA$(id: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(this.api.COMPANY_UNRESTRICT_2FA(id), {});
  }

  public updateWorkflowSettings$(id: string, body: any): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(this.api.WORKFLOW_COMPANY_SETTINGS(id), body);
  }

  public updateWorkflowSettingsOverdue$(id: string, body: TaskOverdueSettings): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(this.api.WORKFLOW_COMPANY_SETTINGS_OVERDUE(id), body);
  }

  public updateCompanySettingsOverdueDelay$(id: string, body: OrderOverdueDelaySettings): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(this.api.ORDER_COMPANY_SETTINGS_OVERDUE(id), body);
  }

  public getWorkflowSettings$(id: string): Observable<CompanyWorkflowSettings> {
    return this.http.get<CompanyWorkflowSettings>(this.api.WORKFLOW_COMPANY_SETTINGS(id));
  }

  public getOrderOverdueDelay$(id: string): Observable<OverdueDelay> {
    return this.http.get<OverdueDelay>(this.api.ORDER_COMPANY_SETTINGS_OVERDUE(id));
  }

  public enableCompanyAsSupplier$(id: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(this.api.COMPANY_AUTHORIZE_AS_SUPPLIER(id), {});
  }

  public disableCompanyAsSupplier$(id: string): Observable<{ ok: boolean }> {
    return this.http.delete<{ ok: boolean }>(this.api.COMPANY_AUTHORIZE_AS_SUPPLIER(id));
  }

  public websocket$(companyId: string): Observable<Company> {
    const params = paramsFromObject({ companyId });

    return this.webSocketService.createWebSocket$<Company>(this.api.COMPANY_WS(), params);
  }

  public searchById$(id: string): Observable<Company> {
    return this.http.get<Company>(this.api.COMPANY_SEARCH_ID(id));
  }

  public query$(body: CompanyQuery): Observable<CompanySearchResponse> {
    return this.http.post<CompanySearchResponse>(this.api.COMPANY_SEARCH(), body);
  }
}
