import { Pipe, PipeTransform } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Order } from '@app/order/models';
import { isNotNullOrUndefined } from '@app/util/operators/is-not-null-or-undefined';
import { orderFetch } from '@app/order/core/state/order.actions';
import { OrderState } from '@app/order/core/state/order.reducer';
import { selectOrderById } from '@app/order/core/state/order.selectors';

@Pipe({
  name: 'tcLazyOrder',
})
export class LazyOrderPipe implements PipeTransform {
  constructor(private store$: Store<OrderState>) {}

  // eslint-disable-next-line
  transform(id?: string, silent = true): Observable<Order> {
    if (!id) {
      throw new Error('LazyOrderPipe can only be used with Order UUID');
    }

    this.store$.dispatch(orderFetch({ id, silent }));

    return this.store$.pipe(select(selectOrderById(id)), isNotNullOrUndefined());
  }
}
