import { createFeatureSelector, createSelector } from '@ngrx/store';
import { orderLineAdapter, OrderLineState } from './order-line.reducer';

export const orderLineFeatureName = 'orderLine';

const selectState = createFeatureSelector<OrderLineState>(orderLineFeatureName);

const { selectEntities, selectIds } = orderLineAdapter.getSelectors(selectState);

export const selectOrderLineById = (id: string) => {
  return createSelector(selectEntities, entities => {
    return entities[id];
  });
};
export const selectOrderLineIds = selectIds;
