import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API } from '@app/shared/api/api.service';
import { reviveDates } from '@app/util/revive-dates';

/**
 * Data returned from the API contains dates formatted as strings. This
 * interceptor transforms those string into Date objects using a reviver
 * function.
 */

@Injectable()
export class DateInterceptor implements HttpInterceptor {
  constructor(private api: API) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.startsWith(this.api.MAIN)) {
      return next.handle(request);
    }

    return next.handle(request).pipe(
      map(event => {
        if (!(event instanceof HttpResponse) || !event.body || event.body instanceof ArrayBuffer) {
          return event;
        }

        const body = reviveDates(event.body);

        return event.clone({ body });
      }),
    );
  }
}
