import { NgModule } from '@angular/core';
import { CompanySharedModule } from '@app/company/shared/company-shared.module';
import { RootSharedModule } from '@app/shared/root-shared.module';
import { UserSharedModule } from '@app/user/shared/user-shared.module';
import { RfqDetailsComponent } from '@app/rfq/private/components/rfq-details/rfq-details.component';
import { OrderSharedModule } from '@app/order/shared/order-shared.module';

const components = [RfqDetailsComponent];

@NgModule({
  imports: [RootSharedModule, CompanySharedModule, UserSharedModule, OrderSharedModule],
  declarations: [components],
  exports: [components],
})
export class RfqSharedModule {}
