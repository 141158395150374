import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { map, take, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { ForecastSearchService } from '@app/forecast/core/services/forecast-search.service';
import { select, Store } from '@ngrx/store';
import { selectAuthIdentityCompanyId } from '@app/auth/core/state/auth.selectors';
import { OrderListState } from '@app/order/routes/order-list/state/order-list.reducer';
import { selectCompanyOrderRoleFromIdentity } from '@app/company/core/state';
import { isNotNullOrUndefined } from '@app/util/operators/is-not-null-or-undefined';
import { CompanyRole } from '@app/company/models';
import { ColDef, GridApi, GridReadyEvent, IDatasource, IGetRowsParams, RowModelType } from 'ag-grid-community';
import { ForecastLine, ForecastSearch } from '@app/forecast/models';
import { DateTime, Interval } from 'luxon';
import { combineLatest, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';

export interface ForecastRow {
  companyName: string;
  forecastNumber: string;
  itemNumber: string;
  itemName: string;
  issueDate: string;
  schedule: {
    [key: string]: {
      value: number;
      days: number;
      date: string;
    };
  };
}

@Component({
  selector: 'tc-forecast-page',
  templateUrl: './forecast-page.component.html',
  styleUrls: ['./forecast-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForecastPageComponent implements OnDestroy, OnInit {
  constructor(
    private fb: UntypedFormBuilder,
    private forecastSearchService: ForecastSearchService,
    private store$: Store<OrderListState>,
    private route: ActivatedRoute,
  ) {}

  private destroyed$ = new Subject<void>();
  public companyId$ = this.store$.pipe(select(selectAuthIdentityCompanyId));
  public companyRole$ = this.store$.pipe(select(selectCompanyOrderRoleFromIdentity)).pipe(
    isNotNullOrUndefined(),
    tap(role => {
      this.isBuyer = role === CompanyRole.BUYER;
    }),
  );

  isBuyer = true;

  public readonly forecastNumberChangedPlaceholderLabel = $localize`:@@forecast-list.search.placeholder:Type to search for forecasts...`;
  public readonly itemPlaceholderLabel = $localize`:@@forecast-list.search.items.placeholder:Type to search for items...`;
  public form = this.fb.group({
    forecastNumber: null,
    itemNumber: null,
    companyId: null,
  });

  query!: any;

  public readonly textBuyerRole = $localize`:@@ts.common.buyer:Buyer`;
  public readonly textSupplierRole = $localize`:@@ts.common.supplier:Supplier`;
  dynamicalCreatedColumns: any;

  public columnDefs = [
    {
      field: 'companyName',
      pinned: 'left',
      lockPosition: 'right',
      headerName: 'Company',
    },
    {
      field: 'forecastNumber',
      pinned: 'left',
      lockPosition: 'right',
      headerName: 'Forecast number',
    },
    {
      field: 'itemName',
      cellRenderer: (params: any) => {
        if (!params?.data) {
          return;
        }
        const [name = '', purchaseUnitOfMeasureIso = ''] = params.data.itemName.split('#');

        return `<div class="ag-cell-both__small">
                <p>${name}<p/>
                <span>${purchaseUnitOfMeasureIso}<span/>
            </div>`;
      },
      pinned: 'left',
      headerName: 'Item name',
      lockPosition: 'right',
    },
    {
      field: 'itemNumber',
      cellRenderer: (params: any) => {
        if (!params?.data) {
          return;
        }
        const [userRoleNumber = '', oppositeRoleNumber = ''] = params.data.itemNumber.split('#');

        return `<div class="ag-cell-both__small">
                <p>${userRoleNumber}<p/>
                <span>${oppositeRoleNumber}<span/>
            </div>`;
      },
      pinned: 'left',
      lockPosition: 'right',
      headerName: 'Item number',
    },
    {
      field: 'issueDate',
      headerName: 'Issue date',
      minWidth: 80,
      lockPosition: 'right',
    },
  ];
  public defaultColDef: ColDef = {
    flex: 1,
    width: 150,
    resizable: true,
  };
  public rowBuffer = 0;
  public colResizeDefault: 'shift' = 'shift';
  public rowSelection: 'single' | 'multiple' = 'multiple';
  public rowModelType: RowModelType = 'infinite';
  public cacheBlockSize = 10;
  public cacheOverflowSize = 0;
  public maxConcurrentDatasourceRequests = 1;
  public infiniteInitialRowCount = 10;
  public maxBlocksInCache = 10;

  forecastQuery: ForecastSearch = {
    offset: 0,
    limit: this.cacheBlockSize,
  };
  forecastQueries: string[] = [];

  initialDataSource!: IDatasource;
  private gridApi!: GridApi<ForecastRow>;

  ngOnInit(): void {
    if (this.route.snapshot.queryParamMap.has('forecastNumber')) {
      const forecastNumber = this.route.snapshot.queryParamMap.get('forecastNumber')!;
      this.forecastQueries = [forecastNumber];
      this.form.patchValue({ forecastNumber }, { emitEvent: false });
    }
    this.form.valueChanges
      .pipe(
        withLatestFrom(this.companyRole$, this.companyId$),
        map(([form, role, companyId]) => {
          if (role === CompanyRole.BUYER) {
            return {
              queries: {
                forecastNumber: form.forecastNumber,
                buyerItemNumber: form.itemNumber,
              },
              filters: {
                buyerForecast: {
                  companyId: companyId,
                },
                supplierForecast: {
                  companyId: form.companyId,
                },
              },
              offset: 0,
              limit: this.cacheBlockSize,
            };
          }
          if (role === CompanyRole.SUPPLIER) {
            return {
              queries: {
                forecastNumber: form.forecastNumber,
                supplierItemNumber: form.itemNumber,
              },
              filters: {
                buyerForecast: {
                  companyId: form.companyId,
                },
                supplierForecast: {
                  companyId: companyId,
                },
              },
              offset: 0,
              limit: this.cacheBlockSize,
            };
          }

          return {
            filters: {
              buyerForecast: {
                companyId: companyId,
              },
            },
            offset: 0,
            limit: this.cacheBlockSize,
          };
        }),
        tap(data => {
          this.forecastQuery = data as ForecastSearch;
          // save query
          this.query = data;
          this.redraw();
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onGridReady(params: GridReadyEvent<ForecastRow>) {
    this.gridApi = params.api;
    const columnApi = params.columnApi;

    let previousForecastQuery: ForecastSearch;

    combineLatest([this.companyId$.pipe(isNotNullOrUndefined()), this.companyRole$])
      .pipe(take(1))
      .subscribe(([userCompanyId, role]) => {
        if (role === CompanyRole.BUYER) {
          this.forecastQuery = {
            queries: {
              forecastNumber: this.form.value.forecastNumber,
            },
            filters: {
              buyerForecast: {
                companyId: userCompanyId,
              },
            },
            offset: 0,
            limit: this.cacheBlockSize,
          };
        }

        if (role === CompanyRole.SUPPLIER) {
          this.forecastQuery = {
            queries: {
              forecastNumber: this.form.value.forecastNumber,
            },
            filters: {
              supplierForecast: {
                companyId: userCompanyId,
              },
            },
            offset: 0,
            limit: this.cacheBlockSize,
          };
        }
        const dataSource: IDatasource = {
          rowCount: undefined,
          getRows: (params: IGetRowsParams) => {
            this.forecastQuery.offset = params.startRow;
            this.forecastSearchService
              .query$(this.forecastQuery)
              .pipe(takeUntil(this.destroyed$))
              .subscribe(response => {
                if (response.firstStartDate) {
                  if (isFilterChanged(previousForecastQuery, this.forecastQuery)) {
                    this.dynamicalCreatedColumns = this.calculateMonthRange(
                      response.firstStartDate,
                      response.lastEndDate,
                    );

                    const newCols = this.dynamicalCreatedColumns;
                    this.gridApi.setColumnDefs([
                      // @ts-ignore
                      ...this.columnDefs,
                      ...newCols,
                    ]);
                  }
                  previousForecastQuery = this.forecastQuery;
                }

                const rowData = this.mapDataToTableRow(response.data);
                const rowsThisPage = rowData;
                // if on or after the last page, work out the last row.
                let lastRow = -1;

                if (response.total <= params.endRow) {
                  lastRow = response.total;
                }
                params.successCallback(rowsThisPage, lastRow);
                columnApi.autoSizeAllColumns();
              });
          },
        };
        this.initialDataSource = dataSource;
        params.api.setDatasource(dataSource);
      });
  }

  redraw() {
    this.gridApi.setDatasource(this.initialDataSource);
  }

  calculateMonthRange(firstStartDate: Date, lastEndDate: Date) {
    const startDate = DateTime.fromJSDate(firstStartDate);
    const endDate = DateTime.fromJSDate(lastEndDate);

    const interval = Interval.fromDateTimes(startDate, endDate);

    const amountMonths = Math.ceil(interval.length('months'));
    const listWithOffsetMonths = Array(amountMonths)
      .fill(null)
      .map((_, i) => {
        return i;
      });
    const columnsNames = listWithOffsetMonths.map(shiftMonth => {
      return startDate.plus({ months: shiftMonth });
    });

    const agridColumns = columnsNames.map(monthDate => {
      const monthKey = monthDate.toFormat('yyyy-MM');

      return {
        field: `schedule.${monthKey}.value`,
        lockPosition: 'right',
        headerName: monthDate.month === 1 ? `${monthDate.monthLong} ${monthDate.year}` : monthDate.monthLong, // show 2023 kan
        headerClass: 'ag-table-header',
        minWidth: 80,
        suppressSizeToFit: false,
      };
    });

    return agridColumns;
  }

  public forecastNumberChanged({ query }: { query: string }): void {
    const forecastNumberControl = this.form.controls.forecastNumber;
    forecastNumberControl.patchValue(query);
  }

  mapDataToTableRow(response: ForecastLine[]): ForecastRow[] {
    return response.map(forecast => {
      // @ts-ignore
      const schedule = forecast.buyerLine.schedule
        .map(schedule => {
          // @ts-ignore
          const startDate = DateTime.fromJSDate(schedule.startDate);
          // @ts-ignore
          const endDate = DateTime.fromJSDate(schedule.endDate);
          const interval = Interval.fromDateTimes(startDate, endDate);
          const length = interval.length('days');

          return {
            value: schedule.quantity,
            days: length,
            date: startDate.toFormat('yyyy-MM'),
          };
        })
        .reduce(
          (acc, mappedSchedule) => {
            const existDate = acc[mappedSchedule.date];
            if (existDate) {
              acc[mappedSchedule.date] = {
                value: existDate.value + mappedSchedule.value,
                days: existDate.days + mappedSchedule.days,
                date: existDate.date,
              };
            } else {
              acc[mappedSchedule.date] = mappedSchedule;
            }

            return acc;
          },
          {} as {
            [key: string]: {
              value: number;
              days: number;
              date: string;
            };
          },
        );

      const date = forecast.forecastMeta?.buyerMeta?.issueDateTime
        ? DateTime.fromJSDate((forecast.forecastMeta.buyerMeta.issueDateTime as unknown) as Date).toFormat('dd/MM/yyyy')
        : forecast.meta?.createdDateTime
        ? DateTime.fromJSDate((forecast.meta.createdDateTime as unknown) as Date).toFormat('dd/MM/yyyy')
        : '';

      return {
        companyName: this.isBuyer ? forecast.supplierForecast.companyName : forecast.buyerForecast.companyName,
        forecastNumber: forecast.buyerForecast.forecastNumber,
        issueDate: date,
        itemNumber: this.isBuyer
          ? `${forecast.buyerLine.item?.number || ''}#${forecast.buyerLine.item?.supplierItemNumber}`
          : `${forecast.buyerLine.item?.supplierItemNumber || ''}#${forecast.buyerLine.item?.number}`,
        itemName: `${forecast.buyerLine.item.name}#${forecast.buyerLine.item.purchaseUnitOfMeasureIso}`,
        schedule: schedule,
      };
    });
  }

  public itemNumberChanged({ query }: { query: string }): void {
    const itemNumberControl = this.form.controls.itemNumber;
    itemNumberControl.patchValue(query);
  }

  public companyIdChanged(data: { id: string; name: string } | undefined) {
    const companyIdControl = this.form.controls.companyId;
    companyIdControl.patchValue(data?.id || null);
  }
}

const isFilterChanged = (previous: ForecastSearch | undefined, current: ForecastSearch | undefined) => {
  return (
    !previous ||
    previous?.queries?.buyerItemNumber !== current?.queries?.buyerItemNumber ||
    previous?.queries?.forecastNumber !== current?.queries?.forecastNumber ||
    previous?.filters?.buyerForecast?.companyId !== current?.filters?.buyerForecast?.companyId ||
    previous?.filters?.supplierForecast?.companyId !== current?.filters?.supplierForecast?.companyId
  );
};
