import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Order } from '@app/order/models';

@Component({
  selector: 'tc-order-invoice',
  templateUrl: './order-invoice.component.html',
  styleUrls: ['./order-invoice.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderInvoiceComponent {
  @Input()
  order?: Order;

  public get buyerParty() {
    return this.order?.buyerOrder?.buyerAccountingParty;
  }
}
