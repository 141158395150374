import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { UserService } from './services/user.service';
import { reducer } from './state/user.reducer';
import { userFeatureName } from './state/user.selectors';

@NgModule({
  imports: [StoreModule.forFeature(userFeatureName, reducer)],
  providers: [UserService],
})
export class UserCoreModule {}
