import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'tc-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateComponent {
  @Input()
  date?: Date;

  @Input()
  format!: string;

  public get dateISO(): string | undefined {
    return this.date && this.date.toISOString();
  }
}
