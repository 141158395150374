import { RFQLine } from '@app/rfq/models/rfq-line.model';

export const rfqLineMock: RFQLine[] = [
  {
    id: '09484ff6-e0f0-133b-819f-5fa3ed780726-15-#47039733-10',
    position: '10',
    category: 'shackles',
    item: {
      number: 'ALNA.225',
      name: 'Double Jaw Swivel Shackle 4.30 INCH',
      purchaseUnitOfMeasureIso: 'PCE',
      dimensions: [],
    },
    deliverySchedule: [
      {
        position: '01',
        date: new Date('2021-10-31T00:00:00.000Z'),
        quantity: 500,
      },
    ],
    prices: {
      grossPrice: {
        priceInTransactionCurrency: {
          value: 10,
          currencyIso: 'EUR',
        },
        priceInBaseCurrency: {
          value: 10,
          currencyIso: 'EUR',
        },
      },
      discountPercentage: 10,
      netPrice: {
        priceInTransactionCurrency: {
          value: 9,
          currencyIso: 'EUR',
        },
        priceInBaseCurrency: {
          value: 9,
          currencyIso: 'EUR',
        },
      },
      priceUnitOfMeasureIso: 'KG',
      priceUnitQuantity: 1,
    },
    leadAt: '5 days',

    indicators: {
      deliveryOverdue: false,
    },
    status: {
      processStatus: 'Submitted',
      logisticsStatus: 'Open',
    },
    eventDates: {
      issuedAt: new Date('2020-09-22T14:43:22.000Z'),
    },

    purchaseOrderNumber: '95-#84369137',

    orderId: '09484ff6-e0f0-133b-819f-5fa3ed780726-15-#47039733',
    endAt: new Date('2020-09-29T14:43:22.000Z'),

    supplierOrder: {
      companyId: '875a9a3b-e134-5515-9b25-2a33d60604f4',
      name: 'Sail - Hardware Store',
      buyerAccountNumber: '',
      description: 'Sales order description',
      properties: [],
      documents: [],
      notes: [],
    },
  },
  {
    id: '09484ff6-e0f0-133b-819f-5fa3ed780726-15-#47039733-20',
    position: '20',
    category: 'shackles',
    item: {
      dimensions: [],
      number: 'ALNA.174',
      name: 'ALLEN BROTHERS 50MM INGLEFIELD CLIP (SPINNAKER)',
      purchaseUnitOfMeasureIso: 'PCE',
    },
    deliverySchedule: [
      {
        position: '01',
        date: new Date('2021-10-31T00:00:00.000Z'),
        quantity: 100,
      },
    ],
    prices: {
      grossPrice: {
        priceInTransactionCurrency: {
          value: 20,
          currencyIso: 'EUR',
        },
      },
      discountPercentage: 5,
      netPrice: {
        priceInTransactionCurrency: {
          value: 19,
          currencyIso: 'EUR',
        },
      },
      priceUnitOfMeasureIso: 'PCE',
      priceUnitQuantity: 100,
    },
    leadAt: '7 days',

    indicators: {
      deliveryOverdue: false,
    },
    status: {
      processStatus: 'Submitted',
      logisticsStatus: 'Open',
    },
    eventDates: {
      issuedAt: new Date('2020-09-24T14:43:22.000Z'),
    },

    purchaseOrderNumber: '95-#84369137',

    orderId: '09484ff6-e0f0-133b-819f-5fa3ed780726-15-#47039733',
    endAt: new Date('2020-10-01T14:43:22.000Z'),

    supplierOrder: {
      companyId: '875a9a3b-e134-5515-9b25-2a33d60604f4',
      name: 'Sail - Hardware Store',
      buyerAccountNumber: '',
      description: 'Sales order description',
      properties: [],
      documents: [],
      notes: [],
    },
  },
  {
    id: '09484ff6-e0f0-510b-819f-5fa3ed780726-15-#47039733-20',
    position: '20',
    category: 'shackles',
    item: {
      number: 'ALNA.174',
      name: 'ALLEN BROTHERS 50MM INGLEFIELD CLIP (SPINNAKER)',
      purchaseUnitOfMeasureIso: 'PCE',
      dimensions: [],
    },
    deliverySchedule: [
      {
        position: '01',
        date: new Date('2021-10-31T00:00:00.000Z'),
        quantity: 100,
      },
    ],
    prices: {
      grossPrice: {
        priceInTransactionCurrency: {
          value: 11,
          currencyIso: 'EUR',
        },
      },
      discountPercentage: 1,
      netPrice: {
        priceInTransactionCurrency: {
          value: 22,
          currencyIso: 'EUR',
        },
      },
      priceUnitOfMeasureIso: 'PCE',
      priceUnitQuantity: 100,
    },
    leadAt: '4 days',

    indicators: {
      deliveryOverdue: false,
    },
    status: {
      processStatus: 'Issued',
      logisticsStatus: 'Open',
    },
    eventDates: {
      issuedAt: new Date('2020-09-17T14:43:22.000Z'),
    },

    purchaseOrderNumber: '12-#4993090',

    orderId: '09484ff6-e0f0-510b-819f-5fa3ed780726-15-#47039733',
    endAt: new Date('2020-09-24T14:43:22.000Z'),

    supplierOrder: {
      companyId: '875a9a3b-e134-5515-9b25-2a33d60604f4',
      name: 'Sail - Hardware Store',
      buyerAccountNumber: '',
      description: 'Sales order description',
      properties: [],
      documents: [],
      notes: [],
    },
  },
  {
    id: '09484ff6-e0f0-111b-819f-5fa3ed780726-15-#47039733-10',
    position: '10',
    category: 'Electronics',
    item: {
      number: '054-4628',
      revision: 'Revision 12.8',
      name: 'MagiDeal Sea Marine Electronic Digital Compass Boat Car Compass 12V LED',
      purchaseUnitOfMeasureIso: 'PCE',
      dimensions: [],
    },
    deliverySchedule: [
      {
        position: '01',
        date: new Date('2021-10-31T00:00:00.000Z'),
        quantity: 4,
      },
    ],
    prices: {
      grossPrice: {
        priceInTransactionCurrency: {
          value: 89,
          currencyIso: 'EUR',
        },
      },
      discountPercentage: 0,
      netPrice: {
        priceInTransactionCurrency: {
          value: 89,
          currencyIso: 'EUR',
        },
      },
      priceUnitOfMeasureIso: 'PCE',
      priceUnitQuantity: 1,
    },
    leadAt: '24 days',

    indicators: {
      deliveryOverdue: false,
    },
    status: {
      processStatus: 'Submitted',
      logisticsStatus: 'Open',
    },
    eventDates: {
      issuedAt: new Date('2020-09-17T14:43:22.000Z'),
    },

    purchaseOrderNumber: '12-#11110900',

    orderId: '09484ff6-e0f0-111b-819f-5fa3ed780726-15-#47039733',
    endAt: new Date('2020-09-24T14:43:22.000Z'),

    supplierOrder: {
      companyId: '875a9a3b-e134-5515-9b25-2a33d60604f4',
      name: 'Blue sailboats',
      buyerAccountNumber: '',
      description: 'Sales order description',
      properties: [],
      documents: [],
      notes: [],
    },
  },
  {
    id: '09484ff6-e0f0-122b-819f-5fa3ed780726-15-#47039733-20',
    position: '20',
    category: 'Electronics',
    item: {
      number: '334-9965',
      revision: 'Revision v3',
      name: 'SeaSense LED Adjustable Stern Light, 26-48-Inch',
      purchaseUnitOfMeasureIso: 'PCE',
      dimensions: [],
    },
    deliverySchedule: [
      {
        position: '01',
        date: new Date('2021-10-31T00:00:00.000Z'),
        quantity: 16,
      },
    ],
    prices: {
      grossPrice: {
        priceInTransactionCurrency: {
          value: 40,
          currencyIso: 'EUR',
        },
      },
      discountPercentage: 10,
      netPrice: {
        priceInTransactionCurrency: {
          value: 36,
          currencyIso: 'EUR',
        },
      },
      priceUnitOfMeasureIso: 'PCE',
      priceUnitQuantity: 1,
    },
    leadAt: '15 days',

    indicators: {
      deliveryOverdue: false,
    },
    status: {
      processStatus: 'Submitted',
      logisticsStatus: 'Open',
    },
    eventDates: {
      issuedAt: new Date('2020-09-18T14:43:22.000Z'),
    },

    purchaseOrderNumber: '08-#86430153',

    orderId: '09484ff6-e0f0-122b-819f-5fa3ed780726-15-#47039733',
    endAt: new Date('2020-09-25T14:43:22.000Z'),

    supplierOrder: {
      companyId: '875a9a3b-e134-5515-9b25-2a33d60604f4',
      name: 'Blue sailboats',
      buyerAccountNumber: '',
      description: 'Sales order description',
      properties: [],
      documents: [],
      notes: [],
    },
  },
  {
    id: '09484ff6-e0f0-133b-819f-5fa3ed780726-15-#47039733-30',
    position: '30',
    category: 'Electronics',
    item: {
      number: '334-9966',
      revision: 'Revision v3',
      name: 'SeaSense LED Adjustable Stern Light, 48-64 -Inch',
      purchaseUnitOfMeasureIso: 'PCE',
      dimensions: [],
    },
    deliverySchedule: [
      {
        position: '01',
        date: new Date('2100-10-10T00:00:00.000Z'),
        quantity: 16,
      },
    ],
    prices: {
      grossPrice: {
        priceInTransactionCurrency: {
          value: 14,
          currencyIso: 'EUR',
        },
      },
      discountPercentage: 10,
      netPrice: {
        priceInTransactionCurrency: {
          value: 9,
          currencyIso: 'EUR',
        },
      },
      priceUnitOfMeasureIso: 'PCE',
      priceUnitQuantity: 1,
    },
    leadAt: '15 days',

    indicators: {
      deliveryOverdue: false,
    },
    status: {
      processStatus: 'Issued',
      logisticsStatus: 'Open',
      inProgressStatus: 'OpenSupplierReopenRequest',
    },
    eventDates: {
      issuedAt: new Date('2020-09-17T14:43:22.000Z'),
    },

    purchaseOrderNumber: '95-#84369137',

    orderId: '09484ff6-e0f0-133b-819f-5fa3ed780726-15-#47039733',
    endAt: new Date('2020-09-24T14:43:22.000Z'),

    supplierOrder: {
      companyId: '875a9a3b-e134-5515-9b25-2a33d60604f4',
      name: 'Blue sailboats',
      buyerAccountNumber: '',
      description: 'Blue sailboats',
      properties: [],
      documents: [],
      notes: [],
    },
  },
];
