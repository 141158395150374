import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import {
  AcceptConnection,
  Connection,
  ConnectionQuery,
  ConnectionQueryResponse,
  RequestConnection,
} from '@app/connection/models';
import { API } from '@app/shared/api/api.service';
import { UUID } from '@app/shared/models';
import { Observable } from 'rxjs';

@Injectable()
export class ConnectionService {
  constructor(private http: HttpClient, private api: API) {}

  public get$(companyId: UUID, otherCompanyId: UUID): Observable<Connection> {
    const encodedId = encodeURIComponent(companyId);

    const encodedOtherCompany = encodeURIComponent(otherCompanyId);

    return this.http.get<Connection>(this.api.COMPANY_CONNECTION_ID(encodedId, encodedOtherCompany));
  }

  public request$({ companyId, otherCompanyId, body }: RequestConnection): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(this.api.COMPANY_CONNECTION_ID_REQUEST(companyId, otherCompanyId), body);
  }

  public accept$({ companyId, otherCompanyId }: AcceptConnection): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(this.api.COMPANY_CONNECTION_ID_ACCEPT(companyId, otherCompanyId), null);
  }

  public search$({ companyId, body }: ConnectionQuery): Observable<ConnectionQueryResponse> {
    return this.http.post<ConnectionQueryResponse>(this.api.COMPANY_CONNECTION_SEARCH_QUERY(companyId), body);
  }
}
