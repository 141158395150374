<ng-container *ngIf="showExpand; else selectionList">
  <tc-expand-collapse [summary]="summary" i18n-summary="@@shared.table.summary">
    <ng-container *ngIf="canSelectAll">
      <mat-list-item class="select-all">
        <span i18n="@@table.select.all" [ngClass]="{bold: checked}">Select all</span>
        <mat-checkbox
          (change)="toggle($event.checked)"
          [checked]="checked"
        >
        </mat-checkbox>
      </mat-list-item>
      <mat-divider></mat-divider>
    </ng-container>
    <ng-container [ngTemplateOutlet]="selectionList"></ng-container>
  </tc-expand-collapse>
</ng-container>
<ng-template #selectionList>
  <mat-selection-list
    [disableRipple]="true"
    [sortablejs]="sortableModel"
    [sortablejsOptions]="{ handle: 'mat-icon' }"
    (selectionChange)="changeSelectionModel($event)"
    dense
  >
    <mat-list-option
      *ngFor="let column of orderedColumns; trackBy: trackByFn"
      [value]="column.columnDef"
      [selected]="column.show"
      class="noselect"
    >
      {{ column.title }}
      <mat-icon>reorder</mat-icon>
    </mat-list-option>
  </mat-selection-list>
</ng-template>
