import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { DownloadService } from './services/download.service';
import { ObjectStorageService } from './services/object-storage.service';
import { reducer } from './state/object-storage.reducer';
import { objectStorageFeatureName } from '@app/object-storage/core/state/object-storage.selectors';

@NgModule({
  imports: [StoreModule.forFeature(objectStorageFeatureName, reducer)],
  providers: [DownloadService, ObjectStorageService],
})
export class ObjectStorageCoreModule {}
