import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

export enum LogoColor {
  DEFAULT = 'default',
  WHITE = 'white',
}

@Component({
  selector: 'tc-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogoComponent {
  @HostBinding('class')
  @Input()
  color = LogoColor.DEFAULT;

  @HostBinding('class.has-tagline')
  @Input()
  tagline = false;
}
