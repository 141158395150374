import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
  public get<T>(key: string): T | null {
    const value = window.localStorage.getItem(key);

    if (!value) {
      return null;
    }

    return JSON.parse(value);
  }

  public set(key: string, value: any): void {
    window.localStorage.setItem(key, JSON.stringify(value));
  }

  public remove(key: string): void {
    window.localStorage.removeItem(key);
  }

  public clear(): void {
    window.localStorage.clear();
  }
}

export enum StorageKeys {
  MSAL_ACCOUNT = 'auth.msal.account.info',
  MSAL_EMAIL = 'auth.msal.email',
}
