import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, ViewChild } from '@angular/core';
import { ControlValueAccessor, ValidationErrors, Validator } from '@angular/forms';
import { MatChipEvent, MatChipList } from '@angular/material/chips';
import { getFormControlProviders } from '@app/shared/abstracts/form-control.component';
import { ChipDefDirective } from './chip-def.directive';

@Component({
  selector: 'tc-chip-list',
  templateUrl: './chip-list.component.html',
  styleUrls: ['./chip-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: getFormControlProviders(ChipListComponent),
})
export class ChipListComponent implements ControlValueAccessor, Validator {
  @ContentChild(ChipDefDirective)
  chipDef!: ChipDefDirective;

  @ViewChild(MatChipList)
  matChipList!: MatChipList;

  public items: string[] = [];

  private onChange?: (value: string[]) => void | undefined;

  constructor(private cd: ChangeDetectorRef) {}

  public onRemove(event: MatChipEvent): void {
    const newItems = this.items.filter(item => {
      return item !== event.chip.value;
    });
    this.updateItems(newItems);
  }

  /**
   * ControlValueAccessor implementation
   */
  writeValue(value: string[]): void {
    this.items = value;
    this.cd.markForCheck();
  }

  /**
   * ControlValueAccessor implementation
   */
  registerOnChange(fn: (value: string[]) => void): void {
    this.onChange = fn;
  }

  /**
   * ControlValueAccessor implementation
   */
  registerOnTouched(fn: any): void {
    // noop
  }

  /**
   * ControlValueAccessor implementation
   */
  setDisabledState(isDisabled: boolean): void {
    // noop
  }

  /**
   * Validator implementation
   */
  validate(): ValidationErrors | null {
    return {};
  }

  private updateItems(items: string[]): void {
    this.items = items;
    if (this.onChange) {
      this.onChange(items);
    }
  }
}
