<form class="card-form" [formGroup]="form" (ngSubmit)="submit()">
  <mat-card>
    <mat-card-title i18n="title @@auth.form.2fa">
      Verify Code
    </mat-card-title>
    <mat-card-content>
      <p i18n="text @@auth.form.2fa.explanation.device">Please enter the code generated by your authenticator app from your device</p>
      <p>
        <mat-form-field appearance="fill">
          <mat-label i18n="@@shared.2fa.code">Enter verification code</mat-label>
          <input type="text" matInput formControlName="code" tcAutofocus />
          <mat-error *tcMatFormFieldError="'identity.2fa.invalid_code'" i18n="error @@auth.form.2fa.invalid.code">
            Invalid Code
          </mat-error>
          <mat-error *tcMatFormFieldError="'identity.2fa.validation_error'" i18n="error @@auth.form.2fa.again.code">
            Try Again!
          </mat-error>
        </mat-form-field>
        <a mat-button color="primary" i18n="button @@auth.form.to.backup" (click)="switchToBackupForm()">Having problems? Use a backup code</a>
      </p>
      <p *ngIf="verifyErrorMessage">
        <mat-error *ngIf="!unlockedDate && !showAttempts">{{ verifyErrorMessage }}</mat-error>
        <mat-error *ngIf="showAttempts">{{ verifyErrorMessage }}, you have {{attempts}} attempts left</mat-error>
        <mat-error *ngIf="!showAttempts && unlockedDate">All your attempts failed, you can try again at {{unlockedDate | date:'HH:mm:ss'}}</mat-error>
      </p>
    </mat-card-content>
    <mat-card-actions>
      <a mat-button color="primary" (click)="goBackToLogin()" i18n="button @@auth.form.2fa.back">BACK</a>
      <button type="submit" mat-raised-button color="primary" [disabled]="form.disabled" i18n="button @@auth.form.2fa.submit">VERIFY</button>
    </mat-card-actions>
  </mat-card>
</form>
