<div class="vertical">
  <mat-list class="mat-list--properties mat-list--compact" fxLayout="row">
    <mat-list-item class="auto-height">
      <div class="value full">
        <ng-container *ngIf="buyerId | tcLazyCompany | async as company">
          <tc-company-name [company]="company"></tc-company-name>
        </ng-container>
      </div>
    </mat-list-item>
    <mat-list-item class="auto-height">
      <div class="value full">
        <ng-container *ngIf="supplierId | tcLazyCompany | async as company">
          <tc-company-name [company]="company"></tc-company-name>
        </ng-container>
      </div>
    </mat-list-item>
  </mat-list>
  <mat-list class="mat-list--properties mat-list--compact" fxLayout="row">
    <mat-list-item class="auto-height">
      <ng-container  *ngIf="buyerNotes?.length > 0">
        <div class="value full">
          <ng-container *ngFor="let note of buyerNotes">
            <p matLine class="wrap tc-formatted" [innerHTML]="note"></p>
          </ng-container>
        </div>
      </ng-container>
    </mat-list-item>
    <mat-list-item class="auto-height">
      <ng-container  *ngIf="supplierNotes?.length > 0">
        <div class="value full">
          <ng-container *ngFor="let note of supplierNotes">
            <p matLine class="wrap tc-formatted" [innerHTML]="note"></p>
          </ng-container>
        </div>
      </ng-container>
    </mat-list-item>
  </mat-list>
</div>
