import { Directive, HostBinding, Input } from '@angular/core';
import { CompanyRole } from '@app/company/models';
import { select, Store } from '@ngrx/store';
import { selectCompanyRoleFromIdentity } from '@app/company/core/state';
import { AuthState } from '@app/auth/core/state/auth.reducer';
import { first } from 'rxjs/operators';

const LABEL_SUPPLIER = $localize`:@@ts.act.supplier:as supplier`;
const LABEL_BUYER = $localize`:@@ts.act.buyer:as buyer`;

@Directive({
  selector: '[tcSuffixAct]',
})
export class SuffixActDirective {
  @Input()
  set tcSuffixAct(val: Partial<CompanyRole.BUYER | CompanyRole.SUPPLIER>) {
    this.calculateSuffix(val);
  }

  private suffixText = '';

  private roles$ = this.store$.pipe(select(selectCompanyRoleFromIdentity)).pipe();

  constructor(private store$: Store<AuthState>) {}

  private calculateSuffix(value: CompanyRole.BUYER | CompanyRole.SUPPLIER): void {
    this.roles$
      .pipe(
        first(r => {
          return !!r;
        }),
      )
      .subscribe(roles => {
        if (roles?.includes(CompanyRole.ACT_AS_SUPPLIER) && value) {
          if (value === CompanyRole.BUYER) {
            this.suffixText = ` ${LABEL_BUYER}`;
          }
          if (value === CompanyRole.SUPPLIER) {
            this.suffixText = ` ${LABEL_SUPPLIER}`;
          }
        } else {
          this.suffixText = '';
        }
      });
  }

  @HostBinding('attr.data-suffix') get suffix(): string {
    return this.suffixText;
  }
}
