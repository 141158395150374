import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Location, Order } from '@app/order/models';

@Component({
  selector: 'tc-order-destination',
  templateUrl: './order-destination.component.html',
  styleUrls: ['./order-destination.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderDestinationComponent {
  @Input()
  order?: Order;

  public get destination(): Location | undefined {
    return this.order?.buyerOrder?.destination;
  }
}
