import { createFeatureSelector, createSelector } from '@ngrx/store';

import { accountEntityAdapter, AccountState, createId } from './account.reducer';

export const accountFeatureName = 'account';

const selectState = createFeatureSelector<AccountState>(accountFeatureName);

const adapterSelectors = accountEntityAdapter.getSelectors(selectState);

export const selectIds = adapterSelectors.selectIds;
export const selectById = (companyId: string, accountCompanyId: string) => {
  const id = createId(companyId, accountCompanyId);

  return createSelector(adapterSelectors.selectEntities, entities => {
    return entities[id];
  });
};
export const selectPending = createSelector(selectState, ({ pending }: AccountState) => {
  return pending;
});
export const selectError = createSelector(selectState, ({ error }: AccountState) => {
  return error;
});
