import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { OrderLineSearchView } from '@app/order/models';

@Component({
  selector: 'tc-order-line-name',
  templateUrl: './order-line-name.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderLineNameComponent {
  @Input()
  orderLine?: OrderLineSearchView;

  public get name(): string {
    if (!this.orderLine) {
      return '';
    }

    const { purchaseOrderNumber } = this.orderLine.buyerOrder;
    const { position } = this.orderLine.buyerLine;

    return `${purchaseOrderNumber} - ${position}`;
  }
}
