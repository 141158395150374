import { NgModule } from '@angular/core';
import { RootSharedModule } from '@app/shared/root-shared.module';
import { AccountCodeComponent } from './components/account-code/account-code.component';
import { LazyAccountPipe } from './pipes/lazy-account.pipe';

const components = [AccountCodeComponent];
const pipes = [LazyAccountPipe];

@NgModule({
  imports: [RootSharedModule],
  declarations: [components, pipes],
  exports: [components, pipes],
})
export class AccountSharedModule {}
