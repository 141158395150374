import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { Subject } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { CachedExpandType, CacheState } from '@app/cache/state/cache.reducer';
import { selectCachedExpandSearch } from '@app/cache/state/cache.selectors';
import { take } from 'rxjs/operators';
import { cachingExpandValue } from '@app/cache/state/cache.actions';

@Component({
  selector: 'tc-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchBoxComponent implements OnDestroy {
  @Input()
  placeholderLabel: string = $localize`:@@order.search.placeholder:Type to search for orders...`;

  @Input()
  expanding = true;

  @Input()
  caching = false;

  @Input()
  set initialQuery(queries: string[]) {
    this.queries = queries.length ? [{ name: queries[0] }] : [];
  }

  @Output()
  readonly searchQueryChange = new EventEmitter<{ query: string }>();

  public readonly expandSearch$ = this.store$.pipe(select(selectCachedExpandSearch), take(1));
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  queries: { name: string }[] = [];

  private destroyed$ = new Subject<void>();

  constructor(private store$: Store<CacheState>) {}

  public cachingExpand(value: boolean): void {
    if (this.caching) {
      this.store$.dispatch(cachingExpandValue({ path: CachedExpandType.OrderSearch, value }));
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    // Add our query
    if ((value || '').trim()) {
      this.queries = [{ name: value.trim() }];
      this.searchQueryChange.emit({ query: value.trim() });
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(query: { name: string }): void {
    const index = this.queries.indexOf(query);

    if (index >= 0) {
      this.queries.splice(index, 1);
    }

    this.searchQueryChange.emit({ query: '' });
  }
}
