import { Pipe, PipeTransform } from '@angular/core';
import { BuyerDelivery, LineDelivery, OrderLine } from '@app/order/models';
import {
  hasOpenBuyerReopenRequest,
  hasOpenSupplierProposal,
  hasOpenSupplierReopenRequest,
} from '@app/order/util/helper';

@Pipe({
  name: 'tcMergeDeliverySchedule',
})
export class MergeDeliverySchedulePipe implements PipeTransform {
  transform(orderLine: OrderLine): LineDelivery[] {
    return (this.deliverySchedule(orderLine) as BuyerDelivery[]).map((value, index) => {
      return {
        ...orderLine.deliverySchedule[index],
        ...value,
      };
    });
  }

  public deliverySchedule(orderLine: OrderLine | undefined): BuyerDelivery[] | LineDelivery[] {
    if (orderLine) {
      if (hasOpenBuyerReopenRequest(orderLine)) {
        return orderLine.buyerLine.requests!.reopenRequest!.deliverySchedule;
      }

      if (hasOpenSupplierReopenRequest(orderLine)) {
        return orderLine.supplierLine.requests.reopenRequest!.deliverySchedule;
      }

      if (hasOpenSupplierProposal(orderLine)) {
        return orderLine.supplierLine.requests.proposal!.deliverySchedule;
      }

      return orderLine.deliverySchedule;
    }

    return [];
  }
}
