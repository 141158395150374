import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BuyerDelivery, BuyerItem, Prices } from '@app/order/models';
import { RFQLine } from '@app/rfq/models/rfq-line.model';

@Component({
  selector: 'tc-rfq-line-details',
  templateUrl: './rfq-line-details.component.html',
  styleUrls: ['./rfq-line-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RfqLineDetailsComponent {
  @Input()
  layout: 'column' | 'row' = 'column';

  @Input()
  line!: RFQLine;

  public readonly fxOptions = {
    column: { flex: '', gap: 'margin-bottom' },
    row: { flex: 50, gap: '40px' },
  };

  public get item(): BuyerItem {
    return this.line.item;
  }

  public get deliverySchedule(): BuyerDelivery[] {
    return this.line.deliverySchedule;
  }

  public get prices(): Prices | undefined {
    return this.line.prices;
  }
}
