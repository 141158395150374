import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BuyerDelivery, BuyerItem, OrderLine } from '@app/order/models';
import { compactLineDetailColumns } from '@app/order/shared/components/line-details/line-details.component';

@Component({
  selector: 'tc-line-delivery-history',
  templateUrl: './line-delivery-history.component.html',
  styleUrls: ['./line-delivery-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineDeliveryHistoryComponent {
  public get columnDefs(): string[] {
    return compactLineDetailColumns;
  }

  @Input()
  orderLine?: OrderLine;

  constructor() {}

  public get item(): BuyerItem | undefined {
    return this.orderLine?.buyerLine.item;
  }

  public get deliveryHistory(): BuyerDelivery[] | undefined {
    return this.orderLine?.confirmedLine?.deliveryHistory || this.orderLine?.buyerLine.deliveryHistory;
  }
}
