import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { CompanyState } from '@app/company/core/state/company.reducer';
import { ConnectionService } from '@app/connection/core/services/connection.service';
import { selectAuthIdentityCompanyId } from '@app/auth/core/state/auth.selectors';
import { isNotNullOrUndefined } from '@app/util/operators/is-not-null-or-undefined';
import { catchError, exhaustMap, map, publishReplay, refCount, take } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable()
export class CompanyLimitationService {
  public readonly connectionIds$: Observable<string[]>;

  constructor(private store$: Store<CompanyState>, private connectionService: ConnectionService) {
    const companyId$ = this.store$.pipe(select(selectAuthIdentityCompanyId)).pipe(isNotNullOrUndefined(), take(1));

    this.connectionIds$ = companyId$.pipe(
      exhaustMap(companyId => {
        return this.connectionService
          .search$({
            companyId,
            body: {
              connected: true,
              offset: 0,
              limit: 100,
            },
          })
          .pipe(
            map(response => {
              const connectionIds = response.data
                .map(connection => {
                  return [connection.requestingCompanyId, connection.acceptingCompanyId];
                })
                .flat();

              return [companyId, ...connectionIds];
            }),
            catchError(() => {
              return of([companyId]);
            }),
          );
      }),
      publishReplay(1),
      refCount(),
    );
  }
}
