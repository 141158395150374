import { Directive, HostListener, Input } from '@angular/core';

import { PageAnalyticsService } from '@app/core/services/page-analytics/page-analytics.service';

@Directive({
  selector: '[tcTrackClick]',
})
export class TrackClickDirective {
  @Input('tcTrackClick')
  parameters: { [index: string]: any } = {};

  constructor(private pageAnalytics: PageAnalyticsService) {}

  @HostListener('click')
  handleClick(): void {
    const { eventName, ...forwardedParameters } = this.parameters;
    this.pageAnalytics.track(eventName || 'Click', forwardedParameters);
  }
}
