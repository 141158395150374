import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Order } from '@app/order/models';
import { OrderActionType, orderAddMany, orderUpsert, orderWebSocketUpsert } from './order.actions';
import { TypedAction } from '@ngrx/store/src/models';
import { createReducer, on } from '@ngrx/store';

export type OrderState = EntityState<Order>;

export const orderAdapter = createEntityAdapter<Order>({
  selectId: ({ id }) => {
    return id;
  },
});

export const initialState = orderAdapter.getInitialState();

const orderReducer = createReducer<OrderState, TypedAction<OrderActionType>>(
  initialState,
  on(orderUpsert, orderWebSocketUpsert, (state, { entity }) => {
    return orderAdapter.upsertOne(entity, state);
  }),
  on(orderAddMany, (state, { entities }) => {
    return orderAdapter.addMany(entities, state);
  }),
);

export function reducer(state = initialState, action: TypedAction<OrderActionType>): OrderState {
  return orderReducer(state, action);
}
