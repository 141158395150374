import { Pipe, PipeTransform } from '@angular/core';
// @ts-ignore
const has = require('has-value');

@Pipe({
  name: 'tcHasAnyValues',
})
export class HasAnyValuesPipe implements PipeTransform {
  transform(value: Record<string, unknown> | undefined | null, key: string): unknown {
    return has(value, key);
  }
}
