import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserInterface } from '@app/user/models';

@Component({
  selector: 'tc-user-name',
  templateUrl: './user-name.component.html',
  styleUrls: ['./user-name.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserNameComponent {
  @Input()
  user?: UserInterface;

  @Input()
  fixWidth?: boolean;

  public get name(): string | undefined {
    return this.user && `${this.user.profile.firstName} ${this.user.profile.lastName || ''}`;
  }
}
