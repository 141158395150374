<div fxLayout class="vertical">
  <mat-list fxFlex="50" class="mat-list--properties mat-list--compact">
    <mat-list-item *ngIf="deliveryHistory && deliveryHistory.length > 1" class="auto-height">
      <div class="property" i18n="@@order.line.details.delivered.quantity">Delivered quantity</div>
      <div class="value wrap">
        {{ deliveryHistory | tcTotalQuantity | number }}
        {{ item?.purchaseUnitOfMeasureIso | tcUnitSymbol }}
      </div>
    </mat-list-item>
  </mat-list>
</div>
<mat-table class="compact-table" [dataSource]="deliveryHistory" *ngIf="deliveryHistory && deliveryHistory.length > 0">
  <ng-container matColumnDef="position">
    <mat-header-cell *matHeaderCellDef i18n="as in the position of an order line in an order @@common.position">Position</mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.position}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="date">
    <mat-header-cell *matHeaderCellDef i18n="@@common.date">Date</mat-header-cell>
    <mat-cell *matCellDef="let element; let i = index;">
      <span class="delivery-date">{{element.date | date:'dd/MM/yyyy'}}</span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="quantity">
    <mat-header-cell *matHeaderCellDef i18n="@@common.quantity">Quantity</mat-header-cell>
    <mat-cell *matCellDef="let element; let i = index;">
          <span>
            <span>{{element.quantity | number}}</span>
            {{ item?.purchaseUnitOfMeasureIso | tcUnitSymbol }}
          </span>
    </mat-cell>

  </ng-container>

  <mat-header-row class="compact-row" *matHeaderRowDef="columnDefs"></mat-header-row>
  <mat-row class="compact" *matRowDef="let row; columns: columnDefs; let i = index;"></mat-row>
</mat-table>

