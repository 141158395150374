import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import {
  Action,
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
  MetaReducer,
} from '@ngrx/store';
import { isLogoutAction } from '../auth/core/state/auth.selectors';
import { RouterState } from './router-serializer';

/**
 * As mentioned, we treat each reducer like a table in a database. This means
 * our top level state interface is just a map of keys to inner state types.
 */
export interface GlobalState {
  readonly router: RouterReducerState<RouterState>;
}

/**
 * Our state is composed of a map of action reducer functions.
 * These reducer functions are called with each dispatched action
 * and the current or initial state and return a new immutable state.
 */
export const routerFeatureName = 'router';

export const globalReducers: ActionReducerMap<GlobalState, any> = {
  router: routerReducer,
};

/**
 * This meta reducer resets the state on logout.
 */
export function logoutMetaReducer(reducer: ActionReducer<GlobalState>): ActionReducer<GlobalState> {
  return (state: GlobalState | undefined, action: Action) => {
    return reducer(isLogoutAction(action) ? undefined : state, action);
  };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<GlobalState>[] = [logoutMetaReducer];

/**
 * Router selectors
 */
export const selectRouterState = createFeatureSelector<RouterReducerState<RouterState>>(routerFeatureName);

export const selectRouterParams = createSelector(selectRouterState, router => {
  return router && router.state && router.state.params;
});

export const selectRouterQueryParams = createSelector(selectRouterState, router => {
  return router && router.state && (router.state.queryParams as { [key: string]: string | string[] });
});

export const selectRouterQueryPageSize = createSelector(selectRouterQueryParams, query => {
  if (!query || query.limit === undefined) {
    return 10;
  }

  return Number(query.limit);
});

export const selectRouterQueryPageIndex = createSelector(selectRouterQueryParams, query => {
  if (!query || query.limit === undefined || query.offset === undefined) {
    return 0;
  }

  return Number(query.offset) / Number(query.limit);
});

export const selectRouterPageQuery = createSelector(selectRouterQueryParams, query => {
  const offset = query && query.offset ? Number(query.offset) : 0;
  const limit = query && query.limit ? Number(query.limit) : 10;

  return { offset, limit };
});

// eslint-disable-next-line @typescript-eslint/ban-types
export const createPagedSliceSelector = <T>(selector: MemoizedSelector<{}, T[] | null>) => {
  return createSelector(selectRouterPageQuery, selector, ({ limit, offset }, data) => {
    return data && data.slice(offset, offset + limit);
  });
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const createPagedSliceSelectorWithoutPlaceholder = <T>(selector: MemoizedSelector<{}, T[] | null>) => {
  return createSelector(selectRouterPageQuery, selector, ({ limit, offset }, data) => {
    return data && data.slice(0, 0 + limit);
  });
};
