import { NgModule } from '@angular/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { RootSharedModule } from '@app/shared/root-shared.module';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { PieChartComponent } from './components/pie-chart/pie-chart.component';
import { SupplierReliabilityMetricComponent } from './components/supplier-reliability-metric/supplier-reliability-metric.component';

const components = [LineChartComponent, PieChartComponent, SupplierReliabilityMetricComponent];

@NgModule({
  imports: [RootSharedModule, HighchartsChartModule],
  declarations: [components],
  exports: [components],
})
export class AnalyticsSharedModule {}
