<form [formGroup]="form" (ngSubmit)="submit()">
  <h1 mat-dialog-title>
    <ng-container i18n="@@common.capitalize.remove">Remove</ng-container> "{{data.text}}" <ng-container i18n="@@common.from">from</ng-container> {{data.entityType}} {{ data.entityId }}
  </h1>

  <mat-dialog-content class="mat-typography tc-webkit-visible-scrollbars">
    <h3 i18n="Reason as a sub header in a dialog @@order.line.dialog.reason" class="label-reason">Reason</h3>
    <mat-form-field appearance="fill">
      <mat-label i18n="@@remove.dialog.input.label">Why are you removing this label?</mat-label>
      <textarea matInput formControlName="reason" matTextareaAutosize></textarea>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button color="primary" type="button" mat-dialog-close i18n="button @@common.btn.cancel">CANCEL</button>
    <button mat-button color="primary" type="submit" i18n="@@common.remove">REMOVE</button>
  </mat-dialog-actions>
</form>
