import { Directive, ElementRef, Input, NgZone, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';

/**
 * Waits for the zone to stabilize, then focuses the element if enabled.
 *
 * Derived from https://github.com/angular/material2/blob/f29da63/src/cdk/a11y/focus-trap.ts
 */
@Directive({
  selector: '[tcAutofocus]',
})
export class AutofocusDirective implements OnInit {
  private enabled = true;

  @Input()
  set tcAutofocus(condition: boolean) {
    // eslint-disable-next-line
    this.enabled = condition !== false;
  }

  constructor(private el: ElementRef, private ngZone: NgZone) {}

  ngOnInit(): void {
    this.focusElementWhenReady();
  }

  private focusElementWhenReady(): void {
    this._executeOnStable(() => {
      this.focusElement();
    });
  }

  private focusElement(): void {
    if (this.enabled) {
      this.el.nativeElement.focus();
    }
  }

  private _executeOnStable(fn: () => any): void {
    if (this.ngZone.isStable) {
      fn();
    } else {
      this.ngZone.onStable.asObservable().pipe(first()).subscribe(fn);
    }
  }
}
