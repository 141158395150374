import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserInterface } from '@app/user/models';

export const defaultColors = [
  '#1abc9c',
  '#3498db',
  '#f1c40f',
  '#8e44ad',
  '#e74c3c',
  '#d35400',
  '#2c3e50',
  '#e040fb',
  '#ffab40',
  '#c77c02',
  '#00AA55',
  '#4dd0e1',
  '#009faf',
  '#009FD4',
  '#B381B3',
  '#939393',
  '#E3BC00',
  '#D47500',
  '#DC2A2A',
];

function calculateAsciiCode(value: string): number {
  return value
    .split('')
    .map(letter => {
      return letter.charCodeAt(0);
    })
    .reduce((previous, current) => {
      return previous + current;
    });
}

@Component({
  selector: 'tc-profile-picture',
  templateUrl: 'profile-picture.component.html',
  styleUrls: ['profile-picture.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfilePictureComponent {
  @Input()
  user?: UserInterface;

  @Input()
  size = 40;

  @Input()
  showRole = false;

  public avatarColors: string[] = defaultColors;

  public get showPlaceholder(): boolean {
    return !!this.user && !this.user.profile.profilePictureId;
  }

  public getRandomColor(avatarText: string): string {
    if (!avatarText) {
      return 'transparent';
    }
    const asciiCodeSum = calculateAsciiCode(avatarText);

    return this.avatarColors[asciiCodeSum % this.avatarColors.length];
  }

  public get initials(): string {
    if (!this.user) {
      return '';
    }

    const name = `${this.user.profile.firstName} ${this.user.profile.lastName || ''}`;

    return name
      .split(' ', 3)
      .map(word => {
        return word.charAt(0).toUpperCase();
      })
      .join('');
  }

  public get fullName(): string {
    if (!this.user) {
      return '';
    }

    const name = `${this.user.profile.firstName} ${this.user.profile.lastName || ''}`;

    return name;
  }

  public get profilePictureId(): string | undefined {
    return this.user && this.user.profile.profilePictureId;
  }
}
