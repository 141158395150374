import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WebSocketService } from '@app/core/services/web-socket/web-socket.service';
import { paramsFromObject } from '@app/util/http';
import { API } from '@app/shared/api/api.service';
import {
  CreateUserInterface,
  UpdateUserInterface,
  UpdateUserSSOInterface,
  UserInterface,
  UserSearch,
  UserSearchResponse,
  UserSuggest,
  UserSuggestResponse,
} from '@app/user/models';
import { Observable } from 'rxjs';

@Injectable()
export class UserService {
  constructor(private http: HttpClient, private webSocketService: WebSocketService, private api: API) {}

  public getByEmail$(email: string): Observable<UserInterface> {
    return this.http.post<UserInterface>(this.api.USER_SEARCH_EMAIL(), { email });
  }

  public create$(body: CreateUserInterface): Observable<{ id: string }> {
    return this.http.post<{ id: string }>(this.api.USER_CREATE(), body);
  }

  public update$(id: string, body: UpdateUserInterface | UpdateUserSSOInterface): Observable<string> {
    const responseType = 'text';

    return this.http.post(this.api.USER_UPDATE(id), body, { responseType });
  }

  public reinvite$(id: string): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(this.api.USER_REINVITE(id), {});
  }

  public remove$(id: string): Observable<{ ok: boolean }> {
    return this.http.delete<{ ok: boolean }>(this.api.USER_DELETE(id));
  }

  public websocket$(companyId: string): Observable<UserInterface> {
    const params = paramsFromObject({ companyId });

    return this.webSocketService.createWebSocket$<UserInterface>(this.api.USER_WS(), params);
  }

  public searchUserById$(id: string): Observable<UserInterface> {
    const encodedId = encodeURIComponent(id);

    return this.http.get<UserInterface>(this.api.USER_SEARCH_ID(encodedId));
  }

  public suggest$(body: UserSuggest): Observable<UserSuggestResponse> {
    return this.http.post<UserSuggestResponse>(this.api.USER_SEARCH_SUGGEST(), body);
  }

  public search$(body: UserSearch): Observable<UserSearchResponse> {
    return this.http.post<UserSearchResponse>(this.api.USER_SEARCH(), body);
  }
}
