import { UpdateUserSettingsInterface, UserSettingsInterface } from '@app/user/models/user-settings.interface';

export function mapUpdatedToUserSettings(value: UpdateUserSettingsInterface): UserSettingsInterface {
  return {
    notification: {
      interval: value.notificationInterval,
      unassignedTasksSubscription: value.unassignedTasksSubscription,
    },
    externalUsername: value.externalUsername,
    languageCode: value.languageCode,
  };
}

export function mapUserSettingsToUpdated(value: UserSettingsInterface | undefined): UpdateUserSettingsInterface {
  return {
    languageCode: value?.languageCode,
    externalUsername: value?.externalUsername,
    notificationInterval: value!.notification.interval,
    unassignedTasksSubscription: value?.notification.unassignedTasksSubscription,
  };
}
