import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import jwt_decode from 'jwt-decode';
import { EMPTY, Observable, of } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';
import { AccessTokenPayload, Tokens } from '@app/auth/models';
import { authLogout, authRefresh, authRefreshSSO } from '../state/auth.actions';
import { AuthState } from '../state/auth.reducer';
import { selectAuthRefreshing, selectAuthTokens } from '../state/auth.selectors';

@Injectable()
export class JwtService {
  constructor(private store$: Store<AuthState>) {}

  public getValidTokens$(): Observable<Tokens> {
    return this.getCurrentTokens$().pipe(
      switchMap(tokens => {
        return this.checkExpiration$(tokens);
      }),
    );
  }

  public isTokenExpired(token: string, offsetSeconds = 60): boolean {
    const decoded = jwt_decode<AccessTokenPayload>(token);
    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);

    return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
  }

  public decodeToken<T>(tokens: Tokens): T {
    return jwt_decode<T>(tokens.accessToken);
  }

  private getCurrentTokens$(): Observable<Tokens> {
    return this.store$.pipe(
      select(selectAuthRefreshing),
      first(refreshing => {
        return !refreshing;
      }),
      switchMap(refreshed => {
        return this.store$.pipe(
          select(selectAuthTokens),
          first(),
          /**
           * If the tokens are not set, the user was logged out during the
           * refresh. In this case we complete the stream without a value
           * so the subscriber's action that required the tokens is cancelled.
           */
          filter((tokens): tokens is Tokens => {
            return !!tokens;
          }),
        );
      }),
    );
  }

  private checkExpiration$(tokens: Tokens): Observable<Tokens> {
    const expired = this.isTokenExpired(tokens.accessToken);
    if (!expired) {
      return of(tokens);
    }
    const identity = tokens.accessToken && this.decodeToken<AccessTokenPayload>(tokens).data;
    if (tokens.refreshToken) {
      this.store$.dispatch(authRefresh({ refreshToken: tokens.refreshToken }));
    } else if (identity && identity.identityProvider === 'azure_ad') {
      this.store$.dispatch(authRefreshSSO());
    } else {
      // logout since the access token is expired and there is no refresh token
      this.store$.dispatch(authLogout());

      return EMPTY;
    }

    return this.store$.pipe(
      select(selectAuthRefreshing),
      first(refreshing => {
        return refreshing;
      }),
      switchMap(() => {
        return this.getCurrentTokens$();
      }),
    );
  }
}
