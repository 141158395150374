import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BuyerOrderTerms, IncotermsCode, Location, LocationType, Money, Order, OrderPrices } from '@app/order/models';
import {
  getLinesPositionWithoutAmount,
  LABEL_AMOUNT_CURRENCY,
  LABEL_AMOUNT_LINE_FIRST,
  LABEL_AMOUNT_LINE_SECOND,
} from '@app/order/private/components/order-details/helpers/order-details.helper';

@Component({
  selector: 'tc-order-details',
  templateUrl: './order-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderDetailsComponent {
  @Input()
  order?: Order;

  public get destination(): Location | undefined {
    return this.order?.buyerOrder?.destination;
  }

  public get incotermsCode(): IncotermsCode | undefined {
    return this.terms && this.terms.incotermsCode;
  }

  public get incotermsLocationName(): string | undefined {
    return this.terms && this.terms.incotermsLocationName;
  }

  public get incoterms(): string | undefined {
    return this.terms && this.terms.incoterms;
  }

  public get incotermsLocationType(): LocationType | undefined {
    return this.terms && this.terms.incotermsLocationType;
  }

  public get paymentTermsCode(): string | undefined {
    return this.terms && this.terms.paymentTermsCode;
  }

  public get paymentTerms(): string | undefined {
    return this.terms && this.terms.paymentTerms;
  }

  public get paymentTermsDescription(): string | undefined {
    return this.terms && this.terms.paymentTermsDescription;
  }

  public get prices(): OrderPrices | undefined {
    return this.order && this.order.buyerOrder.prices;
  }

  private get terms(): BuyerOrderTerms | undefined {
    return this.order && this.order.buyerOrder.terms;
  }

  public get amountChanged(): boolean | undefined {
    return (
      this.order?.totalAmountIncludingRequests?.value !== this.order?.totalAmount?.value ||
      this.order?.totalAmountIncludingRequests?.currencyIso !== this.order?.totalAmount?.currencyIso
    );
  }

  public get amountView(): Money | undefined {
    return this.order?.totalAmountIncludingRequests;
  }

  public get amountHint(): string {
    const positions = getLinesPositionWithoutAmount(this.order?.lines);

    return positions.length
      ? `${LABEL_AMOUNT_LINE_FIRST} ${positions.join(', ')} ${LABEL_AMOUNT_LINE_SECOND}`
      : LABEL_AMOUNT_CURRENCY;
  }
}
