<div class="vertical">
  <mat-list class="mat-list--properties mat-list--compact">
    <mat-list-item *ngIf="showIncotermsView" class="auto-height" fxFlex="50">
      <div class="property" i18n="@@common.field.incoterms">Incoterms</div>
      <div class="value">
        <h4 matLine class="wrap" *ngIf="incotermsCode">
          {{ incotermsCode | tcIncotermsName }}
        </h4>
        <p matLine class="wrap" *ngIf="incotermsLocationName">
          {{ incotermsLocationName }}
        </p>
        <p matLine class="wrap" *ngIf="incoterms">
          {{ incoterms }}
        </p>
      </div>
    </mat-list-item>

    <mat-list-item *ngIf="showPaymentView" class="auto-height" fxFlex="50">
      <div class="property" i18n="@@common.field.payment.terms">Payment terms</div>
      <div class="value">
        <h4 matLine class="wrap" *ngIf="paymentTermsCode">
          {{ paymentTermsCode }}
        </h4>
        <p matLine class="wrap" *ngIf="paymentTermsDescription">
          {{ paymentTermsDescription }}
        </p>
        <p matLine class="wrap" *ngIf="paymentTerms">
          {{ paymentTerms }}
        </p>
      </div>
    </mat-list-item>
  </mat-list>
</div>
