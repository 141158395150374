import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalCallbackLoginPageComponent } from '@app/msal/containers/msal-callback-login-page.component';
import { MsalCallbackLogoutPageComponent } from '@app/msal/containers/msal-callback-logout-page.component';

const routes: Routes = [
  { path: 'login', component: MsalCallbackLoginPageComponent },
  { path: 'logout', component: MsalCallbackLogoutPageComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MsalRoutingModule {}
