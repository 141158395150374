import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Company } from '@app/company/models';
import { UserInterface } from '@app/user/models';
import { MixpanelPageViewEvents } from '@app/core/services/page-analytics/enums/mixpanel-page-view-events.enum';
import { EnvService } from '@app/env.service';

@Component({
  selector: 'tc-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationComponent {
  @Input()
  userId!: string;

  @Input()
  user?: UserInterface;

  @Input()
  companyId!: string;

  @Input()
  company?: Company;

  @Input()
  currentRoute?: string;

  @Output()
  readonly toggleSidenav = new EventEmitter();

  @Output()
  readonly endActingAsCompany = new EventEmitter();

  @Output()
  readonly logout = new EventEmitter();

  public readonly mixpanelPageViewEvents = MixpanelPageViewEvents;
  public readonly ids = this.envService.shipmentModuleCompanyIds || '';

  constructor(private envService: EnvService) {}

  public get isNetworkRoute(): boolean | string | undefined {
    return this.currentRoute && ['company', 'user'].includes(this.currentRoute);
  }

  public get actingAsCompany(): boolean | undefined {
    return this.user && this.user.companyId !== this.companyId;
  }
}
