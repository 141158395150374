import { createFeatureSelector, createSelector } from '@ngrx/store';
import { objectStorageAdapter, ObjectStorageState } from './object-storage.reducer';

export const objectStorageFeatureName = 'objectStorage';

const selectState = createFeatureSelector<ObjectStorageState>('objectStorage');

const { selectEntities, selectIds } = objectStorageAdapter.getSelectors(selectState);

export const selectObjectById = (id: string) => {
  return createSelector(selectEntities, entities => {
    return entities[id] ? entities[id] : null;
  });
};

export const selectObjectStorageIds = selectIds;
export const selectObjectUploadResponse = createSelector(selectState, ({ uploadResponse }) => {
  return uploadResponse;
});
