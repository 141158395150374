import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, UrlTree } from '@angular/router';
import { selectCompanyRoleFromIdentity } from '@app/company/core/state';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AuthState } from '../state/auth.reducer';
import { selectAuthIdentityUserRoles } from '../state/auth.selectors';

@Injectable()
export class RoleGuard implements CanActivate {
  constructor(private store$: Store<AuthState>) {}

  canActivate(
    route: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return combineLatest([
      this.store$.pipe(select(selectAuthIdentityUserRoles)),
      this.store$.pipe(select(selectCompanyRoleFromIdentity)),
    ]).pipe(
      first(([roles]) => {
        return !!roles;
      }),
      map(([userRoles = [], companyRoles = []]) => {
        const roles = [...userRoles, ...companyRoles];
        const allowed = roles.some(role => {
          return route.data.allowedRoles.includes(role);
        });

        return allowed;
      }),
    );
  }
}
