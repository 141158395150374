import { createAction, props } from '@ngrx/store';
import {
  ChangeItemDetails,
  LineRequest,
  Rejection,
  RequestRejection,
  StartAcceptance,
  StartChangeItemDetails,
  StartProposalApproval,
  StartRejection,
  StartRemoveLineLabel,
  StartReopenApproval,
  StartRequest,
  StartRequestRejection,
  StartUpdateLineLabels,
  StartUpdateLogistics,
  UpdateLogistics,
} from '@app/order/models';
import { CompanyRole } from '@app/company/models';

export enum OrderLineDetailActionType {
  START_ACCEPT = '[Order Line Detail] Start Accept',
  ACCEPT = '[Order Line Detail] Accept',
  ACCEPT_SUCCESS = '[Order Line Detail] Accept Success',
  ACCEPT_FAILURE = '[Order Line Detail] Accept Failure',

  START_UPDATE_LABELS = '[Order Line Detail] Start Update Labels',
  UPDATE_LABELS = '[Order Line Detail] Update Labels',

  REMOVE_LABEL = '[Order Line Detail] Remove Label',

  START_REJECT = '[Order Line Detail] Start Reject',
  REJECT = '[Order Line Detail] Reject',
  REJECT_SUCCESS = '[Order Line Detail] Reject Success',
  REJECT_FAILURE = '[Order Line Detail] Reject Failure',

  START_PROPOSE = '[Order Line Detail] Start Propose',
  PROPOSE = '[Order Line Detail] Propose',
  PROPOSE_SUCCESS = '[Order Line Detail] Propose Success',
  PROPOSE_FAILURE = '[Order Line Detail] Propose Failure',

  START_REOPEN = '[Order Line Detail] Start Reopen request',
  REOPEN = '[Order Line Detail] Reopen request',
  REOPEN_SUCCESS = '[Order Line Detail] Reopen request Success',
  REOPEN_FAILURE = '[Order Line Detail] Reopen request Failure',

  APPROVE_REOPEN = '[Order Line Detail] Approve Reopen',
  APPROVE_REOPEN_SUCCESS = '[Order Line Detail] Approve Reopen Success',
  APPROVE_REOPEN_FAILURE = '[Order Line Detail] Approve Reopen Failure',

  START_REJECT_REOPEN = '[Order Line Detail] Start Reject Reopen',
  REJECT_REOPEN = '[Order Line Detail] Reject Reopen',
  REJECT_REOPEN_SUCCESS = '[Order Line Detail] Reject Reopen Success',
  REJECT_REOPEN_FAILURE = '[Order Line Detail] Reject Reopen Failure',

  START_CHANGE_ITEM_DETAILS = '[Order Line Detail] Start Change Item Details',
  CHANGE_ITEM_DETAILS = '[Order Line Detail] Change Item Details',
  CHANGE_ITEM_DETAILS_SUCCESS = '[Order Line Detail] Change Item Details Success',
  CHANGE_ITEM_DETAILS_FAILURE = '[Order Line Detail] Change Item Details Failure',

  START_UPDATE_LOGISTIC = '[Order Line Detail] Start Update Logistic',
  UPDATE_LOGISTIC = '[Order Line Detail] Update Logistic',
  UPDATE_LOGISTIC_SUCCESS = '[Order Line Detail] Update Logistic Success',
  UPDATE_LOGISTIC_FAILURE = '[Order Line Detail] Update Logistic Failure',

  APPROVE_PROPOSAL = '[Order Line Detail] Approve Proposal',
  APPROVE_PROPOSAL_SUCCESS = '[Order Line Detail] Approve Proposal Success',
  APPROVE_PROPOSAL_FAILURE = '[Order Line Detail] Approve Proposal Failure',

  START_REJECT_PROPOSAL = '[Order Line Detail] Start Reject Proposal',
  REJECT_PROPOSAL = '[Order Line Detail] Reject Proposal',
  REJECT_PROPOSAL_SUCCESS = '[Order Line Detail] Reject Proposal Success',
  REJECT_PROPOSAL_FAILURE = '[Order Line Detail] Reject Proposal Failure',

  CONFLICTED = '[Order Line Detail] Conflicted',
}

export const orderLineDetailConflicted = createAction(OrderLineDetailActionType.CONFLICTED);

export const orderLineDetailStartAccept = createAction(
  OrderLineDetailActionType.START_ACCEPT,
  props<StartAcceptance>(),
);
export const orderLineDetailAccept = createAction(OrderLineDetailActionType.ACCEPT, props<StartAcceptance>());
export const orderLineDetailAcceptSuccess = createAction(OrderLineDetailActionType.ACCEPT_SUCCESS);
export const orderLineDetailAcceptFailure = createAction(
  OrderLineDetailActionType.ACCEPT_FAILURE,
  props<{ error: any; status: number }>(),
);
export const orderLineDetailStartReject = createAction(OrderLineDetailActionType.START_REJECT, props<StartRejection>());
export const orderLineDetailReject = createAction(OrderLineDetailActionType.REJECT, props<Rejection>());
export const orderLineDetailRejectSuccess = createAction(OrderLineDetailActionType.REJECT_SUCCESS);
export const orderLineDetailRejectFailure = createAction(
  OrderLineDetailActionType.REJECT_FAILURE,
  props<{ error: any; status: number }>(),
);

export const orderLineDetailStartReopen = createAction(OrderLineDetailActionType.START_REOPEN, props<StartRequest>());
export const orderLineDetailReopen = createAction(OrderLineDetailActionType.REOPEN, props<{ request: LineRequest }>());
export const orderLineDetailReopenSuccess = createAction(OrderLineDetailActionType.REOPEN_SUCCESS);
export const orderLineDetailReopenFailure = createAction(
  OrderLineDetailActionType.REOPEN_FAILURE,
  props<{ error: any; status: number }>(),
);

export const orderLineDetailApproveReopen = createAction(
  OrderLineDetailActionType.APPROVE_REOPEN,
  props<StartReopenApproval>(),
);
export const orderLineDetailApproveReopenSuccess = createAction(OrderLineDetailActionType.APPROVE_REOPEN_SUCCESS);
export const orderLineDetailApproveReopenFailure = createAction(
  OrderLineDetailActionType.APPROVE_REOPEN_FAILURE,
  props<{ error: any; status: number }>(),
);

export const orderLineDetailStartRejectReopen = createAction(
  OrderLineDetailActionType.START_REJECT_REOPEN,
  props<{ startReopenRejection: StartRequestRejection }>(),
);
export const orderLineDetailRejectReopen = createAction(
  OrderLineDetailActionType.REJECT_REOPEN,
  props<{ rejectReopen: RequestRejection; role: CompanyRole }>(),
);
export const orderLineDetailRejectReopenSuccess = createAction(OrderLineDetailActionType.REJECT_REOPEN_SUCCESS);
export const orderLineDetailRejectReopenFailure = createAction(
  OrderLineDetailActionType.REJECT_REOPEN_FAILURE,
  props<{ error: any; status: number }>(),
);

export const orderLineDetailStartPropose = createAction(
  OrderLineDetailActionType.START_PROPOSE,
  props<{ startProposal: StartRequest }>(),
);
export const orderLineDetailPropose = createAction(
  OrderLineDetailActionType.PROPOSE,
  props<{ request: LineRequest }>(),
);
export const orderLineDetailProposeSuccess = createAction(OrderLineDetailActionType.PROPOSE_SUCCESS);
export const orderLineDetailProposeFailure = createAction(
  OrderLineDetailActionType.PROPOSE_FAILURE,
  props<{ error: any; status: number }>(),
);

export const orderLineDetailApproveProposal = createAction(
  OrderLineDetailActionType.APPROVE_PROPOSAL,
  props<{ startProposalApproval: StartProposalApproval }>(),
);
export const orderLineDetailApproveProposalSuccess = createAction(OrderLineDetailActionType.APPROVE_PROPOSAL_SUCCESS);
export const orderLineDetailApproveProposalFailure = createAction(
  OrderLineDetailActionType.APPROVE_PROPOSAL_FAILURE,
  props<{ error: any; status: number }>(),
);
export const orderLineDetailStartRejectProposal = createAction(
  OrderLineDetailActionType.START_REJECT_PROPOSAL,
  props<{ startProposalRejection: StartRequestRejection }>(),
);
export const orderLineDetailRejectProposal = createAction(
  OrderLineDetailActionType.REJECT_PROPOSAL,
  props<{ rejectProposal: RequestRejection }>(),
);
export const orderLineDetailRejectProposalSuccess = createAction(OrderLineDetailActionType.REJECT_PROPOSAL_SUCCESS);
export const orderLineDetailRejectProposalFailure = createAction(
  OrderLineDetailActionType.REJECT_PROPOSAL_FAILURE,
  props<{ error: any; status: number }>(),
);
export const orderLineDetailStartChangeItemDetails = createAction(
  OrderLineDetailActionType.START_CHANGE_ITEM_DETAILS,
  props<StartChangeItemDetails>(),
);
export const orderLineDetailChangeItemDetails = createAction(
  OrderLineDetailActionType.CHANGE_ITEM_DETAILS,
  props<ChangeItemDetails>(),
);
export const orderLineDetailChangeItemSuccess = createAction(OrderLineDetailActionType.CHANGE_ITEM_DETAILS_SUCCESS);
export const orderLineDetailChangeItemFailure = createAction(
  OrderLineDetailActionType.CHANGE_ITEM_DETAILS_FAILURE,
  props<{ error: any; status: number }>(),
);

export const orderLineDetailStartUpdateLogistics = createAction(
  OrderLineDetailActionType.START_UPDATE_LOGISTIC,
  props<StartUpdateLogistics>(),
);
export const orderLineDetailUpdateLogistics = createAction(
  OrderLineDetailActionType.UPDATE_LOGISTIC,
  props<UpdateLogistics>(),
);
export const orderLineDetailUpdateLogisticsSuccess = createAction(OrderLineDetailActionType.UPDATE_LOGISTIC_SUCCESS);
export const orderLineDetailUpdateLogisticsFailure = createAction(
  OrderLineDetailActionType.UPDATE_LOGISTIC_FAILURE,
  props<{ error: any; status: number }>(),
);
export const orderLineDetailStartUpdateLabels = createAction(
  OrderLineDetailActionType.START_UPDATE_LABELS,
  props<StartUpdateLineLabels>(),
);
export const orderLineDetailUpdateLabels = createAction(
  OrderLineDetailActionType.UPDATE_LABELS,
  props<StartUpdateLineLabels>(),
);

export const orderLineDetailRemoveLabel = createAction(
  OrderLineDetailActionType.REMOVE_LABEL,
  props<StartRemoveLineLabel>(),
);
