import { createAction, props } from '@ngrx/store';
import { Order, OrderQuery } from '@app/order/models';
import { ConvertedColumn, FileType, PreConverted } from '@app/converter/models';

export enum ConverterActionType {
  INIT_COLUMNS = '[Converter] Init Columns',
  INITIATED_COLUMNS = '[Converter] Initiated Columns',

  FETCH = '[Converter] Fetch data',
  FETCH_SUCCESS = '[Converter] Fetch data Success',
  FETCH_FAILURE = '[Converter] Fetch data Failure',

  MAKE_CSV_SUCCESS = '[Converter] Make CSV Success',
  MAKE_CSV_FAILURE = '[Converter] Make CSV Failure',

  MAKE_XLSX = '[Converter] Make XLSX file and download',
  MAKE_XLSX_SUCCESS = '[Converter] Make XLSX Success',
  MAKE_XLSX_FAILURE = '[Converter] Make XLSX Failure',

  MAKE_CSV = '[Converter] Make csv file',
  UPDATE_COLUMNS = '[Converter] Update Columns',

  UPDATE_ORDER_GENERAL_COLUMNS = '[Converter] Update Order General Columns',
  UPDATE_ORDER_BUYER_COLUMNS = '[Converter] Update Order Buyer Columns',
  UPDATE_ORDER_SUPPLIER_COLUMNS = '[Converter] Update Order Supplier Columns',

  UPDATE_LINE_GENERAL_COLUMNS = '[Converter] Update Line General Columns',
  UPDATE_LINE_BUYER_COLUMNS = '[Converter] Update Line Buyer Columns',
  UPDATE_LINE_SUPPLIER_COLUMNS = '[Converter] Update Line Supplier Columns',
  UPDATE_LINE_CONFIRMED_COLUMNS = '[Converter] Update Line Confirmed Columns',
  UPDATE_FILE_TYPE = '[Converter] Update File Type',

  MAKE_PDF = '[Converter] Make PDF',
}

export const converterInitColumns = createAction(ConverterActionType.INIT_COLUMNS);

export const converterInitiatedColumns = createAction(
  ConverterActionType.INITIATED_COLUMNS,
  props<{
    generalOrderColumns: ConvertedColumn[];
    buyerOrderColumns: ConvertedColumn[];
    supplierOrderColumns: ConvertedColumn[];

    generalLineColumns: ConvertedColumn[];
    buyerLineColumns: ConvertedColumn[];
    supplierLineColumns: ConvertedColumn[];
    confirmedLineColumns: ConvertedColumn[];
  }>(),
);

export const converterFetch = createAction(
  ConverterActionType.FETCH,
  props<{
    onlyPage: boolean;
    previous: {
      data: PreConverted[];
      query?: OrderQuery;
    };
  }>(),
);
export const converterFetchSuccess = createAction(
  ConverterActionType.FETCH_SUCCESS,
  props<{ response: PreConverted[] }>(),
);
export const converterFetchFailure = createAction(ConverterActionType.FETCH_FAILURE, props<{ error: string }>());

export const converterMakeCsvSuccess = createAction(ConverterActionType.MAKE_CSV_SUCCESS, props<{ data: string }>());
export const converterMakeXLSX = createAction(ConverterActionType.MAKE_XLSX, props<{ data: string }>());
export const converterMakeCsvFetchFailure = createAction(
  ConverterActionType.MAKE_CSV_FAILURE,
  props<{ error: string }>(),
);
export const converterUpdateOrderGeneralColumns = createAction(
  ConverterActionType.UPDATE_ORDER_GENERAL_COLUMNS,
  props<{ columns: ConvertedColumn[] }>(),
);
export const converterUpdateOrderBuyerColumns = createAction(
  ConverterActionType.UPDATE_ORDER_BUYER_COLUMNS,
  props<{ columns: ConvertedColumn[] }>(),
);
export const converterUpdateOrderSupplierColumns = createAction(
  ConverterActionType.UPDATE_ORDER_SUPPLIER_COLUMNS,
  props<{ columns: ConvertedColumn[] }>(),
);

export const converterUpdateLineGeneralColumns = createAction(
  ConverterActionType.UPDATE_LINE_GENERAL_COLUMNS,
  props<{ columns: ConvertedColumn[] }>(),
);
export const converterUpdateLineBuyerColumns = createAction(
  ConverterActionType.UPDATE_LINE_BUYER_COLUMNS,
  props<{ columns: ConvertedColumn[] }>(),
);
export const converterUpdateLineSupplierColumns = createAction(
  ConverterActionType.UPDATE_LINE_SUPPLIER_COLUMNS,
  props<{ columns: ConvertedColumn[] }>(),
);
export const converterUpdateLineConfirmedColumns = createAction(
  ConverterActionType.UPDATE_LINE_CONFIRMED_COLUMNS,
  props<{ columns: ConvertedColumn[] }>(),
);
export const converterUpdateFileType = createAction(
  ConverterActionType.UPDATE_FILE_TYPE,
  props<{ selectedType: FileType }>(),
);

export const converterMakePdf = createAction(ConverterActionType.MAKE_PDF, props<{ order: Order }>());
