import { Pipe, PipeTransform } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { UserInterface } from '@app/user/models';
import { isNotNullOrUndefined } from '@app/util/operators/is-not-null-or-undefined';
import { userFetch } from '@app/user/core/state/user.actions';
import { UserState } from '@app/user/core/state/user.reducer';
import { selectUserById } from '@app/user/core/state/user.selectors';

@Pipe({
  name: 'tcLazyUser',
})
export class LazyUserPipe implements PipeTransform {
  constructor(private store$: Store<UserState>) {}

  // eslint-disable-next-line
  transform(id?: string): Observable<UserInterface | null> {
    if (!id) {
      return of(null);
    }

    this.store$.dispatch(userFetch({ id }));

    return this.store$.pipe(select(selectUserById(id)), isNotNullOrUndefined());
  }
}
