import { Location } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import * as Rollbar from 'rollbar';
import { Identity } from '@app/auth/models';
import { Company, CompanyRole } from '@app/company/models';
import { UserInterface } from '@app/user/models';
import { select, Store } from '@ngrx/store';
import { Intercom } from 'ng-intercom';
import { combineLatest, Observable } from 'rxjs';
import { first, map, take } from 'rxjs/operators';
import { ROLLBAR } from '../error-tracking/rollbar.factory';
import { MIXPANEL } from './mixpanel.factory';
import { MixpanelPageViewEvents } from '@app/core/services/page-analytics/enums/mixpanel-page-view-events.enum';
import { isNotNullOrUndefined } from '@app/util/operators/is-not-null-or-undefined';
import { selectCompanyFromIdentity } from '@app/company/core/state';
import { selectUserFromIdentity } from '@app/user/core/state/user.selectors';
import { GlobalState } from '@app/store/reducers';
import { UserRole } from '@app/user/models/enums/user-role.enum';
import { Environment, EnvType } from '@environments/environment.interface';
import { ENV } from '@app/core/services/environment.provider';

const config: Mixpanel.Config = {
  persistence: 'localStorage',
};

@Injectable()
export class PageAnalyticsService {
  public user$: Observable<UserInterface> = this.store$.pipe(select(selectUserFromIdentity), isNotNullOrUndefined());
  public company$: Observable<Company> = this.store$.pipe(select(selectCompanyFromIdentity), isNotNullOrUndefined());

  constructor(
    @Optional() private intercom: Intercom,
    private location: Location,
    @Inject(ENV) private env: Environment,
    @Inject(MIXPANEL) private mixpanel: Mixpanel,
    @Inject(ROLLBAR) private rollbar: Rollbar,
    private store$: Store<GlobalState>,
  ) {}

  public initialize(): void {
    const token = this.env.mixpanelToken;
    this.mixpanel.init(token, config);
  }

  public logout(): void {
    this.track('Logout');
    this.mixpanel.reset();
  }

  public session({ userId, companyId }: Identity): void {
    this.mixpanel.identify(userId);

    this.user$.pipe(take(1)).subscribe(({ profile: { firstName, lastName }, roles, email }: UserInterface) => {
      this.mixpanel.people.set({
        $email: email,
        $first_name: firstName,
        $last_name: lastName,
        companyId,
        role: roles.join(' '),
      });
    });

    this.rollbar.configure({
      payload: {
        person: {
          id: userId,
        },
      },
    });

    if (this.buildEnv() === 'prod') {
      combineLatest([this.user$, this.company$])
        .pipe(
          map(([user, company]) => {
            return { user, company };
          }),
          first(),
        )
        .subscribe(
          ({
            user: {
              profile: { firstName, lastName, position, linkedInProfile },
              activated,
              roles,
              email,
              companyRoles,
            },
            company: { name },
          }: {
            user: UserInterface;
            company: Company;
          }) => {
            const userFirstName = firstName || '';
            const userLastName = lastName || '';

            const key: { role: string } = {
              role: '',
            };

            if (companyRoles.includes(CompanyRole.SUPPLIER)) {
              key.role = 'Seller / Supplier';
            }

            if (companyRoles.includes(CompanyRole.BUYER)) {
              key.role = 'Buyer';
            }

            const isAdmin = roles.includes(UserRole.ADMIN);

            this.intercom.boot({
              app_id: this.env.intercomAppId,
              email,
              linkedInProfile,
              position,
              activated,
              'Key role': key.role,
              release: 'Tradecloud1',
              admin: isAdmin,
              name: `${userFirstName} ${userLastName}`,
              user_id: userId,
              company: {
                id: companyId,
                name,
              },
              widget: {
                activator: '#intercom',
              },
            });
          },
        );
    }
  }

  public buildEnv(): EnvType {
    return this.env.environment;
  }

  public track(eventName: string, properties?: { [index: string]: any }): void {
    this.mixpanel.track(eventName, properties);
  }

  public trackPageView(eventName: MixpanelPageViewEvents): void {
    const path = this.location.path();
    this.track(eventName, { path });
  }
}
