import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Activity, ActivityType } from '@app/activity/models';
import { MixpanelButtonClickEvents } from '@app/core/services/page-analytics/enums/mixpanel-button-click-events.enum';

@Component({
  selector: 'tc-document-activity-list',
  templateUrl: './document-activity-list.component.html',
  styleUrls: ['./document-activity-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentActivityListComponent {
  @Input()
  activity!: Activity;

  @Input()
  mixpanelOrderClickEvent!: MixpanelButtonClickEvents;

  @Input()
  mixpanelOrderLineClickEvent!: MixpanelButtonClickEvents;

  @Input()
  mixpanelShipmentClickEvent!: MixpanelButtonClickEvents;

  public ActivityType = ActivityType;
}
