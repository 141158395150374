import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@app/shared/api/api.service';
import { AnalyticsStatusQueryDtoInterface } from '@app/order/core/services/dto/analytics-status-query.dto.interface';
import { AnalyticsStatusMetricsDtoInterface } from '@app/order/core/services/dto/analytics-status-metrics.dto.interface';
import {
  OrderLineQuery,
  OrderLineQueryResponse,
  OrderLineSearchView,
  OrderLineSuggest,
  OrderLineSuggestResponse,
} from '@app/order/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class OrderLineSearchService {
  constructor(private http: HttpClient, private api: API) {}

  public getById$(id: string): Observable<OrderLineSearchView> {
    const encodedId = encodeURIComponent(id);

    return this.http.get<OrderLineSearchView>(this.api.ORDER_LINE_SEARCH_ID(encodedId));
  }

  public query$(body: OrderLineQuery): Observable<OrderLineQueryResponse> {
    return this.http.post<OrderLineQueryResponse>(this.api.ORDER_LINE_SEARCH_QUERY(), body);
  }

  public suggest$(body: OrderLineSuggest): Observable<OrderLineSuggestResponse> {
    return this.http.post<OrderLineSuggestResponse>(this.api.ORDER_LINE_SEARCH_SUGGEST(), body);
  }

  public getProcessStatus$(params: any): Observable<AnalyticsStatusMetricsDtoInterface[]> {
    return this.http
      .get<AnalyticsStatusQueryDtoInterface>(this.api.ORDER_LINE_SEARCH_METRICS_PROCESS_STATUS(), { params })
      .pipe(
        map(response => {
          return response.metrics;
        }),
      );
  }
}
