import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BuyerItem, LineShipmentHistory } from '@app/order/models';

export const compactShipmentColumns = ['id', 'position', 'quantity'];

@Component({
  selector: 'tc-line-shipments',
  templateUrl: './line-shipments.component.html',
  styleUrls: ['./line-shipments.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineShipmentsComponent {
  @Input()
  shipmentHistory: LineShipmentHistory[] = [];

  @Input()
  item?: BuyerItem;

  public get columnDefs(): string[] {
    return compactShipmentColumns;
  }

  public get total(): number {
    return this.shipmentHistory.reduce((acc, h) => {
      acc += h.despatchQuantity;

      return acc;
    }, 0);
  }
}
