import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { CompanyService } from './services/company.service';
import { reducer } from './state/company.reducer';
import { companyFeatureName } from '@app/company/core/state';
import { SciConnectorService } from '@app/company/core/services/sci-connector.service';
import { ShipmentConnectorService } from '@app/company/core/services/shipment-connector.service';

@NgModule({
  imports: [StoreModule.forFeature(companyFeatureName, reducer)],
  providers: [CompanyService, SciConnectorService, ShipmentConnectorService],
})
export class CompanyCoreModule {}
