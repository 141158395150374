import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BuyerOrderTerms, IncotermsCode, Location } from '../../../../order/models';
import { RFQ } from '../../../models/rfq.model';

@Component({
  selector: 'tc-rfq-details',
  templateUrl: './rfq-details.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RfqDetailsComponent {
  @Input()
  rfq!: RFQ;

  public get destination(): Location | undefined {
    return this.rfq?.destination;
  }

  public get incotermsCode(): IncotermsCode | undefined {
    return this.terms && this.terms.incotermsCode;
  }

  public get incotermsLocationName(): string | undefined {
    return this.terms && this.terms.incotermsLocationName;
  }

  public get incoterms(): string | undefined {
    return this.terms && this.terms.incoterms;
  }

  public get paymentTermsCode(): string | undefined {
    return this.terms && this.terms.paymentTermsCode;
  }

  public get paymentTerms(): string | undefined {
    return this.terms && this.terms.paymentTerms;
  }

  public get paymentTermsDescription(): string | undefined {
    return this.terms && this.terms.paymentTermsDescription;
  }

  public get endAt(): Date | string {
    return this.rfq?.endAt;
  }

  private get terms(): BuyerOrderTerms | undefined {
    return this.rfq?.terms;
  }
}
