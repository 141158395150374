<mat-list class="mat-list--properties mat-list--compact">

  <h3 mat-subheader class="mat-subheader--border" i18n="@@order.item.details.title">Item details</h3>

  <mat-list-item class="auto-height">
    <div class="property" i18n="@@order.item.details.country">Country of origin</div>
    <div class="value">
      <h4 matLine class="wrap">
        {{countryOfOriginCodeIso2}}
      </h4>
    </div>
  </mat-list-item>

  <mat-list-item class="auto-height">
    <div class="property" i18n="@@order.item.details.code"
         [matTooltipPosition]="'left'"
         matTooltip="Combined Nomenclature"
         i18n-matTooltip="@@tooltip.item.code"
    >CN code</div>
    <div class="value">
      <h4 matLine class="wrap">
        {{combinedNomenclatureCode}}
      </h4>
    </div>
  </mat-list-item>

  <mat-list-item class="auto-height">
    <div class="property" i18n="UoM stand for Unit of measure eg boxes or meters @@order.item.details.weight"
         [matTooltipPosition]="'left'"
         matTooltip="Unit of Measure"
         i18n-matTooltip="@@tooltip.item.weight"
    >Net weight per purchase UoM</div>
    <div class="value">
      <h4 matLine class="wrap">
        {{netWeight}}
        {{netWeightUnitOfMeasureIso}}
      </h4>
    </div>
  </mat-list-item>

  <mat-list-item class="auto-height">
    <div class="property" i18n="@@order.item.details.goods"
         [matTooltipPosition]="'left'"
         matTooltip="United Nations"
         i18n-matTooltip="@@tooltip.item.goods"
    >Dangerous goods code (UN)</div>
    <div class="value">
      <h4 matLine class="wrap">
        {{dangerousGoodsCodeUnece}}
      </h4>
    </div>
  </mat-list-item>

  <mat-list-item class="auto-height">
    <div class="property" i18n="@@order.item.details.serial.number">Serial number</div>
    <div class="value">
      <h4 matLine class="wrap">
        {{serialNumber}}
      </h4>
    </div>
  </mat-list-item>

  <mat-list-item class="auto-height">
    <div class="property" i18n="@@order.item.details.batch.number">Batch number</div>
    <div class="value">
      <h4 matLine class="wrap">
        {{batchNumber}}
      </h4>
    </div>
  </mat-list-item>

</mat-list>
