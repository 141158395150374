import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TwoFACode } from '@app/auth/core/state/auth.model';
import { TwoFactorSetupService } from '@app/shared/services/two-factor-setup.service';

@Component({
  selector: 'tc-setup-2fa',
  templateUrl: './setup-2fa.component.html',
  styleUrls: ['./../base/two-factor-authentication.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Setup2FAComponent {
  public identity$ = this.twoFactorSetupService.identity$;
  public configure2FA$ = this.twoFactorSetupService.configure2FA$;
  public backupCodes$ = this.twoFactorSetupService.backupCodes$;
  public isLastStep$ = this.twoFactorSetupService.lastStep$;

  constructor(private twoFactorSetupService: TwoFactorSetupService) {}

  onEnable2FA(event: TwoFACode): void {
    this.twoFactorSetupService.emitEnable(event);
  }

  onReset(): void {
    this.twoFactorSetupService.emitReset();
  }
}
