import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API } from '@app/shared/api/api.service';
import { LookerToken } from '@app/analytics/models';
import { Observable } from 'rxjs';

@Injectable()
export class LookerService {

  constructor(
    private http: HttpClient,
    private api: API
  ) { }

  public getLookerToken$(companyId: string): Observable<LookerToken> {
    return this.http.get<LookerToken>(this.api.LOOKER_TOKEN_ID(companyId))
  }
}
