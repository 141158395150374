import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { select, Store } from '@ngrx/store';
import { first, map } from 'rxjs/operators';
import { PlatformService } from '@app/core/services/platform/platform.service';
import { paramsFromObject } from '@app/util/http';
import { getImageQuality } from './helpers/get-image-quality.helper';
import { Observable } from 'rxjs';
import { objectStorageDownload } from '../state/object-storage.actions';
import { ObjectStorageState } from '../state/object-storage.reducer';
import { selectObjectById } from '../state/object-storage.selectors';
import { Download, DownloadRequest, ImageOptions } from '../state/object-storage.model';

@Injectable()
export class DownloadService {
  constructor(
    private platform: PlatformService,
    private sanitizer: DomSanitizer,
    private store$: Store<ObjectStorageState>,
  ) {}

  public getDownload$(file: DownloadRequest): Observable<Download> {
    const dpr = this.platform.dpr;
    const q = getImageQuality(dpr);

    const options: ImageOptions = {
      auto: 'format',
      fit: 'min',
      ch: 'Save-Data',
      dpr,
      q,
      ...file.options,
    };

    const params = paramsFromObject(options);
    const id = encodeURIComponent(file.id);
    const fileQuery = `${id}?${params.toString()}`;

    this.store$.dispatch(objectStorageDownload({ fileQuery }));

    return this.store$.pipe(
      select(selectObjectById(fileQuery)),
      first(object => {
        return !!object;
      }),
      map(object => {
        return {
          ...object!,
          downloadUrl: this.sanitizer.bypassSecurityTrustUrl(object!.downloadUrl.toString()),
        };
      }),
    );
  }
}
