<form [formGroup]="form" (ngSubmit)="submit()">

  <h1 mat-dialog-title>
    <ng-container i18n="@@shipment.reject.dialog.title">Reject shipment</ng-container>
  </h1>

  <mat-dialog-content class="mat-typography">
    <p class="light" i18n="@@shipment.reject.dialog.reason.description">The supplier will be notified of your rejection.</p>
    <h3 i18n="@@order.reject.dialog.reason.title">Reason</h3>

    <mat-form-field appearance="fill">
      <mat-label i18n="@@shipment.reject.dialog.reason.label">Why are you rejecting the shipment?</mat-label>
      <textarea matInput formControlName="reason" matTextareaAutosize required></textarea>
      <mat-error *tcMatFormFieldError i18n="@@common.error.required">
        This field is required
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button mat-button color="primary" type="button" mat-dialog-close i18n="button @@common.btn.cancel">CANCEL</button>
    <button mat-button color="primary" type="submit" i18n="@@common.reject">REJECT</button>
  </mat-dialog-actions>

</form>
