<div class="activity-avatar">
  <ng-content select="[tc-activity-picture]"></ng-content>
</div>
<div class="activity-content" [class.internal]="internal">
  <div class="activity-header">
    <h4 class="activity-title">
      <ng-content select="[tc-activity-user-company]"></ng-content>
      <!--<a [routerLink]="companyId && ['/company', companyId]"></a>-->
      <ng-content select="[tc-activity-title]"></ng-content>
      <ng-content select="[tc-activity-document]"></ng-content>
    </h4>
    <div class="activity-timestamp">
      {{ timestamp | date:'dd/MM/yyyy, HH:mm:ss' }}
    </div>
  </div>
  <ng-content></ng-content>
</div>
