import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActionBarComponent } from './components/action-bar/action-bar.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { ChipListComponent } from './components/chip-list/chip-list.component';
import { DateComponent } from './components/date/date.component';
import { DemoComponent } from './components/demo/demo.component';
import { ExpandCollapseComponent } from './components/expand-collapse/expand-collapse.component';
import { LogoComponent } from './components/logo/logo.component';
import { MatFormFieldClearComponent } from './components/mat-form-field-clear/mat-form-field-clear.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { TableColumnsComponent } from './components/table-columns/table-columns.component';
import { TableComponent } from './components/table/table.component';
import { ChipDefDirective } from './components/chip-list/chip-def.directive';
import { ActionButtonDirective } from './directives/action-button/action-button.directive';
import { AutofocusDirective } from './directives/autofocus/autofocus.directive';
import { MatDrawerResponsiveDirective } from './directives/mat-drawer-responsive/mat-drawer-responsive.directive';
import { MatFormFieldErrorDirective } from './directives/mat-form-field-error/mat-form-field-error.directive';
import { MatTabActiveDirective } from './directives/mat-tab-active/mat-tab-active.directive';
import { TrackClickDirective } from './directives/track-click/track-click.directive';
import { NumberSignPipe } from './pipes/number-sign.pipe';
import { API } from '@app/shared/api/api.service';
import { DemoService } from './services/demo.service';
import { UniquePipe } from '@app/shared/pipes/unique.pipe';
import { TwoFactorAuthenticationComponent } from '@app/shared/components/2fa/two-factor-authentication.component';
import { Setup2FAStep1Component } from '@app/shared/components/2fa/step1/setup-2fa-step1.component';
import { Setup2FAStep2Component } from '@app/shared/components/2fa/step2/setup-2fa-step2.component';
import { Setup2FAStep3Component } from '@app/shared/components/2fa/step3/setup-2fa-step3.component';
import { Disable2faComponent } from '@app/shared/components/2fa/disable/disable-2fa.component';
import { QRCodeModule } from 'angularx-qrcode';
import { Setup2FAComponent } from '@app/shared/components/2fa/setup-2fa/setup-2fa.component';
import { SortablejsModule } from 'ngx-sortablejs';
import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { CustomDateAdapter } from '@app/util/custom-date-adapter';
import { Platform } from '@angular/cdk/platform';
import { ProcessStatusComponent } from '@app/shared/components/process-status/process-status.component';
import { LogisticsStatusComponent } from '@app/shared/components/logistics-status/logistics-status.component';
import { CustomI18nMatPaginatorInt } from '@app/util/custom-i18n-paginator';
import { SetupRecoveryCodesComponent } from '@app/shared/components/2fa/step4/setup-recovery-codes.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatStepperModule } from '@angular/material/stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { MatTimepickerModule } from 'mat-timepicker';
import { DebounceInputDirective } from '@app/shared/directives/debounce-input/debounce-input.directive';
import { SuffixActDirective } from '@app/shared/directives/suffix-act/suffix-act.directive';
import { ReRenderDirective } from '@app/shared/directives/re-render/re-render.directive';
import { LabelListComponent } from '@app/shared/components/label-list/label-list.component';
import { AgGridModule } from 'ag-grid-angular';
import { CompanyLimitationService } from '@app/shared/services/company-limitation.service';
import { HasAnyValuesPipe } from '@app/shared/pipes/has-any-values.pipe';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

const materialModules = [
  MatAutocompleteModule,
  MatButtonModule,
  MatButtonToggleModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatNativeDateModule,
  MatExpansionModule,
  MatIconModule,
  MatInputModule,
  MatListModule,
  MatMenuModule,
  MatPaginatorModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatRadioModule,
  MatSelectModule,
  MatSidenavModule,
  MatSnackBarModule,
  MatSortModule,
  MatTableModule,
  MatTabsModule,
  MatToolbarModule,
  MatTooltipModule,
  QRCodeModule,
  ClipboardModule,
  CdkStepperModule,
  MatStepperModule,
  MatSlideToggleModule,
];

const components = [
  ActionBarComponent,
  AutocompleteComponent,
  ChipListComponent,
  DateComponent,
  DemoComponent,
  ExpandCollapseComponent,
  LogoComponent,
  MatFormFieldClearComponent,
  PaginatorComponent,
  TableColumnsComponent,
  TableComponent,
  TwoFactorAuthenticationComponent,
  Setup2FAComponent,
  Setup2FAStep1Component,
  Setup2FAStep2Component,
  Setup2FAStep3Component,
  SetupRecoveryCodesComponent,
  Disable2faComponent,
  ProcessStatusComponent,
  LogisticsStatusComponent,
  LabelListComponent,
];

const entryComponents = [DemoComponent];

const directives = [
  ChipDefDirective,
  ActionButtonDirective,
  AutofocusDirective,
  MatDrawerResponsiveDirective,
  MatFormFieldErrorDirective,
  MatTabActiveDirective,
  TrackClickDirective,
  DebounceInputDirective,
  SuffixActDirective,
  ReRenderDirective,
];

const pipes = [NumberSignPipe, UniquePipe, HasAnyValuesPipe];

const services = [DemoService, API, CompanyLimitationService];

@NgModule({
  imports: [
    CommonModule,
    AgGridModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    RouterModule,
    SortablejsModule,
    MatTimepickerModule,
    NgxSliderModule,
    materialModules,
  ],
  declarations: [components, directives, pipes],
  entryComponents,
  providers: [
    services,
    {
      // Should be removed after fixed issue https://github.com/angular/components/issues/14291
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform],
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'en-GB',
    },
    {
      provide: MatPaginatorIntl,
      useClass: CustomI18nMatPaginatorInt,
    },
  ],
  exports: [
    CommonModule,
    AgGridModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    RouterModule,
    SortablejsModule,
    MatTimepickerModule,
    NgxSliderModule,
    materialModules,
    components,
    directives,
    pipes,
  ],
})
export class RootSharedModule {}
