import { OrderedColumn } from '@app/shared/components/table-columns/table-columns.component';
import { ShipmentTableColumnsType } from '@app/shipment/models/enums/shipment-table-columns-type.enum';

// todo: i18n
export const shipmentDefaultTableColumns: OrderedColumn[] = [
  {
    title: 'Buyer',
    columnDef: ShipmentTableColumnsType.BUYER,
    show: true,
  },
  {
    title: 'Supplier',
    columnDef: ShipmentTableColumnsType.SUPPLIER,
    show: true,
  },
  {
    title: 'Shipment number',
    columnDef: ShipmentTableColumnsType.SHIPMENT_NUMBER,
    show: true,
  },
  {
    title: 'Departure from',
    columnDef: ShipmentTableColumnsType.DEPARTURE_LOCATION,
    show: true,
  },
  {
    title: 'Dispatched at',
    columnDef: ShipmentTableColumnsType.ACTUAL_DATE,
    show: true,
  },
  {
    title: 'Next Destination',
    columnDef: ShipmentTableColumnsType.DESTINATION_LOCATION,
    show: true,
  },
  {
    title: 'Scheduled deliver date',
    columnDef: ShipmentTableColumnsType.DESTINATION_SCHEDULED,
    show: true,
  },
  {
    title: 'Final destination',
    columnDef: ShipmentTableColumnsType.FINAL_DESTINATION,
    show: true,
  },
  {
    title: 'Scheduled deliver date',
    columnDef: ShipmentTableColumnsType.FINAL_DESTINATION_SCHEDULED,
    show: true,
  },
  {
    title: 'Process',
    columnDef: ShipmentTableColumnsType.PROCESS,
    show: true,
  },
];
