import { conflictedMessage, StatusCode } from '@app/core/state/core.model';

export function removeNulls<T extends { [key: string]: any }>(obj: T): T {
  return Object.keys(obj)
    .filter(key => {
      return obj[key] !== undefined;
    })
    .reduce<T>(
      (result, key) => {
        return { ...(result as any), [key]: obj[key] };
      },
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      {} as T,
    );
}

export function coerceArray<T>(value?: T | T[]): T[] {
  return value ? (Array.isArray(value) ? value : [value]) : [];
}

export function coerceNonEmptyArray<T>(value?: T[]): T[] | undefined {
  return value ? (value.length > 0 ? value : undefined) : value;
}

export function isPrerender(): boolean {
  return typeof document !== 'object' || !document;
}

export function removeBreaks(str: string) {
  return `${str.replace(/[\r\n]/gm, '')}`; // Remove all line breaks from string
}

export function formatDate(date: Date): string {
  const leadingZero = (n: number) => {
    return String(`0${n}`).slice(-2);
  };

  const year = date.getFullYear();
  const month = leadingZero(date.getMonth() + 1);
  const day = leadingZero(date.getDate());

  return `${year}-${month}-${day}`;
}

export function makeErrorMessage(initialMsg = ''): ({ error, status }: { error: any; status: number }) => string {
  return ({ error, status }: { error: any; status: number }) => {
    const messageFromAPI = error ? error.message || error : $localize`:@@ts.common.again:Please try again.`;

    let message = `${initialMsg} ${messageFromAPI}`;

    if (status === StatusCode.CONFLICT) {
      message += ` ${conflictedMessage}`;
    }

    return message;
  };
}
