import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EnvService } from '@app/env.service';

@Component({
  selector: 'tc-sidenav-navigation',
  templateUrl: './sidenav-navigation.component.html',
  styleUrls: ['./sidenav-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidenavNavigationComponent {
  @Input()
  companyId!: string;

  @Input()
  currentRoute?: string;

  public readonly shipmentModuleCompanyIds = this.envService.shipmentModuleCompanyIds || '';

  constructor(private envService: EnvService) {}

  public get isNetworkRoute(): boolean | string | undefined {
    return this.currentRoute && ['company', 'user'].includes(this.currentRoute);
  }
}
