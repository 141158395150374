import { NgIfContext } from '@angular/common';
import { Directive, Input, Optional, TemplateRef, ViewContainerRef } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { first, map } from 'rxjs/operators';
import { ConditionalDirective } from '@app/shared/abstracts/conditional.directive';
import { AuthState } from '@app/auth/core/state/auth.reducer';
import { selectAuthIdentityUserRoles } from '@app/auth/core/state/auth.selectors';
import { combineLatest, Observable } from 'rxjs';
import { UserRole } from '@app/user/models/enums/user-role.enum';
import { CompanyRole } from '@app/company/models';
import { selectCompanyRoleFromIdentity } from '@app/company/core/state';
import { RoleManagerService } from '@app/order/routes/order-detail/services/role-manager.service';
import { isNotNullOrUndefined } from '@app/util/operators/is-not-null-or-undefined';

@Directive({
  selector: '[tcRole]',
})
export class RoleDirective extends ConditionalDirective {
  @Input()
  set tcRole(val: (Partial<UserRole> | Partial<CompanyRole>)[]) {
    this.checkRole(val);
  }

  private roles$: Observable<(UserRole | CompanyRole)[] | undefined>;

  constructor(
    private store$: Store<AuthState>,
    viewContainer: ViewContainerRef,
    templateRef: TemplateRef<NgIfContext>,
    @Optional() private roleManagerService?: RoleManagerService,
  ) {
    super(viewContainer, templateRef);
    const companyRoles$: Observable<CompanyRole[]> = this.roleManagerService
      ? this.roleManagerService.appliedRole$
      : this.store$.pipe(select(selectCompanyRoleFromIdentity)).pipe(isNotNullOrUndefined());
    this.roles$ = combineLatest([this.store$.pipe(select(selectAuthIdentityUserRoles)), companyRoles$]).pipe(
      map(([userRoles, companyRoles]) => {
        if (!!userRoles && !!companyRoles) {
          return [...userRoles, ...companyRoles];
        }
        if (!!userRoles && companyRoles) {
          return [...userRoles];
        }
        if (userRoles && !!companyRoles) {
          return [...companyRoles];
        }
      }),
    );
  }

  private checkRole(allowedRoles: (UserRole | CompanyRole)[]): void {
    this.roles$
      .pipe(
        first(r => {
          return !!r;
        }),
      )
      .subscribe(roles => {
        const allowed = roles!.some(role => {
          return allowedRoles.includes(role);
        });

        this.updateView(allowed);
      });
  }
}
