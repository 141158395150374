import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Injectable()
export class CustomI18nMatPaginatorInt extends MatPaginatorIntl {
  /** A label for the page size selector. */
  itemsPerPageLabel: string = $localize`:@@ts.pagination.per.page:Items per page:`;

  /** A label for the button that increments the current page. */
  nextPageLabel: string = $localize`:@@ts.pagination.next.page:Next page`;

  /** A label for the button that decrements the current page. */
  previousPageLabel: string = $localize`:@@ts.pagination.previous.page:Previous page`;

  /** A label for the button that moves to the first page. */
  firstPageLabel: string = $localize`:@@ts.pagination.first.page:First page`;

  /** A label for the button that moves to the last page. */
  lastPageLabel: string = $localize`:@@ts.pagination.first.page:Last page`;

  /** A label for the range of items within the current page and the length of the whole list. */
  getRangeLabel = (page: number, pageSize: number, length: number) => {
    const ofWord = $localize`:Used for paging eg order 1 - 10 of 45 @@ts.pagination.of:of`;

    if (length === 0 || pageSize === 0) {
      return `0 ${ofWord} ${length}`;
    }
    // eslint-disable-next-line
    length = Math.max(length, 0);

    const startIndex = page * pageSize;

    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;

    return `${startIndex + 1} – ${endIndex} ${ofWord} ${length}`;
  };
}
