import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { OrderLineSearchView } from '@app/order/models';
import { OrderLineActionType, orderLineUpsert } from './order-line.actions';
import { TypedAction } from '@ngrx/store/src/models';
import { createReducer, on } from '@ngrx/store';
import { OrderActionType, orderWebSocketUpsert } from '@app/order/core/state/order.actions';

export type OrderLineState = EntityState<OrderLineSearchView>;

export const orderLineAdapter = createEntityAdapter<OrderLineSearchView>({
  selectId: entity => {
    return entity.id;
  },
});

export const initialState = orderLineAdapter.getInitialState();

const orderLineReducer = createReducer<OrderLineState, TypedAction<OrderLineActionType> | TypedAction<OrderActionType>>(
  initialState,
  on(orderLineUpsert, (state, { entity }) => {
    return orderLineAdapter.upsertOne(entity, state);
  }),
  on(orderWebSocketUpsert, (state, { entity: { id, lines, createdAt, supplierOrder, buyerOrder } }) => {
    const orderLineSearchViewList = lines.map(line => {
      const orderLineSearchView: OrderLineSearchView = {
        orderId: id,
        createdAt,
        supplierOrder,
        buyerOrder,
        ...line,
      };

      return orderLineSearchView;
    });

    return orderLineAdapter.upsertMany(orderLineSearchViewList, state);
  }),
);

export function reducer(state = initialState, action: TypedAction<OrderLineActionType>): OrderLineState {
  return orderLineReducer(state, action);
}
