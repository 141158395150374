import { NgIfContext } from "@angular/common";
import { Directive, Input, ViewContainerRef, TemplateRef } from "@angular/core";
import { AuthState } from "@app/auth/core/state/auth.reducer";
import { selectAuthIdentityUserRoles } from "@app/auth/core/state/auth.selectors";
import { selectCompanyRoleFromIdentity } from "@app/company/core/state";
import { CompanyRole } from "@app/company/models";
import { RoleManagerService } from "@app/order/routes/order-detail/services/role-manager.service";
import { ConditionalDirective } from "@app/shared/abstracts/conditional.directive";
import { UserRole } from "@app/user/models/enums/user-role.enum";
import { isNotNullOrUndefined } from "@app/util/operators/is-not-null-or-undefined";
import { Store, select } from "@ngrx/store";
import { Optional } from "ag-grid-community";
import { Observable, combineLatest, map, first } from "rxjs";

@Directive({
  selector: '[tcRoleComplex]',
})
export class RoleComplexDirective extends ConditionalDirective {
  @Input()
  set tcRoleComplex(val: { every?: (Partial<UserRole> | Partial<CompanyRole>)[]; or?: (Partial<UserRole> | Partial<CompanyRole>)[] }) {
    this.checkRole(val);
  }

  private roles$: Observable<(UserRole | CompanyRole)[] | undefined>;

  constructor(
    private store$: Store<AuthState>,
    viewContainer: ViewContainerRef,
    templateRef: TemplateRef<NgIfContext>,
    @Optional() private roleManagerService?: RoleManagerService,
  ) {
    super(viewContainer, templateRef);
    const companyRoles$: Observable<CompanyRole[]> = this.roleManagerService
      ? this.roleManagerService.appliedRole$
      : this.store$.pipe(select(selectCompanyRoleFromIdentity)).pipe(isNotNullOrUndefined());
    this.roles$ = combineLatest([this.store$.pipe(select(selectAuthIdentityUserRoles)), companyRoles$]).pipe(
      map(([userRoles, companyRoles]) => {
        if (!!userRoles && !!companyRoles) {
          return [...userRoles, ...companyRoles];
        }
        if (!!userRoles && companyRoles) {
          return [...userRoles];
        }
        if (userRoles && !!companyRoles) {
          return [...companyRoles];
        }
      }),
    );
  }

  private checkRole(rolesToCheck: { every?: (Partial<UserRole> | Partial<CompanyRole>)[]; or?: (Partial<UserRole> | Partial<CompanyRole>)[] }): void {
    this.roles$
      .pipe(
        first(r => !!r),
      )
      .subscribe(roles => {
        const hasEveryRole = rolesToCheck.every?.every(role => roles!.includes(role)) ?? true;
        const hasOrRole = rolesToCheck.or?.some(role => roles!.includes(role)) ?? false;
        const allowed = hasEveryRole || hasOrRole;

        this.updateView(allowed);
      });
  }
}
