import { createFeatureSelector, createSelector } from '@ngrx/store';
import { orderAdapter, OrderState } from './order.reducer';
import { selectRouterParams } from '@app/store/reducers';

export const orderFeatureName = 'order';

const selectState = createFeatureSelector<OrderState>(orderFeatureName);

const { selectEntities, selectIds } = orderAdapter.getSelectors(selectState);

export const selectOrderById = (id: string) => {
  return createSelector(selectEntities, entities => {
    return entities[id];
  });
};

export const selectOrderIds = selectIds;
export const selectOrderEntities = selectEntities;

export const selectRoutedOrderId = createSelector(selectRouterParams, params => {
  return params && (params.orderId as undefined | string);
});

export const selectRoutedOrder = createSelector(selectRoutedOrderId, selectEntities, (orderId, entities) => {
  return orderId ? entities[orderId] : undefined;
});

export const selectOrderFirstSalesNumber = (id: string) => {
  return createSelector(selectEntities, entities => {
    const order = entities[id];
    if (order && order.lines) {
      const line = order.lines.find(orderLine => {
        return orderLine.supplierLine && orderLine.supplierLine.salesOrderNumber;
      });

      return line && line.supplierLine.salesOrderNumber;
    }

    return undefined;
  });
};

export const selectRoutedOrderLineId = createSelector(selectRouterParams, params => {
  return params && (params.orderLineId as undefined | string);
});

export const selectRoutedOrderLine = createSelector(
  selectRoutedOrder,
  selectRoutedOrderLineId,
  (routedOrder, routedOrderLineId) => {
    return routedOrder
      ? routedOrder.lines.find(line => {
          return line.id === routedOrderLineId;
        })
      : undefined;
  },
);
