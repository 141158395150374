import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[tcReRender]',
})
export class ReRenderDirective implements OnInit {
  constructor(private templateRef: TemplateRef<HTMLElement>, private viewContainer: ViewContainerRef) {}

  @Input() set tcReRender(reload: any) {
    if (reload) {
      this.createComponent();
    }
  }

  ngOnInit(): void {
    this.createComponent();
  }

  private createComponent(): void {
    this.viewContainer.clear();
    this.viewContainer.createEmbeddedView(this.templateRef);
  }
}
