import { NgModule } from '@angular/core';
import { ObjectStorageSharedModule } from '@app/object-storage/shared/object-storage-shared.module';
import { RootSharedModule } from '@app/shared/root-shared.module';
import { CompanyLogoComponent } from './components/company-logo/company-logo.component';
import { CompanyNameComponent } from './components/company-name/company-name.component';
import { CompanySummaryComponent } from './components/company-summary/company-summary.component';
import { LazyCompanyPipe } from './pipes/lazy-company.pipe';
import { TaskOverdueComponent } from '../routes/settings/components/task-overdue/task-overdue.component';


const components = [CompanyLogoComponent,  CompanyNameComponent, TaskOverdueComponent, CompanySummaryComponent];
const pipes = [LazyCompanyPipe];

@NgModule({
  imports: [RootSharedModule, ObjectStorageSharedModule],
  declarations: [components, pipes],
  exports: [components, pipes],
  providers: [LazyCompanyPipe],
})
export class CompanySharedModule {}
