import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { getMsalToken, initiateSsoAuthentication, upsertSsoUser } from '@app/msal/core/state/msal.actions';
import { catchError, concatMap } from 'rxjs/operators';
import { AuthService } from '@app/auth/core/services/auth.service';
import { Observable, of } from 'rxjs';
import { ServerIdentityModel, toIdentity } from '@app/auth/models';
import { UserInterface } from '@app/user/models';
import { authLogout, authRedirectAfterLogin, authSetIdentityAndBootup } from '@app/auth/core/state/auth.actions';
import { notificationSend } from '@app/core/state/core.actions';
import { NotificationType } from '@app/core/state/core.model';
import { AuthenticationResult } from '@azure/msal-browser';
import { StorageKeys, StorageService } from '@app/core/services/storage/storage.service';
import { MsalService } from '@app/msal/msal.service';
import { ROLLBAR } from '@app/core/services/error-tracking/rollbar.factory';
import * as Rollbar from 'rollbar';

@Injectable()
export class MsalEffects {
  initiateSsoAuthentication$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(initiateSsoAuthentication),
      concatMap(({ token }) => {
        return this.authService.upsertSsoIdentity$(token).pipe(
          concatMap((identity: ServerIdentityModel) => {
            return [
              authRedirectAfterLogin({ path: '' }),
              upsertSsoUser({ jwt: token, identity: toIdentity(identity) }),
            ];
          }),
          catchError(() => {
            return this.logoutAndShowTryAgain$();
          }),
        );
      }),
    );
  });

  upsertSsoUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(upsertSsoUser),
      concatMap(payload => {
        return this.authService.upsertSsoUser$({ jwt: payload.jwt, identity: payload.identity }).pipe(
          concatMap((user: UserInterface) => {
            return [authSetIdentityAndBootup(payload.identity)];
          }),
          catchError(() => {
            return this.logoutAndShowTryAgain$();
          }),
        );
      }),
    );
  });

  getAzureADToken$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getMsalToken),
      concatMap(() => {
        return this.msalService.handleRedirectPromise$().pipe(
          concatMap((session: AuthenticationResult | null) => {
            if (!session) {
              return this.logoutAndShowTryAgain$();
            }

            this.storage.set(StorageKeys.MSAL_ACCOUNT, JSON.stringify(session.account));

            return [initiateSsoAuthentication({ account: session.account!, token: session.idToken })];
          }),
          catchError(err => {
            this.rollbar.info('getMsalToken catchError ' + JSON.stringify(err));
            console.error('logoutAndShowTryAgain$', err);

            return this.logoutAndShowTryAgain$();
          }),
        );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private msalService: MsalService,
    private storage: StorageService,
    @Inject(ROLLBAR) private rollbar: Rollbar
  ) {}

  logoutAndShowTryAgain$(): Observable<any> {
    return of(
      authLogout(),
      notificationSend({
        message: 'Try again',
        notificationType: NotificationType.ERROR,
      }),
    );
  }
}
