import { Connection } from '@app/connection/models';
import { UUID } from '@app/shared/models';

export function getOtherCompanyId(companyId: string, connection?: Connection): UUID | undefined {
  return !connection
    ? undefined
    : companyId === connection.requestingCompanyId
    ? connection.acceptingCompanyId
    : connection.requestingCompanyId;
}

export function getOtherCompanyName(companyId: string, connection?: Connection): UUID | undefined {
  return !connection
    ? undefined
    : companyId === connection.requestingCompanyId
    ? connection.acceptingCompanyName
    : connection.requestingCompanyName;
}

export function getOtherCompanyNameAndCode(companyId: string, connection?: Connection): UUID | undefined {
  return !connection
    ? undefined
    : companyId === connection.requestingCompanyId
    ? `${connection.acceptingCompanyName} ${connection.requestingCompanyAccountCode}`
    : `${connection.requestingCompanyName} ${connection.acceptingCompanyAccountCode}`;
}

export function getOtherAccountContactUserId(companyId: string, connection?: Connection): UUID | undefined {
  return !connection
    ? undefined
    : companyId === connection.requestingCompanyId
      ? connection.requestingCompanyAccountContactUserId
      : connection.acceptingCompanyAccountContactUserId;
}

export function getOtherCompanyAccount(companyId: string, connection?: Connection): UUID | undefined {
  return !connection
    ? undefined
    : companyId === connection.requestingCompanyId
    ? connection.requestingCompanyAccountCode
    : connection.acceptingCompanyAccountCode;
}
