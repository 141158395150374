export enum AttachDocumentType {
  GENERAL = 'general',
  CLASS_PRODUCT_CERTIFICATE = 'class-product-certificate',
  MANUFACTURERS_CERTIFICATE = 'manufacturers-certificate',
  TYPE_APPROVAL_CERTIFICATE = 'type-approval-certificate',
  MED_CERTIFICATE = 'med-certificate',
  SAFETY_DATA_SHEET = 'safety-data-sheet',
  MANUAL = 'manual',
  PACKING_INSTRUCTIONS = 'packing-instructions',
  PACKING_LIST = 'packing-list',
  CERTIFICATES_OF_ORIGIN = 'certificates-of-origin',
  BILL_OF_LANDING = 'bill-of-lading',
  DANGEROUS_GOODS_FORMS = 'dangerous-goods-forms',
  INVOICE = 'Invoice',
  CERTIFICATE = 'Certificate',
  PROFORMA_INVOICE = 'Proforma invoice',
  PROOF_DELIVERY = 'Proof of delivery',
  PACKAGING_INSTRUCTION = 'Packaging instruction',
}
