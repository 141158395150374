<mat-list-item class="auto-height two-fa-row">

  <div class="right-column">
    <p i18n="text @@auth.2fa.disable.explanation.with.backup">Enter your 6-digit Two-factor code or unused backup code to disable two factor authentication</p>
    <form [formGroup]="form" (ngSubmit)="submit()">
      <mat-form-field appearance="fill">
        <mat-label i18n="@@shared.2fa.verify.with.backup">Enter a verification or backup code</mat-label>
        <input type="text" matInput formControlName="code">
        <mat-error *tcMatFormFieldError="'required'" i18n="@@shared.err.code">
          Enter a Code
        </mat-error>
        <mat-error *tcMatFormFieldError="'identity.2fa.invalid_code'" i18n="error @@shared.err.invalid.code">
          Invalid Code
        </mat-error>
        <mat-error *tcMatFormFieldError="'identity.2fa.validation_error'" i18n="error @@shared.err.again.code">
          Try Again!
        </mat-error>
      </mat-form-field>

      <button type="submit" mat-raised-button color="primary" [disabled]="!form.valid" i18n="button @@auth.2fa.disable.submit">Disable</button>
    </form>
  </div>
</mat-list-item>
