import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap, exhaustMap, filter, map, take, withLatestFrom } from 'rxjs/operators';
import { AnalyticsService } from '../services/analytics.service';
import {
  AnalyticsStatuses,
  AnalyticsStatusMetricsDtoInterface,
} from '@app/order/core/services/dto/analytics-status-metrics.dto.interface';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { selectAuthIdentityCompanyId } from '@app/auth/core/state/auth.selectors';
import { AuthState } from '@app/auth/core/state/auth.reducer';
import {
  queryAmountPerMonth,
  queryAmountPerMonthSuccess,
  queryCountConnections,
  queryCountConnectionsSuccess,
  queryCountOrderLines,
  queryCountOrderLinesSuccess,
  queryCountPerMonth,
  queryCountPerMonthSuccess,
  queryCountPerStatus,
  queryCountPerStatusSuccess,
  queryCountTasks,
  queryCountTasksSuccess,
  queryKpis,
  queryKpisConfirmed,
  queryKpisConfirmedSuccess,
  queryKpisRequested,
  queryKpisRequestedSuccess,
} from './analytics.actions';
import { selectCompanyOrderRoleFromIdentity } from '@app/company/core/state';
import { TaskService } from '@app/task/core/services/task.service';
import { OrderLineSearchService } from '@app/order/core/services/order-line-search.service';
import { AssignedTo, TaskStatus } from '@app/task/models';
import { ConnectionService } from '@app/connection/core/services/connection.service';

@Injectable()
export class AnalyticsEffects {
  queryAmountPerMonth = createEffect(() => {
    return this.actions$.pipe(
      ofType(queryAmountPerMonth),
      concatMap(() => {
        return this.analyticsService.getAmountPerMonth$().pipe(
          map(response => {
            return queryAmountPerMonthSuccess({ response });
          }),
        );
      }),
    );
  });

  queryCountPerMonth = createEffect(() => {
    return this.actions$.pipe(
      ofType(queryCountPerMonth),
      concatMap(() => {
        return this.analyticsService.getCountPerMonth$().pipe(
          map(response => {
            return queryCountPerMonthSuccess({ response });
          }),
        );
      }),
    );
  });

  queryCountPerStatus = createEffect(() => {
    return this.actions$.pipe(
      ofType(queryCountPerStatus),
      concatMap(() => {
        return combineLatest([
          this.store$.pipe(select(selectAuthIdentityCompanyId)),
          this.store$.pipe(select(selectCompanyOrderRoleFromIdentity)),
        ]).pipe(
          filter(([companyId, companyRoles]) => {
            return !!(companyId && companyRoles);
          }),
          take(1),
        );
      }),
      concatMap(([companyId, companyRole]) => {
        return this.analyticsService
          .getCountPerStatus$(
            [AnalyticsStatuses.ISSUED, AnalyticsStatuses.IN_PROGRESS, AnalyticsStatuses.CONFIRMED],
            companyId,
            companyRole,
          )
          .pipe(
            map((response: AnalyticsStatusMetricsDtoInterface[]) => {
              const issued = response.find(item => {
                return item.status === AnalyticsStatuses.ISSUED;
              });
              const inProgress = response.find(item => {
                return item.status === AnalyticsStatuses.IN_PROGRESS;
              });
              const confirmed = response.find(item => {
                return item.status === AnalyticsStatuses.CONFIRMED;
              });

              return {
                issued: issued && issued.count,
                inProgress: inProgress && inProgress.count,
                confirmed: confirmed && confirmed.count,
              };
            }),
            map(response => {
              return queryCountPerStatusSuccess({ response });
            }),
          );
      }),
    );
  });

  queryKpis$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(queryKpis),
      map(({ query }) => {
        return query;
      }),
      concatMap(query => {
        return [queryKpisConfirmed({ query }), queryKpisRequested({ query })];
      }),
    );
  });

  queryKpisConfirmed$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(queryKpisConfirmed),
      map(({ query }) => {
        return query;
      }),
      concatMap(query => {
        return this.analyticsService.getKpisConfirmed$(query).pipe(
          map(response => {
            return queryKpisConfirmedSuccess({ query, response });
          }),
        );
      }),
    );
  });

  queryKpisRequested$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(queryKpisRequested),
      map(({ query }) => {
        return query;
      }),
      concatMap(query => {
        return this.analyticsService.getKpisRequested$(query).pipe(
          map(response => {
            return queryKpisRequestedSuccess({ query, response });
          }),
        );
      }),
    );
  });

  openedTask$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(queryCountTasks),
      withLatestFrom(this.store$.pipe(select(selectAuthIdentityCompanyId))),
      filter(([, id]) => {
        return !!id;
      }),
      map(([, id]) => {
        return { companyId: id as string };
      }),
      exhaustMap(({ companyId }) => {
        return this.taskService
          .query$({
            offset: 0,
            limit: 1,
            filters: {
              companyId,
              assignee: {
                assignedTo: AssignedTo.COMPANY,
              },
              taskStatus: TaskStatus.Created,
            },
          })
          .pipe(
            map(({ total }) => {
              return queryCountTasksSuccess({ value: total });
            }),
          );
      }),
    );
  });

  connections$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(queryCountConnections),
      withLatestFrom(this.store$.pipe(select(selectAuthIdentityCompanyId))),
      filter(([, id]) => {
        return !!id;
      }),
      map(([, id]) => {
        return { companyId: id as string };
      }),
      exhaustMap(({ companyId }) => {
        return this.connectionService
          .search$({
            companyId,
            body: {
              connected: true,
              offset: 0,
              limit: 1,
            },
          })
          .pipe(
            map(({ total }) => {
              return queryCountConnectionsSuccess({ value: total });
            }),
          );
      }),
    );
  });

  orderLines$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(queryCountOrderLines),
      exhaustMap(() => {
        return this.orderLineSearchService.query$({ offset: 0, limit: 1 }).pipe(
          map(({ total }) => {
            return queryCountOrderLinesSuccess({ value: total });
          }),
        );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private analyticsService: AnalyticsService,
    private taskService: TaskService,
    private orderLineSearchService: OrderLineSearchService,
    private connectionService: ConnectionService,
    private store$: Store<AuthState>,
  ) {}
}
