import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import { AnalyticsStatus, AnalyticsStatusQueryResponse } from '@app/analytics/models';

@Component({
  selector: 'tc-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PieChartComponent implements OnChanges {
  @Input()
  measurements: AnalyticsStatusQueryResponse = {};

  // eslint-disable-next-line
  public isMeasurementsEmpty = true;
  public readonly Highcharts = Highcharts;
  public readonly colorPerStatus = {
    confirmed: '#4caf50', // $tc-positive
    inProgress: '#ffa000', // $tc-warning
    issued: '#448aff', // $tc-action
  };
  public readonly namePerStatus = {
    confirmed: $localize`:@@ts.pie.chart.confirmed:confirmed`,
    inProgress: $localize`:@@ts.pie.chart.in.progress:in progress`,
    issued: $localize`:@@ts.pie.chart.issued:issued`,
  };

  public get options(): Highcharts.Options {
    const statuses = Object.keys(this.measurements) as AnalyticsStatus[];

    const data = statuses
      .map(status => {
        return {
          color: this.colorPerStatus[status],
          name: this.namePerStatus[status],
          y: this.measurements[status] || 0,
        };
      })
      .sort((a, b) => {
        return b.y - a.y;
      });

    return {
      chart: {
        style: {
          fontFamily: 'inherit',
        },
        type: 'pie',
        width: 350,
        height: 200,
      },
      legend: {
        align: 'right',
        layout: 'vertical',
        verticalAlign: 'middle',
        enabled: true,
        itemMarginBottom: 4,
        itemStyle: {
          fontWeight: 'normal',
        },
        /* eslint-disable @typescript-eslint/unbound-method */
        labelFormatter: this.fullPointFormatter,
        /* eslint-enable @typescript-eslint/unbound-method */
      },
      plotOptions: {
        pie: {
          animation: false,
          dataLabels: {
            enabled: false,
          },
          showInLegend: true,
        },
      },
      series: [
        {
          id: Date.now().toString(16),
          type: 'pie',
          data,
        },
      ],
      title: {
        text: '',
      },
      tooltip: {
        headerFormat: '',
        /* eslint-disable @typescript-eslint/unbound-method */
        pointFormatter: this.fullPointFormatter,
        /* eslint-enable @typescript-eslint/unbound-method */
      },
    };
  }

  ngOnChanges({ measurements }: SimpleChanges): void {
    if (measurements && measurements.currentValue) {
      this.isMeasurementsEmpty = Object.values(this.measurements).every(item => {
        return item === 0;
      });
    }
  }

  private fullPointFormatter(this: Highcharts.Point): string {
    const noun =
      this.y === 1 ? $localize`:@@ts.pie.chart.line:order line` : $localize`:@@ts.pie.chart.lines:order lines`;

    return [`<b>${this.percentage.toFixed(1)}% ${this.name}</b>`, `${this.y} ${noun}`].join('<br>');
  }
}
