import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'tc-price-unit',
  templateUrl: './price-unit.component.html',
  styleUrls: ['./price-unit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceUnitComponent {
  @Input()
  quantity?: number;

  @Input()
  unit?: string;

  public get show(): boolean {
    return this.unit !== undefined || this.showQuantity;
  }

  public get showQuantity(): boolean {
    return this.quantity !== undefined && this.quantity !== 1;
  }
}
