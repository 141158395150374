<mat-icon *ngIf="isAdmin" color="primary"
  matTooltip="This user is an admin of this company"
  i18n-matTooltip="@@user.profile.admin"
  matTooltipShowDelay="200">bookmark</mat-icon>

<mat-icon *ngIf="isSupport" color="primary"
  matTooltip="This user is a support user"
  i18n-matTooltip="@@user.profile.support"
  matTooltipShowDelay="200">support_agent</mat-icon>

<mat-icon *ngIf="!isAdmin && isIntegrationUser" color="primary"
  matTooltip="This is an integration user of this company"
  i18n-matTooltip="@@user.profile.no.admin"
  matTooltipShowDelay="200">integration_instructions</mat-icon>
