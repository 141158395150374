import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BuyerLine, OrderLine, OrderLineRequest } from '@app/order/models';
import { dateDifference } from '@app/order/shared/components/date-difference/helpers/date-difference.helper';
import { priceDifference } from '@app/order/shared/components/price-difference/helpers/price-difference.helper';
import { quantityDifference } from '@app/order/shared/components/quantity-difference/helpers/quantity-difference.helper';
import { isChargeLinesChanged } from '@app/order/shared/components/charge-lines-difference/charge-lines-difference.component';
import { priceUnitOfMeasureIsoEquals } from '@app/order/util/helper';

@Component({
  selector: 'tc-line-proposal',
  templateUrl: './line-proposal.component.html',
  styleUrls: ['./line-proposal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineProposalComponent {
  @Input()
  orderLine!: OrderLine;

  public get current(): BuyerLine {
    return this.orderLine.buyerLine;
  }

  public get proposal(): OrderLineRequest {
    if (this.orderLine && this.orderLine.supplierLine.requests && this.orderLine.supplierLine.requests.reopenRequest) {
      return this.orderLine.supplierLine.requests.reopenRequest;
    }

    return this.orderLine.supplierLine.requests.proposal!;
  }

  public get isSingleDelivery(): boolean {
    return this.proposal?.deliverySchedule.length === 1 && this.current.deliverySchedule.length === 1;
  }

  public get singleDeliveryDateChanged(): boolean {
    return this.isDeliveryDateChanged(0);
  }

  public get singleDeliveryQuantityChanged(): boolean {
    return this.isDeliveryQuantityChanged(0);
  }

  public get deliveryScheduleChanged(): boolean {
    return (
      !!this.proposal?.deliverySchedule &&
      (this.proposal?.deliverySchedule.length !== this.current.deliverySchedule.length ||
        this.proposal?.deliverySchedule.some((item, index) => {
          return this.isDeliveryQuantityChanged(index) || this.isDeliveryDateChanged(index);
        }))
    );
  }

  public get grossPriceChanged(): boolean {
    return (
      this.priceUnitChanged ||
      this.proposal.prices.grossPrice?.priceInTransactionCurrency.currencyIso !==
        this.current.prices.grossPrice?.priceInTransactionCurrency.currencyIso ||
      this.proposal.prices.priceUnitQuantity !== this.current.prices.priceUnitQuantity ||
      (!!this.current.prices.grossPrice &&
        priceDifference(this.current.prices.grossPrice, this.proposal.prices.grossPrice) !== 0)
    );
  }

  /* eslint-disable no-prototype-builtins */
  public get discountPercentageChanged(): boolean {
    return (
      this.proposal.prices.hasOwnProperty('discountPercentage') &&
      this.current.prices.hasOwnProperty('discountPercentage') &&
      this.proposal.prices.discountPercentage !== this.current.prices.discountPercentage
    );
  }

  /* eslint-enable no-prototype-builtins */

  public get netPriceChanged(): boolean {
    return this.netPriceUnitChanged || this.pricePerItemChanged;
  }

  public get chargeLineChanged(): boolean {
    return (
      this.current?.chargeLines &&
      this.proposal?.chargeLines &&
      isChargeLinesChanged(this.current.chargeLines, this.proposal.chargeLines)
    );
  }

  private get priceUnitChanged(): boolean {
    return !priceUnitOfMeasureIsoEquals(
      this.proposal.prices.priceUnitOfMeasureIso,
      this.current.prices.priceUnitOfMeasureIso,
    );
  }

  private get netPriceUnitChanged(): boolean {
    return (
      this.priceUnitChanged ||
      this.proposal.prices.netPrice?.priceInTransactionCurrency.currencyIso !==
        this.current.prices.netPrice?.priceInTransactionCurrency.currencyIso
    );
  }

  private get pricePerItemChanged(): boolean {
    return (
      !!this.proposal.prices.netPrice?.priceInTransactionCurrency &&
      !!this.current.prices.netPrice?.priceInTransactionCurrency &&
      this.proposal.prices.netPrice.priceInTransactionCurrency.value / this.proposal.prices.priceUnitQuantity !==
        this.current.prices.netPrice.priceInTransactionCurrency.value / this.current.prices.priceUnitQuantity
    );
  }

  private isDeliveryDateChanged(index: number): boolean {
    return dateDifference(this.current.deliverySchedule[index].date, this.proposal?.deliverySchedule[index].date) !== 0;
  }

  private isDeliveryQuantityChanged(index: number): boolean {
    return (
      quantityDifference(
        this.current.deliverySchedule[index].quantity,
        this.proposal?.deliverySchedule[index].quantity,
      ) !== 0
    );
  }
}
