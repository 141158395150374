import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, exhaustMap, filter, map, mapTo, withLatestFrom } from 'rxjs/operators';
import { NotificationType } from '@app/core/state/core.model';
import { fetchById } from '@app/util/operators/fetch-by-id';
import { filterById } from '@app/util/operators/filter-by-id';
import { AccountService } from '../services/account.service';
import { AccountState, createId } from './account.reducer';
import { selectIds } from './account.selectors';
import {
  accountAdd,
  accountCreate,
  accountCreateFailure,
  accountCreateSuccess,
  accountNotFound, accountReassignSend, accountReassignSendFailure, accountReassignSendSuccess, accountReassignStartSend,
  fetchAccount,
} from './account.actions';
import { notificationSend } from '@app/core/state/core.actions';
import { MatDialog } from '@angular/material/dialog';
import { AssignDialogComponent } from '@app/order/routes/assign/containers/assign-dialog.component';
import { AssignType } from '@app/order/routes/assign/state/assign.model';
import { selectAuthIdentityCompanyId } from '@app/auth/core/state/auth.selectors';

@Injectable()
export class AccountEffects {
  fetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fetchAccount),
      map(({ companyId, accountCompanyId }) => {
        return createId(companyId, accountCompanyId);
      }),
      filterById(this.store$.pipe(select(selectIds))),
      fetchById((id: string) => {
        const [companyId, accountCompanyId]: string[] = id.split('_');

        return this.accountService.getByAccountCompanyId$(companyId, accountCompanyId).pipe(
          map(entity => {
            return accountAdd({ entity });
          }),
          catchError((error: HttpErrorResponse) => {
            if (error.status === 404) {
              return of(accountNotFound({ id }));
            }

            return EMPTY;
          }),
        );
      }),
    );
  });

  create$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountCreate),
      map(({ companyId, body }) => {
        return {
          companyId,
          body,
        };
      }),
      exhaustMap(payload => {
        return this.accountService.create$(payload).pipe(
          mapTo(accountCreateSuccess()),
          catchError(({ error }: HttpErrorResponse) => {
            const id = payload.body.accountCompanyId;

            return of(
              accountCreateFailure({
                id,
                error: error.message || error,
              }),
            );
          }),
        );
      }),
    );
  });

  createSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountCreateSuccess),
      mapTo(
        notificationSend({
          message: $localize`:@@ts.account.success:Account code saved`,
          notificationType: NotificationType.SUCCESS,
        }),
      ),
    );
  });

  createFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountCreateFailure),
      map(({error}) => {
        return notificationSend({
          message: error || `:@@ts.account.failed:Failed to save the account code. Please try again.`,
          notificationType: NotificationType.ERROR,
        });
      }),
    );
  });

  startReassign$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountReassignStartSend),
      withLatestFrom(
        this.store$.pipe(select(selectAuthIdentityCompanyId)),
      ),
      exhaustMap(([{actionType, account}, identityCompanyId]) => {
        return this.dialog.open(AssignDialogComponent, {
          data: {
            value: { companyId: identityCompanyId },
            template: {
              title:
                actionType === AssignType.ASSIGN
                  ? $localize`:@@ts.assign.full.t1:Assign a contact`
                  : $localize`:@@ts.assign.full.t2:Reassign a contact`,
              btnSave:
                actionType === AssignType.ASSIGN
                  ? $localize`:@@ts.assign.b1:ASSIGN`
                  : $localize`:@@ts.assign.b2:REASSIGN`,
              canRemove: actionType === AssignType.REASSIGN,
            },
          },
        })
        .beforeClosed()
        .pipe(
          filter(value => {
            return !!value;
          }),
          map(({ userId }) => {
            return accountReassignSend({
              companyId: identityCompanyId!,
              body: {
                ...account,
                accountContactUserId: userId,
              }
            });
          }),
        )
      }),
    );
  });

  reassign$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountReassignSend),
      concatMap(({companyId, body}) => {
        return this.accountService.update$({ companyId, body})
          .pipe(
            mapTo(accountReassignSendSuccess()),
            catchError((res: HttpErrorResponse) => {
              const id = body.accountCompanyId;

              return of(
                accountReassignSendFailure({
                  id,
                  error: res.error.message || res.error,
                  status: res.status
                }),
              );
            }),
          )
      })
    );
  });

  reassignFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(accountReassignSendFailure),
      map((data) =>
        notificationSend({
          message: data.status === 403 ? data.error : $localize`:@@ts.account.reassign.failed:Failed to change the contact user. Please try again.`,
          notificationType: NotificationType.ERROR,
        }),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private accountService: AccountService,
    private dialog: MatDialog,
    private store$: Store<AccountState>
  ) {}
}
