import { Injectable } from '@angular/core';
import { Parser } from 'json2csv';
import { saveAs } from 'file-saver';
import { formatDate } from '@app/util/helpers';
import { utils, writeFile } from 'xlsx';

@Injectable()
export class CsvFileService {
  public downloadFile(data: any, filename?: string): void {
    const file = new Blob([data], { type: 'text/csv;charset=utf-8' });
    saveAs(file, filename || `${formatDate(new Date())} Tradecloud-One-order-lines-export.csv`);
  }

  public convertToCSV(objArray: any, fields?: any, transforms?: any): string {
    const json2csvParser = new Parser<any>({ fields, transforms });
    const csv = json2csvParser.parse(objArray);

    return csv;
  }

  public downloadToXlsx(str: string): void {
    const arrayOfArrayCsv = str.split('\n').map((row: string) => {
      return this.splitWithQuotedCommas(row).map(col => {
        return col.split('"').join('');
      });
    });
    const wb = utils.book_new();
    const newWs = utils.aoa_to_sheet(arrayOfArrayCsv);
    utils.book_append_sheet(wb, newWs);
    writeFile(wb, `${formatDate(new Date())} Tradecloud-One-order-lines-export.xlsx`);
  }

  private splitWithQuotedCommas(str: string): string[] {
    const regex = /,(?=(?:[^"]*"[^"]*")*(?![^"]*"))/;
    return str.split(regex);
  }
}
