import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AccountCoreModule } from '../account/core/account-core.module';
import { ActivityCoreModule } from '../activity/core/activity-core.module';
import { AnalyticsCoreModule } from '../analytics/core/analytics-core.module';
import { AuthCoreModule } from '../auth/core/auth-core.module';
import { CompanyCoreModule } from '../company/core/company-core.module';
import { ConnectionCoreModule } from '../connection/core/connection-core.module';
import { ConversationCoreModule } from '../conversation/core/conversation-core.module';
import { ObjectStorageCoreModule } from '../object-storage/core/object-storage-core.module';
import { OrderLineCoreModule } from '../order-line/core/order-line-core.module';
import { OrderCoreModule } from '../order/core/order-core.module';
import { ShipmentCoreModule } from '../shipment/core/shipment-core.module';
import { TaskCoreModule } from '../task/core/task-core.module';
import { UserCoreModule } from '../user/core/user-core.module';
import { SharedModule } from '../shared/shared.module';
import { NavigationComponent } from './components/navigation/navigation.component';
import { SearchComponent } from './components/search/search.component';
import { SidenavNavigationComponent } from './components/sidenav-navigation/sidenav-navigation.component';
import { AppComponent } from './containers/app/app.component';
import { DateInterceptor } from './interceptors/date.interceptor';
import { RetryInterceptor } from './interceptors/retry.interceptor';
import { ErrorTrackingService } from './services/error-tracking/error-tracking.service';
import { ROLLBAR, rollbarFactory } from './services/error-tracking/rollbar.factory';
import { MIXPANEL, mixpanelFactory } from './services/page-analytics/mixpanel.factory';
import { PageAnalyticsService } from './services/page-analytics/page-analytics.service';
import { PlatformService } from './services/platform/platform.service';
import { StorageService } from './services/storage/storage.service';
import { UpdateService } from './services/update/update.service';
import { WebSocketService } from './services/web-socket/web-socket.service';
import { RfqCoreModule } from '@app/rfq/core/rfq-core.module';
import { MsalModule } from '@app/msal/msal.module';

import '@angular/common/locales/global/en-GB';
import { DateAdapter } from '@angular/material/core';
import { CacheCoreModule } from '@app/cache/cache-core.module';
import { ConverterCoreModule } from '@app/converter/core/converter-core.module';
import { TtlStorageService } from '@app/core/services/storage/ttl-storage.service';
import { ENV, getEnv } from '@app/core/services/environment.provider';
import { ForecastModule } from '@app/forecast/forecast.module';

@NgModule({
  imports: [
    RouterModule,
    SharedModule,
    AccountCoreModule,
    ActivityCoreModule,
    AnalyticsCoreModule,
    CacheCoreModule,
    AuthCoreModule,
    MsalModule,
    CompanyCoreModule,
    ConversationCoreModule,
    ConnectionCoreModule,
    ObjectStorageCoreModule,
    ConverterCoreModule,
    OrderLineCoreModule,
    OrderCoreModule,
    ForecastModule,
    RfqCoreModule,
    ShipmentCoreModule,
    TaskCoreModule,
    UserCoreModule,
  ],
  declarations: [AppComponent, NavigationComponent, SidenavNavigationComponent, SearchComponent],
  providers: [
    { provide: MIXPANEL, useFactory: mixpanelFactory },
    { provide: ROLLBAR, useFactory: rollbarFactory },
    { provide: ENV, useFactory: getEnv },
    { provide: ErrorHandler, useClass: ErrorTrackingService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DateInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RetryInterceptor,
      multi: true,
    },
    PageAnalyticsService,
    PlatformService,
    StorageService,
    TtlStorageService,
    UpdateService,
    WebSocketService,
  ],
})
export class CoreModule {
  constructor(private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-GB');
  }
}
