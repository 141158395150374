import { UUID } from '@app/shared/models';
import {
  AssignedTo,
  TaskCategory,
  TaskModule,
  TaskQueryFilters,
  TaskQueryFiltersDto,
  TaskStatus,
  TaskType,
} from '@app/task/models';
import { coerceArray } from '@app/util/helpers';

export function mapQueryParametersToFilters(
  companyId: UUID | undefined,
  params: { [key: string]: string | string[] } | undefined | null,
): TaskQueryFilters {
  if (params) {
    // give priority to URL companyId as it might be needed for impersonation
    const selectedCompanyId = params.companyId || companyId;

    return {
      taskType: params.taskType as TaskType,
      taskStatus: (params.taskStatus || TaskStatus.Created) as TaskStatus,
      companyId: selectedCompanyId as UUID,
      relatedCompanyIds: coerceArray(params.relatedCompanyIds),
      contactUserIds: coerceArray(params.contactUserIds),
      module: coerceArray(params?.module) as TaskModule[],
      category: coerceArray(params?.category) as TaskCategory[],
      assignedTo: (params?.assignedTo || undefined) as AssignedTo,
      unassigned: params?.unassigned === 'true',
    };
  }

  return {
    companyId,
  };
}

export function mapQueryParametersToDtoFilters(
  companyId: UUID | undefined,
  params: { [key: string]: string | string[] } | undefined | null,
): TaskQueryFiltersDto {
  if (params) {
    // give priority to URL companyId as it might be needed for impersonation
    const selectedCompanyId = params.companyId || companyId;

    return {
      taskType: params.taskType as TaskType,
      taskStatus: (params.taskStatus || TaskStatus.Created) as TaskStatus,
      companyId: selectedCompanyId as UUID,
      relatedCompanyIds: coerceArray(params.relatedCompanyIds),
      assignee: {
        assignedTo: (params.assignedTo || undefined) as AssignedTo,
        includeUnassigned: params?.unassigned === 'true',
        userIds: coerceArray(params.contactUserIds),
      },
      classification: {
        modules: coerceArray(params?.module) as TaskModule[],
        categories: coerceArray(params?.category) as TaskCategory[],
      },
    };
  }

  return {
    companyId,
  };
}
