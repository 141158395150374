import { createAction, props } from '@ngrx/store';
import { Download } from './object-storage.model';

export enum ObjectStorageActionType {
  UPLOAD = '[Object Storage] Upload',
  UPLOAD_SUCCESS = '[Object Storage] Upload Success',
  UPLOAD_FAILURE = '[Object Storage] Upload Failure',
  DOWNLOAD = '[Object Storage] Download',
  DOWNLOAD_SUCCESS = '[Object Storage] Download Success',
  CROP = '[Object Storage] Crop',
}

export const objectStorageUpload = createAction(
  ObjectStorageActionType.UPLOAD,
  props<{ file: File; correlationId: number }>(),
);
export const objectStorageUploadSuccess = createAction(
  ObjectStorageActionType.UPLOAD_SUCCESS,
  props<{ id: string; correlationId: number }>(),
);
export const objectStorageUploadFailure = createAction(
  ObjectStorageActionType.UPLOAD_FAILURE,
  props<{ correlationId: number; error: string }>(),
);
export const objectStorageDownload = createAction(ObjectStorageActionType.DOWNLOAD, props<{ fileQuery: string }>());
export const objectStorageDownloadSuccess = createAction(
  ObjectStorageActionType.DOWNLOAD_SUCCESS,
  props<{ fileQuery: string; response: Download }>(),
);
export const objectStorageCrop = createAction(
  ObjectStorageActionType.CROP,
  props<{ file: File; correlationId: number }>(),
);
