import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Company } from '@app/company/models';

@Component({
  selector: 'tc-company-summary',
  templateUrl: 'company-summary.component.html',
  styleUrls: ['company-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompanySummaryComponent {
  @Input()
  company!: Company;

  @Input()
  accountCode?: string;
}
