import { Injectable } from '@angular/core';
import { CompanyRole } from '@app/company/models';
import { select, Store } from '@ngrx/store';
import { AuthState } from '@app/auth/core/state/auth.reducer';
import { Observable, ReplaySubject } from 'rxjs';
import { selectCompanyRoleFromIdentity } from '@app/company/core/state';
import { tap } from 'rxjs/operators';
import { isNotNullOrUndefined } from '@app/util/operators/is-not-null-or-undefined';

@Injectable()
export class RoleManagerService {
  public readonly appliedRole$: Observable<CompanyRole[]>;

  private readonly chooseRole$: ReplaySubject<CompanyRole[]> = new ReplaySubject<CompanyRole[]>(1);

  constructor(private store$: Store<AuthState>) {
    this.store$
      .pipe(select(selectCompanyRoleFromIdentity))
      .pipe(
        isNotNullOrUndefined(),
        tap(list => {
          const currentRole = list.filter(role => {
            return role !== CompanyRole.ACT_AS_SUPPLIER;
          });
          this.chooseRole$.next(currentRole);
        }),
      )
      .subscribe();
    this.appliedRole$ = this.chooseRole$.asObservable();
  }

  toggle(role: CompanyRole.SUPPLIER | CompanyRole.BUYER): void {
    this.chooseRole$.next([role]);
  }
}
