import { Pipe, PipeTransform } from '@angular/core';

export function getProperty<T>(pathKey: string, obj: { [key: string]: T | any }): T | null {
  const keys: string[] = pathKey.split('.');

  let checkedProp = obj;

  for (const key of keys) {
    /* eslint-disable no-prototype-builtins */
    if (checkedProp === null || !checkedProp.hasOwnProperty(key)) {
      return null;
    }
    checkedProp = checkedProp[key];
    /* eslint-enable no-prototype-builtins */
  }

  return checkedProp as T;
}

@Pipe({
  name: 'tcUnique',
})
export class UniquePipe implements PipeTransform {
  transform(list: string[] | { [key: string]: string }[], path?: string): string[] {
    if (list && list.length) {
      if (path) {
        const getItem = getProperty.bind(null, path);
        const items = (list as { [key: string]: string }[]).map(getItem);

        return Array.from(new Set(items as string[]));
      }

      return Array.from(new Set(list as string[]));
    }

    return [];
  }
}
