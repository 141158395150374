import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { EnvService } from '@app/env.service';
import { CompanyLimitationService } from '@app/shared/services/company-limitation.service';
import { filter, map, take } from 'rxjs/operators';

@Injectable()
export class EnvSwitchGuard implements CanLoad {
  private readonly shipmentModuleCompanyIdsList = this.envService.shipmentModuleCompanyIds.split(',');

  constructor(private envService: EnvService, private companyLimitService: CompanyLimitationService) {}

  canLoad(
    route: Route,
    segments: UrlSegment[],
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.envService.showMockModules) {
      return of(true);
    }

    if (this.envService.showMetrics) {
      return of(true);
    }

    if (this.shipmentModuleCompanyIdsList.length === 0) {
      return of(false);
    }

    return this.companyLimitService.connectionIds$.pipe(
      filter(r => {
        return !!r;
      }),
      take(1),
      map(ids => {
        const allowed = ids.some(id => {
          return this.shipmentModuleCompanyIdsList.includes(id);
        });

        return allowed;
      }),
    );
  }
}
