import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { TaskService } from './services/task.service';
import { reducer } from './state/task.reducer';
import { taskFeatureName } from '@app/task/core/state/task.selectors';

@NgModule({
  imports: [StoreModule.forFeature(taskFeatureName, reducer)],
  providers: [TaskService],
})
export class TaskCoreModule {}
