import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { EnvService } from '@app/env.service';
import { ConditionalDirective } from '@app/shared/abstracts/conditional.directive';
import { NgIfContext } from '@angular/common';
import { getProperty } from '@app/shared/pipes/unique.pipe';

@Directive({
  selector: '[tcFeatureToggle]',
})
export class FeatureToggleDirective extends ConditionalDirective {
  @Input()
  set tcFeatureToggle(val: string) {
    this.checkFlag(val);
  }

  constructor(private envService: EnvService, viewContainer: ViewContainerRef, templateRef: TemplateRef<NgIfContext>) {
    super(viewContainer, templateRef);
  }

  private checkFlag(flagName: string): void {
    const flag = !!getProperty(flagName, this.envService);
    this.updateView(flag);
  }
}
