import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Account } from '@app/account/models';

@Component({
  selector: 'tc-account-code',
  template: ' <span [class.text-placeholder]="account === undefined">{{ code }}</span> ',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountCodeComponent {
  @Input()
  account?: Account | null;

  public get code(): string | undefined | null {
    return this.account && this.account.accountCode;
  }
}
