import { OrderLine } from '@app/order/models';

export const LABEL_AMOUNT_CURRENCY = $localize`:@@ts.order.details.hint.currency:The total amount could not be calculated because multiple currencies are used within the order`;

export const LABEL_AMOUNT_LINE_FIRST = $localize`:@@ts.order.details.hint.line.1:The total amount could not be calculated because line(s)`;
export const LABEL_AMOUNT_LINE_SECOND = $localize`:@@ts.order.details.hint.line.2:does not have an total amount.`;

export function getLinesPositionWithoutAmount(lines: OrderLine[] = []): string[] {
  return lines
    .filter(line => {
      return !Object.prototype.hasOwnProperty.call(line, 'totalAmountIncludingRequests');
    })
    .map(line => {
      return line.buyerLine.position;
    });
}
