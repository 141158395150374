import { CurrencyPipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { Money } from '@app/order/models';

@Pipe({
  name: 'tcMoney',
})
export class MoneyPipe implements PipeTransform {
  private currencyPipe: CurrencyPipe;

  constructor(@Inject(LOCALE_ID) locale: string) {
    this.currencyPipe = new CurrencyPipe(locale);
  }

  transform(money?: Money): string | null {
    if (!money) {
      return null;
    }

    return this.currencyPipe.transform(money.value, money.currencyIso, 'symbol', '0.2-5');
  }
}
