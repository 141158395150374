import { NgModule } from '@angular/core';
import { CsvFileService } from '@app/converter/core/services/csv-file.service';
import { DatePipe } from '@angular/common';
import { UniquePipe } from '@app/shared/pipes/unique.pipe';

@NgModule({
  imports: [],
  providers: [CsvFileService, DatePipe, UniquePipe],
})
export class ConverterCoreModule {}
