<div fxLayout>
  <div fxFlex="50" class="buyer-col vertical">
    <mat-list class="mat-list--properties mat-list--compact">
      <mat-list-item *ngIf="certification?.type" class="auto-height">
        <div class="property" i18n="@@order.line.details.certification">Certification type</div>
        <div class="value wrap">
          <h4 matLine>{{certification?.type}}</h4>
        </div>
      </mat-list-item>

      <mat-list-item *ngIf="certification?.classificationSociety" class="auto-height">
        <div class="property" i18n="@@order.line.details.classificationSociety">Classification society</div>
        <div class="value wrap">
          <h4 matLine>{{certification?.classificationSociety}}</h4>
        </div>
      </mat-list-item>
    </mat-list>
  </div>
  <div fxFlex="50">

  </div>
</div>
