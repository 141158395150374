import { createFeatureSelector } from '@ngrx/store';
import { connectionAdapter, ConnectionState } from './connection.reducer';

export const connectionFeatureName = 'connection';

const selectState = createFeatureSelector<ConnectionState>(connectionFeatureName);

const { selectEntities } = connectionAdapter.getSelectors(selectState);

export const selectConnectionEntities = selectEntities;
