import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WebSocketService } from '@app/core/services/web-socket/web-socket.service';
import { paramsFromObject } from '@app/util/http';
import { API } from '@app/shared/api/api.service';
import { UUID } from '@app/shared/models';
import {
  AssignedTo,
  CloseConversationTask,
  CloseForecastAcknowledgeTasks,
  CloseOrderAcknowledgeTasks,
  CloseShipmentTasks,
  CloseTaskResponse,
  Task,
  TaskQueryDto,
  TaskQueryResponse,
} from '@app/task/models';
import { Observable } from 'rxjs';
import {
  TaskConversationReassignRequest,
  TaskForecastReassignRequest,
  TaskOrderLineReassignRequest,
  TaskShipmentReassignRequest,
} from '@app/task/routes/task-list/state/task-list.actions';

export const EXTRA_LIMIT = 5;

@Injectable()
export class TaskService {
  constructor(private http: HttpClient, private webSocketService: WebSocketService, private api: API) {}

  public query$(body: TaskQueryDto): Observable<TaskQueryResponse> {
    const bodyWithExtraLimit: TaskQueryDto = {
      ...body,
      limit: body.limit + EXTRA_LIMIT,
    };

    return this.http.post<TaskQueryResponse>(this.api.WORKFLOW_QUERY(), bodyWithExtraLimit);
  }

  public websocket$(
    companyId: UUID,
    assignedTo: AssignedTo | undefined,
    excludeUpdatesFromUserId: string | undefined,
  ): Observable<Task> {
    const assignedToObject = assignedTo ? { assignedTo } : {};
    const excludeToObject = excludeUpdatesFromUserId ? { excludeUpdatesFromUserId } : {};

    const params = paramsFromObject({
      companyId,
      ...assignedToObject,
      ...excludeToObject,
    });

    return this.webSocketService.createWebSocket$<Task>(this.api.WORKFLOW_WS(), params);
  }

  public closeTask$(closeTaskData: CloseConversationTask): Observable<CloseTaskResponse> {
    return this.http.post<CloseTaskResponse>(this.api.WORKFLOW_TASK_CLOSE(), closeTaskData);
  }

  public closeOrderAcknowledgeTasks$(closeTaskData: CloseOrderAcknowledgeTasks): Observable<CloseTaskResponse> {
    return this.http.post<CloseTaskResponse>(this.api.WORKFLOW_ORDER_ACKNOWLEDGE_TASKS_CLOSE(), closeTaskData);
  }

  public closeShipmentTasks$(closeTaskData: CloseShipmentTasks): Observable<CloseTaskResponse> {
    return this.http.post<CloseTaskResponse>(this.api.WORKFLOW_SHIPMENT_TASKS_CLOSE(), closeTaskData);
  }

  public closeForecastAcknowledgeTasks$(closeTasksData: CloseForecastAcknowledgeTasks): Observable<CloseTaskResponse> {
    return this.http.post<CloseTaskResponse>(this.api.WORKFLOW_FORECAST_ACKNOWLEDGE_TASK_CLOSE(), closeTasksData);
  }

  public reassignOrderTask$({ body }: TaskOrderLineReassignRequest): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(this.api.WORKFLOW_ORDER_REASSIGN(), body);
  }

  public reassignConversation$({ body }: TaskConversationReassignRequest): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(this.api.WORKFLOW_CONVERSATION_REASSIGN(), body);
  }

  public reassignForecast$({ body }: TaskForecastReassignRequest): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(this.api.WORKFLOW_FORECAST_REASSIGN(), body);
  }

  public reassignShipment$({ body }: TaskShipmentReassignRequest): Observable<{ ok: boolean }> {
    return this.http.post<{ ok: boolean }>(this.api.WORKFLOW_SHIPMENT_REASSIGN(), body);
  }
}
