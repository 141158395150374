import { createAction, props } from '@ngrx/store';
import { OrderLineSearchView } from '@app/order/models';

export enum OrderLineActionType {
  FETCH = '[Order Line] Fetch',
  UPSERT = '[Order Line] Upsert',
}

export const orderLineFetch = createAction(OrderLineActionType.FETCH, props<Pick<OrderLineSearchView, 'id'>>());
export const orderLineUpsert = createAction(OrderLineActionType.UPSERT, props<{ entity: OrderLineSearchView }>());
