<div fxLayout>
  <div fxFlex="50" class="buyer-col vertical">
    <mat-list class="mat-list--properties mat-list--compact">
      <mat-list-item class="auto-height">
      <div class="property" i18n="@@order.line.details.item">Item</div>
      <div class="value wrap">
        <h4 matLine [class.loading-text]="!item">
          {{ item?.number }}
        </h4>
        <p matLine *ngIf="item?.revision">
          <ng-container i18n="As in the version of an item @@order.line.details.revision">Revision</ng-container>
          &nbsp;
          {{ item?.revision }}
        </p>
        <p matLine *ngIf="item?.name">
          {{ item?.name }}
        </p>
        <p matLine *ngIf="item?.description">
          {{ item?.description }}
        </p>
      </div>
    </mat-list-item>
      <mat-list-item *ngIf="!!dimensions.length" class="auto-height">
      <div class="property" i18n="@@order.line.dimensions">Dimensions</div>
      <div class="value wrap">
        <ng-container *ngFor="let dimension of dimensions">
          <p matLine class="dimension">
            <span class="secondary">{{ dimension.attribute }}</span>
            <span>{{dimension.measure}} {{dimension.unitOfMeasureUnece | tcUnitSymbol}}</span>
          </p>
        </ng-container>
      </div>
    </mat-list-item>
    </mat-list>
  </div>
  <div fxFlex="50" class="supplier-col vertical">
    <mat-list class="mat-list--properties mat-list--compact">
      <mat-list-item *ngIf="item?.supplierItemNumber" class="auto-height">
        <div class="property" i18n="@@order.line.details.supplier">Supplier item</div>
        <div class="value wrap">
          {{ item?.supplierItemNumber }}
        </div>
      </mat-list-item>
    </mat-list>
  </div>
</div>
