<div>
  <span class="section" *ngIf="isSingleDelivery && singleDeliveryDateChanged">
    <strong i18n="@@order.line.proposal.delivery">Delivery</strong>
    {{ current.deliverySchedule[0].date | date:'dd/MM/yyyy' }}
    →
    <span class="change">
      {{ proposal.deliverySchedule[0].date | date:'dd/MM/yyyy' }}
      <tc-date-difference
        [currentDate]="current.deliverySchedule[0].date"
        [proposedDate]="proposal.deliverySchedule[0].date"
      ></tc-date-difference>
    </span>
  </span>

  <span class="section" *ngIf="isSingleDelivery && singleDeliveryQuantityChanged">
    <strong i18n="@@order.line.proposal.quantity">Quantity</strong>
    {{ current.deliverySchedule[0].quantity }} {{ current.item.purchaseUnitOfMeasureIso | tcUnitSymbol }}
    →
    <span class="change">
      {{ proposal.deliverySchedule[0].quantity }} {{ current.item.purchaseUnitOfMeasureIso | tcUnitSymbol }}
      <tc-quantity-difference
        [currentQuantity]="current.deliverySchedule[0].quantity"
        [proposedQuantity]="proposal.deliverySchedule[0].quantity"
      ></tc-quantity-difference>
    </span>
  </span>

  <span class="section" *ngIf="!isSingleDelivery && deliveryScheduleChanged">
    <div class="schedule">
      <div class="schedule__current">
        <strong i18n="@@order.line.proposal.schedule">Current schedule</strong><br>
        <div *ngFor="let line of current.deliverySchedule" class="tc-request__line">
          <span>{{ line.position }}</span>
          <span>{{ line.date | date:'dd/MM/yyyy' }}</span>
          <span>{{ line.quantity }} {{ current.item.purchaseUnitOfMeasureIso | tcUnitSymbol }}</span>
        </div>
      </div>
      <div class="schedule__proposal">
        <strong i18n="@@order.line.proposal.proposal">Proposal</strong><br>
        <div *ngFor="let line of proposal.deliverySchedule; let i = index" class="tc-diff__line">
          <span class="tc-pad-left">{{ line.position }}</span>
          <span>
            {{ line.date | date:'dd/MM/yyyy' }}
            <tc-date-difference
              [currentDate]="current.deliverySchedule[i]?.date"
              [proposedDate]="line.date"
            ></tc-date-difference>
          </span>
          <span class="tc-pad-right">
            {{ line.quantity }} {{ current.item.purchaseUnitOfMeasureIso | tcUnitSymbol }}
            <tc-quantity-difference
              [currentQuantity]="current.deliverySchedule[i]?.quantity"
              [proposedQuantity]="line.quantity"
            ></tc-quantity-difference>
          </span>
        </div>
      </div>
    </div>
  </span>
</div>

<div>
  <ng-container *ngIf="proposal?.prices">
     <span class="section" *ngIf="grossPriceChanged">
    <strong i18n="@@order.line.proposal.price.gross">Gross price</strong>
    {{ current.prices.grossPrice?.priceInTransactionCurrency | tcMoney }}
    <tc-price-unit [quantity]="current.prices.priceUnitQuantity" [unit]="current.prices.priceUnitOfMeasureIso"></tc-price-unit>
    →
    <span class="change">
      {{ proposal.prices.grossPrice?.priceInTransactionCurrency | tcMoney }}
      <tc-price-unit [quantity]="proposal.prices.priceUnitQuantity" [unit]="proposal.prices.priceUnitOfMeasureIso"></tc-price-unit>
    <tc-price-difference
      [currentPrice]="current.prices.grossPrice"
      [proposedPrice]="proposal.prices.grossPrice"
    ></tc-price-difference>
    </span>
  </span>

  <span class="section" *ngIf="discountPercentageChanged">
    <strong i18n="@@order.line.proposal.discount">Discount</strong>
    {{ current.prices.discountPercentage | number: '1.0-5' }}%
    →
    <span class="change">
      {{ proposal.prices.discountPercentage | number: '1.0-5' }}%
    <tc-quantity-difference
      [currentQuantity]="current.prices.discountPercentage"
      [proposedQuantity]="proposal.prices.discountPercentage"
    ></tc-quantity-difference>
    </span>
  </span>

  <span class="section" *ngIf="netPriceChanged">
    <strong i18n="@@order.line.proposal.price.net">Net price</strong>
    {{ current.prices.netPrice?.priceInTransactionCurrency | tcMoney }}
    <tc-price-unit [quantity]="current.prices.priceUnitQuantity" [unit]="current.prices.priceUnitOfMeasureIso"></tc-price-unit>
    →
    <span class="change">
      {{ proposal.prices.netPrice?.priceInTransactionCurrency | tcMoney }}
      <tc-price-unit [quantity]="proposal.prices.priceUnitQuantity" [unit]="proposal.prices.priceUnitOfMeasureIso"></tc-price-unit>
    <tc-price-difference
      [currentPrice]="current.prices.netPrice"
      [proposedPrice]="proposal.prices.netPrice"
    ></tc-price-difference>
    </span>
  </span>

  </ng-container>
</div>

<ng-container *ngIf="chargeLineChanged">
  <tc-charge-lines-difference
    [current]="current!.chargeLines"
    [requested]="proposal!.chargeLines"
  >
  </tc-charge-lines-difference>
</ng-container>
