<ng-container [ngSwitch]="status">
  <span *ngSwitchCase="'Open'" i18n="@@common.open">Open</span>
  <span *ngSwitchCase="'Shipped'" i18n="@@common.logistics.shipped">Shipped</span>
  <span *ngSwitchCase="'Delivered'" i18n="@@common.logistics.delivered">Delivered</span>
  <span *ngSwitchCase="'Produced'" i18n="@@common.logistics.produced">Produced</span>
  <span *ngSwitchCase="'ReadyToShip'" i18n="@@common.logistics.readyToShip">Ready to Ship</span>
  <span *ngSwitchCase="'Cancelled'" i18n="@@common.logistics.cancelled">Cancelled</span>
  <span *ngSwitchDefault><ng-content></ng-content></span>
</ng-container>

<ng-container *ngIf="transportMode">
  / {{ transportMode }}
</ng-container>
