import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tcUnitSymbol',
})
export class UnitSymbolPipe implements PipeTransform {
  static SCSN_CODES_MAP: Record<string, string> = {
    C62: 'pce',
    CMT: 'cm',
    EA: 'pce',
    GRM: 'g',
    KGM: 'kg',
    LTR: 'l',
    MTR: 'm',
    MMT: 'mm',
    MTK: 'm2',
    MTQ: 'm3',
    MLT: 'ml',
  };

  transform(value?: string): string | null {
    if (!value) {
      return null;
    }

    const foundName = UnitSymbolPipe.SCSN_CODES_MAP[value.toUpperCase()];

    return foundName || value;
  }
}
