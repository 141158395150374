import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map, switchMap, tap } from 'rxjs/operators';
import { API } from '@app/shared/api/api.service';
import { JwtService } from '../services/jwt.service';
import { setHeader, setLogoutHeader } from './helpers/set-header.helper';
import { authSetTokens } from '@app/auth/core/state/auth.actions';
import { AuthState } from '@app/auth/core/state/auth.reducer';
import { selectAuthTokens } from '@app/auth/core/state/auth.selectors';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private jwtService: JwtService, private store$: Store<AuthState>, private api: API) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.toExternalDomain(request)) {
      return next.handle(request);
    }

    const startsWithNonProtectedRoutes =
      request.url.startsWith(this.api.TOKEN_REFRESH()) ||
      request.url.startsWith(this.api.LOGIN()) ||
      request.url.startsWith(this.api.VALIDATE()) ||
      request.url.startsWith(this.api.PASSWORD()) ||
      request.url.startsWith(this.api.UPSERT_SSO_IDENTITY());

    if (startsWithNonProtectedRoutes) {
      return next.handle(request).pipe(
        tap(event => {
          this.readTokens(event);
        }),
      );
    }

    // logout with providing refresh token
    if (request.url.startsWith(this.api.LOGOUT())) {
      return this.store$.pipe(
        select(selectAuthTokens),
        first(),
        map(tokens => {
          return tokens ? setLogoutHeader(request, tokens) : request;
        }),
        switchMap(authRequest => {
          return next.handle(authRequest);
        }),
        tap(event => {
          this.readTokens(event);
        }),
      );
    }

    return this.jwtService.getValidTokens$().pipe(
      map(tokens => {
        return setHeader(request, tokens.accessToken);
      }),
      switchMap(authRequest => {
        return next.handle(authRequest);
      }),
      tap(event => {
        this.readTokens(event);
      }),
    );
  }

  private toExternalDomain(request: HttpRequest<any>): boolean {
    try {
      const requestUrl = new URL(request.url);

      return !!requestUrl && !request.url.startsWith(this.api.MAIN);
    } catch (e) {
      // if we're here, the request is made to the same domain as the Angular app so it's safe to proceed
      return false;
    }
  }

  private readTokens(event: HttpEvent<any>): void {
    if (!(event instanceof HttpResponse) || !event.headers.has('Set-Authorization')) {
      return;
    }

    const tokens = {
      accessToken: event.headers.get('Set-Authorization')!,
      refreshToken: event.headers.get('Set-Refresh-Token') || undefined,
    };
    this.store$.dispatch(authSetTokens(tokens));
  }
}
