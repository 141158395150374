<mat-list class="mat-list--properties mat-list--compact">
  <h3 mat-subheader class="mat-subheader--border" i18n="@@order.rfq.line.details.title">RFQ line details</h3>
</mat-list>

<main class="sections" [fxLayout]="layout" [fxLayoutGap]="fxOptions[layout].gap">

  <mat-list class="mat-list--properties mat-list--compact" [fxFlex]="fxOptions[layout].flex">

    <mat-list-item class="auto-height">
      <div class="property" i18n="@@order.rfq.line.details.category">Product class (category)</div>
      <div class="value">
        <h4 matLine class="wrap">
          {{line?.category}}
        </h4>
      </div>
    </mat-list-item>

    <mat-list-item class="auto-height">
      <div class="property" i18n="@@order.rfq.line.details.item">Item</div>
      <div class="value wrap">
        <h4 matLine [class.loading-text]="!item">
          {{ item?.number }}
        </h4>
        <p matLine *ngIf="item?.revision">
          Revision {{ item?.revision }}
        </p>
        <p matLine *ngIf="item?.name">
          {{ item?.name }}
        </p>
        <p matLine *ngIf="item?.description">
          {{ item?.description }}
        </p>
      </div>
    </mat-list-item>

    <mat-list-item class="auto-height">
      <div class="property" i18n="@@order.rfq.line.details.price.title">Pricing</div>
      <div class="value wrap" [class.loading-text]="!prices">
        <h4 matLine *ngIf="prices?.grossPrice">
          <span class="secondary" i18n="@@order.rfq.line.details.price.gross">Gross</span>
          <span>
            {{ prices?.grossPrice?.priceInTransactionCurrency | tcMoney }}
            <tc-price-unit [quantity]="prices?.priceUnitQuantity" [unit]="prices?.priceUnitOfMeasureIso"></tc-price-unit>
          </span>
        </h4>
        <h4 matLine *ngIf="prices?.discountPercentage">
          <span class="secondary" i18n="@@order.rfq.line.details.discount">Discount</span>
          <span>
            {{ prices?.discountPercentage | number }}%
          </span>
        </h4>
        <h4 matLine *ngIf="prices?.netPrice">
          <span class="secondary" i18n="@@order.rfq.line.details.price.net">Net</span>
          <span>
            {{ prices?.netPrice?.priceInTransactionCurrency | tcMoney }}
            <tc-price-unit [quantity]="prices?.priceUnitQuantity" [unit]="prices?.priceUnitOfMeasureIso"></tc-price-unit>
          </span>
        </h4>
      </div>
    </mat-list-item>

  </mat-list>

  <mat-list class="mat-list--properties mat-list--compact" [fxFlex]="fxOptions[layout].flex">
    <mat-list-item *ngIf="deliverySchedule && deliverySchedule.length > 1" class="auto-height">
      <div class="property" i18n="@@order.rfq.line.details.quantity">Quantity</div>
      <div class="value wrap" [class.loading-text]="!deliverySchedule">
        {{ deliverySchedule | tcTotalQuantity | number }}
        {{ item?.purchaseUnitOfMeasureIso }}
      </div>
    </mat-list-item>

    <mat-list-item *ngIf="deliverySchedule && deliverySchedule.length > 0" class="auto-height">
      <div class="property" i18n="@@order.rfq.line.details.delivery">First possible delivery</div>
      <div class="value wrap" [class.loading-text]="!deliverySchedule">
        <ng-container *ngFor="let delivery of deliverySchedule;">
          <h4 matLine>
            <span>
              {{ delivery.date | date:'dd/MM/yyyy' }}
            </span>
            <span class="secondary">
              {{ delivery.quantity | number }}
              {{ item?.purchaseUnitOfMeasureIso }}
            </span>
          </h4>
        </ng-container>
      </div>
    </mat-list-item>
    <mat-list-item class="auto-height">
      <div class="property" i18n="@@order.rfq.line.details.lead">Lead time</div>
      <div class="value wrap">
          <h4 matLine>
            <span>
              {{ line?.leadAt }}
            </span>
          </h4>
      </div>
    </mat-list-item>
  </mat-list>
</main>
