<ng-container [ngSwitch]="activity.activityType">
  <ng-container *ngSwitchCase="ActivityType.OrderDocumentAdded">
    <ng-container i18n="As in attach a document @@activity.document.add">
      attached
    </ng-container>
    <tc-download-document
      *ngIf="activity.content.documents.documents.length === 1"
      [document]="activity.content.documents.documents[0]"
      display="inline"
    ></tc-download-document>
    <ng-container *ngIf="activity.content.documents.documents.length > 1">
      {{ activity.content.documents.documents.length }} <ng-container i18n="@@activity.document.docs">documents</ng-container>
    </ng-container>
    <ng-container i18n="As in Attaching document to order line #123 1@@activity.document.to">
      to
    </ng-container>
    <a
      class="document-order-link"
      [tcTrackClick]="{ eventName: mixpanelOrderClickEvent }"
      [routerLink]="['/orders', activity.entityId]"
    >
      {{ activity.content.documents.buyerOrderDocument.purchaseOrderNumber }}
    </a>
  </ng-container>
  <ng-container *ngSwitchCase="ActivityType.OrderLineDocumentAdded">
    <ng-container i18n="A button @@activity.document.add">
      attached
    </ng-container>
    <tc-download-document
      *ngIf="activity.content.documents.lines[0].documents.length === 1"
      [document]="activity.content.documents.lines[0].documents[0]"
      display="inline"
    ></tc-download-document>
    <ng-container *ngIf="activity.content.documents.lines[0].documents.length > 1">
      {{ activity.content.documents.lines[0].documents.length }} <ng-container i18n="@@activity.document.docs">documents</ng-container>
    </ng-container>
    <ng-container i18n="@@activity.document.to">
      to
    </ng-container>
    <ng-container *ngIf="activity.entityId | tcLazyOrderLine | async as orderLine">
      <a
        class="document-order-link"
        [tcTrackClick]="{ eventName: mixpanelOrderLineClickEvent }"
        [routerLink]="orderLine | tcOrderLineRouterLink"
      >
        <tc-order-line-name [orderLine]="orderLine"></tc-order-line-name>
      </a>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="ActivityType.ShipmentDocumentAdded">
    <ng-container i18n="As in attach a document @@activity.document.add">
      attached
    </ng-container>
    <tc-download-document
      *ngIf="activity.content.shipment?.documents.length === 1"
      [document]="activity.content.shipment?.documents[0]"
      display="inline"
    ></tc-download-document>
    <ng-container *ngIf="activity.content.shipment?.documents.length > 1">
      {{ activity.content.shipment?.documents.length }} <ng-container i18n="@@activity.document.docs">documents</ng-container>
    </ng-container>
    <ng-container i18n="As in Attaching document to order line #123 1@@activity.document.to">
      to
    </ng-container>
    <a
      class="document-order-link"
      [tcTrackClick]="{ eventName: mixpanelShipmentClickEvent }"
      [routerLink]="['/shipments/detail', activity.content?.shipment.id]"
    >
      {{ activity.content?.shipment.supplierShipment.shipmentNumber }}
    </a>
  </ng-container>
</ng-container>
