<div fxLayout>
  <div fxFlex="50" class="buyer-col vertical">
    <mat-list class="mat-list--properties mat-list--compact">
      <mat-list-item  class="mat-list-item--multi-line auto-height">
        <ng-container *ngIf="buyerId | tcLazyCompany | async as company">
          <tc-company-summary [company]="company" [accountCode]="buyerAccountNumber"></tc-company-summary>
        </ng-container>
      </mat-list-item>

      <mat-list-item class="auto-height">
        <ng-container *ngIf="order?.buyerOrder?.orderType === 'RFQ'; else noRfqType" >
          <div class="property" i18n="@@common.rqf.line">RFQ line</div>
        </ng-container>
        <ng-template #noRfqType>
          <div class="property" i18n="@@order.line.buyer.details.supplier.line">Purchase order line</div>
        </ng-template>
        <div class="value">
          <h4 matLine class="wrap" [class.loading-text]="!purchaseOrderLinePosition">
            {{ purchaseOrderLinePosition }}
          </h4>
        </div>
      </mat-list-item>

      <mat-list-item class="auto-height" *ngIf="purchaseOrderLineDescription">
        <div class="property" i18n="line.desc">
          Line description
        </div>
        <div class="value">
          <p matLine class="wrap">
            {{ purchaseOrderLineDescription }}
          </p>
        </div>
      </mat-list-item>

      <mat-list-item class="auto-height">
        <div class="property"
             i18n="@@common.field.amount"
             matTooltip="Order total amount could not be calculated because there are differences in the price & purchase unit of measure, or in the currency of the line and charge line."
             i18n-matTooltip="@@tooltip.amount"
             matTooltipPosition="after"
             [matTooltipDisabled]="!!amountView"
        >Total amount</div>
        <div class="value wrap">
          <h4 matLine class="pos-end">
          <span [ngClass]="{'tc-change': amountChanged}">
            {{ amountView | tcMoney }}
            <tc-amount-difference
              [amount]="orderLine?.totalAmount"
              [amountIncludingRequests]="orderLine?.totalAmountIncludingRequests"
            ></tc-amount-difference>
          </span>
          </h4>
        </div>
      </mat-list-item>

      <mat-list-item *ngIf="buyerProjectNumber" class="auto-height">
        <div class="property" i18n="@@order.line.buyer.details.project.number">Project number</div>
        <div class="value wrap">
          {{ buyerProjectNumber }}
        </div>
      </mat-list-item>

      <mat-list-item *ngIf="buyerProductionNumber" class="auto-height">
        <div class="property" i18n="@@order.line.buyer.details.production.number">Production number</div>
        <div class="value wrap">
          {{ buyerProductionNumber }}
        </div>
      </mat-list-item>

      <mat-list-item *ngIf="salesOrderNumber" class="auto-height">
        <div class="property" i18n="@@common.field.sales.order.number">Sales order number</div>
        <div class="value wrap">
          {{ salesOrderNumber }}
        </div>
      </mat-list-item>

      <mat-list-item class="auto-height">
        <tc-label-list [removable]="buyerId === companyId" (removeLabel)="onRemoveLabel($event)" [labels]="orderLine?.buyerLine.customLabels"></tc-label-list>
      </mat-list-item>

    </mat-list>
  </div>
  <div fxFlex="50" class="supplier-col vertical">
    <mat-list class="mat-list--properties mat-list--compact">
      <mat-list-item class="mat-list-item--multi-line auto-height">
        <ng-container *ngIf="supplierId | tcLazyCompany | async as company">
          <tc-company-summary [company]="company" [accountCode]="supplierAccountNumber"></tc-company-summary>
        </ng-container>
      </mat-list-item>

      <mat-list-item *ngIf="supplierSalesOrderNumber" class="auto-height">
        <div class="property" i18n="@@order.line.supplier.details.number">
          Sales order number
        </div>
        <div class="value wrap">
          <h4 matLine>
            {{ supplierSalesOrderNumber }}
          </h4>
        </div>
      </mat-list-item>
      <mat-list-item *ngIf="supplierSalesOrderLineDescription" class="auto-height">
        <div class="property" i18n="line.desc">
          Line description
        </div>
        <div class="value wrap">
          <h4 matLine>
            {{ supplierSalesOrderLineDescription }}
          </h4>
        </div>
      </mat-list-item>

      <mat-list-item class="auto-height">
        <tc-label-list [removable]="supplierId === companyId" (removeLabel)="onRemoveLabel($event)" [labels]="orderLine?.supplierLine.customLabels"></tc-label-list>
      </mat-list-item>

      <mat-list-item class="auto-height" *ngIf="labels?.length > 0">
        <div class="property" i18n="@@order.line.buyer.details.services">Value-added services labels</div>
        <div class="value wrap">
          <ng-container *ngFor="let label of labels">
            <p matLine>
              {{ label }}
            </p>
          </ng-container>
        </div>
      </mat-list-item>

    </mat-list>
  </div>

</div>
