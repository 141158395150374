<ng-container *ngIf="expanding; else searchBox">
  <tc-expand-collapse
    [open]="expandSearch$ | async"
    (toggleChange)="cachingExpand($event)"
    i18n-summary="@@order.search.summary"
    summary="Search"
  >
    <ng-container
      *ngTemplateOutlet="searchBox"
    ></ng-container>
  </tc-expand-collapse>
  <mat-divider></mat-divider>
</ng-container>

<ng-template #searchBox>
  <mat-list-item class="chip-list-container">
    <mat-chip-list #chipList>
      <mat-chip
        *ngFor="let query of queries"
        [selectable]="true"
        [removable]="true"
        (removed)="remove(query)"
        class="search-chip"
      >
        {{query.name}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </mat-list-item>

  <mat-list-item>
    <mat-form-field floatLabel="never">
      <input matInput
             type="text"
             [placeholder]="placeholderLabel"
             [matChipInputFor]="chipList"
             [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
             [matChipInputAddOnBlur]="true"
             (matChipInputTokenEnd)="add($event)">
    </mat-form-field>
  </mat-list-item>
</ng-template>
