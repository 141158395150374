import { Pipe, PipeTransform } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';

import { Account } from '../../models';
import { fetchAccount } from '../../core/state/account.actions';
import { AccountState } from '../../core/state/account.reducer';
import { selectById } from '../../core/state/account.selectors';

@Pipe({
  name: 'tcLazyAccount',
})
export class LazyAccountPipe implements PipeTransform {
  constructor(private store$: Store<AccountState>) {}

  // eslint-disable-next-line
  transform(accountCompanyId?: string, companyId?: string): Observable<Account | null | undefined> {
    if (!accountCompanyId || !companyId) {
      return of(undefined);
    }

    this.store$.dispatch(fetchAccount({ accountCompanyId, companyId }));

    return this.store$.pipe(select(selectById(companyId, accountCompanyId)));
  }
}
