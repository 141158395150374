import { Injectable } from '@angular/core';
import { CanLoad } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { first, tap } from 'rxjs/operators';

import { Observable } from 'rxjs';
import { authRedirectToLogin } from '../state/auth.actions';
import { AuthState } from '../state/auth.reducer';
import { selectAuthAuthenticated } from '../state/auth.selectors';

@Injectable()
export class PrivateGuard implements CanLoad {
  constructor(private store$: Store<AuthState>) {}

  canLoad(): Observable<boolean> {
    return this.store$.pipe(
      select(selectAuthAuthenticated),
      first((authed): authed is boolean => {
        return authed !== undefined;
      }),
      tap(authed => {
        if (!authed) {
          this.store$.dispatch(authRedirectToLogin({ path: window.location.pathname }));
        }
      }),
    );
  }
}
