import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@app/shared/api/api.service';
import { Observable } from 'rxjs';
import { FreeGeoIpAPP, TwoFACode } from '@app/auth/core/state/auth.model';
import { BackupCode, Identity, ServerIdentityModel } from '@app/auth/models';
import { UserInterface } from '@app/user/models';
import { CreateOrGetSsoUserPayload } from '@app/msal/core/state/msal.model';

@Injectable()
export class AuthService {
  private REFRESH_TOKEN = 'Refresh-Token';

  constructor(private http: HttpClient, private api: API) {}

  public refresh$(refreshToken: string): Observable<string> {
    const headers = new HttpHeaders().set(this.REFRESH_TOKEN, refreshToken);

    return this.http.get(this.api.TOKEN_REFRESH(), {
      headers,
      responseType: 'text',
    });
  }

  public logout$(): Observable<string> {
    return this.http.post(this.api.LOGOUT(), null, {
      responseType: 'text',
    });
  }

  generate2FASecret$(): Observable<{ secret: string }> {
    return this.http.post<{ secret: string }>(this.api.GENERATE_2FA_SECRET(), null);
  }

  enable2FA$(twoFACode: TwoFACode, refreshToken?: string): Observable<ServerIdentityModel> {
    const headers = refreshToken ? { [this.REFRESH_TOKEN]: refreshToken } : {};

    return this.http.post<ServerIdentityModel>(
      this.api.ENABLE_2FA(),
      {
        code: parseInt(twoFACode.code, 10),
      },
      {
        headers,
      },
    );
  }

  disable2FA$(twoFACode: TwoFACode, refreshToken?: string): Observable<ServerIdentityModel> {
    const headers = refreshToken ? { [this.REFRESH_TOKEN]: refreshToken } : {};

    return this.http.post<ServerIdentityModel>(
      this.api.DISABLE_2FA(),
      {
        code: parseInt(twoFACode.code, 10),
      },
      {
        headers,
      },
    );
  }

  upsertSsoIdentity$(jwtToken: string): Observable<ServerIdentityModel> {
    return this.http.post<Identity>(this.api.UPSERT_SSO_IDENTITY(), { jwt: jwtToken });
  }

  upsertSsoUser$(payload: CreateOrGetSsoUserPayload): Observable<UserInterface> {
    return this.http.put<UserInterface>(this.api.UPSERT_SSO_USER(), {
      newUserId: payload.identity.userId,
      jwt: payload.jwt,
    });
  }

  disable2FAViaRecoveryCode$(body: BackupCode): Observable<ServerIdentityModel> {
    return this.http.post<ServerIdentityModel>(this.api.DISABLE_RECOVERY_2FA(), body);
  }

  checkUserGEO$(): Observable<FreeGeoIpAPP> {
    return this.http.get<FreeGeoIpAPP>(this.api.GEO_IP());
  }
}
