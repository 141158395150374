import { NgModule } from '@angular/core';
import { ConversationPrivateModule } from '@app/conversation/private/conversation-private.module';
import { SendMessageComponent } from '@app/conversation/shared/containers/send-message/send-message.component';
import { RootSharedModule } from '@app/shared/root-shared.module';

const containers = [SendMessageComponent];

@NgModule({
  imports: [ConversationPrivateModule, RootSharedModule],
  declarations: [containers],
  exports: [containers],
})
export class ConversationSharedModule {}
