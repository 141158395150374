import { NgModule } from '@angular/core';
import { CompanySharedModule } from '@app/company/shared/company-shared.module';
import { RootSharedModule } from '@app/shared/root-shared.module';
import { UserSharedModule } from '@app/user/shared/user-shared.module';
import { DateDifferenceComponent } from './components/date-difference/date-difference.component';
import { LineDetailsComponent } from './components/line-details/line-details.component';
import { LineProposalComponent } from './components/line-proposal/line-proposal.component';
import { OrderActivityComponent } from './components/order-activity/order-activity.component';
import { OrderCommentComponent } from './components/order-comment/order-comment.component';
import { OrderNameComponent } from './components/order-name/order-name.component';
import { PriceDifferenceComponent } from './components/price-difference/price-difference.component';
import { PriceUnitComponent } from './components/price-unit/price-unit.component';
import { QuantityDifferenceComponent } from './components/quantity-difference/quantity-difference.component';
import { CurrencySymbolPipe } from './pipes/currency-symbol.pipe';
import { IncotermsNamePipe } from './pipes/incoterms-name.pipe';
import { LazyOrderPipe } from './pipes/lazy-order.pipe';
import { MoneyPipe } from './pipes/money.pipe';
import { TotalQuantityPipe } from './pipes/total-quantity.pipe';

import { OrderDetailsComponent } from '@app/order/private/components/order-details/order-details.component';
import { RfqLineDetailsComponent } from '@app/order/shared/components/rfq-line-details/rfq-line-details.component';
import { LineReopenComponent } from './components/line-reopen/line-reopen.component';
import { HasRequestOpenStatusPipe } from '@app/order/shared/pipes/has-request-open-status.pipe';
import { FirstOrderSalesNumberPipe } from './pipes/first-order-sales-number.pipe';
import { MergeDeliverySchedulePipe } from './pipes/merge-delivery-schedule.pipe';
import { UnitSymbolPipe } from './pipes/unit-symbol.pipe';
import { OrderTypeComponent } from './components/order-type/order-type.component';
import { HasLineChargeLinesPipe } from './pipes/has-line-charge-lines.pipe';
import { ChargeLinesDifferenceComponent } from './components/charge-lines-difference/charge-lines-difference.component';
import { QuantityChargeLinesPipe } from './pipes/quantity-charge-lines.pipe';
import { ChargeLineTableComponent } from '@app/order/shared/components/charge-line-table/charge-line-table.component';
import { LazyShipmentPipe } from './pipes/lazy-shipment.pipe';
import { SearchBoxComponent } from '@app/order/shared/components/search-box/search-box.component';
import { AmountDifferenceComponent } from './components/amount-difference/amount-difference.component';
import { NotesComponent } from './components/notes/notes.component';
import { OrderPropertiesComponent } from './components/order-properties/order-properties.component';
import { OrderDestinationComponent } from './components/order-destination/order-destination.component';
import { ContactInfoComponent } from './components/contact-info/contact-info.component';
import { AuthSharedModule } from '@app/auth/shared/auth-shared.module';
import { RemoveLabelDialogComponent } from './components/remove-label-dialog/remove-label-dialog.component';
import { OrderInvoiceComponent } from './components/order-invoice/order-invoice.component';
import { LineRiskIndicatorComponent } from './components/line-risk-indicator/line-risk-indicator.component';

const components = [
  DateDifferenceComponent,
  OrderDetailsComponent,
  LineDetailsComponent,
  RfqLineDetailsComponent,
  LineProposalComponent,
  OrderActivityComponent,
  OrderCommentComponent,
  OrderNameComponent,
  PriceDifferenceComponent,
  PriceUnitComponent,
  QuantityDifferenceComponent,
  LineReopenComponent,
  OrderTypeComponent,
  ChargeLinesDifferenceComponent,
  ChargeLineTableComponent,
  SearchBoxComponent,
  AmountDifferenceComponent,
  NotesComponent,
  OrderPropertiesComponent,
  OrderDestinationComponent,
  ContactInfoComponent,
  OrderInvoiceComponent,
];

const pipes = [
  CurrencySymbolPipe,
  IncotermsNamePipe,
  LazyOrderPipe,
  MoneyPipe,
  TotalQuantityPipe,
  HasRequestOpenStatusPipe,
  MergeDeliverySchedulePipe,
  FirstOrderSalesNumberPipe,
  UnitSymbolPipe,
  HasLineChargeLinesPipe,
  QuantityChargeLinesPipe,
  LazyShipmentPipe,
];

@NgModule({
  imports: [RootSharedModule, CompanySharedModule, UserSharedModule, AuthSharedModule],
  declarations: [components, pipes, RemoveLabelDialogComponent, LineRiskIndicatorComponent],
  exports: [components, pipes, LineRiskIndicatorComponent],
  providers: [HasRequestOpenStatusPipe, MergeDeliverySchedulePipe, UnitSymbolPipe, MoneyPipe],
})
export class OrderSharedModule {}
