<ng-select
  #select
  [items]="items$ | async"
  class="forecast-select"
  placeholder="Type to search"
  bindLabel="name"
  bindValue="id"
  [loading]="loading"
  [typeahead]="input$"
  [(ngModel)]="selectedId"
  (change)="onChange($event)"
></ng-select>
