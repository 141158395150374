import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { ShipmentService } from './services/shipment.service';
import { reducer } from './state/shipment.reducer';
import { ShipmentSearchService } from '@app/shipment/core/services/shipment-search.service';

@NgModule({
  imports: [StoreModule.forFeature('shipment', reducer)],
  providers: [ShipmentService, ShipmentSearchService],
})
export class ShipmentCoreModule {}
