import { Price } from '@app/order/models';

export function priceDifference(currentPrice?: Price, proposedPrice?: Price): number | undefined {
  if (!currentPrice || !proposedPrice || currentPrice.priceInTransactionCurrency.value === 0) {
    return undefined;
  }

  const ratio = proposedPrice.priceInTransactionCurrency.value / currentPrice.priceInTransactionCurrency.value;

  return ratio - 1;
}
