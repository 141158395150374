import { createAction, props } from '@ngrx/store';
import { AcceptConnection, Connection, RequestConnection } from '@app/connection/models';

export enum ConnectionActionType {
  ADD_MANY = '[Connection] Add Many',
  REQUEST = '[Connection] Request',
  REQUEST_SUCCESS = '[Connection] Request Success',
  REQUEST_FAILURE = '[Connection] Request Failure',
  ACCEPT = '[Connection] Accept',
  ACCEPT_SUCCESS = '[Connection] Accept Success',
  ACCEPT_FAILURE = '[Connection] Accept Failure',
}

export const connectionAddMany = createAction(ConnectionActionType.ADD_MANY, props<{ entities: Connection[] }>());
export const connectionRequest = createAction(ConnectionActionType.REQUEST, props<RequestConnection>());
export const connectionRequestSuccess = createAction(ConnectionActionType.REQUEST_SUCCESS);
export const connectionRequestFailure = createAction(
  ConnectionActionType.REQUEST_FAILURE,
  props<{ id: string; error: string }>(),
);
export const connectionAccept = createAction(ConnectionActionType.ACCEPT, props<AcceptConnection>());
export const connectionAcceptSuccess = createAction(ConnectionActionType.ACCEPT_SUCCESS);
export const connectionAcceptFailure = createAction(
  ConnectionActionType.ACCEPT_FAILURE,
  props<{ id: string; error: string }>(),
);
