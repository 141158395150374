import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Directive, Host, OnDestroy, OnInit } from '@angular/core';
import { MatDrawer } from '@angular/material/sidenav';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[tcMatDrawerResponsive]',
})
export class MatDrawerResponsiveDirective implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();

  constructor(@Host() private matDrawer: MatDrawer, private breakpointObserver: BreakpointObserver) {}

  ngOnInit(): void {
    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small])
      .pipe(
        map(result => {
          return result.matches;
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe(smallScreen => {
        this.matDrawer.mode = smallScreen ? 'over' : 'side';
        this.matDrawer.opened = !smallScreen;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
