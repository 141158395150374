import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import { select, Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, exhaustMap, filter, map, mapTo, withLatestFrom } from 'rxjs/operators';
import { NotificationType } from '@app/core/state/core.model';
import { fetchById } from '@app/util/operators/fetch-by-id';
import { filterById } from '@app/util/operators/filter-by-id';
import { UserService } from '../services/user.service';
import { notificationSend } from '@app/core/state/core.actions';
import {
  userFetch,
  userFetchFailure,
  userListen,
  userReinvite,
  userReinviteFailure,
  userReinviteSuccess,
  userRemove,
  userRemoveFailure,
  userRemoveSuccess,
  userSSOUpdate,
  userUpdate,
  userUpdateFailure,
  userUpdateSuccess,
  userUpsert,
} from './user.actions';
import { UserState } from '@app/user/core/state/user.reducer';
import { selectRoutedUserId, selectUserById, selectUserIds } from './user.selectors';
import { UpdateUserSSOInterface, UserInterface } from '@app/user/models';

@Injectable()
export class UserEffects {
  fetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userFetch),
      map(({ id }) => {
        return id;
      }),
      filterById(this.store$.pipe(select(selectUserIds))),
      fetchById(id => {
        return this.userService.searchUserById$(id).pipe(
          map(entity => {
            return userUpsert({ entity });
          }),
          catchError(({ error }: HttpErrorResponse) => {
            return of(userFetchFailure({ id, error: error.message || error }));
          }),
        );
      }),
    );
  });

  update$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userUpdate),
      concatMap(({ id, changes }) => {
        return this.userService.update$(id, changes).pipe(
          map(userUpdateSuccess),
          catchError(({ error }: HttpErrorResponse) => {
            return of(
              userUpdateFailure({
                id,
                error: error.message || error,
              }),
            );
          }),
        );
      }),
    );
  });

  updateSSO$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userSSOUpdate),
      concatMap(({ id, changes }) => {
        const {
          profile: { firstName: skippedField1, lastName: skippedField2, ...profInfo },
          settings,
        } = changes;

        const userWithoutName: UpdateUserSSOInterface = {
          settings,
          profile: { ...profInfo },
        };

        return this.userService.update$(id, userWithoutName).pipe(
          map(userUpdateSuccess),
          catchError(({ error }: HttpErrorResponse) => {
            return of(
              userUpdateFailure({
                id,
                error: error.message || error,
              }),
            );
          }),
        );
      }),
    );
  });

  updateSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userUpdateSuccess),
      mapTo(
        notificationSend({
          message: $localize`:@@ts.user.update.success:Operation completed. Your information will be updated in several minutes.`,
          notificationType: NotificationType.SUCCESS,
        }),
      ),
    );
  });

  updateFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userUpdateFailure),
      mapTo(
        notificationSend({
          message: $localize`:@@ts.user.update.failed:Failed to update the user settings. Please try again.`,
          notificationType: NotificationType.ERROR,
        }),
      ),
    );
  });

  reinvite$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userReinvite),
      exhaustMap(({ id }) => {
        return this.userService.reinvite$(id).pipe(
          map(userReinviteSuccess),
          catchError(({ status }: HttpErrorResponse) => {
            if (status === 403) {
              return of(
                userReinviteFailure({
                  id,
                  error: 'You are not authorized for this action. Please ask an admin user.',
                }),
              );
            }

            return of(
              userReinviteFailure({
                id,
                error: 'Operation completed. Your user will be reinvited in several minutes.',
              }),
            );
          }),
        );
      }),
    );
  });

  reinviteSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userReinviteSuccess),
      mapTo(
        notificationSend({
          message: $localize`:@@ts.user.reinvite.success:Operation completed. Your user will be reinvited in several minutes.`,
          notificationType: NotificationType.SUCCESS,
        }),
      ),
    );
  });

  reinviteFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userReinviteFailure),
      map(({ error }) => {
        return notificationSend({
          message: error,
          notificationType: NotificationType.ERROR,
        });
      }),
    );
  });

  remove$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userRemove),
      exhaustMap(({ id }) => {
        return this.userService.remove$(id).pipe(
          withLatestFrom(this.store$.pipe(select(selectUserById(id)))),
          map(([_, user]) => {
            return userRemoveSuccess({ entity: user as UserInterface });
          }),
          catchError(({ error }: HttpErrorResponse) => {
            return of(userRemoveFailure({ error: error.message, id }));
          }),
        );
      }),
    );
  });

  removeSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userRemoveSuccess),
      map(({ entity }) => {
        const fullName = ` ${entity.profile.firstName} ${entity.profile.lastName}`;
        const messageSuccess = $localize`:As in removed user X @@ts.user.remove.success:Successfully removed`;

        return notificationSend({
          message: messageSuccess + fullName,
          notificationType: NotificationType.SUCCESS,
        });
      }),
    );
  });

  removeFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userRemoveFailure),
      concatMap(({ id }) => {
        return of(id).pipe(withLatestFrom(this.store$.pipe(select(selectUserById(id)))));
      }),
      map(([_, user]) => {
        const fullName = ` ${user!.profile.firstName} ${user!.profile.lastName} `;
        const messageStart = $localize`:As in Failed to remove user X @@ts.user.remove.failure:Failed to remove`;
        const messageEnd = $localize`:@@ts.common.again:Please try again.`;

        return notificationSend({
          message: messageStart + fullName + messageEnd,
          notificationType: NotificationType.ERROR,
        });
      }),
    );
  });

  listen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(userListen),
      map(({ companyId }) => {
        return companyId;
      }),
      concatMap(companyId => {
        return this.userService.websocket$(companyId).pipe(
          map(
            entity => {
              return userUpsert({ entity, force: true });
            },
            catchError(() => {
              return EMPTY;
            }),
          ),
        );
      }),
    );
  });

  route$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      withLatestFrom(this.store$.pipe(select(selectRoutedUserId))),
      filter(([action, id]) => {
        return !!id;
      }),
      map(([action, id]) => {
        return userFetch({ id: id! });
      }),
    );
  });

  constructor(private actions$: Actions, private store$: Store<UserState>, private userService: UserService) {}
}
