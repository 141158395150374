import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { filter, first, tap } from 'rxjs/operators';
import { getCorrelationId } from '@app/util/correlation-id';
import { objectStorageCrop } from '@app/object-storage/core/state/object-storage.actions';
import { ObjectStorageState } from '@app/object-storage/core/state/object-storage.reducer';
import { selectObjectUploadResponse } from '@app/object-storage/core/state/object-storage.selectors';

@Component({
  selector: 'tc-upload-picture',
  templateUrl: './upload-picture.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadPictureComponent {
  @Input()
  current?: string; // todo: i18n into template

  @Output()
  readonly uploaded = new EventEmitter<string>();

  public uploading$ = new BehaviorSubject(false);

  constructor(private store$: Store<ObjectStorageState>) {}

  public onFileSelect(file: File): void {
    const correlationId = getCorrelationId();
    this.store$.dispatch(objectStorageCrop({ file, correlationId }));
    this.store$
      .pipe(
        select(selectObjectUploadResponse),
        filter(response => {
          return !!response && response.correlationId === correlationId;
        }),
        tap(() => {
          this.uploading$.next(true);
        }),
        first(response => {
          return !!response.id;
        }),
        tap(response => {
          this.uploaded.emit(response.id);
          this.uploading$.next(false);
        }),
      )
      .subscribe();
  }
}
