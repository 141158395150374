import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { EMPTY } from 'rxjs';
import { catchError, filter, map, withLatestFrom } from 'rxjs/operators';
import { fetchById } from '@app/util/operators/fetch-by-id';
import { filterById } from '@app/util/operators/filter-by-id';
import { OrderLineSearchService } from '@app/order/core/services/order-line-search.service';
import { orderLineFetch, orderLineUpsert } from './order-line.actions';
import { OrderLineState } from './order-line.reducer';
import { selectOrderLineIds } from './order-line.selectors';
import { orderWebSocketConnected } from '@app/order/core/state/order.actions';
import { selectRoutedOrderLineId } from '@app/order/core/state/order.selectors';

@Injectable()
export class OrderLineEffects {
  fetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(orderLineFetch),
      map(({ id }) => {
        return id;
      }),
      filterById(this.store$.pipe(select(selectOrderLineIds))),
      fetchById(id => {
        return this.orderLineSearchService.getById$(id).pipe(
          map(entity => {
            return orderLineUpsert({ entity });
          }),
          catchError(() => {
            return EMPTY;
          }),
        );
      }),
    );
  });

  connected$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(orderWebSocketConnected),
      withLatestFrom(this.store$.pipe(select(selectRoutedOrderLineId))),
      filter(([action, id]) => {
        return !!id;
      }),
      map(([_, id]) => {
        return id as string;
      }),
      fetchById(id => {
        return this.orderLineSearchService.getById$(id).pipe(
          map(entity => {
            return orderLineUpsert({ entity });
          }),
          catchError(() => {
            return EMPTY;
          }),
        );
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private orderLineSearchService: OrderLineSearchService,
    private store$: Store<OrderLineState>,
  ) {}
}
