import { AfterContentInit, Directive, Host, OnDestroy, OnInit } from '@angular/core';
import { MatTabLink } from '@angular/material/tabs';
import { NavigationEnd, Router, RouterLinkActive } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[tcMatTabActive]',
})
export class MatTabActiveDirective implements OnInit, AfterContentInit, OnDestroy {
  private destroyed$ = new Subject();

  constructor(
    @Host() private matTabLink: MatTabLink,
    @Host() private routerLinkActive: RouterLinkActive,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter(s => {
          return s instanceof NavigationEnd;
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe(s => {
        this.update();
      });
  }

  ngAfterContentInit(): void {
    this.update();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(null);
    this.destroyed$.complete();
  }

  private update(): void {
    Promise.resolve().then(() => {
      this.matTabLink.active = this.routerLinkActive.isActive;
    });
  }
}
