import { Directive, QueryList, ViewChildren } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';

import { FormComponent } from '@app/shared/abstracts/form.component';

@Directive() // eslint-disable-next-line @angular-eslint/directive-class-suffix
export abstract class ExpansionFormComponent<T, E = T> extends FormComponent<T, E> {
  @ViewChildren(MatExpansionPanel)
  panels!: QueryList<MatExpansionPanel>;

  public cancel(): void {
    this.reset();
    this.closePanels();
  }

  public submit(): void {
    super.submit();
    this.closePanels();
  }

  private closePanels(): void {
    const openPanel = this.panels.find(p => {
      return p.expanded;
    });
    if (openPanel) {
      openPanel.close();
    }
  }
}
