<div fxLayout class="vertical">
  <mat-list fxFlex="50" class="mat-nav-list--compact">
    <mat-list-item  class="mat-list-item--multi-line auto-height">
      <ng-container *ngIf="buyerId | tcLazyCompany | async as company">
        <tc-company-summary [company]="company" [accountCode]="buyerAccountNumber"></tc-company-summary>
      </ng-container>
    </mat-list-item>

    <mat-list-item
      *ngIf="!!buyerContactUserId"
      class="mat-list-item--multi-line auto-height"
    >
      <ng-container *ngIf="buyerContactUserId | tcLazyUser | async as user">
        <tc-user-summary [user]="user"></tc-user-summary>
      </ng-container>
      <ng-container *ngIf="assignEnabled">
        <button
          *tcRole="['buyer']"
          mat-icon-button i18n-matTooltip="@@common.user.reassign" matTooltip="Change assigned contact" class="show-on-hover" (click)="assign.emit(assignType.REASSIGN)">
          <mat-icon>create</mat-icon>
        </button>
      </ng-container>
    </mat-list-item>
    <ng-container *ngIf="assignEnabled">
      <ng-container *tcRole="['buyer']">
        <mat-list-item
          *ngIf="!buyerContactUserId"
          class="mat-list-item--multi-line auto-height"
        >
          <button
            mat-icon-button
            i18n-matTooltip="@@order.buyer.details.tooltip.assign"
            matTooltip="Assign contact" class="show-on-hover"
            (click)="assign.emit(assignType.ASSIGN)">
            <mat-icon>person_add_alt</mat-icon>
          </button>
          <ng-container *ngIf="buyerContactIsAssigned">
            <small>No active user with the email address {{buyerContactUserEmail}} was found.</small>
          </ng-container>
        </mat-list-item>
      </ng-container>
    </ng-container>
  </mat-list>

  <mat-list fxFlex="50" class="mat-nav-list--compact">
    <mat-list-item class="mat-list-item--multi-line auto-height">
      <ng-container *ngIf="supplierId | tcLazyCompany | async as company">
        <tc-company-summary [company]="company" [accountCode]="supplierAccountNumber"></tc-company-summary>
      </ng-container>
    </mat-list-item>

    <mat-list-item *ngIf="supplierContactUserId" class="mat-list-item--multi-line auto-height">
      <tc-user-summary [user]="supplierContactUserId | tcLazyUser | async"></tc-user-summary>
      <ng-container *ngIf="assignEnabled">
        <button
          *tcRole="['supplier']"
          mat-icon-button i18n-matTooltip="@@common.user.reassign" matTooltip="Change assigned contact" class="show-on-hover" (click)="assign.emit(assignType.REASSIGN)">
          <mat-icon>create</mat-icon>
        </button>
      </ng-container>

    </mat-list-item>
    <ng-container *ngIf="assignEnabled">
      <ng-container *tcRole="['supplier']">
        <mat-list-item *ngIf="!supplierContactUserId" class="mat-list-item--multi-line auto-height">
          <button mat-icon-button matTooltip="Assign contact" class="show-on-hover" (click)="assign.emit(assignType.ASSIGN)">
            <mat-icon>person_add_alt</mat-icon>
          </button>
          <ng-container *ngIf="supplierContactIsAssigned">
            <small>No active user with the email address {{supplierContactUserEmail}} was found.</small>
          </ng-container>
        </mat-list-item>
      </ng-container>
    </ng-container>
  </mat-list>

</div>
