import { Pipe, PipeTransform } from '@angular/core';
import { BuyerLine, LineProposalStatus, SupplierLine } from '@app/order/models';
import { getProperty } from '@app/shared/pipes/unique.pipe';

@Pipe({
  name: 'tcHasRequestOpenStatus',
})
export class HasRequestOpenStatusPipe implements PipeTransform {
  transform(value: { buyerLine: BuyerLine; supplierLine: SupplierLine }, field: 'buyerLine' | 'supplierLine'): boolean {
    const status = getProperty<LineProposalStatus>('requests.reopenRequest.status', value[field]);

    return !!status && status === 'Open';
  }
}
