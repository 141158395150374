export class EnvService {
  // The values that are defined here are the default values that can
  // be overridden by env.js

  // API url
  public apiUrl = '';

  // show mock modules
  public showMockModules = false;
  public showMetrics = false;

  public shipmentModuleCompanyIds = '';

  public aiRiskIndicatorCompanyIds = '';

  public showLogisticalCompanyIds = '';
  public lookerReportUrl = '';
}
