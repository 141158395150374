import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DocumentInterface, Order, OrderLine } from '@app/order/models';
import { AttachDocumentConfig } from '@app/order/private/components/attach-document-dialog/config/attach-document.config';

@Component({
  selector: 'tc-line-documents',
  templateUrl: './line-documents.component.html',
  styleUrls: ['./line-documents.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineDocumentsComponent {
  public readonly displayedColumns = ['file', 'attachedBy', 'name', 'code', 'revision', 'type', 'description'];

  @Input()
  orderLine?: OrderLine;

  @Input()
  order?: Order;

  @Input()
  deliverySchedulePosition?: string;

  @Input()
  transportMode?: string;

  get documents() {
    if (this.orderLine) {
      const documentInfo = (item: DocumentInterface) => {
        return {
          name: item.name || '',
          revision: item.revision || '',
          code: item.code || '',
          type: (item.type && AttachDocumentConfig.DOCUMENT_TYPE_DESCRIPTION[item.type]) || '',
          description: item.description || '',
          url: item.url,
          objectId: item.objectId,
        };
      };

      const allDocuments = [
        ...(this.orderLine.buyerLine.documents.map((item: DocumentInterface) => {
          return {
            linkedTo: this.orderLine?.buyerLine.position,
            attachedBy: this.order!.buyerOrder.companyId,
            ...documentInfo(item),
          };
        }) || []),
        ...(this.orderLine.supplierLine.documents.map((item: DocumentInterface) => {
          return {
            linkedTo: this.orderLine?.buyerLine.position,
            attachedBy: this.order!.supplierOrder.companyId,
            ...documentInfo(item),
          };
        }) || []),
      ];

      return allDocuments;
    }

    return [];
  }

  get showLineMode(): string {
    const delivery = this.orderLine?.deliverySchedule.find(d => {
      return d.position === this.deliverySchedulePosition;
    });

    return delivery?.transportMode || '';
  }
}
