import { CompanyRole } from '@app/company/models';
import { coerceArray, coerceNonEmptyArray } from '@app/util/helpers';
import {
  OrderFilterParameters,
  OrderQueryFilters,
  OrderQueryFiltersDto,
  OrderQuerySort,
  OrderSortParameters,
} from '@app/order/models';

export function mapQueryParametersToFilters({
  companyIds,
  logisticsStatus,
  deliveryLineStatus,
  processStatus,
  inProgressStatus,
  contactIds,
  includeUnassigned,
  destinations,
  query,
  orderType,
  deliveryOverdue,
  customLabels,
  prediction,
}: OrderFilterParameters): OrderQueryFilters {
  let slide: number[] = [0, 100];
  if (prediction) {
    slide = [Number(prediction[0]), Number(prediction[1])];
  }

  return {
    companyIds: coerceArray(companyIds),
    processStatus: coerceArray(processStatus),
    inProgressStatus: coerceArray(inProgressStatus),
    logisticsStatus: coerceArray(logisticsStatus),
    deliveryLineStatus: coerceArray(deliveryLineStatus),
    contactIds: coerceArray(contactIds),
    destinations: coerceArray(destinations),
    customLabels: coerceArray(customLabels),
    query: coerceArray(query),
    orderType: coerceArray(orderType),
    includeUnassigned: includeUnassigned === 'true',
    deliveryOverdue: deliveryOverdue === 'true',
    sliderControl: slide,
  };
}

export function mapFiltersToQueryParameters({
  companyIds,
  logisticsStatus,
  deliveryLineStatus,
  processStatus,
  inProgressStatus,
  contactIds,
  destinations,
  query,
  orderType,
  includeUnassigned,
  deliveryOverdue,
  customLabels,
  sliderControl,
}: OrderQueryFilters): OrderFilterParameters {
  const data = {
    contactIds: coerceNonEmptyArray(contactIds),
    companyIds: coerceNonEmptyArray(companyIds),
    processStatus: coerceNonEmptyArray(processStatus),
    inProgressStatus: coerceNonEmptyArray(inProgressStatus),
    logisticsStatus: coerceNonEmptyArray(logisticsStatus),
    deliveryLineStatus: coerceNonEmptyArray(deliveryLineStatus),
    destinations: coerceNonEmptyArray(destinations),
    customLabels: coerceNonEmptyArray(customLabels),
    prediction: coerceNonEmptyArray(sliderControl),
    orderType: coerceNonEmptyArray(orderType),
    includeUnassigned: (includeUnassigned || false).toString(),
    deliveryOverdue: (deliveryOverdue || false).toString(),
  };

  if (query) {
    return {
      ...data,
      query: query[0],
    };
  }

  return data;
}

export function addCompanyToFilters(
  {
    companyIds,
    processStatus,
    inProgressStatus,
    logisticsStatus,
    deliveryLineStatus,
    contactIds,
    destinations,
    orderType,
    includeUnassigned,
    deliveryOverdue,
    customLabels,
    sliderControl,
  }: OrderQueryFilters,
  companyId?: string,
  role?: CompanyRole | null,
): OrderQueryFiltersDto | null {
  if (role === undefined || !companyId) {
    return null;
  }

  if (role === null) {
    return {
      buyerOrder: {
        orderType,
        companyId: companyIds,
        contact: {
          userIds: contactIds,
          includeUnassigned: false,
        },
        destination: {
          ids: destinations,
        },
      },
      supplierOrder: {
        companyId: [],
        contact: {
          userIds: [],
          includeUnassigned: false,
        },
      },
      indicators: deliveryOverdue ? { deliveryOverdue } : {},
      status: {
        processStatus,
        logisticsStatus,
        inProgressStatus,
        deliveryLineStatus: deliveryLineStatus,
      },
    };
  }

  const [start, end] = sliderControl;
  let predictionsFilter;
  if (start > 0 || end < 100) {
    predictionsFilter = {
      confirmationExceptionPredictionFilter: {
        gte: start / 100,
        lte: end / 100,
      },
    };
  }

  if (role === CompanyRole.BUYER) {
    return {
      buyerOrder: {
        orderType,
        companyId: [companyId],
        contact: {
          userIds: contactIds,
          includeUnassigned,
        },
        destination: {
          ids: destinations,
        },
      },
      supplierOrder: {
        companyId: companyIds,
        contact: {
          userIds: [],
          includeUnassigned: false,
        },
      },
      customLabels: {
        texts: customLabels || [],
      },
      indicators: deliveryOverdue ? { deliveryOverdue } : {},
      status: {
        processStatus,
        inProgressStatus,
        logisticsStatus,
        deliveryLineStatus: deliveryLineStatus,
      },
      predictions: predictionsFilter,
    };
  }

  if (role === CompanyRole.SUPPLIER) {
    return {
      buyerOrder: {
        companyId: companyIds,
        orderType,
        contact: {
          userIds: [],
          includeUnassigned: false,
        },
        destination: {
          ids: destinations,
        },
      },
      customLabels: {
        texts: customLabels || [],
      },
      supplierOrder: {
        companyId: [companyId],
        contact: {
          userIds: contactIds,
          includeUnassigned,
        },
      },
      indicators: deliveryOverdue ? { deliveryOverdue } : {},
      status: {
        processStatus,
        logisticsStatus,
        inProgressStatus,
        deliveryLineStatus: deliveryLineStatus,
      },
      predictions: predictionsFilter,
    };
  }

  return {
    buyerOrder: {
      orderType,
      companyId: companyIds,
      contact: {
        userIds: contactIds,
        includeUnassigned: false,
      },
      destination: {
        ids: destinations,
      },
    },
    customLabels: {
      texts: customLabels || [],
    },
    supplierOrder: {
      companyId: [companyId],
      contact: {
        userIds: [],
        includeUnassigned: false,
      },
    },
    indicators: deliveryOverdue ? { deliveryOverdue } : {},
    status: {
      processStatus,
      inProgressStatus,
      logisticsStatus,
      deliveryLineStatus: deliveryLineStatus,
    },
    predictions: predictionsFilter,
  };
}

export function mapQueryParametersToSort(params: OrderSortParameters): OrderQuerySort {
  return {
    field: params.sort || 'eventDates.issuedAt',
    order: params.direction || 'desc',
  };
}

export function mapSortToQueryParameters(sort: OrderQuerySort): OrderSortParameters {
  return {
    sort: sort.field,
    direction: sort.order,
  };
}


export function mapOrderLineQueryParametersToSort(params: OrderSortParameters): OrderQuerySort {
  return {
    field: params.sort || 'firstDeliveryDate',
    order: params.direction || 'asc',
  };
}
