<mat-table class="compact-table" [dataSource]="requestedChargeLines">
  <ng-container matColumnDef="position">
    <mat-header-cell *matHeaderCellDef i18n="@@common.charge.line">Charge line</mat-header-cell>
    <mat-cell *matCellDef="let row"> {{row.position}} </mat-cell>
  </ng-container>


  <ng-container matColumnDef="type">
    <mat-header-cell *matHeaderCellDef i18n="@@common.chargeType">Charge type</mat-header-cell>
    <mat-cell *matCellDef="let row let i = index;">
      <span [ngClass]="{change: isChargeTypeCodeChanged(i)}">{{ chargeTypeMap.get(row.chargeTypeCode) || row.chargeTypeCode }}</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="description">
    <mat-header-cell *matHeaderCellDef class="column-large" i18n="@@order.charge.line.description">Description</mat-header-cell>
    <mat-cell class="column-large" *matCellDef="let row">{{ row.chargeDescription }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="quantity">
    <mat-header-cell *matHeaderCellDef i18n="@@common.quantity">Quantity</mat-header-cell>
    <mat-cell *matCellDef="let row  let i = index;">
      <span>
        <span [ngClass]="{change: isQuantityChanged(i)}">{{row.quantity | number}}</span>
        <span [ngClass]="{change: isPriceUnitOfMeasureChanged(i)}">{{ row.priceUnitOfMeasureIso | tcUnitSymbol }}</span>
      </span>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="price">
    <mat-header-cell *matHeaderCellDef i18n="@@charge.table.price">Price</mat-header-cell>
    <mat-cell *matCellDef="let row let i = index;">
      <span>
      <span [ngClass]="{change: isPriceChanged(i)}">
        {{ row.price.priceInTransactionCurrency | tcMoney }}
      </span>
      <tc-price-unit
        [quantity]="1"
        [unit]="row.priceUnitOfMeasureIso"
        [ngClass]="{change: isPriceUnitOfMeasureChanged(i)}"
      ></tc-price-unit>
    </span>
    </mat-cell>
  </ng-container>

  <mat-header-row class="compact-row" *matHeaderRowDef="columnDefs"></mat-header-row>
  <mat-row class="compact" *matRowDef="let row; columns: columnDefs; let i = index;"></mat-row>
</mat-table>
