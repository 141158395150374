import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Order, OrderLine } from '@app/order/models';
import { UUID } from '@app/shared/models';

@Component({
  selector: 'tc-line-notes',
  templateUrl: './line-notes.component.html',
  styleUrls: ['./line-notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LineNotesComponent {
  @Input()
  order?: Order;

  @Input()
  orderLine?: OrderLine;

  public get buyerId(): UUID | undefined {
    return this.order?.buyerOrder.companyId;
  }

  public get supplierId(): UUID | undefined {
    return this.order?.supplierOrder.companyId;
  }

  public get supplierNotes(): string[] | undefined {
    return this.orderLine?.supplierLine.notes;
  }

  public get buyerNotes(): string[] | undefined {
    return this.orderLine?.buyerLine.notes;
  }
}
