import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { ConditionalDirective } from '@app/shared/abstracts/conditional.directive';
import { NgIfContext } from '@angular/common';
import { filter, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { CompanyLimitationService } from '@app/shared/services/company-limitation.service';
import { EnvService } from '@app/env.service';

@Directive({
  selector: '[tcCompanyLimitation]',
})
export class CompanyLimitationDirective extends ConditionalDirective {
  @Input()
  set tcCompanyLimitation(stringifiedList: string) {
    const list = stringifiedList.split(',');
    this.checkList(list || []);
  }

  private connectionIds$: Observable<string[]> = this.companyLimitService.connectionIds$;

  constructor(
    private companyLimitService: CompanyLimitationService,
    private envService: EnvService,
    viewContainer: ViewContainerRef,
    templateRef: TemplateRef<NgIfContext>,
  ) {
    super(viewContainer, templateRef);
  }

  private checkList(allowedListIds: string[]): void {
    this.connectionIds$
      .pipe(
        filter(r => {
          return !!r;
        }),
        take(1),
      )
      .subscribe(ids => {
        const allowed = ids.some(id => {
          return allowedListIds.includes(id);
        });

        this.updateView(this.envService.showMockModules || allowed);
      });
  }
}
