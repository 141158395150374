import { createEntityAdapter, EntityState } from '@ngrx/entity';
import {
  ObjectStorageActionType,
  objectStorageDownloadSuccess,
  objectStorageUpload,
  objectStorageUploadSuccess,
} from './object-storage.actions';
import { Download } from './object-storage.model';
import { TypedAction } from '@ngrx/store/src/models';
import { createReducer, on } from '@ngrx/store';

export interface ObjectStorageState extends EntityState<Download> {
  readonly uploadResponse: { correlationId: number; id?: string };
}

export const objectStorageAdapter = createEntityAdapter<Download>();

export const initialState = objectStorageAdapter.getInitialState({
  uploadResponse: {
    correlationId: 0,
  },
  ids: [],
  entities: {},
});

const objectStorageReducer = createReducer<ObjectStorageState, TypedAction<ObjectStorageActionType>>(
  initialState,
  on(objectStorageUpload, (state, { correlationId }) => {
    return { ...state, uploadResponse: { correlationId } };
  }),
  on(objectStorageUploadSuccess, (state, { id, correlationId }) => {
    return {
      ...state,
      uploadResponse: { id, correlationId },
    };
  }),
  on(objectStorageDownloadSuccess, (state, { response, fileQuery }) => {
    return {
      ...state,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ids: [...(state.ids as string[]), fileQuery],
      entities: {
        ...state.entities,
        [fileQuery]: response,
      },
    };
  }),
);

export function reducer(state = initialState, action: TypedAction<ObjectStorageActionType>): ObjectStorageState {
  return objectStorageReducer(state, action);
}
