import { EnvService } from './env.service';

export interface CustomWindow extends Window {
  __env: any;
}

export function envServiceFactory(): EnvService {
  // Create env
  const env = new EnvService();

  // Read environment variables from browser window
  const browserWindow: Window = window || {};
  const browserWindowEnv = (browserWindow as CustomWindow).__env || {};
  /* eslint-disable no-prototype-builtins */
  // Assign environment variables from browser window to env
  // In the current implementation, properties from env.js overwrite defaults from the EnvService.
  // If needed, a deep merge can be performed here to merge properties instead of overwriting them.
  for (const key in browserWindowEnv) {
    if (browserWindowEnv.hasOwnProperty(key)) {
      (env as any)[key] = browserWindowEnv[key];
    }
  }
  /* eslint-enable no-prototype-builtins */

  return env;
}

export const envServiceProvider = {
  provide: EnvService,
  useFactory: envServiceFactory,
  deps: [],
};
