import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tcNumberSign',
})
export class NumberSignPipe implements PipeTransform {
  transform(formattedNumber: string | null): string | null {
    if (!formattedNumber) {
      return null;
    }

    return formattedNumber.charAt(0) === '-' ? formattedNumber : `+${formattedNumber}`;
  }
}
