import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CompanyRole } from '@app/company/models';
import { Money, Order, StartRemoveOrderLabel } from '@app/order/models';
import { UUID } from '@app/shared/models';
import {
  getLinesPositionWithoutAmount,
  LABEL_AMOUNT_CURRENCY,
  LABEL_AMOUNT_LINE_FIRST,
  LABEL_AMOUNT_LINE_SECOND,
} from '../order-details/helpers/order-details.helper';
import { AttachDocumentEntityType } from '@app/order/private/components/attach-document-dialog/interfaces/enums/attach-document-entity-type.enum';
import { isNotNullOrUndefined } from '@app/util/operators/is-not-null-or-undefined';
import { RemoveLabelDialogComponent } from '@app/order/shared/components/remove-label-dialog/remove-label-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'tc-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderSummaryComponent {
  @Input()
  order?: Order;

  @Input()
  role?: CompanyRole;

  @Input()
  companyId?: string;

  @Output()
  readonly removeOrderLabel = new EventEmitter<StartRemoveOrderLabel>();

  public get buyerId(): UUID | undefined {
    return this.order?.buyerOrder.companyId;
  }

  public get supplierId(): UUID | undefined {
    return this.order?.supplierOrder.companyId;
  }

  public get supplierAccountNumber(): string | undefined {
    return this.order?.buyerOrder.supplierAccountNumber;
  }

  public get partyNames(): string[] | undefined {
    return this.order?.buyerOrder?.buyerAccountingParty?.names;
  }

  public get buyerAccountNumber(): string | undefined {
    return this.order?.supplierOrder.buyerAccountNumber;
  }

  public get purchaseOrderNumber(): string | undefined {
    return this.order?.buyerOrder.purchaseOrderNumber;
  }

  public get buyerOrderDescription(): string | undefined {
    return this.order?.buyerOrder.description;
  }

  public get labels(): string[] | undefined {
    return this.order?.buyerOrder?.labels;
  }

  public get amountChanged(): boolean | undefined {
    return (
      this.order?.totalAmountIncludingRequests?.value !== this.order?.totalAmount?.value ||
      this.order?.totalAmountIncludingRequests?.currencyIso !== this.order?.totalAmount?.currencyIso
    );
  }

  public get amountView(): Money | undefined {
    return this.order?.totalAmountIncludingRequests;
  }

  public get amountHint(): string {
    const positions = getLinesPositionWithoutAmount(this.order?.lines);

    return positions.length
      ? `${LABEL_AMOUNT_LINE_FIRST} ${positions.join(', ')} ${LABEL_AMOUNT_LINE_SECOND}`
      : LABEL_AMOUNT_CURRENCY;
  }

  public get salesOrderNumbers(): string[] | undefined {
    if (this.order) {
      const orderNumbers = this.order.lines
        .filter(line => {
          return line.supplierLine && line.supplierLine.salesOrderNumber;
        })
        .map(({ supplierLine }) => {
          return supplierLine.salesOrderNumber;
        });

      return orderNumbers as string[];
    }
  }

  public get supplierOrderDescription(): string | undefined {
    return this.order?.supplierOrder.description;
  }

  constructor(private dialog: MatDialog) {}

  onRemoveLabel(text: string) {
    const dialogRef = this.dialog.open(RemoveLabelDialogComponent, {
      data: {
        text,
        entityType: AttachDocumentEntityType.ORDER,
        entityId: this.order && this.order.buyerOrder.purchaseOrderNumber,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(
        isNotNullOrUndefined(),
        filter(d => {
          return !!d;
        }),
      )
      .subscribe(result => {
        if (this.order && this.role) {
          this.removeOrderLabel.emit({
            orderId: this.order.id,
            removedLabel: {
              reason: result.reason,
              text,
            },
            role: this.role,
          });
        }
      });
  }
}
