import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ConversationService } from '@app/conversation/core/services/conversation.service';
import { Message } from '@app/conversation/models';

@Component({
  selector: 'tc-send-message',
  templateUrl: './send-message.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendMessageComponent {
  @Input()
  entityId!: string;

  @Input()
  internal = true;

  @Input()
  entityType!: string;

  @Input()
  mixpanelClickEvent!: string;

  constructor(private conversationService: ConversationService) {}

  public onSubmit(event: Message): void {
    this.conversationService.sendMessage({
      entityId: this.entityId,
      entityType: this.entityType,
      message: event.message,
      internal: event.internal,
    });
  }
}
