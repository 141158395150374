import { Pipe, PipeTransform } from '@angular/core';
import { IncotermsCode } from '@app/order/models';

@Pipe({
  name: 'tcIncotermsName',
})
export class IncotermsNamePipe implements PipeTransform {
  transform(code?: IncotermsCode): string | null {
    if (!code) {
      return null;
    }

    switch (
      code // todo: i18n
    ) {
      case 'EXW':
        return 'Ex Works';
      case 'FCA':
        return 'Free Carrier';
      case 'CPT':
        return 'Carriage Paid To';
      case 'CIP':
        return 'Carriage and Insurance Paid to';
      case 'DAT':
        return 'Deliverd At Terminal';
      case 'DAP':
        return 'Delivered At Place';
      case 'DDP':
        return 'Delivered Duty Paid';
      case 'FAS':
        return 'Free Alongside Ship';
      case 'FOB':
        return 'Free on Board';
      case 'CFR':
        return 'Cost and Freight';
      case 'CIF':
        return 'Cost, Insurance & Freight';
      default:
        return code;
    }
  }
}
