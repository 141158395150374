import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { reducer } from './state/cache.reducer';
import { cacheFeatureName } from '@app/cache/state/cache.selectors';
import { CacheManagerService } from '@app/cache/services/cache-manager.service';

@NgModule({
  imports: [StoreModule.forFeature(cacheFeatureName, reducer)],
  providers: [CacheManagerService],
})
export class CacheCoreModule {}
