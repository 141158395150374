export const CHARGE_TYPES = [
  {
    value: 'AA',
    label: 'Advertising',
  },
  {
    value: 'AAA',
    label: 'Telecommunication',
  },
  {
    value: 'AAC',
    label: 'Technical modification',
  },
  {
    value: 'AAD',
    label: 'Job-order production',
  },
  {
    value: 'AAE',
    label: 'Outlays',
  },
  {
    value: 'AAF',
    label: 'Off-premises',
  },
  {
    value: 'AAH',
    label: 'Additional processing',
  },
  {
    value: 'AAI',
    label: 'Attesting',
  },
  {
    value: 'AAS',
    label: 'Acceptance',
  },
  {
    value: 'AAT',
    label: 'Rush delivery',
  },
  {
    value: 'AAV',
    label: 'Special construction',
  },
  {
    value: 'AAY',
    label: 'Airport facilities',
  },
  {
    value: 'AAZ',
    label: 'Concession',
  },
  {
    value: 'ABA',
    label: 'Compulsory storage',
  },
  {
    value: 'ABB',
    label: 'Fuel removal',
  },
  {
    value: 'ABC',
    label: 'Into plane',
  },
  {
    value: 'ABD',
    label: 'Overtime',
  },
  {
    value: 'ABF',
    label: 'Tooling',
  },
  {
    value: 'ABK',
    label: 'Miscellaneous',
  },
  {
    value: 'ABL',
    label: 'Additional packaging',
  },
  {
    value: 'ABN',
    label: 'Dunnage',
  },
  {
    value: 'ABR',
    label: 'Containerisation',
  },
  {
    value: 'ABS',
    label: 'Carton packing',
  },
  {
    value: 'ABT',
    label: 'Hessian wrapped',
  },
  {
    value: 'ABU',
    label: 'Polyethylene wrap packing',
  },
  {
    value: 'ACF',
    label: 'Miscellaneous treatment',
  },
  {
    value: 'ACG',
    label: 'Enamelling treatment',
  },
  {
    value: 'ACH',
    label: 'Heat treatment',
  },
  {
    value: 'ACI',
    label: 'Plating treatment',
  },
  {
    value: 'ACJ',
    label: 'Painting',
  },
  {
    value: 'ACK',
    label: 'Polishing',
  },
  {
    value: 'ACL',
    label: 'Priming',
  },
  {
    value: 'ACM',
    label: 'Preservation treatment',
  },
  {
    value: 'ACS',
    label: 'Fitting',
  },
  {
    value: 'ADC',
    label: 'Consolidation',
  },
  {
    value: 'ADE',
    label: 'Bill of lading',
  },
  {
    value: 'ADJ',
    label: 'Airbag',
  },
  {
    value: 'ADK',
    label: 'Transfer',
  },
  {
    value: 'ADL',
    label: 'Slipsheet',
  },
  {
    value: 'ADM',
    label: 'Binding',
  },
  {
    value: 'ADN',
    label: 'Repair or replacement of broken returnable package',
  },
  {
    value: 'ADO',
    label: 'Efficient logistics',
  },
  {
    value: 'ADP',
    label: 'Merchandising',
  },
  {
    value: 'ADQ',
    label: 'Product mix',
  },
  {
    value: 'ADR',
    label: 'Other services',
  },
  {
    value: 'ADT',
    label: 'Pick-up',
  },
  {
    value: 'ADW',
    label: 'Chronic illness',
  },
  {
    value: 'ADY',
    label: 'New product introduction',
  },
  {
    value: 'ADZ',
    label: 'Direct delivery',
  },
  {
    value: 'AEA',
    label: 'Diversion',
  },
  {
    value: 'AEB',
    label: 'Disconnect',
  },
  {
    value: 'AEC',
    label: 'Distribution',
  },
  {
    value: 'AED',
    label: 'Handling of hazardous cargo',
  },
  {
    value: 'AEF',
    label: 'Rents and leases',
  },
  {
    value: 'AEH',
    label: 'Location differential',
  },
  {
    value: 'AEI',
    label: 'Aircraft refueling',
  },
  {
    value: 'AEJ',
    label: 'Fuel shipped into storage',
  },
  {
    value: 'AEK',
    label: 'Cash on delivery',
  },
  {
    value: 'AEL',
    label: 'Small order processing service',
  },
  {
    value: 'AEM',
    label: 'Clerical or administrative services',
  },
  {
    value: 'AEN',
    label: 'Guarantee',
  },
  {
    value: 'AEO',
    label: 'Collection and recycling',
  },
  {
    value: 'AEP',
    label: 'Copyright fee collection',
  },
  {
    value: 'AES',
    label: 'Veterinary inspection service',
  },
  {
    value: 'AET',
    label: 'Pensioner service',
  },
  {
    value: 'AEU',
    label: 'Medicine free pass holder',
  },
  {
    value: 'AEV',
    label: 'Environmental protection service',
  },
  {
    value: 'AEW',
    label: 'Environmental clean-up service',
  },
  {
    value: 'AEX',
    label: 'National cheque processing service outside account area',
  },
  {
    value: 'AEY',
    label: 'National payment service outside account area',
  },
  {
    value: 'AEZ',
    label: 'National payment service within account area',
  },
  {
    value: 'AJ',
    label: 'Adjustments',
  },
  {
    value: 'AU',
    label: 'Authentication',
  },
  {
    value: 'CA',
    label: 'Cataloguing',
  },
  {
    value: 'CAB',
    label: 'Cartage',
  },
  {
    value: 'CAD',
    label: 'Certification',
  },
  {
    value: 'CAE',
    label: 'Certificate of conformance',
  },
  {
    value: 'CAF',
    label: 'Certificate of origin',
  },
  {
    value: 'CAI',
    label: 'Cutting',
  },
  {
    value: 'CAJ',
    label: 'Consular service',
  },
  {
    value: 'CAK',
    label: 'Customer collection',
  },
  {
    value: 'CAL',
    label: 'Payroll payment service',
  },
  {
    value: 'CAM',
    label: 'Cash transportation',
  },
  {
    value: 'CAN',
    label: 'Home banking service',
  },
  {
    value: 'CAO',
    label: 'Bilateral agreement service',
  },
  {
    value: 'CAP',
    label: 'Insurance brokerage service',
  },
  {
    value: 'CAQ',
    label: 'Cheque generation',
  },
  {
    value: 'CAR',
    label: 'Preferential merchandising location',
  },
  {
    value: 'CAS',
    label: 'Crane',
  },
  {
    value: 'CAT',
    label: 'Special colour service',
  },
  {
    value: 'CAU',
    label: 'Sorting',
  },
  {
    value: 'CAV',
    label: 'Battery collection and recycling',
  },
  {
    value: 'CAW',
    label: 'Product take back fee',
  },
  {
    value: 'CAX',
    label: 'Quality control released',
  },
  {
    value: 'CAY',
    label: 'Quality control held',
  },
  {
    value: 'CAZ',
    label: 'Quality control embargo',
  },
  {
    value: 'CD',
    label: 'Car loading',
  },
  {
    value: 'CG',
    label: 'Cleaning',
  },
  {
    value: 'CS',
    label: 'Cigarette stamping',
  },
  {
    value: 'CT',
    label: 'Count and recount',
  },
  {
    value: 'DAB',
    label: 'Layout/design',
  },
  {
    value: 'DAC',
    label: 'Assortment allowance',
  },
  {
    value: 'DAD',
    label: 'Driver assigned unloading',
  },
  {
    value: 'DAF',
    label: 'Debtor bound',
  },
  {
    value: 'DAG',
    label: 'Dealer allowance',
  },
  {
    value: 'DAH',
    label: 'Allowance transferable to the consumer',
  },
  {
    value: 'DAI',
    label: 'Growth of business',
  },
  {
    value: 'DAJ',
    label: 'Introduction allowance',
  },
  {
    value: 'DAK',
    label: 'Multi-buy promotion',
  },
  {
    value: 'DAL',
    label: 'Partnership',
  },
  {
    value: 'DAM',
    label: 'Return handling',
  },
  {
    value: 'DAN',
    label: 'Minimum order not fulfilled charge',
  },
  {
    value: 'DAO',
    label: 'Point of sales threshold allowance',
  },
  {
    value: 'DAP',
    label: 'Wholesaling discount',
  },
  {
    value: 'DAQ',
    label: 'Documentary credits transfer commission',
  },
  {
    value: 'DL',
    label: 'Delivery',
  },
  {
    value: 'EG',
    label: 'Engraving',
  },
  {
    value: 'EP',
    label: 'Expediting',
  },
  {
    value: 'ER',
    label: 'Exchange rate guarantee',
  },
  {
    value: 'FAA',
    label: 'Fabrication',
  },
  {
    value: 'FAB',
    label: 'Freight equalization',
  },
  {
    value: 'FAC',
    label: 'Freight extraordinary handling',
  },
  {
    value: 'FC',
    label: 'Freight service',
  },
  {
    value: 'FH',
    label: 'Filling/handling',
  },
  {
    value: 'FI',
    label: 'Financing',
  },
  {
    value: 'GAA',
    label: 'Grinding',
  },
  {
    value: 'HAA',
    label: 'Hose',
  },
  {
    value: 'HD',
    label: 'Handling',
  },
  {
    value: 'HH',
    label: 'Hoisting and hauling',
  },
  {
    value: 'IAA',
    label: 'Installation',
  },
  {
    value: 'IAB',
    label: 'Installation and warranty',
  },
  {
    value: 'ID',
    label: 'Inside delivery',
  },
  {
    value: 'IF',
    label: 'Inspection',
  },
  {
    value: 'IR',
    label: 'Installation and training',
  },
  {
    value: 'IS',
    label: 'Invoicing',
  },
  {
    value: 'KO',
    label: 'Koshering',
  },
  {
    value: 'L1',
    label: 'Carrier count',
  },
  {
    value: 'LA',
    label: 'Labelling',
  },
  {
    value: 'LAA',
    label: 'Labour',
  },
  {
    value: 'LAB',
    label: 'Repair and return',
  },
  {
    value: 'LF',
    label: 'Legalisation',
  },
  {
    value: 'MAE',
    label: 'Mounting',
  },
  {
    value: 'MI',
    label: 'Mail invoice',
  },
  {
    value: 'ML',
    label: 'Mail invoice to each location',
  },
  {
    value: 'NAA',
    label: 'Non-returnable containers',
  },
  {
    value: 'OA',
    label: 'Outside cable connectors',
  },
  {
    value: 'PA',
    label: 'Invoice with shipment',
  },
  {
    value: 'PAA',
    label: 'Phosphatizing (steel treatment)',
  },
  {
    value: 'PC',
    label: 'Packing',
  },
  {
    value: 'PL',
    label: 'Palletizing',
  },
  {
    value: 'RAB',
    label: 'Repacking',
  },
  {
    value: 'RAC',
    label: 'Repair',
  },
  {
    value: 'RAD',
    label: 'Returnable container',
  },
  {
    value: 'RAF',
    label: 'Restocking',
  },
  {
    value: 'RE',
    label: 'Re-delivery',
  },
  {
    value: 'RF',
    label: 'Refurbishing',
  },
  {
    value: 'RH',
    label: 'Rail wagon hire',
  },
  {
    value: 'RV',
    label: 'Loading',
  },
  {
    value: 'SA',
    label: 'Salvaging',
  },
  {
    value: 'SAA',
    label: 'Shipping and handling',
  },
  {
    value: 'SAD',
    label: 'Special packaging',
  },
  {
    value: 'SAE',
    label: 'Stamping',
  },
  {
    value: 'SAI',
    label: 'Consignee unload',
  },
  {
    value: 'SG',
    label: 'Shrink-wrap',
  },
  {
    value: 'SH',
    label: 'Special handling',
  },
  {
    value: 'SM',
    label: 'Special finish',
  },
  {
    value: 'SU',
    label: 'Set-up',
  },
  {
    value: 'TAB',
    label: 'Tank renting',
  },
  {
    value: 'TAC',
    label: 'Testing',
  },
  {
    value: 'TT',
    label: 'Transportation - third party billing',
  },
  {
    value: 'TV',
    label: 'Transportation by vendor',
  },
  {
    value: 'V1',
    label: 'Drop yard',
  },
  {
    value: 'V2',
    label: 'Drop dock',
  },
  {
    value: 'WH',
    label: 'Warehousing',
  },
  {
    value: 'XAA',
    label: 'Combine all same day shipment',
  },
  {
    value: 'YY',
    label: 'Split pick-up',
  },
  {
    value: 'ZZZ',
    label: 'Mutually defined',
  },
];
export const DIALOG_CREATION_CHARGE_TYPES = [
  {
    value: 'FC',
    label: 'Freight service', // todo: i18n
  },
  {
    value: 'AAH',
    label: 'Additional processing',
  },
  {
    value: 'AEM',
    label: 'Administrative services', // todo: i18n
  },
  {
    value: 'AEL',
    label: 'Small order processing service', // todo: i18n
  },
  {
    value: 'PC',
    label: 'Packing', // todo: i18n
  },
  {
    value: 'CAD',
    label: 'Certification', // todo: i18n
  },
  {
    value: 'IF',
    label: 'Inspection', // todo: i18n
  },
  {
    value: 'ADR',
    label: 'Other services',
  },
];
