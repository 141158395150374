import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import { select, Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { catchError, concatMap, filter, map, mapTo, withLatestFrom } from 'rxjs/operators';

import { fetchById } from '@app/util/operators/fetch-by-id';
import { filterById } from '@app/util/operators/filter-by-id';

import { OrderSearchService } from '../services/order-search.service';
import { OrderService } from '../services/order.service';

import { NotificationType } from '@app/core/state/core.model';
import { notificationSend } from '@app/core/state/core.actions';
import {
  orderFetch,
  orderFetchFailure,
  orderListen,
  orderUpsert,
  orderWebSocketConnected,
  orderWebSocketUpsert,
} from './order.actions';
import { OrderState } from '@app/order/core/state/order.reducer';
import { selectOrderIds, selectRoutedOrderId } from './order.selectors';
import { orderDetailUpdateConflicted } from '@app/order/routes/order-detail/state/order-detail.actions';

@Injectable()
export class OrderEffects {
  fetch$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(orderFetch),
      concatMap(({ id, silent }) => {
        return of(id).pipe(
          filterById(this.store$.pipe(select(selectOrderIds))),
          fetchById(orderId => {
            return this.orderSearchService.getById$(orderId).pipe(
              map(entity => {
                return orderUpsert({ entity });
              }),
              catchError(({ error }: HttpErrorResponse) => {
                return of(
                  orderFetchFailure({
                    id: orderId,
                    error: error.message || error,
                    silent: Boolean(silent),
                  }),
                );
              }),
            );
          }),
        );
      }),
    );
  });

  fetchFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(orderFetchFailure),
      filter(({ silent }) => {
        return !silent;
      }),
      mapTo(
        notificationSend({
          message: $localize`:@@ts.order.fetch.failed:Failed to fetch the order. Please try again.`,
          notificationType: NotificationType.ERROR,
        }),
      ),
    );
  });

  listen$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(orderListen),
      map(({ companyId }) => {
        return companyId;
      }),
      concatMap(companyId => {
        return this.orderService.websocket$(companyId, orderWebSocketConnected).pipe(
          map(
            entity => {
              return orderWebSocketUpsert({ entity });
            },
            catchError(() => {
              return EMPTY;
            }),
          ),
        );
      }),
    );
  });

  connected$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(...[orderWebSocketConnected.type, orderDetailUpdateConflicted.type]),
      withLatestFrom(this.store$.pipe(select(selectRoutedOrderId))),
      filter(([action, id]) => {
        return !!id;
      }),
      map(([_, id]) => {
        return id as string;
      }),
      fetchById(orderId => {
        return this.orderSearchService.getById$(orderId).pipe(
          map(entity => {
            return orderUpsert({ entity });
          }),
          catchError(({ error }: HttpErrorResponse) => {
            return of(
              orderFetchFailure({
                id: orderId,
                error: error.message || error,
                silent: false,
              }),
            );
          }),
        );
      }),
    );
  });

  route$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ROUTER_NAVIGATION),
      withLatestFrom(this.store$.pipe(select(selectRoutedOrderId))),
      filter(([action, id]) => {
        return !!id;
      }),
      map(([action, id]) => {
        return orderFetch({ id: id!, silent: false });
      }),
    );
  });

  constructor(
    private actions$: Actions,
    private store$: Store<OrderState>,
    private orderService: OrderService,
    private orderSearchService: OrderSearchService,
  ) {}
}
