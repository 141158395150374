import { AnalyticsStatusQueryResponse, Measurement } from '../../models';
import {
  AnalyticsActionType,
  queryAmountPerMonth,
  queryAmountPerMonthSuccess,
  queryCountConnectionsSuccess,
  queryCountOrderLinesSuccess,
  queryCountPerMonth,
  queryCountPerMonthSuccess,
  queryCountPerStatus,
  queryCountPerStatusSuccess,
  queryCountTasksSuccess,
  queryKpisConfirmed,
  queryKpisConfirmedSuccess,
  queryKpisRequested,
  queryKpisRequestedSuccess,
} from './analytics.actions';
import { TypedAction } from '@ngrx/store/src/models';
import { createReducer, on } from '@ngrx/store';

export interface AnalyticsState {
  readonly amountPerMonth: readonly Measurement[];
  readonly countPerMonth: readonly Measurement[];
  readonly countPerStatus: Readonly<AnalyticsStatusQueryResponse>;
  readonly kpisConfirmed: readonly Measurement[];
  readonly kpisRequested: readonly Measurement[];
  readonly countOpenedTask: number;
  readonly countOrderLines: number;
  readonly countConnectedCompanies: number;
}

export const initialState: AnalyticsState = {
  amountPerMonth: [],
  countPerMonth: [],
  countPerStatus: {},
  kpisConfirmed: [],
  kpisRequested: [],
  countOpenedTask: 0,
  countConnectedCompanies: 0,
  countOrderLines: 0,
};

const analyticsReducer = createReducer<AnalyticsState, TypedAction<AnalyticsActionType>>(
  initialState,
  on(queryAmountPerMonth, state => {
    return { ...state, amountPerMonth: [] };
  }),
  on(queryCountPerMonth, state => {
    return { ...state, countPerMonth: [] };
  }),
  on(queryCountPerStatus, state => {
    return { ...state, countPerStatus: {} };
  }),
  on(queryKpisConfirmed, state => {
    return { ...state, kpisConfirmed: [] };
  }),
  on(queryAmountPerMonthSuccess, (state, { response: { data } }) => {
    return { ...state, amountPerMonth: data.slice() };
  }),
  on(queryCountPerMonthSuccess, (state, { response: { data } }) => {
    return { ...state, countPerMonth: data.slice() };
  }),
  on(queryCountPerStatusSuccess, (state, { response }) => {
    return { ...state, countPerStatus: response };
  }),
  on(queryKpisConfirmedSuccess, (state, { response: { data } }) => {
    return { ...state, kpisConfirmed: data.slice() };
  }),
  on(queryKpisRequested, state => {
    return { ...state, kpisRequested: [] };
  }),
  on(queryKpisRequestedSuccess, (state, { response: { data } }) => {
    return { ...state, kpisRequested: data.slice() };
  }),
  on(queryCountOrderLinesSuccess, (state, { value }) => {
    return { ...state, countOrderLines: value };
  }),
  on(queryCountTasksSuccess, (state, { value }) => {
    return { ...state, countOpenedTask: value };
  }),
  on(queryCountConnectionsSuccess, (state, { value }) => {
    return { ...state, countConnectedCompanies: value };
  }),
);

export function reducer(state = initialState, action: TypedAction<AnalyticsActionType>): AnalyticsState {
  return analyticsReducer(state, action);
}
