<mat-toolbar color="primary" class="tc-sticky-toolbar">
  <h1 i18n="@@forecast.page.title">Forecasts</h1>
</mat-toolbar>

<div class="tc-container">
  <mat-card fxLayout="row" class="card-filters" fxLayoutGap="40px">

    <mat-list class="control-wrap">
      <mat-list-item class="tc-upcase">
        <ng-container> {{isBuyer ? textSupplierRole : textBuyerRole }}</ng-container>
      </mat-list-item>
      <mat-list-item>
        <tc-forecast-select
          (connectionChange)="companyIdChanged($event)"
        ></tc-forecast-select>
      </mat-list-item>
    </mat-list>

    <mat-list class="control-wrap">
      <mat-list-item class="tc-upcase">Forecast number</mat-list-item>
      <tc-search-box
        class="search-box"
        [initialQuery]="forecastQueries"
        [expanding]="false"
        [placeholderLabel]="forecastNumberChangedPlaceholderLabel"
        (searchQueryChange)="forecastNumberChanged($event)"
      ></tc-search-box>
    </mat-list>


    <mat-list class="control-wrap">
      <mat-list-item class="tc-upcase">Item number</mat-list-item>
        <tc-search-box
          class="search-box"
          [expanding]="false"
          [placeholderLabel]="itemPlaceholderLabel"
          (searchQueryChange)="itemNumberChanged($event)"
        ></tc-search-box>
      </mat-list>
  </mat-card>
</div>

<div class="tc-container">
  <mat-card style="height: calc(100vh - 300px);">
    <ag-grid-angular
      style="width: 100%; height: 100%;"
      class="ag-theme-alpine"
      [columnDefs]="columnDefs"
      [defaultColDef]="defaultColDef"
      [colResizeDefault]="colResizeDefault"
      [rowBuffer]="rowBuffer"
      [rowSelection]="rowSelection"
      [rowModelType]="rowModelType"
      [cacheBlockSize]="cacheBlockSize"
      [cacheOverflowSize]="cacheOverflowSize"
      [maxConcurrentDatasourceRequests]="maxConcurrentDatasourceRequests"
      [infiniteInitialRowCount]="infiniteInitialRowCount"
      [maxBlocksInCache]="maxBlocksInCache"
      (gridReady)="onGridReady($event)"
    ></ag-grid-angular>
  </mat-card>
</div>
