<mat-list class="mat-list--properties mat-list--compact">
  <h3 mat-subheader class="mat-subheader--border">
    <ng-container *ngIf="order?.buyerOrder?.orderType === 'RFQ'; else noRfqType" >
      <ng-container i18n="@@order.order.details.title.rfq">RFQ details</ng-container>
    </ng-container>
    <ng-template #noRfqType>
      <ng-container i18n="@@order.order.details.title">Order details</ng-container>
    </ng-template>
  </h3>

  <mat-list-item class="auto-height">
    <div class="property" i18n="@@common.field.destination">Destination</div>
    <div class="value">
      <h4 matLine class="wrap" [class.loading-text]="!destination">
        {{ destination?.code }}
      </h4>
      <p matLine class="wrap" *ngFor="let name of destination?.names">
        {{ name }}
      </p>
      <p matLine class="wrap" *ngFor="let line of destination?.addressLines">
        {{ line }}
      </p>
      <p matLine class="wrap" *ngIf="destination?.postalCode || destination?.city">
        {{ destination?.postalCode }} {{ destination?.city }}
      </p>
      <p matLine class="wrap">
        {{ destination?.countryCodeIso2 }} {{ destination?.countryName }}
      </p>
      <p matLine class="wrap">
        {{ destination?.locationType }}
      </p>
    </div>
  </mat-list-item>

  <mat-list-item *ngIf="incotermsCode" class="auto-height">
    <div class="property" i18n="@@common.field.incoterms">Incoterms</div>
    <div class="value">
      <h4 matLine class="wrap">
        {{ incotermsCode | tcIncotermsName }}
      </h4>
      <p matLine class="wrap" *ngIf="incotermsLocationName">
        {{ incotermsLocationName }}
      </p>
      <p matLine class="wrap">
        {{ incoterms }}
      </p>
    </div>
  </mat-list-item>

  <mat-list-item *ngIf="paymentTermsCode" class="auto-height">
    <div class="property" i18n="@@common.field.payment.terms">Payment terms</div>
    <div class="value">
      <h4 matLine class="wrap">
        {{ paymentTermsCode }}
      </h4>
      <p matLine class="wrap" *ngIf="paymentTermsDescription">
        {{ paymentTermsDescription }}
      </p>
      <p matLine class="wrap">
        {{ paymentTerms }}
      </p>
    </div>
  </mat-list-item>

  <mat-list-item class="auto-height">
    <div class="property"
         i18n="@@common.field.amount"
         [matTooltip]="amountHint"
         matTooltipPosition="after"
         [matTooltipDisabled]="!!amountView"
    >Total amount</div>
    <div class="value">
      <h4 matLine class="wrap">
        <span [ngClass]="{'tc-change': amountChanged}">
          {{ amountView | tcMoney }}
          <tc-amount-difference
            [amount]="order?.totalAmount"
            [amountIncludingRequests]="order?.totalAmountIncludingRequests"
          ></tc-amount-difference>
        </span>
      </h4>
    </div>
  </mat-list-item>
</mat-list>
