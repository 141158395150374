import { MessageMeta } from '@app/shared/models';
import {
  BuyerLine,
  BuyerOrder,
  ConfirmedLine,
  CustomLabel,
  DocumentInterface,
  OrderLineRequest,
  SupplierOrder,
} from '@app/order/models';
import { SendMessage } from '@app/conversation/models';

export enum ActivityType {
  OrderChangesProposedBySupplier = 'order-changes-proposed-by-supplier',
  OrderLineChangesProposedBySupplier = 'order-line-changes-proposed-by-supplier',

  OrderCompletedByBuyer = 'order-lines-completed-by-buyer',
  OrderLineCompletedByBuyer = 'order-line-completed-by-buyer',

  OrderIssuedByBuyer = 'order-issued-by-buyer',
  OrderLineIssuedByBuyer = 'order-line-issued-by-buyer',

  OrderLineMarkedOpenByBuyer = 'order-line-marked-as-open-by-buyer',
  OrderLineMarkedOpenBySupplier = 'order-line-marked-as-open-by-supplier',
  OrderLineMarkedDeliveredByBuyer = 'order-line-marked-as-delivered-by-buyer',
  OrderLineMarkedDeliveredBySupplier = 'order-line-marked-as-delivered-by-supplier',

  OrderMarkedDeliveredByBuyer = 'order-marked-as-delivered-by-buyer',
  OrderMarkedDeliveredBySupplier = 'order-marked-as-delivered-by-supplier',
  OrderMarkedOpenByBuyer = 'order-marked-as-open-by-buyer',
  OrderMarkedOpenBySupplier = 'order-marked-as-open-by-supplier',

  OrderUpdatedByBuyer = 'order-updated-by-buyer',
  OrderLineUpdatedByBuyer = 'order-line-updated-by-buyer',

  OrderLineCancelledByBuyer = 'order-line-cancelled-by-buyer',
  OrderLinesCancelledByBuyer = 'order-lines-cancelled-by-buyer',

  OrderSynced = 'order-synced',
  OrderLineSynced = 'order-line-synced',

  OrderUpdatedBySupplier = 'order-updated-by-supplier',
  OrderLineUpdatedBySupplier = 'order-line-updated-by-supplier',

  OrderResentBySupplier = 'order-resent-by-supplier',
  OrderResentByBuyer = 'order-resent-by-buyer',

  OrderLineResentBySupplier = 'order-line-resent-by-supplier',
  OrderLineResentByBuyer = 'order-line-resent-by-buyer',

  OrderLinesAcceptedBySupplier = 'order-lines-accepted-by-supplier',
  OrderLineAcceptedBySupplier = 'order-line-accepted-by-supplier',

  OrderLinesRejectedBySupplier = 'order-lines-rejected-by-supplier',
  OrderLineRejectedBySupplier = 'order-line-rejected-by-supplier',

  OrderLinesReissuedByBuyer = 'order-lines-reissued-by-buyer',
  OrderLineReissuedByBuyer = 'order-line-reissued-by-buyer',

  OrderReopenedByBuyer = 'order-reopened-by-buyer',
  OrderLineReopenedByBuyer = 'order-line-reopened-by-buyer',

  OrderChangesProposalApprovedByBuyer = 'order-changes-proposal-approved-by-buyer',
  OrderLineChangesProposalApprovedByBuyer = 'order-line-changes-proposal-approved-by-buyer',

  OrderChangesProposalRejectedByBuyer = 'order-changes-proposal-rejected-by-buyer',
  OrderLineChangesProposalRejectedByBuyer = 'order-line-changes-proposal-rejected-by-buyer',

  OrderLineDeliveryHistoryAppendedByBuyer = 'order-line-delivery-history-appended-by-buyer',
  OrderLineConfirmedBySupplier = 'order-line-confirmed-by-supplier',
  OrderLineConfirmedByBuyer = 'order-line-confirmed-by-buyer',

  ConversationMessageAdded = 'conversation-message-added-view',
  ConversationInternalMessageAdded = 'conversation-internal-message-added',

  OrderDocumentAdded = 'order-document-added',
  OrderLineDocumentAdded = 'order-line-document-added',
  ShipmentDocumentAdded = 'shipment-document-added',

  CompletedOrderLineRevertedByBuyer = 'completed-order-line-reverted-by-buyer',

  OrderResponseMessageSentToBuyer = 'order-response-message-sent-to-buyer',
  OrderLineResponseMessageSentToBuyer = 'order-line-response-message-sent-to-buyer',

  OrderLineReopenByBuyer = 'order-lines-reopen-requested-by-buyer',
  OrderLineReopenApprovedBySupplier = 'order-lines-reopen-request-approved-by-supplier',
  OrderLineReopenRejectedBySupplier = 'order-lines-reopen-request-rejected-by-supplier',

  OrderLineReopenBySupplier = 'order-lines-reopen-requested-by-supplier',
  OrderLineReopenApprovedByBuyer = 'order-lines-reopen-request-approved-by-buyer',
  OrderLineReopenRejectedByBuyer = 'order-lines-reopen-request-rejected-by-buyer',
  OrderLineReopenRevertedByBuyer = 'order-line-reopen-request-reverted-by-buyer',
  OrderLineReconfirmationRequestByBuyer = 'order-line-reconfirmation-requested-by-buyer',
  OrderLineReconfirmedBySupplier = 'order-line-reconfirmed-by-supplier',

  OrderLineDeliveryScheduleLogisticsUpdatedByBuyer = 'order-line-delivery-schedule-logistics-updated-by-buyer',
  OrderLineDeliveryScheduleLogisticsUpdatedBySupplier = 'order-line-delivery-schedule-logistics-updated-by-supplier',

  OrderLineDeliveryOverdue = 'order-line-delivery-overdue',
  OrderLineShipmentApprovedByBuyer = 'order-line-shipment-approved-by-buyer',
  OrderLineShipmentIssuedBySupplier = 'order-line-shipment-issued-by-supplier',
  OrderLineShipmentReissuedBySupplier = 'order-line-shipment-reissued-by-supplier',
  OrderLineShipmentRejectedByBuyer = 'order-line-shipment-rejected-by-buyer',

  ShipmentDespatchedBySupplier = 'shipment-despatched-by-supplier',
  ShipmentResentByBuyer = 'shipment-resent-by-buyer',

  ShipmentReissueBySupplier = 'shipment-reissued-by-supplier',
  ShipmentIssueBySupplier = 'shipment-issued-by-supplier',
  ShipmentApprovedByBuyer = 'shipment-approved-by-buyer',
  ShipmentRejectedByBuyer = 'shipment-rejected-by-buyer',

  OrderCustomLabelsAddedByBuyer = 'order-custom-labels-added-by-buyer',
  OrderCustomLabelsAddedBySupplier = 'order-custom-labels-added-by-supplier',
  OrderCustomLabelsRemovedByBuyer = 'order-custom-labels-removed-by-buyer',
  OrderCustomLabelsRemovedBySupplier = 'order-custom-labels-removed-by-supplier',
  OrderLineCustomLabelsAddedByBuyer = 'order-line-custom-labels-added-by-buyer',
  OrderLineCustomLabelsAddedBySupplier = 'order-line-custom-labels-added-by-supplier',
  OrderLineCustomLabelsRemovedByBuyer = 'order-line-custom-labels-removed-by-buyer',
  OrderLineCustomLabelsRemovedBySupplier = 'order-line-custom-labels-removed-by-supplier',
}

export enum ActivityEntityType {
  Order = 'order',
  OrderLine = 'order-line',
  Conversation = 'conversation',
  Shipment = 'shipment',
  RFQ = 'rfq',
  RFQLine = 'rfq-line',
}

export interface ActivityContent {
  order: OrderView;
  line: ActivityContentLineView;
  conversation: ConversationView;
  documents: DocumentsView;
  shipment?: ShipmentContent;
}

export interface ConversationView {
  messageId: string;
  conversationId: Pick<SendMessage, 'entityType' | 'entityId'>;
  content: string;
  meta: MessageMeta;
}

export interface DocumentsView {
  orderId: string;
  buyerOrderDocument: Pick<BuyerOrder, 'companyId' | 'purchaseOrderNumber' | 'supplierAccountNumber'>;
  supplierOrderDocument: Pick<SupplierOrder, 'companyId' | 'buyerAccountNumber'>;
  documents: DocumentInterface[];
  lines: LineElement[];
}

export interface ShipmentContent {
  id: string;
  supplierShipment: SupplierShipmentContent;
  documents: DocumentInterface[];
}

export interface SupplierShipmentContent {
  shipmentNumber: string;
}

export interface LineElement {
  orderLineId: string;
  documents: DocumentInterface[];
  buyerLineDocument: Pick<BuyerLine, 'position'>;
  supplierLineDocument: SupplierLineDocument;
}

export interface SupplierLineDocument {
  salesOrderNumber: string;
  salesOrderPosition: string;
}

export interface ActivityContentLineView {
  message?: string;
  id: string;
  orderId: string;
  purchaseOrderNumber: string;
  supplierLine: SupplierLineView;
  buyerLine: Pick<
    BuyerLine,
    'position' | 'deliveryHistory' | 'deliverySchedule' | 'item' | 'prices' | 'requests' | 'customLabels'
  >;
  confirmedLine: ConfirmedLine;
}

export interface SupplierLineView {
  requests: {
    proposal: Pick<OrderLineRequest, 'deliverySchedule' | 'prices' | 'reason' | 'status'>;
    reopenRequest?: OrderLineRequest;
  };
  customLabels?: CustomLabel[];
}

export interface OrderView {
  id: string;
  legacyOrderId: string;
  buyerOrder: Pick<BuyerOrder, 'purchaseOrderNumber' | 'customLabels'>;
  supplierOrder: Pick<SupplierOrder, 'customLabels'>;
  message?: string;
}

export interface Activity {
  id: string;
  activityType: ActivityType;
  entityId: string;
  entityType: ActivityEntityType;
  createdAt: Date;
  userIds: string[];
  companyIds: string[];
  content: ActivityContent;
  meta: ActivityMeta;
}

export interface ActivityMeta {
  messageId: string;
  source: {
    traceId: string;
    userId: string;
    companyId: string;
  };
}
